import {
  REQUEST_FILTERS,
  RECEIVE_FILTERS,
  RECEIVE_CREATE_FILTER,
  RECEIVE_DELETE_FILTER,
} from './actions'
import React from 'react'

const initialState = {
  deviceType: '',
  isFetchingFilters: false,
  filters: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case REQUEST_FILTERS:
      return {
        ...state,
        deviceType: action.deviceType,
        isFetchingFilters: true,
      }
    case RECEIVE_FILTERS:
      return {
        ...state,
        deviceType: '',
        isFetchingFilters: false,
        filters: action.filters,
      }
    case RECEIVE_CREATE_FILTER:
      if (action.editId) {
        const index = state.filters.map((filter) => filter._id).indexOf(action.editId)
        if (index !== -1) {
          state.filters.splice(index, 1)
        }
      }
      state.filters.push(action.newFilter)
      return {
        ...state,
        filters: state.filters,
      }
    case RECEIVE_DELETE_FILTER:
      const filterId = action.filterToDeleteId
      const index = state.filters.map((filter) => filter._id).indexOf(filterId)
      if (index !== -1) {
        state.filters.splice(index, 1)
      }
      return {
        ...state,
        filters: state.filters,
      }
    default:
      return state
  }
}
