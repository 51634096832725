import React, { Fragment, useMemo } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { isLeasePassed, isretiredAvailableForUser, openInNewTab } from '../common/Utils'
import {
  callBuildATrainingBatch,
  exploreBatch,
  handleOpenLogs,
  handleRetiredBatch,
  handleStampBatch,
  handleUpdateBatch,
  openBatch,
  openBatchInNewTab,
  saveBatch,
} from '../backlog/Tools'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'
import Typography from '@material-ui/core/Typography'
import { t } from 'i18next'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Menu from '@material-ui/core/Menu'
import Divider from '@material-ui/core/Divider'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import SchoolIcon from '@material-ui/icons/School'
import { AZURE_ENV, FLASK_URL } from '../../utils/config'
import LoopIcon from '@material-ui/icons/Loop'
import ReplayIcon from '@material-ui/icons/Replay'
import Link from '@material-ui/core/Link/Link'
import { displayPopupMessage } from '../common/Message'
import { XLSFileInvert } from '../common/Style'
import ApprovalIcon from '@mui/icons-material/Approval'
import SearchIcon from '@material-ui/icons/Search'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TerminalIcon from '@mui/icons-material/Terminal'
import FolderDeleteIcon from '@mui/icons-material/FolderDelete'

const useStyles = makeStyles((theme) => ({
  loadingBatchIcon: {
    animation: 'MuiCircularProgress-keyframes-circular-rotate 0.5s linear infinite',
    animationDirection: 'reverse',
    fontSize: '2rem',
  },
  divider: {
    margin: '5px 0px',
  },
}))

const ExtraMenu = withRouter((props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { anchorEl, open, onClose, hideOpenBatch } = props

  const delta = useSelector((state) => state.settings.dateTimeDelta)

  const { anchor, id, row } = useMemo(() => {
    if (anchorEl) {
      const updatedRow = {
        ...anchorEl.row,
        lease:
          anchorEl.row.content.lease && typeof anchorEl.row.content.lease === 'number'
            ? { date: anchorEl.row.content.lease, operator: null, reason: null }
            : anchorEl.row.content.lease &&
              anchorEl.row.content.lease !== null &&
              anchorEl.row.content.lease !== undefined &&
              typeof anchorEl.row.content.lease === 'object'
            ? anchorEl.row.content.lease
            : { date: 0, operator: null, reason: null },
      }
      return { anchor: anchorEl.anchor, id: anchorEl.id, row: updatedRow }
    } else {
      return { anchor: null, id: null, row: null }
    }
  }, [anchorEl])

  return (
    <Menu
      anchorEl={anchor}
      getcontentanchorel={null}
      // keepMounted
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      {!hideOpenBatch && (
        <MenuItem
          disabled={
            (anchorEl &&
              !isLeasePassed(row.lease.date, delta) &&
              row.lease.reason === 'treatment') ||
            (anchorEl ? row.lockUpdate : true)
          }
          onClick={function () {
            openBatch(row, props.history)
            onClose()
          }}
        >
          <ListItemIcon>
            <OpenInBrowserIcon />
          </ListItemIcon>
          <Typography variant="inherit">{t('view.panel.batchesCmp.actions.open')}</Typography>
        </MenuItem>
      )}
      <MenuItem
        disabled={
          (anchorEl && !isLeasePassed(row.lease.date, delta) && row.lease.reason === 'treatment') ||
          (anchorEl ? row.lockUpdate : true)
        }
        onClick={() => {
          openBatchInNewTab(row)
          onClose()
        }}
      >
        <ListItemIcon>
          <OpenInNewIcon />
        </ListItemIcon>
        <Typography variant="inherit">{t('view.panel.batchesCmp.actions.openNew')}</Typography>
      </MenuItem>
      <Divider className={classes.divider} sx={{ my: 0.5 }} />
      <MenuItem
        disabled={
          (anchorEl && !isLeasePassed(row.lease.date, delta) && row.lease.reason === 'treatment') ||
          (anchorEl ? row.lockUpdate : true)
        }
        onClick={(event) => {
          // me.setState({ otherMenuEl: null })
          callBuildATrainingBatch(event, row, dispatch)
          onClose()
        }}
      >
        <ListItemIcon>
          <Fragment>
            <SchoolIcon />
          </Fragment>
        </ListItemIcon>
        <Typography variant="inherit">{t('view.backlog.menu.training')}</Typography>
      </MenuItem>
      {/*{AZURE_ENV ? null : (*/}
      <MenuItem
        disabled={
          (anchorEl && !isLeasePassed(row.lease.date, delta) && row.lease.reason === 'treatment') ||
          (anchorEl ? row.lockUpdate : true)
        }
        onClick={(event) => {
          // me.setState({ otherMenuEl: null })
          handleUpdateBatch(event, row, dispatch)
          onClose()
        }}
      >
        <ListItemIcon>
          {anchorEl && !isLeasePassed(row.lease.date, delta) && row.lease.reason === 'treatment' ? (
            <LoopIcon className={classes.loadingBatchIcon} />
          ) : (
            <ReplayIcon />
          )}
        </ListItemIcon>
        <Typography variant="inherit">{t('view.backlog.menu.replace')}</Typography>
      </MenuItem>
      {/*)}*/}

      <MenuItem
        disabled={
          (anchorEl && !isLeasePassed(row.lease.date, delta) && row.lease.reason === 'treatment') ||
          (anchorEl ? row.lockUpdate : true)
        }
      >
        <Link
          href={`${FLASK_URL()}/batch/${anchorEl ? row._id : ''}/reporting`}
          style={{ textDecoration: 'none !important', display: 'flex', padding: 0 }}
          color="inherit"
          onClick={() => {
            displayPopupMessage(
              dispatch,
              'info',
              t('view.backlog.data_extraction.title'),
              <Fragment>
                <div>
                  {t('view.backlog.data_extraction.title')} + +{anchorEl ? row._id : ''}
                </div>
              </Fragment>,
            )
            onClose()
          }}
        >
          <ListItemIcon>
            <XLSFileInvert style={{ width: 26, height: 21 }} className={classes.colorBlue} />
          </ListItemIcon>
          <Typography variant="inherit">{t('view.panel.batchesCmp.actions.download')}</Typography>
        </Link>
      </MenuItem>
      <MenuItem
        disabled={false}
        onClick={(event) => {
          handleStampBatch(event, row, dispatch)
          onClose()
        }}
      >
        <ListItemIcon>
          <ApprovalIcon />
        </ListItemIcon>
        <Typography variant="inherit">{t('view.backlog.menu.stamp')}</Typography>
      </MenuItem>
      {anchorEl && isretiredAvailableForUser(row) && (
        <MenuItem
          disabled={anchorEl ? row.retired : false}
          onClick={(event) => {
            handleRetiredBatch(event, row, dispatch)
            onClose()
          }}
        >
          <ListItemIcon>
            <FolderDeleteIcon />
          </ListItemIcon>
          <Typography variant="inherit">{t('view.backlog.menu.retired')}</Typography>
        </MenuItem>
      )}
      <Divider className={classes.divider} sx={{ my: 0.5 }} />
      <MenuItem
        onClick={function () {
          // handleOpenLogs(dispatch)
          openInNewTab(`${window.location.origin}${window.location.pathname}?logs/batch/${row._id}`)
          onClose()
        }}
      >
        <ListItemIcon>
          <TerminalIcon />
        </ListItemIcon>
        <Typography variant="inherit">{t('view.panel.batchesCmp.actions.logs')}</Typography>
      </MenuItem>
      <MenuItem
        onClick={function () {
          exploreBatch(row, dispatch)
          onClose()
        }}
      >
        <ListItemIcon>
          <SearchIcon />
        </ListItemIcon>
        <Typography variant="inherit">{t('view.panel.batchesCmp.actions.explore')}</Typography>
      </MenuItem>
      <MenuItem onClick={() => saveBatch(row)}>
        <ListItemIcon>
          <SaveAltIcon />
        </ListItemIcon>
        <Typography variant="inherit">{t('view.panel.batchesCmp.actions.save')}</Typography>
      </MenuItem>
    </Menu>
  )
})
export default ExtraMenu
