import React, { Fragment, useEffect, useState } from 'react'
import { StyledDialogTitle, TransitionTop } from '../common/Style'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { FormLabel, makeStyles, Radio, RadioGroup, TextField } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { DATABASE, FLASK_URL, PUBLIC_URL, RELEASE_VERSION_PATH, SERVICE } from '../../utils/config'
import { displayPopupMessage, generateErrorPart } from '../common/Message'
import FaceTwoToneIcon from '@material-ui/icons/FaceTwoTone'
import DnsTwoToneIcon from '@material-ui/icons/DnsTwoTone'
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone'
import ArchiveTwoToneIcon from '@material-ui/icons/ArchiveTwoTone'
import Tooltip from '@material-ui/core/Tooltip'
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone'
import KoIcon from '@material-ui/icons/Clear'
import OkIcon from '@material-ui/icons/Done'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import { fetchChangeSettings } from '../../redux/settings/actions'
import Box from '@material-ui/core/Box'
import CardMedia from '@material-ui/core/CardMedia'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { FormControlLabel } from '@mui/material'
import { formulas_dico } from '../common/Utils'
import { fetchAddTask, fetchFinishTask } from '../../redux/tasks/actions'
import { fetchMoveSampleToSubBatch } from '../../redux/batches/actions'
import { fetchUpdateAnalysesFormula } from '../../redux/analyses/actions'
import { t } from 'i18next'
import { Trans } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  informationContainer: {
    paddingLeft: 10,
    '& .MuiGrid-item': {
      paddingTop: 15,
    },
  },
  auditButton: {
    padding: 0,
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
    },
  },
  header: {
    boxShadow: 'inset 0px 0px 82px 10px rgba(0,0,0,1)',
  },
  cardImg: {
    opacity: 0.8,
  },
  titleCard: {
    position: 'absolute',
    top: 55,
    left: 34,
    fontSize: 40,
    color: theme.palette.secondary.main,
    textShadow: '2px 1px 10px white',
  },
}))

const formulas_dico_extern = Object.assign(
  {},
  ...Object.entries(formulas_dico)
    .filter((dico) => dico[1].origin === 'content')
    .map((a) => ({ [a[1].correspondence]: a[0] })),
)

export const convert_formulas = (analysis, formu, formulas_dico_updated) => {
  let result = formu
  result = result.replaceAll('CST', '1000')
  Object.keys(formulas_dico_updated).forEach((dico) => {
    result = result.replaceAll(dico, '#@#' + dico + '#@#')
  })
  result = result.split('#@#')
  return result.map((res) => {
    if (
      formulas_dico_updated[res] &&
      analysis[formulas_dico_updated[res].correspondence] !== null &&
      !isNaN(parseFloat(analysis[formulas_dico_updated[res].correspondence]))
    ) {
      return (
        <TextField
          style={{ margin: 5, width: 100 }}
          size="small"
          type="number"
          label={res}
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
          value={analysis[formulas_dico_updated[res].correspondence]}
          // onChange={(event) =>
          //   setTmpValues({
          //     ...tmpValues,
          //     [key]: {
          //       ...tmpValues[key],
          //       [res]: parseFloat(event.target.value),
          //     },
          //   })
          // }
        />
      )
    } else {
      return res
    }
  })
}

export default function FormulasCmp({ analyses, batch, open, closeFct, onSaveAnalyseValue }) {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [formulas, setFormulas] = useState(null)

  let formulas_dico_updated = { ...formulas_dico }
  delete formulas_dico_updated['PE']

  useEffect(
    function () {
      if (open) {
        setFormulas(null)
      }
    },
    [open],
  )

  const handleClose = (save) => {
    closeFct()
    if (save) {
      // Apply the formulas
      const [id, analysis_id] = formulas.split('#&#')
      if (id && analysis_id) {
        const analysis = analyses.filter((analysis) => analysis._id === analysis_id)[0]
        const mocSearched =
          analysis && analysis.content.formulas_infos_by_moc
            ? Object.entries(analysis.content.formulas_infos_by_moc).filter(
                (moc) => moc[1].filter((formula) => formula.id === parseFloat(id)).length === 1,
              )
            : null
        const formula =
          mocSearched && mocSearched[0] && mocSearched[0][1]
            ? mocSearched[0][1].filter((formula) => formula.id === parseFloat(id))[0]
            : null
        if (formula) {
          // Save formula
          displayPopupMessage(
            dispatch,
            'info',
            t('view.batch.formulasCmp.title'),
            <Fragment>
              <div>
                <Trans i18nKey="view.batch.formulasCmp.info">
                  The formula <i>{{ val: formula.resultat_calcule_sans_rdt }}</i> will be apply to
                  analyses:
                </Trans>
              </div>
              <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
                {analyses.map((item) => (
                  <Chip variant="outlined" size="small" style={{ margin: 3 }} label={item.name} />
                ))}
              </div>
              <div style={{ marginTop: 10 }}>
                <i>{t('view.batch.formulasCmp.time')}</i>
              </div>
            </Fragment>,
          )
          const taskId = new Date().getTime() + '_applyFormula_' + formula.id
          const taskSubBatch = {
            id: taskId,
            title: t('view.batch.formulasCmp.operator'),
            operator: localStorage.getItem('SESSION_user_name'),
            date: new Date().getTime(),
            percentage: 50,
            action: 'apply',
            state: { value: 'running', message: '' },
            operation: 'formula ' + formula.resultat_calcule_sans_rdt,
            items: analyses.map((orData) => ({
              id: orData._id,
              type: 'analysis',
              name: orData.name,
              batch: {
                id: orData.content.batch.id,
                name: orData.content.batch.name,
              },
            })),
          }
          dispatch(fetchAddTask(taskSubBatch))
          delete formula.analysis_id
          dispatch(fetchUpdateAnalysesFormula(batch._id, formula))
            .then(function (resp) {
              displayPopupMessage(
                dispatch,
                'success',
                t('view.batch.formulasCmp.title'),
                <Fragment>
                  <div>
                    <Trans i18nKey="view.batch.formulasCmp.info">
                      The formula <i>{{ val: formula.resultat_calcule_sans_rdt }}</i> is applied to
                      :
                    </Trans>
                  </div>
                  <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
                    {analyses.map((item) => (
                      <Chip
                        variant="outlined"
                        size="small"
                        style={{ margin: 3 }}
                        label={item.name}
                      />
                    ))}
                  </div>
                </Fragment>,
              )
              dispatch(fetchFinishTask(taskId, 'success', t('view.batch.formulasCmp.fine')))
            })
            .catch(function (error) {
              if (error && error.status !== undefined && error.status === 401) {
                error.statusText = t('view.common.utils.lease_locked.code')
                displayPopupMessage(
                  dispatch,
                  'warning',
                  t('view.batch.formulasCmp.title'),
                  <Fragment>
                    <div>
                      <Trans i18nKey="view.batch.formulasCmp.error">
                        Can not apply the new formula
                        <i>{{ val: formula.resultat_calcule_sans_rdt }}</i>:
                      </Trans>
                    </div>
                    {generateErrorPart(error)}
                  </Fragment>,
                )
              } else {
                displayPopupMessage(
                  dispatch,
                  'error',
                  t('view.batch.formulasCmp.title'),
                  <Fragment>
                    <div>
                      <Trans i18nKey="view.batch.formulasCmp.error">
                        Can not apply the new formula
                        <i>{{ val: formula.resultat_calcule_sans_rdt }}</i>:
                      </Trans>
                    </div>
                    {generateErrorPart(error)}
                  </Fragment>,
                )
              }
              dispatch(fetchFinishTask(taskId, 'error', error))
            })
        }
      }
    }
  }

  function handleFormulasChange(evt) {
    setFormulas(evt.target.value)
  }

  return (
    <Dialog
      open={open}
      // fullWidth={true}
      maxWidth={'md'}
      onClose={(event) => handleClose(false)}
      TransitionComponent={TransitionTop}
      aria-labelledby="dialog-reset-title"
    >
      <DialogTitle style={{ padding: 0 }}>
        <Box className={classes.header}>
          <CardMedia
            className={classes.cardImg}
            component="img"
            alt="header image"
            height="130"
            image={PUBLIC_URL + '/images/formulas.jpg'}
            title="Formulas"
          />
          <span className={classes.titleCard}>{t('view.batch.formulasCmp.mass')}</span>
        </Box>
      </DialogTitle>
      <DialogContent style={{ overflow: 'hidden', margin: '24px 10px 10px' }}>
        <Typography gutterBottom variant="h5" color="secondary">
          {t('view.batch.formulasCmp.choose')}
        </Typography>
      </DialogContent>
      <DialogContent style={{ margin: '0px 10px 10px' }}>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={formulas}
            onChange={handleFormulasChange}
          >
            {Object.entries(
              analyses
                .map((analyse) => {
                  let ret = Object.entries(
                    analyse.hasOwnProperty('formulas_infos_by_moc')
                      ? analyse.formulas_infos_by_moc
                      : {},
                  )
                  ret.forEach((r) =>
                    r[1].forEach((obj) => {
                      obj['analysis_id'] = analyse._id
                      Object.keys(formulas_dico_extern).forEach(
                        (key) => (obj[key] = analyse[key]),
                      )
                    }),
                  )
                  return ret
                })
                .flat()
                .map((moc) => ({ key: moc[0], value: moc[1] }))
                .reduce(function (acc, obj) {
                  var cle = obj['key']
                  if (!acc[cle]) {
                    acc[cle] = []
                  }
                  obj.value.forEach((val) => {
                    if (acc[cle].map((c) => c.id).indexOf(val.id) === -1) {
                      acc[cle].push(val)
                    }
                  })
                  return acc
                }, {}),
            ).map((moc, index) => (
              <Fragment>
                <FormLabel
                  style={{ margin: (index !== 0 ? '25px' : '0px') + ' 0px 5px' }}
                  id={'radio-buttons-group-' + moc[0]}
                >
                  {moc[0]}
                </FormLabel>
                {moc[1]
                  .filter((moc) => moc.hasOwnProperty('id'))
                  .map((f, index) => (
                    <FormControlLabel
                      value={f.id.toString() + '#&#' + f.analysis_id}
                      control={<Radio />}
                      label={
                        <Typography
                          component="div"
                          color="primary"
                          style={{ lineHeight: '48px', marginTop: 3 }}
                        >
                          {convert_formulas(f, f.resultat_calcule_sans_rdt, formulas_dico_updated)}
                        </Typography>
                      }
                    />
                  ))}
              </Fragment>
            ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={formulas === null}
          autoFocus
          onClick={(event) => handleClose(true)}
          color="primary"
        >
          {t('view.batch.formulasCmp.apply')}
        </Button>
        <Button onClick={(event) => handleClose(false)} color="primary">
          {t('view.batch.formulasCmp.close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
