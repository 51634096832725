import React from 'react'
import { withRouter } from 'react-router-dom'
import { DRAWER_WIDTH } from '../../utils/config'
import { Stack } from '@mui/material'

const EmptyAnalysis = withRouter((props) => {
  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
      style={{ height: 'calc(100% + 0px)', filter: 'blur(4px)' }}
    >
      <div
        style={{
          background: 'white',
          height: 'calc(100% + 26px)',
          width: localStorage.getItem('LAYOUT_first_secondaryPaneSize')
            ? parseFloat(localStorage.getItem('LAYOUT_first_secondaryPaneSize'))
            : DRAWER_WIDTH(),
          // boxShadow:
          //   '0px 2px 7px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        }}
      ></div>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        style={{ paddingRight: 13, width: '100%', height: '100%' }}
      >
        <div
          style={{
            width: '100%',
            background: 'white',
            height: 100,
            borderRadius: 4,
            // boxShadow:
            //   '0px 2px 7px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
          }}
        ></div>
        <div
          style={{
            width: '100%',
            background: 'white',
            height: 'calc(50% - 70px)',
            borderRadius: 4,
            // boxShadow:
            //   '0px 2px 7px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
          }}
        ></div>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          style={{
            width: '100%',
            height: 'calc(50% - 70px)',
          }}
        >
          <div
            style={{
              width: 'calc(60% - 10px)',
              background: 'white',
              height: '100%',
              borderRadius: 4,
              // boxShadow:
              //   '0px 2px 7px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
            }}
          ></div>
          <div
            style={{
              width: 'calc(40% - 10px)',
              background: 'white',
              height: '100%',
              borderRadius: 4,
              // boxShadow:
              //   '0px 2px 7px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
            }}
          ></div>
        </Stack>
      </Stack>
    </Stack>
  )
})
export default EmptyAnalysis
