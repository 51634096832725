import React, { Fragment, useMemo } from 'react'
import Paper from '@material-ui/core/Paper'
import { connect, useSelector } from 'react-redux'
import { fade, withStyles } from '@material-ui/core/styles'
import Title from '../Title'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import TableMultiGrid from '../common/Table_MultiGrid'
import clsx from 'clsx'
import {
  convertMassConcentrationValue,
  convertVolumeConcentrationValue,
  getAnalyseDemandeesTooltip,
  getClickAction,
  getComparator,
  handleValidation,
  stableSort,
} from '../common/Utils'
import { Molecule, Nothing, Relance, StyledDialogTitle, TransitionTop } from '../common/Style'
import { withRouter } from 'react-router-dom'
import { API_BO_URL, CONFIGURATION_PROFILE } from '../../utils/config'
import Tooltip from '@material-ui/core/Tooltip'
import AlphacodFlagIcon from '../common/AlphacodFlagIcon'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import Loading from '../Loading'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import TableContainer from '@material-ui/core/TableContainer'
import LinearProgress from '@material-ui/core/LinearProgress'
import Chip from '@material-ui/core/Chip'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import ArchiveIcon from '@material-ui/icons/Archive'
import NotesIcon from '@material-ui/icons/Notes'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import MetaboliteBadge from '../common/MetaboliteBadge'
import { displayPopupMessage } from '../common/Message'
import LimsBadge from './LimsBadge'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button/Button'
import ErrorIcon from '@material-ui/icons/Error'
import Badge from '@material-ui/core/Badge/Badge'
import { fetchAutoValidationLims, fetchIgnoreFlags } from '../../redux/lims/actions'
import PhotoFilterIcon from '@material-ui/icons/PhotoFilter'
import LimsAutoValidationConfirm from '../common/LimsAutoValidationConfirm'
import GC_Concentration_Badge from './GC_Concentration_Badge'
import { Checkbox, FormControlLabel, OutlinedInput } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { Stack } from '@mui/material'
import ListItemText from '@material-ui/core/ListItemText'
import ClearIcon from '@material-ui/icons/Clear'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined'
import { fetchFinishTask } from '../../redux/tasks/actions'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import { t } from 'i18next'
import { Trans } from 'react-i18next'

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    overflow: 'hidden',
  },
  tableContainer: {
    // height: 'calc(100% - 35px)',
    // overflow: 'hidden',
    '& .ReactVirtualized__Grid:focus': {
      outline: 'none',
    },
  },
  selected: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  notSelected: {
    // opacity: 0.5,
    // '&:hover': {
    //   opacity: 1,
    // },
  },
  selectedRow: {
    cursor: 'pointer',
    background: 'rgba(0, 0, 0, 0.1)',
  },
  cellFlags: {
    padding: 7,
  },
  tooltip: {
    fontSize: 13,
    padding: 5,
  },
  toolbarButton: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.03),
    marginRight: 5,
    width: 34,
    height: 34,
    padding: 8,
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: fade(theme.palette.common.black, 0.06),
    },
  },
  iconDisabled: {
    color: 'grey',
    opacity: 0.2,
  },
  colorDefault: {
    color: '#606060de',
  },
  expandButton: {
    padding: 15,
    borderRadius: 0,
    '&:hover': {
      background: 'none',
      color: theme.palette.secondary.main,
    },
  },
  dialogLims: {
    '& .MuiDialog-paperScrollPaper': {
      maxWidth: 'none !important',
    },
  },
  badgeLims: {
    '& .MuiBadge-badge': {
      width: 15,
      height: 15,
      padding: 0,
      minWidth: 0,
      right: 3,
      top: 5,
      color: fade(theme.palette.primary.main, 0.6),
      background: 'white',
      // boxShadow: '0px 2px 5px 1px rgba(0,0,0,0.15)',
      border: '1px solid lightgrey',
    },
  },
  iconLims: {
    fontSize: 17,
    position: 'relative',
    color: theme.palette.common.warning,
  },
  iconErrorLims: {
    fontSize: 17,
    position: 'relative',
    color: theme.palette.common.error,
  },
  valueError: {
    color: theme.palette.common.error,
  },
  exportCheckbox: {
    '& .MuiTypography-root': {
      fontSize: 14,
    },
    '& svg': {
      width: '0.8em',
    },
  },
  actionContainer: {
    '& button': {
      padding: 0,
      marginRight: 5,
      width: 24,
    },
    '& > button:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
    },
  },
  newInfo_high: {
    backgroundColor: '#ff00001f !important',
  },
  newInfo_middle: {
    backgroundColor: '#ff76001f !important',
  },
  newInfo_low: {
    backgroundColor: '#ffeb001f !important',
  },
})

const mapStateToProps = (state) => {
  const { lims, configuration } = state
  return {
    lims: lims,
    uaLims: lims.lims.unitaryAnalysesLims,
    isFetching: lims.isFetchingLims,
    configFeatures: configuration.configFeatures,
    isFetchingConfigFeatures: configuration.isFetchingConfigFeatures,
  }
}

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
})

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#fafafa',
    },
    '&:nth-of-type(even)': {
      backgroundColor: 'white',
    },
    '& .MuiTableCell-root': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      border: 'none',
    },
    '& .MuiTableCell-root button:hover': {
      backgroundColor: 'transparent',
    },
  },
}))(TableRow)

const StyledMainTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(4n+1)': {
      backgroundColor: '#fafafa',
    },
    '&:nth-of-type(4n+2)': {
      backgroundColor: '#fafafa',
    },
    '&:nth-of-type(2n+1)': {
      borderTop: '1px solid #e0e0e0',
    },
    '& .MuiTableCell-root': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      border: 'none',
    },
  },
}))(TableRow)

const BorderLinearProgress = withStyles({
  root: {
    height: 12,
    borderRadius: 10,
  },
  bar: {
    borderRadius: 10,
  },
})(LinearProgress)

function CustomRow(props) {
  const { row, defaultClasses, dispatch, openAutoValid, history } = props
  const [open, setOpen] = React.useState(false)
  const classes = useRowStyles()

  const concentration_report = useSelector(function (state) {
    let returnCR = 'mass'
    const config = state.configuration.configurations.filter(
      (config) => config._id === CONFIGURATION_PROFILE(),
    )[0]
    if (
      config &&
      config.content.analysis_parameters_by_analysis &&
      config.content.analysis_parameters_by_analysis.sample &&
      config.content.analysis_parameters_by_analysis.sample.formula_infos &&
      config.content.analysis_parameters_by_analysis.sample.formula_infos.unite
        .slice(-1)
        .toLowerCase() === 'l'
    ) {
      returnCR = 'vol'
    }
    return returnCR
  })

  const reportedConcentrationUnit = useMemo(() => {
    return concentration_report === 'vol'
      ? localStorage.getItem('SETTINGS_vol_reported_concentration_unit')
        ? localStorage.getItem('SETTINGS_vol_reported_concentration_unit')
        : 'µg/l'
      : localStorage.getItem('SETTINGS_mass_reported_concentration_unit')
      ? localStorage.getItem('SETTINGS_mass_reported_concentration_unit')
      : 'mg/kg'
  }, [concentration_report])

  const volumeConcentrationUnit = localStorage.getItem('SETTINGS_volume_concentration_unit')
    ? localStorage.getItem('SETTINGS_volume_concentration_unit')
    : 'µg/l'

  const configFeatures = useSelector(function (state) {
    return state.configuration.configFeatures
  })

  function handleDoubleValidation(event, limsRow) {
    dispatch(fetchIgnoreFlags(limsRow))
      .then(function (resp) {
        displayPopupMessage(
          dispatch,
          'success',
          t('view.batch.limsTable.double_validation_title'),
          <Fragment>{t('view.batch.limsTable.double_validation_ok')}.</Fragment>,
        )
      })
      .catch(function (error) {
        if (error && error.code !== undefined && error.code === 554) {
          displayPopupMessage(
            dispatch,
            'warning',
            t('view.batch.limsTable.double_validation_title'),
            <Fragment>
              <div>{t('view.batch.limsTable.double_validation_ko')}:</div>
              <div>{error.toString()}</div>
            </Fragment>,
          )
        } else {
          displayPopupMessage(
            dispatch,
            'error',
            t('view.batch.limsTable.double_validation_title'),
            <Fragment>
              <div>{t('view.batch.limsTable.double_validation_ko')}:</div>
              <div>{error.toString()}</div>
            </Fragment>,
          )
        }
      })
  }

  return (
    <Fragment>
      <StyledMainTableRow className={classes.root}>
        <TableCell style={{ padding: 0 }}>
          {/*[#507] Add condition to display the expand button only if there is a Lims UA or a Lims somme*/}
          {((row.limsSomme && row.limsSomme.length > 0) || row.limsUA) &&
            (row.limsUA.some((lua) => lua.validation === '1') ||
              row.limsUA
                .map((lua) => lua?.flags?.globals)
                .flat()
                .some((item) => item.blocking === true)) && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
                className={defaultClasses.expandButton}
              >
                <Badge
                  badgeContent={
                    row.limsUA
                      .map((lua) => lua?.flags?.globals)
                      .flat()
                      .some((item) => item.blocking === true && item.ignore === false) ? (
                      <Tooltip
                        key={'tooltip_blocking'}
                        placement="right"
                        arrow
                        title={
                          <div style={{ fontSize: 12, padding: 10 }}>
                            {t('view.batch.limsTable.untreated_blocking_flag')}.
                          </div>
                        }
                      >
                        <ErrorIcon className={defaultClasses.iconLims} />
                      </Tooltip>
                    ) : (
                      0
                    )
                  }
                >
                  <KeyboardArrowRightIcon
                    style={{
                      transform: `rotate(${open ? 90 : 0}deg)`,
                      transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    }}
                  />
                </Badge>
              </IconButton>
            )}
        </TableCell>
        <TableCell component="th" scope="row">
          <ErrorBoundaryGuard isDialog>
            <LimsBadge analysis={row} column={'name'} />
          </ErrorBoundaryGuard>
        </TableCell>
        <TableCell align="right">{row.content.pesee}</TableCell>
        <TableCell align="right">{row.content.formula_infos.moc}</TableCell>
        {/* <TableCell align="right">{row.content.sm_concentration}</TableCell> */}
        {/* <TableCell align="right">{row.content.formula_infos.facteur_dilution}</TableCell> */}
        {/* <TableCell align="right">{row.content.formula_infos.vol_sol_ext}</TableCell> */}
        <TableCell component="th" scope="row">
          <ErrorBoundaryGuard isDialog>
            <LimsBadge analysis={row} column={'progression'} />
          </ErrorBoundaryGuard>
        </TableCell>
        <TableCell align="right">
          <Tooltip
            arrow
            title={
              row.infos && row.infos.analyses_demandees ? (
                <div style={{ fontSize: 13, padding: 5 }}>
                  {getAnalyseDemandeesTooltip(row.infos.analyses_demandees)}
                </div>
              ) : (
                ''
              )
            }
          >
            <ArchiveIcon
              style={{ marginRight: 0 }}
              className={
                row.infos && row.infos.analyses_demandees
                  ? defaultClasses.colorDefault
                  : defaultClasses.iconDisabled
              }
            />
          </Tooltip>
          <Tooltip
            arrow
            title={
              row.infos && row.infos.remarques.trim() !== '' ? (
                <div style={{ fontSize: 13, padding: 5 }}>{row.infos && row.infos.remarques}</div>
              ) : (
                ''
              )
            }
          >
            <NotesIcon
              style={{ marginRight: 0 }}
              className={
                row.infos && row.infos.remarques && row.infos.remarques.trim() !== ''
                  ? defaultClasses.colorDefault
                  : defaultClasses.iconDisabled
              }
            />
          </Tooltip>
          <Tooltip
            arrow
            title={
              <div style={{ fontSize: 13, padding: 5 }}>
                {t('view.batch.limsTable.auto_complete')}
              </div>
            }
          >
            <IconButton
              disabled={row.content.progression_requested === 100}
              style={{ padding: 0, top: -8 }}
              className={defaultClasses.colorDefault}
              aria-label="autoValidation"
              onClick={() => {
                dispatch(fetchAutoValidationLims(row._id))
                // this.setState({ openAutoValidation: true })
                openAutoValid()
              }}
            >
              <PhotoFilterIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </StyledMainTableRow>
      <StyledMainTableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 'none' }} colSpan={8}>
          {/*[#507] Add condition to display the molecule table / somme only if there is a Lims UA or a Lims somme*/}
          {((row.limsSomme && row.limsSomme.length > 0) || row.limsUA) &&
            (row.limsUA.some((lua) => lua.validation === '1') ||
              row.limsUA
                .map((lua) => lua?.flags?.globals)
                .flat()
                .some((item) => item.blocking === true)) && (
              <Collapse in={open} timeout="auto" unmountOnExit style={{ paddingLeft: 30 }}>
                <Box margin={1}>
                  <Typography gutterBottom component="div" color="secondary">
                    {t('view.batch.limsTable.analysis_detail')}
                  </Typography>
                  <Table
                    size="small"
                    aria-label="purchases"
                    style={{ border: '1px dashed #e0e0e0' }}
                  >
                    <TableHead>
                      <TableRow style={{ backgroundColor: 'white' }}>
                        <TableCell>{t('view.batch.limsTable.flags')}</TableCell>
                        <TableCell>{t('view.batch.limsTable.mol')}</TableCell>
                        <TableCell>{t('view.batch.limsTable.state')}</TableCell>
                        <TableCell>{t('view.batch.limsTable.homologation')}</TableCell>
                        {configFeatures &&
                          typeof configFeatures.lmr === 'boolean' &&
                          configFeatures.lmr && (
                            <TableCell>
                              <Trans i18nKey="view.batch.limsTable.eu_lmr">
                                EU LMR ({{ val: reportedConcentrationUnit }})
                              </Trans>
                            </TableCell>
                          )}
                        <TableCell align="right">
                          <Trans i18nKey="view.batch.limsTable.conc">
                            Conc reported ({{ val: reportedConcentrationUnit }})
                          </Trans>
                        </TableCell>
                        <TableCell align="right">
                          <Trans i18nKey="view.batch.limsTable.conc_rdt">
                            Conc RDT ({{ val: reportedConcentrationUnit }})
                          </Trans>
                        </TableCell>
                        <TableCell align="right">
                          <Trans i18nKey="view.batch.limsTable.conc_report">
                            Conc final ({{ val: reportedConcentrationUnit }})
                          </Trans>
                        </TableCell>
                        <TableCell align="right">{t('view.batch.limsTable.rdt')}</TableCell>
                        <TableCell align="right">
                          <Trans i18nKey="view.batch.limsTable.conc_vol">
                            Conc inj ({{ val: volumeConcentrationUnit }})
                          </Trans>
                        </TableCell>
                        <TableCell align="right">
                          <Trans i18nKey="view.batch.limsTable.ld">
                            LD ({{ val: reportedConcentrationUnit }})
                          </Trans>
                        </TableCell>
                        <TableCell align="right">
                          <Trans i18nKey="view.batch.limsTable.lq">
                            LQ ({{ val: reportedConcentrationUnit }})
                          </Trans>
                        </TableCell>
                        <TableCell align="center">{t('view.batch.limsTable.reported')}</TableCell>
                        <TableCell align="center">2nd Validation</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.limsUA &&
                        row.limsUA
                          .filter(
                            (lua) =>
                              lua.validation === '1' ||
                              lua?.flags?.globals?.some((item) => item.blocking === true),
                          )
                          .map((limsRow) => (
                            <StyledTableRow
                              key={limsRow._id}
                              className={clsx(
                                limsRow.extraFlags
                                  ? defaultClasses['newInfo_' + limsRow.extraFlags.severity]
                                  : null,
                              )}
                            >
                              {/* Flags column */}
                              <TableCell component="th" scope="row">
                                <ErrorBoundaryGuard isDialog>
                                  <AlphacodFlagIcon item={limsRow} hideGeneralTab={true} />
                                </ErrorBoundaryGuard>
                              </TableCell>
                              {/* Molecule column */}
                              {limsRow.molecule && (
                                <TableCell>
                                  <ErrorBoundaryGuard isDialog>
                                    <MetaboliteBadge molecule={limsRow.molecule} />
                                  </ErrorBoundaryGuard>
                                </TableCell>
                              )}
                              {/* Status column */}
                              <TableCell>{limsRow.status}</TableCell>
                              {/* Homologation column */}
                              <TableCell>{limsRow.homologation}</TableCell>
                              {/* EU LMR column */}
                              {configFeatures &&
                                typeof configFeatures.lmr === 'boolean' &&
                                configFeatures.lmr && (
                                  <TableCell align="right">
                                    {limsRow.molecule &&
                                    !limsRow.molecule.res_sum &&
                                    limsRow.molecule.eu_lmr !== undefined &&
                                    limsRow.molecule.eu_lmr !== null ? (
                                      !isNaN(parseFloat(limsRow.molecule.eu_lmr)) &&
                                      isFinite(limsRow.molecule.eu_lmr) ? (
                                        <Tooltip
                                          key={'tooltip_lmr'}
                                          arrow
                                          title={
                                            <div style={{ fontSize: 13, padding: 5 }}>
                                              <Trans i18nKey="view.batch.limsTable.lmr_lower">
                                                LMR is lower than mass concentration (
                                                {{
                                                  val:
                                                    convertMassConcentrationValue(
                                                      limsRow.mass_conc,
                                                      limsRow,
                                                      null,
                                                      concentration_report,
                                                    ) +
                                                    ' ' +
                                                    reportedConcentrationUnit,
                                                }}
                                                )
                                              </Trans>
                                            </div>
                                          }
                                        >
                                          <span
                                            className={
                                              parseFloat(limsRow.molecule.eu_lmr) <=
                                              limsRow.mass_conc
                                                ? clsx(defaultClasses.valueError)
                                                : ''
                                            }
                                          >
                                            {convertMassConcentrationValue(
                                              parseFloat(limsRow.molecule.eu_lmr),
                                              limsRow,
                                              null,
                                              concentration_report,
                                            ).toFixed(
                                              Math.max(
                                                0,
                                                4 -
                                                  parseInt(
                                                    convertMassConcentrationValue(
                                                      parseFloat(limsRow.molecule.eu_lmr),
                                                      limsRow,
                                                      null,
                                                      concentration_report,
                                                    ),
                                                  ).toString().length,
                                              ),
                                            )}
                                          </span>
                                        </Tooltip>
                                      ) : (
                                        limsRow.molecule.eu_lmr
                                      )
                                    ) : (
                                      ''
                                    )}
                                  </TableCell>
                                )}
                              {/* COnc reported column */}
                              <TableCell align="right">
                                {limsRow.mass_conc !== '' && limsRow.mass_conc !== null
                                  ? convertMassConcentrationValue(
                                      limsRow.mass_conc,
                                      limsRow,
                                      null,
                                      concentration_report,
                                    ).toFixed(
                                      Math.max(
                                        0,
                                        4 -
                                          parseInt(
                                            convertMassConcentrationValue(
                                              limsRow.mass_conc,
                                              limsRow,
                                              null,
                                              concentration_report,
                                            ),
                                          ).toString().length,
                                      ),
                                    )
                                  : limsRow.mass_conc}
                              </TableCell>
                              {/* Conc RDT column */}
                              <TableCell align="right">
                                {limsRow.mass_conc_rdt !== '' && limsRow.mass_conc_rdt !== null
                                  ? convertMassConcentrationValue(
                                      limsRow.mass_conc_rdt,
                                      limsRow,
                                      null,
                                      concentration_report,
                                    ).toFixed(
                                      Math.max(
                                        0,
                                        4 -
                                          parseInt(
                                            convertMassConcentrationValue(
                                              limsRow.mass_conc_rdt,
                                              limsRow,
                                              null,
                                              concentration_report,
                                            ),
                                          ).toString().length,
                                      ),
                                    )
                                  : undefined}
                              </TableCell>
                              {/* Conc final column */}
                              <TableCell align="right">
                                {limsRow.mass_conc !== undefined &&
                                limsRow.mass_conc !== null &&
                                limsRow.mass_conc !== '' &&
                                limsRow.molecule !== undefined &&
                                limsRow.molecule &&
                                limsRow.molecule.res_meta !== undefined &&
                                limsRow.molecule.res_meta &&
                                limsRow.molecule.res_meta !== null &&
                                limsRow.molecule.res_meta !== ''
                                  ? convertMassConcentrationValue(
                                      limsRow.molecule.res_meta,
                                      limsRow,
                                      null,
                                      concentration_report,
                                    ).toFixed(
                                      Math.max(
                                        0,
                                        4 - parseInt(limsRow.molecule.res_meta).toString().length,
                                      ),
                                    )
                                  : limsRow.reported_conc !== undefined &&
                                    limsRow.reported_conc !== null &&
                                    limsRow.reported_conc !== ''
                                  ? convertMassConcentrationValue(
                                      limsRow.reported_conc,
                                      limsRow,
                                      null,
                                      concentration_report,
                                    ).toFixed(
                                      Math.max(
                                        0,
                                        4 - parseInt(limsRow.reported_conc).toString().length,
                                      ),
                                    )
                                  : ''}
                              </TableCell>
                              {/* RDT column */}
                              <TableCell align="right">{limsRow.rdt}</TableCell>
                              {/* Conc inj column */}
                              <TableCell align="right">
                                <ErrorBoundaryGuard isDialog>
                                  <GC_Concentration_Badge
                                    row={limsRow}
                                    factor={
                                      limsRow.factor !== undefined &&
                                      limsRow.factor !== null &&
                                      limsRow.factor !== ''
                                        ? limsRow.factor
                                        : undefined
                                    }
                                    concentration={
                                      limsRow.amt_conc !== '' && limsRow.amt_conc !== null
                                        ? convertVolumeConcentrationValue(
                                            limsRow.amt_conc,
                                            limsRow,
                                          ).toFixed(
                                            Math.max(
                                              0,
                                              4 -
                                                parseInt(
                                                  convertVolumeConcentrationValue(
                                                    limsRow.amt_conc,
                                                    limsRow,
                                                  ),
                                                ).toString().length,
                                            ),
                                          )
                                        : limsRow.amt_conc
                                    }
                                  />
                                </ErrorBoundaryGuard>
                              </TableCell>
                              {/* LD column */}
                              <TableCell align="right">
                                {limsRow.lod !== '' && limsRow.lod !== null
                                  ? convertMassConcentrationValue(
                                      limsRow.lod,
                                      limsRow,
                                      null,
                                      concentration_report,
                                    ).toFixed(
                                      Math.max(
                                        0,
                                        4 -
                                          parseInt(
                                            convertMassConcentrationValue(
                                              limsRow.lod,
                                              limsRow,
                                              null,
                                              concentration_report,
                                            ),
                                          ).toString().length,
                                      ),
                                    )
                                  : limsRow.lod}
                              </TableCell>
                              {/* LQ column */}
                              <TableCell align="right">
                                {limsRow.use_loq_default && limsRow.use_loq_default === true ? (
                                  <Tooltip
                                    key={'tooltip_loq'}
                                    arrow
                                    title={
                                      <div
                                        style={{
                                          fontSize: 13,
                                          padding: 5,
                                        }}
                                      >
                                        {t('view.batch.limsTable.default_lq')}
                                      </div>
                                    }
                                  >
                                    <div className={defaultClasses.valueError}>
                                      {limsRow.loq !== '' && limsRow.loq !== null
                                        ? convertMassConcentrationValue(
                                            limsRow.loq,
                                            limsRow,
                                            null,
                                            concentration_report,
                                          ).toFixed(
                                            Math.max(
                                              0,
                                              4 -
                                                parseInt(
                                                  convertMassConcentrationValue(
                                                    limsRow.loq,
                                                    limsRow,
                                                    null,
                                                    concentration_report,
                                                  ),
                                                ).toString().length,
                                            ),
                                          )
                                        : limsRow.loq}
                                    </div>
                                  </Tooltip>
                                ) : (
                                  <Fragment>
                                    {limsRow.loq !== '' && limsRow.loq !== null
                                      ? convertMassConcentrationValue(
                                          limsRow.loq,
                                          limsRow,
                                          null,
                                          concentration_report,
                                        ).toFixed(
                                          Math.max(
                                            0,
                                            4 -
                                              parseInt(
                                                convertMassConcentrationValue(
                                                  limsRow.loq,
                                                  limsRow,
                                                  null,
                                                  concentration_report,
                                                ),
                                              ).toString().length,
                                          ),
                                        )
                                      : limsRow.loq}
                                  </Fragment>
                                )}
                              </TableCell>
                              {/* Reported column */}
                              <TableCell align="center">
                                {limsRow.reported === 'True' ? (
                                  <CheckIcon />
                                ) : limsRow.reported_msg && limsRow.reported_msg !== '' ? (
                                  <Tooltip
                                    key={'tooltip_reported'}
                                    arrow
                                    title={
                                      <div style={{ fontSize: 13, padding: 5 }}>
                                        {limsRow.reported_msg}
                                      </div>
                                    }
                                  >
                                    <CloseIcon />
                                  </Tooltip>
                                ) : (
                                  ''
                                )}
                              </TableCell>
                              {/* 2nd validation column */}
                              <TableCell align="center">
                                {limsRow.extraFlags ? (
                                  <div className={defaultClasses.actionContainer}>
                                    <Tooltip
                                      arrow
                                      placement={'top'}
                                      title={
                                        <div style={{ fontSize: 13, padding: 5 }}>
                                          {t('view.batch.limsTable.go_to_ua')}
                                        </div>
                                      }
                                    >
                                      <IconButton
                                        onClick={() => {
                                          const to = `/?${limsRow.batchId}/compound/${limsRow.event}`
                                          history.push(to)
                                        }}
                                      >
                                        <ExitToAppIcon className={defaultClasses.notSelected} />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                      arrow
                                      placement={'top'}
                                      title={
                                        <div style={{ fontSize: 13, padding: 5 }}>
                                          {t('view.batch.limsTable.double_validation')}
                                        </div>
                                      }
                                    >
                                      <IconButton
                                        disabled={limsRow.extraFlags.ignored}
                                        aria-label="notDetected"
                                        onClick={(event) => handleDoubleValidation(event, limsRow)}
                                      >
                                        <BeenhereOutlinedIcon
                                          className={
                                            limsRow.extraFlags.ignored
                                              ? defaultClasses.selected
                                              : defaultClasses.notSelected
                                          }
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                ) : (
                                  ''
                                )}
                              </TableCell>
                            </StyledTableRow>
                          ))}
                    </TableBody>
                  </Table>
                </Box>
                {row.limsSomme && (
                  <Box margin={1}>
                    <Table
                      size="small"
                      aria-label="purchases"
                      style={{ border: '1px dashed #e0e0e0' }}
                    >
                      <TableHead>
                        <TableRow style={{ backgroundColor: 'white' }}>
                          <TableCell>{t('view.batch.limsTable.mol_somme')}</TableCell>
                          {configFeatures &&
                            typeof configFeatures.lmr === 'boolean' &&
                            configFeatures.lmr && (
                              <TableCell align="right">
                                <Trans i18nKey="view.batch.limsTable.eu_lmr">
                                  EU LMR ({{ val: reportedConcentrationUnit }})
                                </Trans>
                              </TableCell>
                            )}
                          {/*<TableCell>Coeff</TableCell>*/}
                          {/*<TableCell align="right">Conc(mg/kg)</TableCell>*/}
                          <TableCell align="right">
                            <Trans i18nKey="view.batch.limsTable.calculated_conc">
                              Calculated Concentration ({{ val: reportedConcentrationUnit }})
                            </Trans>
                          </TableCell>
                          {/*<TableCell align="center">Reported</TableCell>*/}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {row.limsSomme &&
                          row.limsSomme.map((limsRow) => (
                            <StyledTableRow key={limsRow.name}>
                              {/*<TableCell>{limsRow.reason}</TableCell>*/}
                              <TableCell component="th" scope="row">
                                {limsRow.name}{' '}
                              </TableCell>
                              {configFeatures &&
                                typeof configFeatures.lmr === 'boolean' &&
                                configFeatures.lmr && (
                                  <TableCell component="th" scope="row" align="right">
                                    {limsRow.eu_lmr !== null ? (
                                      !isNaN(parseFloat(limsRow.eu_lmr)) &&
                                      isFinite(limsRow.eu_lmr) ? (
                                        <Tooltip
                                          key={'tooltip_lmr'}
                                          arrow
                                          title={
                                            <div style={{ fontSize: 13, padding: 5 }}>
                                              <Trans i18nKey="view.batch.limsTable.lmr_lower">
                                                LMR is lower than mass concentration (
                                                {{
                                                  val:
                                                    convertMassConcentrationValue(
                                                      limsRow.reported_conc,
                                                      limsRow,
                                                      null,
                                                      concentration_report,
                                                    ) +
                                                    ' ' +
                                                    reportedConcentrationUnit,
                                                }}
                                                )
                                              </Trans>
                                            </div>
                                          }
                                        >
                                          <span
                                            className={
                                              parseFloat(limsRow.eu_lmr) <= limsRow.reported_conc
                                                ? clsx(defaultClasses.valueError)
                                                : ''
                                            }
                                          >
                                            {convertMassConcentrationValue(
                                              parseFloat(limsRow.eu_lmr),
                                              limsRow,
                                              null,
                                              concentration_report,
                                            ).toFixed(
                                              Math.max(
                                                0,
                                                4 -
                                                  parseInt(
                                                    convertMassConcentrationValue(
                                                      parseFloat(limsRow.eu_lmr),
                                                      limsRow,
                                                      null,
                                                      concentration_report,
                                                    ),
                                                  ).toString().length,
                                              ),
                                            )}
                                          </span>
                                        </Tooltip>
                                      ) : (
                                        limsRow.eu_lmr
                                      )
                                    ) : (
                                      ''
                                    )}
                                  </TableCell>
                                )}

                              {/*<TableCell align="right">*/}
                              {/*{limsRow.coeff_str.split(';;').map((s, index) => (*/}
                              {/*<Fragment key={index}> {s} <br />  </Fragment>*/}
                              {/*))}*/}
                              {/*</TableCell>*/}
                              <TableCell align="right">
                                {limsRow.reported_conc !== undefined &&
                                limsRow.reported_conc !== null &&
                                limsRow.reported_conc !== ''
                                  ? !isNaN(parseFloat(limsRow.reported_conc)) &&
                                    isFinite(limsRow.reported_conc)
                                    ? convertMassConcentrationValue(
                                        parseFloat(limsRow.reported_conc),
                                        limsRow,
                                        null,
                                        concentration_report,
                                      ).toFixed(
                                        Math.max(
                                          0,
                                          4 -
                                            parseInt(
                                              convertMassConcentrationValue(
                                                parseFloat(limsRow.reported_conc),
                                                limsRow,
                                                null,
                                                concentration_report,
                                              ),
                                            ).toString().length,
                                        ),
                                      )
                                    : limsRow.reported_conc
                                  : ''}
                              </TableCell>
                              {/*<TableCell align="center">*/}
                              {/*{limsRow.reported !== undefined && limsRow.reported === true ? (*/}
                              {/*<CheckIcon />*/}
                              {/*) : (*/}
                              {/*''*/}
                              {/*)}*/}
                              {/*</TableCell>*/}
                            </StyledTableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </Box>
                )}
              </Collapse>
            )}
        </TableCell>
      </StyledMainTableRow>
    </Fragment>
  )
}

export default withRouter(
  connect(mapStateToProps)(
    withStyles(styles)(
      class LimsTable extends React.Component {
        constructor(props) {
          super(props)
          this.state = {
            error: null,
            isLoaded: false,
            echClient: [],
            analyses: null,
            selection: [],
            openAutoValidation: false,
            exportResults: true,
            exportLMR: false,
            mocFilters: [],
          }
          this.onSelect = this.onSelect.bind(this)
          this.multiValidate = this.multiValidate.bind(this)
        }

        async componentDidMount() {
          const { uaLims } = this.props

          this.setState({
            echClient: uaLims,
          })
        }

        onSelect(selected) {
          this.setState({
            selection: selected.map((i) => this.state.echClient[i]),
          })
        }

        multiValidate() {
          const selection = this.state.selection
          const dispatch = this.props.dispatch
          displayPopupMessage(
            dispatch,
            'warning',
            t('view.batch.limsTable.send_lims'),
            <Fragment>
              <div>{t('view.batch.limsTable.send_lims_msg')}</div>
              <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
                {selection.map((item) => (
                  <Chip variant="outlined" size="small" style={{ margin: 3 }} label={item.name} />
                ))}
              </div>
              <div style={{ marginTop: 10 }}>{t('view.batch.limsTable.not_implemented')}</div>
            </Fragment>,
          )
        }

        handleCloseAutoValidation(event, scope) {
          // const { dispatch, batch } = scope.props
          scope.setState({ openAutoValidation: false })
        }

        render() {
          const {
            classes,
            dispatch,
            uaLims,
            isFetching,
            open,
            closeFct,
            configFeatures,
            history,
          } = this.props
          const { echClient, isLoaded, openAutoValidation, mocFilters } = this.state
          const uniqueMocList = [
            ...new Set(
              uaLims
                .map((ua) => ua.limsUA)
                .flat()
                .filter(
                  (ua) =>
                    (ua.validation === '1' || ua.validation === '2') && typeof ua.moc === 'string',
                )
                .map((ua) => ua.moc),
            ),
          ]
          const uaLimsFiltered = uaLims.map((ua) => ({
            ...ua,
            limsUA:
              mocFilters.length > 0
                ? ua.limsUA.filter((lua) => mocFilters.indexOf(lua.moc) > -1)
                : ua.limsUA,
          }))

          const onDisplayClient = (id) => {
            // No more
            // this.props.history.push('/?' + this.props.match.params.batchId + '/analysis/' + id)
          }

          const selectionEmpty = this.state.selection.length === 0

          const isEmptyEmail = JSON.parse(localStorage.getItem('SESSION_user_json'))
            ? JSON.parse(localStorage.getItem('SESSION_user_json')).email === ''
            : true
          const emptyMailMsg = (
            <div style={{ fontSize: 12, padding: 10, fontWeight: 'bold' }}>
              {/*Export is disabled because your account has no email. Please contact your
              administrator.*/}
              {t('view.batch.limsTable.export_disabled')}
            </div>
          )

          const isISTDNotValidated = !uaLims
            .map((lims) => lims.istd)
            .every((lims) => lims.relance === 0 && lims.none === 0 && lims.ko === 0)
          const ISTDNotValidatedMsg = (
            <div style={{ fontSize: 12, padding: 10 }}>
              {/*Some analyses do not have all their ISTDs validated in OK.*/}
              {t('view.batch.limsTable.istd_not_valid')}
            </div>
          )

          const isProgressionNotComplete = !uaLims.every(
            (lims) => lims.content.progression_requested === 100,
          )
          const progressionNotCompleteMsg = (
            <div style={{ fontSize: 12, padding: 10 }}>
              {/*Some analyses do not have the progression complete.*/}
              {t('view.batch.limsTable.permission')}
            </div>
          )

          const isUntreatedBlockingFLags = uaLims
            .map((ual) => ual.limsUA.map((lua) => lua?.flags?.globals).flat())
            .flat()
            .some((item) => item.blocking === true && item.ignore === false)
          const untreatedBlockingFlagsMsg = (
            <div style={{ fontSize: 12, padding: 10 }}>
              {t('view.batch.limsTable.untreated_blocking_flag_bis')}
            </div>
          )

          return (
            <Fragment>
              <ErrorBoundaryGuard isDialog>
                <LimsAutoValidationConfirm
                  open={openAutoValidation}
                  closeFct={(event) => this.handleCloseAutoValidation(event, this)}
                />
              </ErrorBoundaryGuard>
              <Dialog
                open={open}
                onClose={closeFct}
                TransitionComponent={TransitionTop}
                aria-labelledby="draggable-dialog-title"
                className={classes.dialogLims}
              >
                <StyledDialogTitle>{t('view.batch.limsTable.export_lims')}</StyledDialogTitle>
                {isFetching ? (
                  <div style={{ width: 800, height: 500 }}>
                    <Loading mode={'miniCircle'} />
                  </div>
                ) : (
                  <DialogContent>
                    <div
                      className={classes.tableContainer}
                      style={{ width: 'fit-content', height: 'fit-content', overflow: 'hidden' }}
                    >
                      <TableContainer style={{ width: 'fit-content', height: 'fit-content' }}>
                        <Table aria-label="collapsible table" style={{ overflow: 'hidden' }}>
                          <TableHead>
                            <TableRow>
                              <TableCell />
                              <TableCell>{t('view.batch.limsTable.analysis')}</TableCell>
                              <TableCell align="right">
                                {t('view.batch.limsTable.weighing')}
                              </TableCell>
                              <TableCell align="right">
                                {t('view.batch.limsTable.formula')}
                              </TableCell>
                              {/* <TableCell align="right">Concentration(mg/l)</TableCell> */}
                              {/* <TableCell align="right">Dilution</TableCell> */}
                              {/* <TableCell align="right">V ext.(ml)</TableCell> */}
                              <TableCell align="right">
                                {t('view.batch.limsTable.progress')}
                              </TableCell>
                              <TableCell align="right">{t('view.batch.limsTable.infos')}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {uaLimsFiltered.map((row) => (
                              <CustomRow
                                history={history}
                                key={row.name}
                                row={row}
                                defaultClasses={classes}
                                dispatch={dispatch}
                                openAutoValid={() => this.setState({ openAutoValidation: true })}
                              />
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </DialogContent>
                )}
                <DialogActions style={{ paddingTop: 20, paddingBottom: 0 }}>
                  {!isFetching && (
                    <Fragment>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Typography
                          variant="h6"
                          gutterBottom
                          style={{
                            marginBottom: 0,
                            marginLeft: 20,
                            fontWeight: 500,
                            marginRight: 15,
                            fontSize: 14,
                          }}
                        >
                          {t('view.batch.limsTable.options')}
                        </Typography>
                        <FormControl
                          style={{
                            maxWidth: 'calc(100% - 180px)',
                            minWidth: 200,
                            marginRight: 20,
                          }}
                          variant="outlined"
                          size="small"
                        >
                          <InputLabel>{t('view.batch.limsTable.export_moc')}</InputLabel>
                          <Select
                            multiple
                            value={mocFilters}
                            onChange={(event) => this.setState({ mocFilters: event.target.value })}
                            input={<OutlinedInput label={t('view.batch.limsTable.export_moc')} />}
                            renderValue={(selected) => (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                  <Chip key={value} label={value} size="small" />
                                ))}
                              </Box>
                            )}
                          >
                            {uniqueMocList.map((moc) => (
                              <MenuItem key={moc} value={moc}>
                                <Checkbox checked={mocFilters.indexOf(moc) > -1} />
                                <ListItemText primary={moc} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {configFeatures &&
                          typeof configFeatures.lmr === 'boolean' &&
                          configFeatures.lmr && (
                            <Fragment>
                              <FormControlLabel
                                className={classes.exportCheckbox}
                                control={
                                  <Checkbox
                                    checked={this.state.exportResults}
                                    onChange={(event) =>
                                      this.setState({ exportResults: event.target.checked })
                                    }
                                    name="checkedResults"
                                    color="primary"
                                  />
                                }
                                label={t('view.batch.limsTable.export_result')}
                              />
                              <FormControlLabel
                                disabled={
                                  !uaLims ||
                                  uaLims.length === 0 ||
                                  !uaLims.every((ua) =>
                                    ua.limsUA
                                      .filter(
                                        (lua) =>
                                          lua.validation === '1' &&
                                          !lua?.flags?.globals?.some((item) => item.blocking),
                                      )
                                      .every((ua) => ua.reported === 'True'),
                                  )
                                }
                                className={classes.exportCheckbox}
                                control={
                                  <Checkbox
                                    checked={
                                      !uaLims ||
                                      !uaLims.every((ua) =>
                                        ua.limsUA
                                          .filter(
                                            (lua) =>
                                              lua.validation === '1' &&
                                              !lua?.flags?.globals?.some((item) => item.blocking),
                                          )
                                          .every((ua) => ua.reported === 'True'),
                                      )
                                        ? false
                                        : this.state.exportLMR
                                    }
                                    onChange={(event) =>
                                      this.setState({ exportLMR: event.target.checked })
                                    }
                                    name="checkedLMR"
                                    color="primary"
                                  />
                                }
                                label={t('view.batch.limsTable.export_lmr')}
                              />
                            </Fragment>
                          )}
                      </Grid>
                    </Fragment>
                  )}
                </DialogActions>
                <DialogActions style={{ paddingTop: 0 }}>
                  <Badge
                    className={classes.badgeLims}
                    badgeContent={
                      isEmptyEmail ||
                      isISTDNotValidated ||
                      isProgressionNotComplete ||
                      isUntreatedBlockingFLags ? (
                        <Tooltip
                          key={'tooltip_lims_button'}
                          placement="top"
                          arrow
                          title={
                            <Fragment>
                              {isEmptyEmail && emptyMailMsg}
                              {isISTDNotValidated && ISTDNotValidatedMsg}
                              {isProgressionNotComplete && progressionNotCompleteMsg}
                              {isUntreatedBlockingFLags && untreatedBlockingFlagsMsg}
                            </Fragment>
                          }
                        >
                          <ErrorIcon
                            className={isEmptyEmail ? classes.iconErrorLims : classes.iconLims}
                          />
                        </Tooltip>
                      ) : (
                        0
                      )
                    }
                  >
                    <Tooltip
                      key={'tooltip_lims_button'}
                      placement="top"
                      arrow
                      disableHoverListener={
                        !(
                          isEmptyEmail ||
                          isISTDNotValidated ||
                          isProgressionNotComplete ||
                          isUntreatedBlockingFLags
                        )
                      }
                      title={
                        <Fragment>
                          {isEmptyEmail && emptyMailMsg}
                          {isISTDNotValidated && ISTDNotValidatedMsg}
                          {isProgressionNotComplete && progressionNotCompleteMsg}
                          {isUntreatedBlockingFLags && untreatedBlockingFlagsMsg}
                        </Fragment>
                      }
                    >
                      <span>
                        <Button
                          autoFocus
                          disabled={
                            isFetching ||
                            isEmptyEmail ||
                            (!this.state.exportLMR && !this.state.exportResults) ||
                            isUntreatedBlockingFLags
                          }
                          onClick={(event) =>
                            closeFct(
                              event,
                              true,
                              this.state.exportResults,
                              this.state.exportLMR,
                              this.state.mocFilters,
                            )
                          }
                          color="primary"
                        >
                          {t('view.batch.limsTable.export')}
                        </Button>
                      </span>
                    </Tooltip>
                  </Badge>
                  <Button onClick={closeFct} color="primary">
                    {t('view.batch.limsTable.cancel')}
                  </Button>
                </DialogActions>
              </Dialog>
            </Fragment>
            // </Paper>
          )
        }
      },
    ),
  ),
)
