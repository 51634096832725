import { API_BO_URL } from '../../utils/config'
import { receiveCurrentBatchLease, requestSearchBatches } from '../batches/actions'
import { fetchChangeSettings } from '../settings/actions'
import { displayPopupMessage, generateErrorPart } from '../../view/common/Message'
import React, { Fragment } from 'react'
import { getTimeEndDay, getTimeStartDay } from '../../view/common/Utils'
import { requestSearchAnalyses } from '../analyses/actions'
import { receiveBatchTasks } from '../tasks/actions'

export const REQUEST_LOGS = 'REQUEST_LOGS'
export const REQUEST_LOGS_POLLING = 'REQUEST_LOGS_POLLING'
export const RECEIVE_LOGS = 'RECEIVE_LOGS'
export const CLEAN_LOGS = 'CLEAN_LOGS'

export const FILTER_LIST = ['info', 'warning', 'error', 'debug', 'critical']

let controllerLogsSaved = null
export function fetchClearLogs() {
  return {
    type: CLEAN_LOGS,
  }
}

export function fetchGetLogs(polling, batchId, codDriveId) {
  return async (dispatch, getState) => {
    const { logs } = getState()
    const limit = localStorage.getItem('LOG_limit')
      ? parseFloat(localStorage.getItem('LOG_limit'))
      : 100

    if (controllerLogsSaved) {
      controllerLogsSaved.abort()
    }
    const controller = new AbortController()
    controllerLogsSaved = controller
    const dateLock = new Date().getTime()

    let driveId = codDriveId
    if (!polling) {
      dispatch(requestGetLogs(false, dateLock, limit, batchId, codDriveId))
    } else {
      // Mark that it is a polling request
      dispatch(requestGetLogs(true, dateLock, limit))
      // Get the previous codDriveId for polling
      driveId = logs.codDriveId
    }
    const filters = localStorage.getItem('LOG_filters')
      ? JSON.parse(localStorage.getItem('LOG_filters'))
      : FILTER_LIST
    const search = localStorage.getItem('LOG_search')

    const dateFilter = localStorage.getItem('LOG_activeDateFilter') === 'true'

    let filter = []

    if (polling && logs.lastLogCreationDate) {
      filter.push({
        property: 'creationDate',
        value: logs.lastLogCreationDate,
        filterType: 'number',
        operator: 'gt',
      })
    }

    if (dateFilter) {
      const from = localStorage.getItem('LOG_filter_fromDate')
        ? Math.trunc(new Date(localStorage.getItem('LOG_filter_fromDate')).getTime() / 1000)
        : Math.trunc(new Date(getTimeStartDay() - 86400000) / 1000)
      const to = localStorage.getItem('LOG_filter_toDate')
        ? Math.trunc(new Date(localStorage.getItem('LOG_filter_toDate')).getTime() / 1000)
        : Math.trunc(new Date(getTimeEndDay()) / 1000)
      filter.push({
        property: 'modificationDate',
        value: from,
        filterType: 'number',
        operator: 'gte',
      })
      filter.push({
        property: 'modificationDate',
        value: to,
        filterType: 'number',
        operator: 'lte',
      })
    }

    if (batchId) {
      filter.push({
        property: 'content.batch.id',
        value: batchId,
        filterType: 'string',
        operator: 'eq',
      })
    }
    if (driveId) {
      filter.push({
        property: 'content.cod_drive_id',
        value: driveId,
        filterType: 'string',
        operator: 'eq',
      })
    }
    if (filters.length > 0) {
      FILTER_LIST.forEach((f) => {
        if (filters.indexOf(f) === -1) {
          filter.push({
            property: 'content.level',
            value: f,
            filterType: 'string',
            operator: 'ne',
          })
        }
      })
    } else {
      // Force the find 0 result in case where n filter as selected in the GUI
      filter.push({
        property: 'content.level',
        value: 'null',
        filterType: 'string',
        operator: 'eq',
      })
    }
    // if (search) {
    //   filter.push({
    //     operator: 'or',
    //     filters: [
    //       {
    //         property: 'content.action',
    //         value: search,
    //         filterType: 'string',
    //         operator: 'like',
    //       },
    //       {
    //         property: 'content.batch_path',
    //         value: search,
    //         filterType: 'string',
    //         operator: 'like',
    //       },
    //       {
    //         property: 'content.batch_name',
    //         value: search,
    //         filterType: 'string',
    //         operator: 'like',
    //       },
    //       {
    //         property: 'content.batch_id',
    //         value: search,
    //         filterType: 'string',
    //         operator: 'like',
    //       },
    //     ],
    //   })
    // }
    let f = encodeURIComponent(JSON.stringify(filter))
    return fetch(
      `${API_BO_URL()}.cod_drive_log/page/?filter=${f}&sort=creationDate&dir=DESC&limit=${limit}`,
      {
        method: 'GET',
        credentials: 'include',
      },
    )
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((json) => {
        return dispatch(receiveLogs(json, dateLock))
      })
      .catch((error) => {
        if (error.status === 403) {
          return dispatch(fetchChangeSettings('loginPopup', true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to get Logs',
          <Fragment>
            <div>An error does not allow to get Logs:</div>
            {generateErrorPart(error)}
          </Fragment>,
        )
        dispatch(receiveLogs({ result: [], size: 0 }, dateLock))
      })
      .finally(() => {
        if (controllerLogsSaved === controller) {
          controllerLogsSaved = null
        }
        controller.abort()
      })
  }
}

export function requestGetLogs(polling, dateLock, limit, batchId, codDriveId) {
  if (polling) {
    return {
      type: REQUEST_LOGS_POLLING,
      dateLock: dateLock,
      limit: limit,
    }
  } else {
    return {
      type: REQUEST_LOGS,
      batchId: batchId,
      codDriveId: codDriveId,
      dateLock: dateLock,
      limit: limit,
    }
  }
}

export function receiveLogs(logs, dateLock) {
  return {
    type: RECEIVE_LOGS,
    logs: logs,
    dateLock: dateLock,
  }
}
