import { find } from 'lodash'
import { API_BO_URL } from '../../utils/config'
import { fetchChangeSettings } from '../settings/actions'

export const getBatchById = (store, id) => find(store.items, { _id: id })

export async function getBatchRawById(id, dispatch) {
  try {
    const response = await fetch(`${API_BO_URL()}.batch/${id}`, {
      method: 'GET',
      credentials: 'include',
    })
    const json = await response.json()
    const error = response.status !== 200
    if (error) {
      console.log(response.statusText)
      const authenticationError = response.status === 403
      if (authenticationError) {
        dispatch(fetchChangeSettings('loginPopup', true))
      }
      return null
    } else {
      return json
    }
  } catch (e) {
    console.log(e)
    return null
  }
}
