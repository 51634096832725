import React from 'react'
import { genericStyles, TaskTooltipMessage, ZipFileInvert } from './Style'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import { alpha, CssBaseline } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Stack } from '@mui/material'
import Badge from '@mui/material/Badge'
import SendIcon from '@mui/icons-material/Send'
import DescriptionIcon from '@mui/icons-material/Description'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled'
import Progress from '../Progress'
import Button from '@mui/material/Button'
import { ZIP_LEVEL } from '../../utils/config'
import { fetchChangeSettings } from '../../redux/settings/actions'
import { t } from 'i18next'
import Tooltip from '@material-ui/core/Tooltip'
import { fade } from '@material-ui/core/styles'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'

const useStyles = makeStyles((theme) => ({
  zipButton: {
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.04),
    },
  },
  timeBadge: {
    background: '#f7f7f7',
    border: '2px solid #7b7b7b',
    borderRadius: 30,
    padding: '0px 10px 0px 0px',
    color: '#4a4a4a',
    textTransform: 'lowercase',
  },
  badgeZip: {
    '& .MuiBadge-badge': {
      transform: 'scale(0.8) translate(50%, 50%)',
      right: '22%',
    },
  },
}))
const UploadCmp = withRouter((props) => {
  const classes = useStyles()
  const genericClasses = genericStyles()
  const dispatch = useDispatch()
  // const [loginPassword, setLoginPassword] = React.useState()

  const task = useSelector(function (state) {
    return state.tasks.task
  })

  // const uploadSendMessage = useSelector(function (state) {
  //   return state.settings.uploadSendMessage
  // })
  // const uploadRemainingTime = useSelector(function (state) {
  //   return state.settings.uploadRemainingTime
  // })
  // const uploadGlobalProgress = useSelector(function (state) {
  //   return state.settings.uploadGlobalProgress
  // })
  // const uploadCustomMessage = useSelector(function (state) {
  //   return state.settings.uploadCustomMessage
  // })
  // const uploadAcceptedFiles = useSelector(function (state) {
  //   return state.settings.uploadAcceptedFiles
  // })
  // const uploadIsDragActive = useSelector(function (state) {
  //   return state.settings.uploadIsDragActive
  // })

  const {
    uploadSendMessage,
    uploadRemainingTime,
    uploadGlobalProgress,
    uploadCustomMessage,
    uploadAcceptedFiles,
    uploadIsDragActive,
  } = useSelector(function (state) {
    return state.settings
  })

  const zipLevel = () => {
    return localStorage.getItem('SETTINGS_zip_level')
      ? parseFloat(localStorage.getItem('SETTINGS_zip_level'))
      : ZIP_LEVEL
  }

  return (
    <Tooltip
      arrow
      title={
        <div style={{ fontSize: 13, padding: 5 }}>
          {t('view.panel.toolbar.toolbar.upload_tooltip_cmp')}
        </div>
      }
    >
      <Button
        onClick={() => dispatch(fetchChangeSettings('uploadOpenCompress', true))}
        className={classes.zipButton}
        style={{
          color: 'inherit',
          paddingLeft: 4,
          border: '1px dashed #ffffff82',
          borderRadius: 6,
          display: uploadAcceptedFiles.length > 0 && !uploadIsDragActive ? 'flex' : 'none',
        }}
      >
        {uploadAcceptedFiles.length > 0 && !uploadIsDragActive && (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            style={
              {
                // border: '2px dashed black',
                // paddingTop: 4,
                // borderRadius: 10,
                // background: '#fdfdfd',
              }
            }
          >
            {uploadSendMessage === null ? (
              <Badge
                className={classes.badgeZip}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                color="secondary"
                badgeContent={'l' + zipLevel()}
                overlap="circular"
              >
                <ZipFileInvert style={{ marginTop: 2, height: 23 }} />
              </Badge>
            ) : task === null ? (
              <SendIcon />
            ) : (
              <DescriptionIcon />
            )}
            <Badge
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              overlap="circular"
              badgeContent={
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                  className={classes.timeBadge}
                >
                  <AccessTimeFilledIcon />
                  <div>{uploadRemainingTime}</div>
                </Stack>
              }
              invisible={
                !uploadRemainingTime || uploadGlobalProgress === 100 || uploadSendMessage !== null
              }
            >
              <ErrorBoundaryGuard isDialog>
                <Progress
                  progress={uploadGlobalProgress}
                  customMessage={uploadCustomMessage}
                  style={{ backgroundColor: 'rgba(255, 255, 255, 0.15)', height: 35 }}
                />
              </ErrorBoundaryGuard>
            </Badge>
          </Stack>
        )}
      </Button>
    </Tooltip>
  )
})
export default UploadCmp
