import {
  REQUEST_UNITARY_ANALYSES,
  RECEIVE_UNITARY_ANALYSES,
  REQUEST_UNITARY_ANALYSES_FILTERED,
  RECEIVE_UNITARY_ANALYSES_FILTERED,
  RECEIVE_UNITARY_ANALYSES_COMPOUND,
  REQUEST_UNITARY_ANALYSES_COMPOUND,
  SORT_UNITARY_ANALYSES,
  SORT_UNITARY_COMPOUNDS,
  REQUEST_SORT_UNITARY_ANALYSES,
  RECEIVE_SORT_UNITARY_ANALYSES,
  REQUEST_SORT_UNITARY_COMPOUNDS,
  RECEIVE_SORT_UNITARY_COMPOUNDS,
  UPDATE_VALIDATION_ANALYSE,
  UPDATE_VALIDATION_COMPOUND,
  REQUEST_UNITARY_ANALYSES_DISTINCT_VALIDATION,
  RECEIVE_UNITARY_ANALYSES_DISTINCT_VALIDATION,
  REQUEST_UPDATE_PEAK_ANALYSE,
  RECEIVE_UPDATE_PEAK_ANALYSE,
  REQUEST_UPDATE_PEAK_COMPOUND,
  RECEIVE_UPDATE_PEAK_COMPOUND,
  RECEIVE_UPDATE_MAIN_CHANNEL,
  RECEIVE_UPDATE_UA_VALIDATION,
  RECEIVE_UPDATE_UA_INTERPOLATION,
  RECEIVE_UPDATE_UA_STD_POINT,
  RECEIVE_UPDATE_UA_SETTINGS,
  RECEIVE_UPDATE_COMPOUND_ISTD,
  RECEIVE_UPDATE_UNITARY_ANALYSES,
  REQUEST_UNITARY_ANALYSES_DISTINCT,
  RECEIVE_UNITARY_ANALYSES_DISTINCT,
  REQUEST_UNITARY_COMPOUND_DISTINCT,
  RECEIVE_UNITARY_COMPOUND_DISTINCT,
  REQUEST_UNITARY_ANALYSES_CHANGE_SELECTED_CHANNEL,
  REQUEST_UNITARY_COMPOUNDS_CHANGE_SELECTED_CHANNEL,
  RECEIVE_UPDATE_UA_CALIBRATION,
  REQUEST_UNITARY_ANALYSES_CHANGE_LOCK,
  RECEIVE_UNITARY_COMPOUND_ISTD,
  REQUEST_UNITARY_COMPOUND_ISTD,
  REQUEST_UNITARY_ANALYSES_COMPOUND_QC,
  RECEIVE_UNITARY_ANALYSES_COMPOUND_QC,
  REQUEST_ALL_UNITARY_ANALYSES_ISTD,
  RECEIVE_ALL_UNITARY_ANALYSES_ISTD,
  REQUEST_ALL_UNITARY_ANALYSES_COMPOUND_ISTD,
  RECEIVE_ALL_UNITARY_ANALYSES_COMPOUND_ISTD,
  REQUEST_UNITARY_ANALYSES_COMPOUNDS_FOR_DETAIL,
  RECEIVE_UNITARY_ANALYSES_COMPOUNDS_FOR_DETAIL,
  RECEIVE_RECOMPUTE_PEAKS,
  FORCE_UA_FETCHING,
  REQUEST_GLOBAL_UA,
  RECEIVE_GLOBAL_UA,
  REQUEST_GLOBAL_UA_SELECTED,
  RECEIVE_GLOBAL_UA_SELECTED,
} from './actions'
import { AUTHENTICATION_FAILURE } from '../batches/actions'
import {
  computeUnitaryAnalysesForGlobalTable,
  getComparator,
  rebuildUnitaryAnalyses,
  rebuildUnitaryCompounds,
  setOrderValueFromAnalysisOrder,
  stableSort,
} from '../../view/common/Utils'
import {
  RECEIVE_UNITARY_CALIBRATIONS_DISTINCT,
  REQUEST_UNITARY_CALIBRATIONS_DISTINCT,
} from '../unitaryCalibrations/actions'

const initialState = {
  unitaryAnalyses: [],
  unitaryCompounds: [],
  unitaryAnalysesFiltered: [],
  unitaryAnalysesCompound: [],
  unitaryAnalysesDistinctValidation: [],
  unitaryAnalysesDistinct: [],
  unitaryCompoundDistinct: [],
  isFetchingUnitaryAnalyses: false,
  isFetchingUnitaryAnalysesFiltered: false,
  isFetchingUnitaryAnalysesCompound: false,
  isFetchingUnitaryAnalysesDistinctValidation: false,
  isFetchingUnitaryAnalysesDistinct: false,
  isFetchingUnitaryCompoundDistinct: false,
  analyseId: null,
  error: false,
  isFetchingUnitaryCompoundISTD: false,
  unitaryCompoundISTD: [],
  isFetchingUnitaryAnalysesQC: false,
  unitaryAnalysesQC: [],
  isFetchingUnitaryAnalysesCompoundQC: false,
  unitaryAnalysesCompoundQC: [],
  isFetchingAllUnitaryAnalysesIstd: false,
  allUnitaryAnalysesIstd: [],
  isFetchingAllUnitaryAnalysesCompoundIstd: false,
  allUnitaryAnalysesCompoundIstd: [],
  unitaryAnalysesCompoundForDetail: [],
  unitaryAnalysesFilteredLastDateRequest: null,
  unitaryAnalysesCompoundLastDateRequest: null,
  globalUnitaryAnalyses: [],
  isFetchingGlobalUnitaryAnalyses: false,
  globalUnitaryAnalysesSelected: null,
  isFetchingGlobalUnitaryAnalysesSelected: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FORCE_UA_FETCHING:
      return {
        ...state,
        isFetchingUnitaryAnalysesFiltered: true,
      }
    case RECEIVE_UPDATE_UA_SETTINGS:
      const uaIdsS = action.objectsUpdated.uas.reduce(function (map, obj) {
        map[obj._id] = obj
        return map
      }, {})
      switch (action.collection) {
        case 'unitary_analysis':
          return {
            ...state,
            isFetchingUnitaryAnalysesFiltered: false,
            unitaryAnalysesFiltered: state.unitaryAnalysesFiltered.map(function (ua) {
              if (uaIdsS[ua._id]) {
                return rebuildUnitaryAnalyses(uaIdsS[ua._id])
              }
              return ua
            }),
          }
        case 'unitary_compound':
          return {
            ...state,
          }
        default:
          return {
            ...state,
          }
      }
    case RECEIVE_UPDATE_UA_VALIDATION:
      const uaIdsV = action.objectsUpdated.uas.reduce(function (map, obj) {
        map[obj._id] = obj
        return map
      }, {})
      switch (action.collection) {
        case 'unitary_analysis':
          return {
            ...state,
            isFetchingUnitaryAnalysesFiltered: false,
            unitaryAnalysesFiltered: state.unitaryAnalysesFiltered.map(function (ua) {
              if (uaIdsV[ua._id]) {
                return rebuildUnitaryAnalyses(uaIdsV[ua._id])
              }
              return ua
            }),
            // globalUnitaryAnalyses: [],
            globalUnitaryAnalysesSelected:
              state.globalUnitaryAnalysesSelected && uaIdsV[state.globalUnitaryAnalysesSelected._id]
                ? rebuildUnitaryAnalyses(uaIdsV[state.globalUnitaryAnalysesSelected._id])
                : state.globalUnitaryAnalysesSelected,
          }
        case 'unitary_compound':
          //Recompute rank for the list
          const tmpList = state.unitaryAnalysesCompound.map(function (ua) {
            if (uaIdsV[ua._id]) {
              let rebuildUC = rebuildUnitaryCompounds(uaIdsV[ua._id])
              if (action.peakChannel !== undefined && action.peakChannel !== null) {
                rebuildUC.selectedChannel = action.peakChannel
              }
              return rebuildUC
            }
            return ua
          })
          return {
            ...state,
            isFetchingUnitaryAnalysesCompound: false,
            unitaryAnalysesCompound: setOrderValueFromAnalysisOrder(tmpList),
            // unitaryAnalysesCompound: state.unitaryAnalysesCompound.map(function (ua) {
            //   if (uaIdsV[ua._id]) {
            //     return rebuildUnitaryCompounds(uaIdsV[ua._id])
            //   }
            //   return ua
            // }),
          }
        default:
          return {
            ...state,
          }
      }
    case RECEIVE_UPDATE_MAIN_CHANNEL:
      const uaIdsMc = action.unitaryAnalysesUpdated.uas.reduce(function (map, obj) {
        map[obj._id] = obj
        return map
      }, {})
      switch (action.collection) {
        case 'analyse':
          return {
            ...state,
            isFetchingUnitaryAnalysesFiltered: false,
            unitaryAnalysesFiltered: state.unitaryAnalysesFiltered.map(function (ua) {
              if (uaIdsMc[ua._id]) {
                return rebuildUnitaryAnalyses(uaIdsMc[ua._id])
              }
              return ua
            }),
          }
        case 'compound':
          //Recompute rank for the list
          const tmpList = state.unitaryAnalysesCompound.map(function (ua) {
            if (uaIdsMc[ua._id]) {
              let rebuildUC = rebuildUnitaryCompounds(uaIdsMc[ua._id])
              if (action.peakChannel !== undefined && action.peakChannel !== null) {
                rebuildUC.selectedChannel = action.peakChannel
              }
              return rebuildUC
            }
            return ua
          })
          return {
            ...state,
            isFetchingUnitaryAnalysesCompound: false,
            unitaryAnalysesCompound: setOrderValueFromAnalysisOrder(tmpList),
            // unitaryAnalysesCompound: state.unitaryAnalysesCompound.map(function (ua) {
            //   if (uaIdsMc[ua._id]) {
            //     return rebuildUnitaryCompounds(uaIdsMc[ua._id])
            //   }
            //   return ua
            // }),
          }
        default:
          return {
            ...state,
          }
      }

    case REQUEST_UPDATE_PEAK_ANALYSE:
      return {
        ...state,
        isFetchingUnitaryAnalysesFiltered: true,
      }
    case RECEIVE_UPDATE_PEAK_ANALYSE:
      const uaIds = action.unitaryAnalysesUpdated.uas.reduce(function (map, obj) {
        map[obj._id] = obj
        return map
      }, {})
      return {
        ...state,
        isFetchingUnitaryAnalysesFiltered: false,
        unitaryAnalysesFiltered: state.unitaryAnalysesFiltered.map(function (ua) {
          if (uaIds[ua._id]) {
            let rebuildUA = { ...rebuildUnitaryAnalyses(uaIds[ua._id]), unlocked: ua.unlocked }
            if (action.peakChannel !== undefined && action.peakChannel !== null) {
              rebuildUA.selectedChannel = action.peakChannel
            }
            return rebuildUA
          }
          return ua
        }),
        unitaryAnalysesCompound: state.unitaryAnalysesCompound.map(function (ua) {
          if (uaIds[ua._id]) {
            let rebuildUC = { ...rebuildUnitaryCompounds(uaIds[ua._id]), unlocked: ua.unlocked }
            if (action.peakChannel !== undefined && action.peakChannel !== null) {
              rebuildUC.selectedChannel = action.peakChannel
            }
            return rebuildUC
          }
          return ua
        }),
        allUnitaryAnalysesCompoundIstd: state.allUnitaryAnalysesCompoundIstd.map(function (ua) {
          if (uaIds[ua._id]) {
            let rebuildUC = { ...rebuildUnitaryCompounds(uaIds[ua._id]), unlocked: ua.unlocked }
            if (action.peakChannel !== undefined && action.peakChannel !== null) {
              rebuildUC.selectedChannel = action.peakChannel
            }
            return rebuildUC
          }
          return ua
        }),
        allUnitaryAnalysesIstd: state.allUnitaryAnalysesIstd.map(function (ua) {
          if (uaIds[ua._id]) {
            let rebuildUA = { ...rebuildUnitaryAnalyses(uaIds[ua._id]), unlocked: ua.unlocked }
            if (action.peakChannel !== undefined && action.peakChannel !== null) {
              rebuildUA.selectedChannel = action.peakChannel
            }
            return rebuildUA
          }
          return ua
        }),
        unitaryCompoundISTD: state.unitaryCompoundISTD.map(function (ua) {
          if (uaIds[ua._id]) {
            let rebuildUC = {
              isIstd: true,
              ...rebuildUnitaryCompounds(uaIds[ua._id]),
              unlocked: ua.unlocked,
            }
            if (action.peakChannel !== undefined && action.peakChannel !== null) {
              rebuildUC.selectedChannel = action.peakChannel
            }
            return rebuildUC
          }
          return ua
        }),
        unitaryAnalysesCompoundForDetail: state.unitaryAnalysesCompoundForDetail.map(function (ua) {
          if (uaIds[ua._id]) {
            let rebuildACFD = { ...rebuildUnitaryCompounds(uaIds[ua._id]), unlocked: ua.unlocked }
            if (action.peakChannel !== undefined && action.peakChannel !== null) {
              rebuildACFD.selectedChannel = action.peakChannel
            }
            return rebuildACFD
          }
          return ua
        }),
        // globalUnitaryAnalyses: [],
        globalUnitaryAnalysesSelected:
          state.globalUnitaryAnalysesSelected && uaIds[state.globalUnitaryAnalysesSelected._id]
            ? rebuildUnitaryAnalyses(uaIds[state.globalUnitaryAnalysesSelected._id])
            : state.globalUnitaryAnalysesSelected,
      }
    case REQUEST_UPDATE_PEAK_COMPOUND:
      return {
        ...state,
        isFetchingUnitaryAnalysesFiltered: true,
      }
    case RECEIVE_UPDATE_PEAK_COMPOUND:
      const uacpIds = action.unitaryAnalysesUpdated.uas.reduce(function (map, obj) {
        map[obj._id] = obj
        return map
      }, {})

      //Recompute rank for the Analyses list
      const tmpListAnalyses = state.unitaryAnalysesFiltered.map(function (ua) {
        if (uacpIds[ua._id]) {
          let rebuildUC = { ...rebuildUnitaryAnalyses(uacpIds[ua._id]), unlocked: ua.unlocked }
          if (action.peakChannel !== undefined && action.peakChannel !== null) {
            rebuildUC.selectedChannel = action.peakChannel
          }
          return rebuildUC
        }
        return ua
      })
      //Recompute rank for the Compound list
      const tmpListCompound = state.unitaryAnalysesCompound.map(function (ua) {
        if (uacpIds[ua._id]) {
          let rebuildUC = { ...rebuildUnitaryCompounds(uacpIds[ua._id]), unlocked: ua.unlocked }
          if (action.peakChannel !== undefined && action.peakChannel !== null) {
            rebuildUC.selectedChannel = action.peakChannel
          }
          return rebuildUC
        }
        return ua
      })

      // unitaryAnalysesCompoundForDetail ??
      //Recompute for ISTD compounds in view detail
      const tmpListCompoundDetailISTD = state.unitaryCompoundISTD.map(function (ua) {
        if (uacpIds[ua._id]) {
          let rebuildUC = {
            isIstd: true,
            ...rebuildUnitaryCompounds(uacpIds[ua._id]),
            unlocked: ua.unlocked,
          }
          if (action.peakChannel !== undefined && action.peakChannel !== null) {
            rebuildUC.selectedChannel = action.peakChannel
          }
          return rebuildUC
        }
        return ua
      })

      //Recompute rank for the Compound list ISTD
      const tmpListCompoundISTD = state.allUnitaryAnalysesCompoundIstd.map(function (ua) {
        if (uacpIds[ua._id]) {
          let rebuildUC = { ...rebuildUnitaryCompounds(uacpIds[ua._id]), unlocked: ua.unlocked }
          if (action.peakChannel !== undefined && action.peakChannel !== null) {
            rebuildUC.selectedChannel = action.peakChannel
          }
          return rebuildUC
        }
        return ua
      })
      //Recompute rank for the Analyses list ISTD
      const tmpListAnalysesISTD = state.allUnitaryAnalysesIstd.map(function (ua) {
        if (uacpIds[ua._id]) {
          let rebuildUC = { ...rebuildUnitaryAnalyses(uacpIds[ua._id]), unlocked: ua.unlocked }
          if (action.peakChannel !== undefined && action.peakChannel !== null) {
            rebuildUC.selectedChannel = action.peakChannel
          }
          return rebuildUC
        }
        return ua
      })

      return {
        ...state,
        isFetchingUnitaryAnalysesCompound: false,
        unitaryAnalysesFiltered: setOrderValueFromAnalysisOrder(tmpListAnalyses),
        unitaryAnalysesCompound: setOrderValueFromAnalysisOrder(tmpListCompound),
        allUnitaryAnalysesCompoundIstd: setOrderValueFromAnalysisOrder(tmpListCompoundISTD),
        allUnitaryAnalysesIstd: setOrderValueFromAnalysisOrder(tmpListAnalysesISTD),
        unitaryCompoundISTD: setOrderValueFromAnalysisOrder(tmpListCompoundDetailISTD),
        // unitaryAnalysesCompound: state.unitaryAnalysesCompound.map(function (ua) {
        //   if (uacpIds[ua._id]) {
        //     let rebuildUC = rebuildUnitaryCompounds(uacpIds[ua._id])
        //     rebuildUC.selectedChannel = action.peakChannel
        //     return rebuildUC
        //   }
        //   return ua
        // }),
      }
    case UPDATE_VALIDATION_ANALYSE:
      const unitaryAnalysesFiltered = state.unitaryAnalysesFiltered
      const index = unitaryAnalysesFiltered.findIndex((element) => element._id === action.analyseId)

      const analyse = unitaryAnalysesFiltered[index]

      const newDistinct = {
        ...state.unitaryAnalysesDistinctValidation,
        [analyse.validation]: {
          count: --state.unitaryAnalysesDistinctValidation[analyse.validation].count,
        },
        [action.validation]: state.unitaryAnalysesDistinctValidation[action.validation]
          ? { count: ++state.unitaryAnalysesDistinctValidation[action.validation].count }
          : { count: 1 },
      }

      analyse.validation = action.validation
      unitaryAnalysesFiltered[index] = analyse

      return {
        ...state,
        // unitaryCalibrationsFiltered: unitaryCalibrationsFiltered,
        unitaryAnalysesDistinctValidation: newDistinct,
      }
    case UPDATE_VALIDATION_COMPOUND:
      return {
        ...state,
        unitaryAnalysesCompound: state.unitaryAnalysesCompound.map(function (analyse) {
          if (analyse._id === action.analyseId) {
            analyse.validation = action.validation
          }
          return analyse
        }),
      }
    case REQUEST_SORT_UNITARY_ANALYSES:
      return {
        ...state,
        isFetchingUnitaryAnalysesFiltered: true,
      }
    case RECEIVE_SORT_UNITARY_ANALYSES:
      return {
        ...state,
        isFetchingUnitaryAnalysesFiltered: false,
        unitaryAnalysesFiltered: stableSort(
          state.unitaryAnalysesFiltered,
          getComparator(action.sort, action.sortBy),
        ),
      }
    case REQUEST_SORT_UNITARY_COMPOUNDS:
      return {
        ...state,
        isFetchingUnitaryAnalysesCompound: true,
      }
    case RECEIVE_SORT_UNITARY_COMPOUNDS:
      return {
        ...state,
        isFetchingUnitaryAnalysesCompound: false,
        unitaryAnalysesCompound: stableSort(
          state.unitaryAnalysesCompound,
          getComparator(action.sort, action.sortBy),
        ),
      }
    case SORT_UNITARY_ANALYSES:
      return {
        ...state,
        unitaryAnalysesFiltered: stableSort(
          state.unitaryAnalysesFiltered,
          getComparator(action.sort, action.sortBy),
        ),
      }
    case SORT_UNITARY_COMPOUNDS:
      return {
        ...state,
        unitaryAnalysesCompound: stableSort(
          state.unitaryAnalysesCompound,
          getComparator(action.sort, action.sortBy),
        ),
      }
    case REQUEST_UNITARY_ANALYSES:
      return {
        ...state,
        isFetchingUnitaryAnalyses: true,
        analyseId: action.analyseId,
        error: false,
      }

    case RECEIVE_UNITARY_ANALYSES:
      return {
        ...state,
        error: false,
        isFetchingUnitaryAnalyses: false,
        unitaryAnalyses: action.unitaryAnalyses,
      }

    case REQUEST_UNITARY_ANALYSES_FILTERED:
      return {
        ...state,
        isFetchingUnitaryAnalysesFiltered: true,
        analyseId: action.analyseId,
        filter: action.filter,
        requested: action.requested,
        error: false,
        unitaryAnalysesFilteredLastDateRequest: action.dateLock,
      }

    case RECEIVE_UNITARY_ANALYSES_FILTERED:
      // Avoid displaying an old request after a more recent one which was faster.
      if (action.dateLock !== state.unitaryAnalysesFilteredLastDateRequest) {
        return {
          ...state,
        }
      } else {
        return {
          ...state,
          error: false,
          isFetchingUnitaryAnalysesFiltered: false,
          unitaryAnalysesFiltered: action.unitaryAnalysesFiltered,
        }
      }

    case REQUEST_UNITARY_ANALYSES_COMPOUND:
      return {
        ...state,
        isFetchingUnitaryAnalysesCompound: true,
        moleculeEvent: action.moleculeEvent,
        error: false,
        unitaryAnalysesCompoundLastDateRequest: action.dateLock,
      }

    case RECEIVE_UNITARY_ANALYSES_COMPOUND:
      // Avoid displaying an old request after a more recent one which was faster.
      if (action.dateLock !== state.unitaryAnalysesCompoundLastDateRequest) {
        return {
          ...state,
        }
      } else {
        return {
          ...state,
          error: false,
          isFetchingUnitaryAnalysesCompound: false,
          unitaryAnalysesCompound: action.unitaryAnalysesCompound,
        }
      }
    case REQUEST_UNITARY_ANALYSES_DISTINCT_VALIDATION:
      return {
        ...state,
        isFetchingUnitaryAnalysesDistinctValidation: true,
        batchId: action.batchId,
        error: false,
      }

    case RECEIVE_UNITARY_ANALYSES_DISTINCT_VALIDATION:
      return {
        ...state,
        error: false,
        isFetchingUnitaryAnalysesDistinctValidation: false,
        unitaryAnalysesDistinctValidation: action.unitaryAnalysesDistinctValidation,
      }

    case AUTHENTICATION_FAILURE:
      return {
        ...state,
        isFetchingUnitaryAnalysesCompound: false,
        isFetchingUnitaryAnalysesFiltered: false,
        isFetchingUnitaryAnalyses: false,
        error: true,
      }
    case RECEIVE_UPDATE_UA_INTERPOLATION:
      const uaIdsI = action.objectsUpdated.uas.reduce(function (map, obj) {
        map[obj._id] = obj
        return map
      }, {})
      switch (action.collection) {
        case 'analyse':
          return {
            ...state,
            isFetchingUnitaryAnalysesFiltered: false,
            unitaryAnalysesFiltered: state.unitaryAnalysesFiltered.map(function (ua) {
              if (uaIdsI[ua._id]) {
                return rebuildUnitaryAnalyses(uaIdsI[ua._id])
              }
              return ua
            }),
          }
        case 'compound':
          //Recompute rank for the list
          const tmpList = state.unitaryAnalysesCompound.map(function (ua) {
            if (uaIdsI[ua._id]) {
              return rebuildUnitaryCompounds(uaIdsI[ua._id])
            }
            return ua
          })

          return {
            ...state,
            isFetchingUnitaryAnalysesCompound: false,
            unitaryAnalysesCompound: setOrderValueFromAnalysisOrder(tmpList),
            // unitaryAnalysesCompound: state.unitaryAnalysesCompound.map(function (ua) {
            //   if (uaIdsI[ua._id]) {
            //     return rebuildUnitaryCompounds(uaIdsI[ua._id])
            //   }
            //   return ua
            // }),
          }
        default:
          return state
      }
      break
    case RECEIVE_UPDATE_UA_STD_POINT:
      const uaIdsP = action.objectsUpdated.uas.reduce(function (map, obj) {
        map[obj._id] = obj
        return map
      }, {})
      switch (action.collection) {
        case 'analyse':
          return {
            ...state,
            isFetchingUnitaryAnalysesFiltered: false,
            unitaryAnalysesFiltered: state.unitaryAnalysesFiltered.map(function (ua) {
              if (uaIdsP[ua._id]) {
                return rebuildUnitaryAnalyses(uaIdsP[ua._id])
              }
              return ua
            }),
          }
        case 'compound':
          //Recompute rank for the list
          const tmpList = state.unitaryAnalysesCompound.map(function (ua) {
            if (uaIdsP[ua._id]) {
              return rebuildUnitaryCompounds(uaIdsP[ua._id])
            }
            return ua
          })
          return {
            ...state,
            isFetchingUnitaryAnalysesCompound: false,
            unitaryAnalysesCompound: setOrderValueFromAnalysisOrder(tmpList),
            // unitaryAnalysesCompound: state.unitaryAnalysesCompound.map(function (ua) {
            //   if (uaIdsP[ua._id]) {
            //     return rebuildUnitaryCompounds(uaIdsP[ua._id])
            //   }
            //   return ua
            // }),
          }
        default:
          return state
      }
      break
    case RECEIVE_UPDATE_COMPOUND_ISTD:
      const uaIdsMap = action.objectsUpdated.uas.reduce(function (map, obj) {
        map[obj._id] = obj
        return map
      }, {})

      //Recompute rank for the list
      const tmpListIds = state.unitaryAnalysesCompound.map(function (ua) {
        if (uaIdsMap[ua._id]) {
          return rebuildUnitaryCompounds(uaIdsMap[ua._id])
        }
        return ua
      })
      return {
        ...state,
        isFetchingUnitaryAnalysesCompound: false,
        unitaryAnalysesCompound: setOrderValueFromAnalysisOrder(tmpListIds),
        // unitaryAnalysesCompound: state.unitaryAnalysesCompound.map(function (ua) {
        //   if (uaIdsMap[ua._id]) {
        //     return rebuildUnitaryCompounds(uaIdsMap[ua._id])
        //   }
        //   return ua
        // }),
      }

    case RECEIVE_UPDATE_UNITARY_ANALYSES:
      const uasMap = action.objectsUpdated.uas.reduce(function (map, obj) {
        map[obj._id] = obj
        return map
      }, {})
      return {
        ...state,
        isFetchingUnitaryAnalysesFiltered: false,
        unitaryAnalysesFiltered: state.unitaryAnalysesFiltered.map(function (ua) {
          if (uasMap[ua._id]) {
            return rebuildUnitaryAnalyses(uasMap[ua._id])
          }
          return ua
        }),
      }
    case REQUEST_UNITARY_ANALYSES_DISTINCT:
      return {
        ...state,
        isFetchingUnitaryAnalysesDistinct: true,
        analysisId: action.analysisId,
        error: false,
      }

    case RECEIVE_UNITARY_ANALYSES_DISTINCT:
      return {
        ...state,
        error: false,
        isFetchingUnitaryAnalysesDistinct: false,
        unitaryAnalysesDistinct: action.unitaryAnalysesDistinct,
      }
    case REQUEST_UNITARY_COMPOUND_DISTINCT:
      return {
        ...state,
        isFetchingUnitaryCompoundDistinct: true,
        batchId: action.batchId,
        moleculeEvent: action.moleculeEvent,
        error: false,
      }

    case RECEIVE_UNITARY_COMPOUND_DISTINCT:
      return {
        ...state,
        error: false,
        isFetchingUnitaryCompoundDistinct: false,
        unitaryCompoundDistinct: action.unitaryCompoundDistinct,
      }
    case REQUEST_UNITARY_ANALYSES_CHANGE_SELECTED_CHANNEL:
      return {
        ...state,
        error: false,
        isFetchingUnitaryAnalysesFiltered: false,
        unitaryAnalysesFiltered: state.unitaryAnalysesFiltered.map(function (ua) {
          if (ua._id === action.uaId) {
            return { ...ua, selectedChannel: action.channel, channelDate: new Date().toString() }
          }
          return ua
        }),
        allUnitaryAnalysesIstd: state.allUnitaryAnalysesIstd.map(function (ua) {
          if (ua._id === action.uaId) {
            return { ...ua, selectedChannel: action.channel, channelDate: new Date().toString() }
          }
          return ua
        }),
        // globalUnitaryAnalyses: [],
        globalUnitaryAnalysesSelected:
          state.globalUnitaryAnalysesSelected &&
          state.globalUnitaryAnalysesSelected._id === action.uaId
            ? {
                ...state.globalUnitaryAnalysesSelected,
                selectedChannel: action.channel,
                channelDate: new Date().toString(),
              }
            : state.globalUnitaryAnalysesSelected,
      }
    case REQUEST_UNITARY_COMPOUNDS_CHANGE_SELECTED_CHANNEL:
      return {
        ...state,
        error: false,
        isFetchingUnitaryAnalysesCompound: false,
        unitaryAnalysesCompound: state.unitaryAnalysesCompound.map(function (ua) {
          if (ua._id === action.uaId) {
            return { ...ua, selectedChannel: action.channel, channelDate: new Date().toString() }
          }
          return ua
        }),
        allUnitaryAnalysesCompoundIstd: state.allUnitaryAnalysesCompoundIstd.map(function (ua) {
          if (ua._id === action.uaId) {
            return { ...ua, selectedChannel: action.channel, channelDate: new Date().toString() }
          }
          return ua
        }),
      }
    case RECEIVE_UPDATE_UA_CALIBRATION:
      const uaIdsC = action.objectsUpdated.uas.reduce(function (map, obj) {
        map[obj._id] = obj
        return map
      }, {})
      switch (action.collection) {
        case 'analyse':
          return {
            ...state,
            isFetchingUnitaryAnalysesFiltered: false,
            unitaryAnalysesFiltered: state.unitaryAnalysesFiltered.map(function (ua) {
              if (uaIdsC[ua._id]) {
                return rebuildUnitaryAnalyses(uaIdsC[ua._id])
              }
              return ua
            }),
          }
        case 'compound':
          //Recompute rank for the list
          const tmpList = state.unitaryAnalysesCompound.map(function (ua) {
            if (uaIdsC[ua._id]) {
              return rebuildUnitaryCompounds(uaIdsC[ua._id])
            }
            return ua
          })
          return {
            ...state,
            isFetchingUnitaryAnalysesCompound: false,
            unitaryAnalysesCompound: setOrderValueFromAnalysisOrder(tmpList),
            // unitaryAnalysesCompound: state.unitaryAnalysesCompound.map(function (ua) {
            //   if (uaIdsC[ua._id]) {
            //     return rebuildUnitaryCompounds(uaIdsC[ua._id])
            //   }
            //   return ua
            // }),
          }
        default:
          return state
      }
      break
    case REQUEST_UNITARY_ANALYSES_CHANGE_LOCK:
      switch (action.collection) {
        case 'analyse':
          return {
            ...state,
            unitaryAnalysesFiltered: state.unitaryAnalysesFiltered.map(function (ua) {
              if (ua._id === action.uaId) {
                return { ...ua, unlocked: typeof ua.unlocked !== 'undefined' ? !ua.unlocked : true }
              }
              return ua
            }),
            allUnitaryAnalysesIstd: state.allUnitaryAnalysesIstd.map(function (ua) {
              if (ua._id === action.uaId) {
                return { ...ua, unlocked: typeof ua.unlocked !== 'undefined' ? !ua.unlocked : true }
              }
              return ua
            }),
          }
        case 'compound':
          return {
            ...state,
            unitaryAnalysesCompound: state.unitaryAnalysesCompound.map(function (ua) {
              if (ua._id === action.uaId) {
                return { ...ua, unlocked: typeof ua.unlocked !== 'undefined' ? !ua.unlocked : true }
              }
              return ua
            }),
            allUnitaryAnalysesCompoundIstd: state.allUnitaryAnalysesCompoundIstd.map(function (ua) {
              if (ua._id === action.uaId) {
                return { ...ua, unlocked: typeof ua.unlocked !== 'undefined' ? !ua.unlocked : true }
              }
              return ua
            }),
          }
          break
        default:
          return state
      }
    case REQUEST_UNITARY_COMPOUND_ISTD:
      return {
        ...state,
        error: false,
        isFetchingUnitaryCompoundISTD: true,
      }
      break
    case RECEIVE_UNITARY_COMPOUND_ISTD:
      return {
        ...state,
        error: false,
        isFetchingUnitaryCompoundISTD: false,
        unitaryCompoundISTD: action.unitaryCompoundISTD,
      }
      break
    case REQUEST_UNITARY_ANALYSES_COMPOUND_QC:
      return {
        ...state,
        error: false,
        isFetchingUnitaryAnalysesCompoundQC: true,
      }
      break
    case RECEIVE_UNITARY_ANALYSES_COMPOUND_QC:
      return {
        ...state,
        error: false,
        isFetchingUnitaryAnalysesCompoundQC: false,
        unitaryAnalysesCompoundQC: action.unitaryAnalysesCompoundQC,
      }
      break
    case REQUEST_ALL_UNITARY_ANALYSES_ISTD:
      return {
        ...state,
        error: false,
        isFetchingAllUnitaryAnalysesIstd: true,
      }
      break
    case RECEIVE_ALL_UNITARY_ANALYSES_ISTD:
      return {
        ...state,
        error: false,
        isFetchingAllUnitaryAnalysesIstd: false,
        allUnitaryAnalysesIstd: action.allUnitaryAnalysesIstd,
      }
      break
    case REQUEST_ALL_UNITARY_ANALYSES_COMPOUND_ISTD:
      return {
        ...state,
        error: false,
        isFetchingAllUnitaryAnalysesCompoundIstd: true,
      }
      break
    case RECEIVE_ALL_UNITARY_ANALYSES_COMPOUND_ISTD:
      return {
        ...state,
        error: false,
        isFetchingAllUnitaryAnalysesCompoundIstd: false,
        allUnitaryAnalysesCompoundIstd: action.allUnitaryAnalysesCompoundIstd,
      }
      break
    case REQUEST_UNITARY_ANALYSES_COMPOUNDS_FOR_DETAIL:
      return {
        ...state,
        error: false,
      }
      return
    case RECEIVE_UNITARY_ANALYSES_COMPOUNDS_FOR_DETAIL:
      return {
        ...state,
        error: false,
        unitaryAnalysesCompoundForDetail: action.unitaryAnalysesCompoundForDetail,
      }
    case RECEIVE_RECOMPUTE_PEAKS:
      // Update UA / UAC / UAISTD / UACISTD:
      const uaRR = action.unitaryAnalysesUpdated.uas.reduce(function (map, obj) {
        map[obj._id] = obj
        return map
      }, {})
      return {
        ...state,
        error: false,
        unitaryAnalysesFiltered: state.unitaryAnalysesFiltered.map(function (ua) {
          if (uaRR[ua._id]) {
            return rebuildUnitaryAnalyses(uaRR[ua._id])
          }
          return ua
        }),
        allUnitaryAnalysesIstd: state.allUnitaryAnalysesIstd.map(function (ua) {
          if (uaRR[ua._id]) {
            return rebuildUnitaryAnalyses(uaRR[ua._id])
          }
          return ua
        }),
        unitaryAnalysesCompound: state.unitaryAnalysesCompound.map(function (ua) {
          if (uaRR[ua._id]) {
            return rebuildUnitaryCompounds(uaRR[ua._id])
          }
          return ua
        }),
        allUnitaryAnalysesCompoundIstd: state.allUnitaryAnalysesCompoundIstd.map(function (ua) {
          if (uaRR[ua._id]) {
            return rebuildUnitaryCompounds(uaRR[ua._id])
          }
          return ua
        }),
      }
    case REQUEST_GLOBAL_UA:
      return {
        ...state,
        criteria: action.criteria,
        isFetchingGlobalUnitaryAnalyses: true,
      }
    case RECEIVE_GLOBAL_UA:
      const formatedData = action.globalUnitaryAnalysesUpdated.map(function (g) {
        return computeUnitaryAnalysesForGlobalTable(g, state.criteria)
      })
      return {
        ...state,
        globalUnitaryAnalyses: formatedData,
        isFetchingGlobalUnitaryAnalyses: false,
      }
    case REQUEST_GLOBAL_UA_SELECTED:
      return {
        ...state,
        isFetchingGlobalUnitaryAnalysesSelected: true,
      }
    case RECEIVE_GLOBAL_UA_SELECTED:
      return {
        ...state,
        globalUnitaryAnalysesSelected: rebuildUnitaryAnalyses(action.globalUnitaryAnalysesSelected),
        isFetchingGlobalUnitaryAnalysesSelected: false,
      }
    default:
      return state
  }
}
