import { REQUEST_ACCOUNTINGS, RECEIVE_ACCOUNTINGS } from './actions'
import React from 'react'

const initialState = {
  accountings: [],
  isFetchingAccountings: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case REQUEST_ACCOUNTINGS:
      return {
        ...state,
        isFetchingAccountings: true,
      }

    case RECEIVE_ACCOUNTINGS:
      return {
        ...state,
        accountings: action.accountings,
        isFetchingAccountings: false,
      }
    default:
      return state
  }
}
