import {
  REQUEST_CONFIGURATION_FEATURES,
  RECEIVE_CONFIGURATION_FEATURES,
  RECEIVE_CONFIGURATIONS,
  REQUEST_CONFIGURATIONS,
  REQUEST_UPDATE_CONFIGURATION,
  RECEIVE_UPDATE_CONFIGURATION,
  RECEIVE_CONFIGURATIONS_ERROR,
  CLEAN_CONFIGURATION_FEATURES,
} from './actions'
import React from 'react'

const initialState = {
  configName: null,
  configFeatures: {},
  isConfigurationLoaded: false,
  isFetchingConfigFeatures: false,
  configurations: [],
  isFetchingConfigurations: false,
  configurationUpdated: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case REQUEST_CONFIGURATION_FEATURES:
      return {
        ...state,
        configName: action.configName,
        isFetchingConfigFeatures: true,
      }

    case RECEIVE_CONFIGURATION_FEATURES:
      const features =
        action.configFeatures && action.configFeatures.content.features
          ? action.configFeatures.content.features
          : {}
      return {
        ...state,
        configFeatures: features,
        isFetchingConfigFeatures: false,
      }
    case REQUEST_CONFIGURATIONS:
      return {
        ...state,
        isFetchingConfigurations: true,
      }
    case RECEIVE_CONFIGURATIONS:
      // const currentConfigId = localStorage.getItem('SETTINGS_configuration_profile')
      // const currentConfig = action.configurations.filter((conf) => conf._id === currentConfigId)[0]
      return {
        ...state,
        // configFeatures:
        //   currentConfig && currentConfig.content.features ? currentConfig.content.features : {},
        configurations: action.configurations,
        isFetchingConfigurations: false,
        isConfigurationLoaded: true,
      }
    case RECEIVE_CONFIGURATIONS_ERROR:
      return {
        ...state,
        isFetchingConfigurations: false,
      }
    case REQUEST_UPDATE_CONFIGURATION:
      return {
        ...state,
        // isFetchingConfigurations: true,
      }
    case RECEIVE_UPDATE_CONFIGURATION:
      return {
        ...state,
        configurations: state.configurations.map(function (config) {
          if (action.configurationUpdated._id === config._id) {
            return action.configurationUpdated
          }
          return config
        }),
        configurationUpdated: state.configurations.map(function (config) {
          if (action.configurationUpdated._id === config._id) {
            return action.configurationUpdated
          }
          return config
        }),
        // isFetchingConfigurations: false,
      }
    case CLEAN_CONFIGURATION_FEATURES:
      return {
        ...state,
        configFeatures: {},
      }
    default:
      return state
  }
}
