import React from 'react'
import { updateGlobalPeak } from '../common/Utils'
import { StyledDialogTitle, TransitionTop } from '../common/Style'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { useDispatch } from 'react-redux'
import { Checkbox, FormControlLabel, FormGroup, Radio } from '@material-ui/core'
import { t } from 'i18next'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { Tooltip } from '@mui/material'

export default function ChromatoApplyAllDialog({ open, closeFct, unitaryAnalyse }) {
  const dispatch = useDispatch()

  const [reintegration, setReintegration] = React.useState(
    localStorage.getItem('CHROMATO_reintegration_types')
      ? JSON.parse(localStorage.getItem('CHROMATO_reintegration_types'))
      : {
          blank: false,
          calibration: true,
          matrix_bio: false,
          matrix_bio_doped: false,
          other: false,
          sample: false,
          std_end: false,
        },
  )
  // const [reintegrationAll, setReintegrationAll] = React.useState(true)
  const [reintegrationMethod, setReintegrationMethod] = React.useState(
    localStorage.getItem('CHROMATO_reintegration_method')
      ? localStorage.getItem('CHROMATO_reintegration_method')
      : 'default',
  )

  const handleConfirmClose = (event, save) => {
    localStorage.setItem('CHROMATO_reintegration_types', JSON.stringify(reintegration))
    localStorage.setItem('CHROMATO_reintegration_method', reintegrationMethod)
    closeFct()
    if (save) {
      updateGlobalPeak(dispatch, unitaryAnalyse, reintegration, reintegrationMethod)
    }
  }

  function handleReintagrationChange(event) {
    setReintegration({
      ...reintegration,
      [event.target.name]: event.target.checked,
    })
  }

  return (
    <Dialog
      open={open}
      onClose={(event) => handleConfirmClose(event, false)}
      TransitionComponent={TransitionTop}
      aria-labelledby="dialog-reset-title"
    >
      <StyledDialogTitle>{t('view.dialogs.chromato_apply_all.title')}</StyledDialogTitle>
      <DialogContent style={{ paddingBottom: 0 }}>
        <DialogContentText>{t('view.dialogs.chromato_apply_all.msg1') + ':'}</DialogContentText>
        <DialogContentText>
          {t('view.dialogs.chromato_apply_all.msg2')}{' '}
          {Object.entries(reintegration).filter((entry) => entry[1]).length === 0 && (
            <Tooltip
              placement="top"
              arrow
              title={
                <div
                  style={{
                    fontSize: 13,
                    padding: 5,
                  }}
                >
                  {t('view.dialogs.chromato_apply_all.error')}
                </div>
              }
            >
              <WarningAmberIcon color="warning" style={{ position: 'absolute', marginLeft: 12 }} />
            </Tooltip>
          )}
        </DialogContentText>
        {/*<FormGroup row>*/}
        {/*  <FormControlLabel*/}
        {/*    control={*/}
        {/*      <Radio*/}
        {/*        checked={reintegrationAll}*/}
        {/*        onChange={() => setReintegrationAll(true)}*/}
        {/*        name="all"*/}
        {/*        value="all"*/}
        {/*      />*/}
        {/*    }*/}
        {/*    label={t('view.dialogs.chromato_apply_all.all')}*/}
        {/*  />*/}
        {/*  <FormControlLabel*/}
        {/*    control={*/}
        {/*      <Radio*/}
        {/*        checked={!reintegrationAll}*/}
        {/*        onChange={() => setReintegrationAll(false)}*/}
        {/*        name="custom"*/}
        {/*        value="custom"*/}
        {/*      />*/}
        {/*    }*/}
        {/*    label={t('view.dialogs.chromato_apply_all.custom')}*/}
        {/*  />*/}
        {/*  <FormControlLabel*/}
        {/*    control={*/}
        {/*      <Radio*/}
        {/*        checked={reintegration === 'samples'}*/}
        {/*        onChange={() => setReintegration('samples')}*/}
        {/*        name="samples"*/}
        {/*        value="samples"*/}
        {/*      />*/}
        {/*    }*/}
        {/*    label={t('view.dialogs.chromato_apply_all.samples')}*/}
        {/*  />*/}
        {/*</FormGroup>*/}
      </DialogContent>
      <DialogContent style={{ paddingTop: 0 }}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                name={'blank'}
                checked={reintegration.blank}
                onChange={handleReintagrationChange}
              />
            }
            label={t('view.dialogs.chromato_apply_all.blank')}
          />
          <FormControlLabel
            control={
              <Checkbox
                name={'calibration'}
                checked={reintegration.calibration}
                onChange={handleReintagrationChange}
              />
            }
            label={t('view.dialogs.chromato_apply_all.calibration')}
          />
          <FormControlLabel
            control={
              <Checkbox
                name={'matrix_bio'}
                checked={reintegration.matrix_bio}
                onChange={handleReintagrationChange}
              />
            }
            label={t('view.dialogs.chromato_apply_all.matrix_bio')}
          />
          <FormControlLabel
            control={
              <Checkbox
                name={'matrix_bio_doped'}
                checked={reintegration.matrix_bio_doped}
                onChange={handleReintagrationChange}
              />
            }
            label={t('view.dialogs.chromato_apply_all.matrix_bio_doped')}
          />
          <FormControlLabel
            control={
              <Checkbox
                name={'sample'}
                checked={reintegration.sample}
                onChange={handleReintagrationChange}
              />
            }
            label={t('view.dialogs.chromato_apply_all.sample')}
          />
          <FormControlLabel
            control={
              <Checkbox
                name={'std_end'}
                checked={reintegration.std_end}
                onChange={handleReintagrationChange}
              />
            }
            label={t('view.dialogs.chromato_apply_all.std_end')}
          />
          <FormControlLabel
            control={
              <Checkbox
                name={'other'}
                checked={reintegration.other}
                onChange={handleReintagrationChange}
              />
            }
            label={t('view.dialogs.chromato_apply_all.other')}
          />
        </FormGroup>
      </DialogContent>
      <DialogContent>
        <DialogContentText> Integration method: </DialogContentText>
        <FormGroup row>
          <FormControlLabel
            control={
              <Radio
                checked={reintegrationMethod === 'default'}
                onChange={() => setReintegrationMethod('default')}
                name="default"
                value="default"
              />
            }
            label={t('view.dialogs.chromato_apply_all.default')}
          />
          <FormControlLabel
            control={
              <Radio
                checked={reintegrationMethod === 'line'}
                onChange={() => setReintegrationMethod('line')}
                name="line"
                value="line"
              />
            }
            label={t('view.dialogs.chromato_apply_all.line')}
          />
          <FormControlLabel
            control={
              <Radio
                checked={reintegrationMethod === 'square'}
                onChange={() => setReintegrationMethod('square')}
                name="square"
                value="square"
              />
            }
            label={t('view.dialogs.chromato_apply_all.square')}
          />
          <FormControlLabel
            control={
              <Radio
                checked={reintegrationMethod === 'zero'}
                onChange={() => setReintegrationMethod('zero')}
                name="zero"
                value="zero"
              />
            }
            label={t('view.dialogs.chromato_apply_all.zero')}
          />
          <FormControlLabel
            control={
              <Radio
                checked={reintegrationMethod === 'base'}
                onChange={() => setReintegrationMethod('base')}
                name="base"
                value="base"
              />
            }
            label={t('view.dialogs.chromato_apply_all.base')}
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={Object.entries(reintegration).filter((entry) => entry[1]).length === 0}
          onClick={(event) => handleConfirmClose(event, true)}
          color="primary"
        >
          {t('view.dialogs.chromato_apply_all.launch')}
        </Button>
        <Button autoFocus onClick={(event) => handleConfirmClose(event, false)} color="primary">
          {t('view.dialogs.chromato_apply_all.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
