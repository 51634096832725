import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'

import ChromatoScatter from './ChromatoScatter'
import { fade } from '@material-ui/core/styles'
import ErrorBoundaryGuard from '../../ErrorBoundaryGuard'

const useStyles = makeStyles((theme) => ({
  chromatoGrid: {
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)',
      '& button': {
        opacity: 1,
      },
    },
    // '&:hover span.fullscreenMode': {
    //   opacity: '0.5 !important',
    //   transition: '0.1s',
    // },
    // '&:hover span.fullscreenMode:hover': {
    //   opacity: '1 !important',
    //   transition: '0.1s',
    // },
  },
  blink: {
    // animation: 'blink-fade 1s ease-in-out 0s',
    background: fade(theme.palette.secondary.light, 0.19),
    '&:hover': {
      background: fade(theme.palette.secondary.light, 0.37),
    },
  },
  noBlink: {
    cursor: 'pointer',
  },
}))

export default function ChromatoGraph({
  getSelectedChannel,
  onChangeChannel,
  unitaryAnalyse,
  style,
  selected,
  chromatoSize,
  dispatch,
  uidKey,
  onClick,
  onDoubleClick,
  title,
  collection,
  isIstd,
  checkSelected,
  onSelect,
  index,
  selection,
  canEdit,
}) {
  const classes = useStyles()

  return (
    <div
      style={style}
      key={uidKey}
      className={clsx(
        'unitaryAnalyse',
        classes.chromatoGrid,
        selected ? classes.blink : classes.noBlink,
      )}
      event={unitaryAnalyse.event}
      unitaryanalysesid={unitaryAnalyse._id}
      onClick={(event) => {
        // Do not trigger the clic if it's just a selection for the checkbox
        if (event.target.type !== 'checkbox') {
          onClick(unitaryAnalyse)
        }
      }}
      onDoubleClick={(event) => {
        // Do not trigger the dblclic if it's just a selection for the checkbox
        if (event.target.type !== 'checkbox') {
          onDoubleClick(event)
        }
      }}
    >
      <ErrorBoundaryGuard>
        <ChromatoScatter
          isSelected={selected}
          canEdit={canEdit}
          index={index}
          checkSelected={checkSelected}
          selection={selection}
          onSelect={onSelect}
          updateCalibrationAfterPeakUpdate={onClick}
          getSelectedChannel={getSelectedChannel}
          onChangeChannel={onChangeChannel}
          keyValue={`scatter_${uidKey}`}
          unitaryAnalyse={unitaryAnalyse}
          dispatch={dispatch}
          collection={collection}
          title={title(unitaryAnalyse)}
          chromatoSize={chromatoSize}
          showChannels={true}
          currentPeaks={
            unitaryAnalyse.mainChannel === unitaryAnalyse.selectedChannel
              ? unitaryAnalyse.main_peak_base &&
                unitaryAnalyse.main_peak_base.left &&
                unitaryAnalyse.main_peak_base.right
                ? [unitaryAnalyse.main_peak_base.left, unitaryAnalyse.main_peak_base.right]
                : []
              : unitaryAnalyse.channels_data.filter(
                  (ch) => ch.channelNumber === unitaryAnalyse.selectedChannel,
                ).length !== 0 &&
                unitaryAnalyse.channels_data.filter(
                  (ch) => ch.channelNumber === unitaryAnalyse.selectedChannel,
                )[0].peak &&
                unitaryAnalyse.channels_data.filter(
                  (ch) => ch.channelNumber === unitaryAnalyse.selectedChannel,
                )[0].peak.base
              ? [
                  unitaryAnalyse.channels_data.filter(
                    (ch) => ch.channelNumber === unitaryAnalyse.selectedChannel,
                  )[0].peak.base.left,
                  unitaryAnalyse.channels_data.filter(
                    (ch) => ch.channelNumber === unitaryAnalyse.selectedChannel,
                  )[0].peak.base.right,
                ]
              : []
          }
          initPeaks={
            unitaryAnalyse.main_peak_base_left_init && unitaryAnalyse.main_peak_base_right_init
              ? [unitaryAnalyse.main_peak_base_left_init, unitaryAnalyse.main_peak_base_right_init]
              : []
          }
          isInitPeaks={
            Object.keys(unitaryAnalyse.main_peak_base_left_init).length === 2 &&
            Object.keys(unitaryAnalyse.main_peak_base_right_init).length === 2 &&
            Object.keys(unitaryAnalyse.main_peak_base).length === 2
              ? unitaryAnalyse.main_peak_base_left_init.x !==
                  unitaryAnalyse.main_peak_base.left.x ||
                unitaryAnalyse.main_peak_base_left_init.y !==
                  unitaryAnalyse.main_peak_base.left.y ||
                unitaryAnalyse.main_peak_base_right_init.x !==
                  unitaryAnalyse.main_peak_base.right.x ||
                unitaryAnalyse.main_peak_base_right_init.y !== unitaryAnalyse.main_peak_base.right.y
              : false
          }
        />
      </ErrorBoundaryGuard>
    </div>
  )
}
