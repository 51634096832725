import { DISPLAY_JSON, HIDE_JSON, REQUEST_JSON } from './actions'
import React from 'react'

const initialState = {
  json: null,
  visibility: false,
  isFetchingJson: false,
  collection: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case DISPLAY_JSON:
      return {
        ...state,
        visibility: action.visibility,
        json: action.json,
        isFetchingJson: false,
      }

    case HIDE_JSON:
      return {
        ...state,
        visibility: action.visibility,
        isFetchingJson: false,
      }
    case REQUEST_JSON:
      return {
        ...state,
        visibility: action.visibility,
        collection: action.collection,
        isFetchingJson: true,
      }
    default:
      return state
  }
}
