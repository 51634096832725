import { PIN_UNPIN_DRAWER } from './actions'
import React from 'react'

const initialState = {
  pin: localStorage.getItem('NAVIGATION_pin_panel')
    ? localStorage.getItem('NAVIGATION_pin_panel') === 'true'
    : false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case PIN_UNPIN_DRAWER:
      return {
        ...state,
        pin: action.pin,
      }
    default:
      return state
  }
}
