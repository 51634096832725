import {
  REQUEST_GLOBAL_CONFIGURATIONS,
  RECEIVE_GLOBAL_CONFIGURATIONS,
  FAILED_RECEIVE_GLOBAL_CONFIGURATIONS,
} from './actions'
import React from 'react'

const initialState = {
  globalConfigs: [],
  featuresConfig: null,
  customerConfig: null,
  isFetchingGlobalConfigs: false,
  isGlobalConfigsLoaded: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case REQUEST_GLOBAL_CONFIGURATIONS:
      return {
        ...state,
        isFetchingGlobalConfigs: true,
      }

    case RECEIVE_GLOBAL_CONFIGURATIONS:
      return {
        ...state,
        globalConfigs: action.globalConfigs,
        featuresConfig: action.globalConfigs.filter((gc) => gc.name === 'features')[0],
        customerConfig: action.globalConfigs.filter((gc) => gc.name === 'customer')[0],
        isFetchingGlobalConfigs: false,
        isGlobalConfigsLoaded: true,
      }
    case FAILED_RECEIVE_GLOBAL_CONFIGURATIONS:
      return {
        ...state,
        isFetchingGlobalConfigs: false,
        isGlobalConfigsLoaded: true,
      }
    default:
      return state
  }
}
