import { find } from 'lodash'
import { API_BO_URL } from '../../utils/config'

export async function getFilterRawById(id) {
  try {
    const response = await fetch(`${API_BO_URL()}.web.filter/${id}`, {
      method: 'GET',
      credentials: 'include',
    })
    const json = await response.json()
    const error = response.status !== 200
    if (error) {
      console.log(response.statusText)
      return null
    } else {
      return json
    }
  } catch (e) {
    console.log(e)
    return null
  }
}
