import sizeof from 'object-sizeof'

const DB_NAME = 'CacheDB'
const DB_VERSION = 1
export const STORE_NAME = 'cacheStore'

export function openDatabase() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION)
    request.onupgradeneeded = function (event) {
      const db = event.target.result
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        const store = db.createObjectStore(STORE_NAME, { keyPath: '_id' })
        store.createIndex('lastUsed', 'lastUsed', { unique: false })
        store.createIndex('frequency', 'frequency', { unique: false })
      }
    }
    request.onsuccess = function (event) {
      resolve(event.target.result)
    }
    request.onerror = function (event) {
      reject(event.target.error)
    }
  })
}

export function getCache(db) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], 'readonly')
    const store = transaction.objectStore(STORE_NAME)
    const getAllRequest = store.getAll()
    getAllRequest.onsuccess = function (event) {
      resolve(event.target.result)
    }
    getAllRequest.onerror = function (event) {
      reject(event.target.error)
    }
  })
}

export function addObjectsToCache(db, toAdd) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], 'readwrite')
    const store = transaction.objectStore(STORE_NAME)
    toAdd.forEach((item) => {
      const { _id, modificationDate } = item
      const getRequest = store.get(_id)
      getRequest.onsuccess = function (event) {
        const existingItem = event.target.result

        if (existingItem) {
          if (existingItem.modificationDate < modificationDate) {
            store.put({
              ...item,
              lastUsed: Date.now(),
              frequency: (existingItem.frequency || 0) + 1,
            })
          }
        } else {
          store.put({ ...item, lastUsed: Date.now(), frequency: 1 })
        }
      }
    })
    transaction.oncomplete = function () {
      resolve(true)
    }
    transaction.onerror = function (event) {
      reject(event.target.error)
    }
  })
}

export function manageCacheSize(db, maxCacheSize, cachePolicy, dispatch) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], 'readwrite')
    const store = transaction.objectStore(STORE_NAME)
    const getAllRequest = store.getAll()
    getAllRequest.onsuccess = async function (event) {
      const items = event.target.result
      let totalSize = items.reduce((acc, item) => acc + sizeof(item), 0)
      if (totalSize <= maxCacheSize) {
        dispatch({ type: 'SET_CACHE_SIZE', size: totalSize })
        resolve(true)
        return
      }
      const removeItems = async (comparator) => {
        items.sort(comparator)
        for (const item of items) {
          if (totalSize <= maxCacheSize) break
          const itemToRemove = items.shift()
          const deleteRequest = store.delete(itemToRemove._id)
          await new Promise((resolve, reject) => {
            deleteRequest.onsuccess = function () {
              totalSize -= sizeof(itemToRemove)
              resolve()
            }
            deleteRequest.onerror = function (event) {
              reject(event.target.error)
            }
          })
        }
      }
      if (cachePolicy === 'fifo') {
        await removeItems((a, b) => a.lastUsed - b.lastUsed)
      } else if (cachePolicy === 'lru') {
        await removeItems((a, b) => a.lastUsed - b.lastUsed)
      } else if (cachePolicy === 'lfu') {
        await removeItems((a, b) => a.frequency - b.frequency)
      } else if (cachePolicy === 'random') {
        while (totalSize > maxCacheSize && items.length > 0) {
          const randomIndex = Math.floor(Math.random() * items.length)
          const itemToRemove = items.splice(randomIndex, 1)[0]
          const deleteRequestR = store.delete(itemToRemove._id)
          await new Promise((resolve, reject) => {
            deleteRequestR.onsuccess = function () {
              totalSize -= sizeof(itemToRemove)
              resolve()
            }
            deleteRequestR.onerror = function (event) {
              reject(event.target.error)
            }
          })
        }
      }
      transaction.oncomplete = function () {
        dispatch({ type: 'SET_CACHE_SIZE', size: totalSize })
        resolve(true)
      }
      transaction.onerror = function (event) {
        reject(event.target.error)
      }
    }
    getAllRequest.onerror = function (event) {
      reject(event.target.error)
    }
  })
}
export const initializeCacheSize = () => async (dispatch) => {
  try {
    const db = await openDatabase()
    const cachePopulation = await getCache(db)
    const cacheSize = cachePopulation.reduce((acc, item) => acc + sizeof(item), 0)
    dispatch({ type: 'SET_CACHE_SIZE', size: cacheSize })
  } catch (error) {
    console.error('Error initializing cache size', error)
  }
}
