import React from 'react'
import { connect, useSelector } from 'react-redux'
import { fade, withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import CheckIcon from '@material-ui/icons/Check'
import Grid from '@material-ui/core/Grid'
import { withRouter } from 'react-router-dom'
import Title from '../Title'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ClearIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'
import {
  fetchUnitaryCalibrationsDistinct,
  fetchUnitaryCalibrationsFiltered,
  sortUnitaryCalibrationFiltered,
} from '../../redux/unitaryCalibrations/actions'
import {
  callValidationDispatch,
  canIModifyTheCalibration,
  customFormatDecimals,
  displayHumanReadable,
  getClickAction,
  handleValidation,
  updateISTD,
} from '../common/Utils'
import { StyledDialogTitle, TransitionTop } from '../common/Style'
import clsx from 'clsx'
import TableMultiGrid from '../common/Table_MultiGrid'
import AlphacodFlagIcon from '../common/AlphacodFlagIcon'
import { Tooltip } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Draggable from 'react-draggable'
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import Badge from '@material-ui/core/Badge'
import GroupWorkIcon from '@material-ui/icons/GroupWork'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore'
import CalibrationResetDialog from '../dialog/CalibrationResetDialog'
import AlphacodModifiedButtonIcon from '../common/AlphacodModifiedButtonIcon'
import NoteButton from '../common/NoteButton'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import { t } from 'i18next'
import { Stack } from '@mui/material'

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    // height: '500px',
    height: '100%',
    justifyContent: 'flex-start',
    flex: '1 1 auto',
    position: 'relative',
    overflowY: 'auto',
  },
  buttonCalib: {
    borderRadius: '18px !important',
    backgroundColor: '#e0e0e0',
    padding: '3px 12px 3px 14px',
    marginRight: 20,
    height: 32,
    border: '1px solid rgba(0, 0, 0, 0.12) !important',
    '&.Mui-selected .MuiBadge-badge': {
      color: fade(theme.palette.secondary.main, 0.8),
    },
  },
  progress: {
    paddingTop: '0px !important',
    paddingBottom: 10,
  },
  iconValid: {
    fontSize: 13,
  },
  tableMolecule: {
    whiteSpace: 'nowrap',

    '& > tbody .MuiTableCell-sizeSmall': {
      padding: '2px 24px 0px 16px',
    },
  },
  tableContainer: {
    height: '100%',
    // overflow: 'hidden',
    // '&:hover': {
    //   overflow: 'auto',
    // },
    '& .ReactVirtualized__Grid:focus': {
      outline: 'none',
    },
  },
  tableHeader: {
    backgroundColor: 'white',
    padding: 10,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  actionContainer: {
    '& button': {
      padding: 0,
      marginRight: 5,
      width: 24,
    },
    '& > button:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
    },
  },
  success: {
    color: theme.palette.common.success,
  },
  error: {
    color: theme.palette.common.error,
  },
  noInfo: {
    opacity: 0,
  },
  selected: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  notSelected: {
    opacity: 0.5,
    '&:hover': {
      opacity: 1,
    },
  },
  selectedRow: {
    cursor: 'pointer',
    background: 'rgba(0, 0, 0, 0.1)',
  },
  blink: {
    // animation: 'blink-fade 1s ease-in-out 0s',
    background: fade(theme.palette.secondary.light, 0.19),
  },
  selectedRowBlink: {
    background: fade(theme.palette.secondary.light, 0.37),
  },
  noBlink: {
    cursor: 'pointer',
  },
  cellFlags: {
    padding: 7,
  },
  tooltip: {
    fontSize: 13,
    padding: 5,
  },
  toolbarButton: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.03),
    marginRight: 5,
    width: 34,
    height: 34,
    padding: 8,
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: fade(theme.palette.common.black, 0.03),
    },
  },
  toolbar: {
    position: 'absolute',
    top: -38,
    left: 49,
    background: '#fff',
    padding: 5,
    paddingRight: 1,
    border: '2px solid',
    borderColor: theme.palette.secondary.main,
    borderRadius: 8,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.03),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.06),
    },
    '& div:first-child': {
      color: fade(theme.palette.common.black, 0.54),
    },
    '&:hover div:first-child': {
      color: theme.palette.secondary.main,
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    // pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  clearIcon: {
    top: 0,
    right: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.main,
    },
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    color: 'black',
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    // paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '16ch',
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
      },
    },
  },
  whiteSpace: {
    flexGrow: 1,
  },
  formControl: {
    '& .MuiInput-root::before': {
      display: 'none',
    },
    '& .MuiSelect-select.MuiSelect-select': {
      padding: '0px 24px 0px 0px !important',
      fontSize: 14,
    },
  },
  badgeCount: {
    '& .MuiBadge-badge': {
      right: '-12px',
      top: '-5px',
      color: fade(theme.palette.primary.main, 0.6),
      background: 'white',
      boxShadow: '0px 2px 5px 1px rgba(0,0,0,0.15)',
    },
  },
  badgeCountRect: {
    '& .MuiBadge-badge': {
      right: '6%',
      top: 0,
      background: 'white',
      color: fade(theme.palette.primary.main, 0.6),
      boxShadow: '0px 2px 5px 1px rgba(0,0,0,0.15)',
    },
  },
  badgeCountSelected: {
    '& .MuiBadge-badge': {
      right: '-12px',
      top: '-5px',
      background: 'white',
      color: fade(theme.palette.secondary.main, 0.8),
      boxShadow: '0px 2px 5px 1px rgba(0,0,0,0.15)',
    },
  },
  badgeCountRectSelected: {
    '& .MuiBadge-badge': {
      right: '6%',
      top: 0,
      background: 'white',
      color: fade(theme.palette.secondary.main, 0.8),
      boxShadow: '0px 2px 5px 1px rgba(0,0,0,0.15)',
    },
  },
  filterButton: {
    borderRadius: 18,
    border: '1px solid #c5c5c5',
    backgroundColor: 'rgb(224 224 224)',
    marginRight: 5,
    marginLeft: 15,
    width: 50,
    height: 34,
    padding: 8,
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.05),
    },
  },
  selectedButton: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
})

const mapStateToProps = (state) => {
  const { unitaryCalibrations, global } = state
  return {
    unitaryCalibrationsFiltered: unitaryCalibrations.unitaryCalibrationsFiltered,
    isFetchingUnitaryCalibrationsFiltered:
      unitaryCalibrations.isFetchingUnitaryCalibrationsFiltered,
    unitaryCalibrationsDistinct: unitaryCalibrations.unitaryCalibrationsDistinct,
    isFetchingUnitaryCalibrationsDistinct:
      unitaryCalibrations.isFetchingUnitaryCalibrationsDistinct,
    featuresConfig: global.featuresConfig,
  }
}

export default withRouter(
  connect(mapStateToProps)(
    withStyles(styles)(
      class CalibTable extends React.Component {
        constructor(props) {
          super(props)
          this.state = {
            error: null,
            isLoaded: false,
            items: [],
            batch: null,
            order: 'asc',
            orderBy: 'name',
            filterSelected: localStorage.getItem('BATCH_filter_calibration')
              ? localStorage.getItem('BATCH_filter_calibration')
              : 'istd',
            countLoaded: false,
            filterCount: null,
            selection: [],
            search: '',
            timer: null,
            displayClear: false,
            open: false,
            itemToUpdate: null,
            newISTDGroup: null,
            untreatedChecked: localStorage.getItem('BATCH_filter_untreated')
              ? JSON.parse(localStorage.getItem('BATCH_filter_untreated')) === true
              : false,
            validationOk: localStorage.getItem('BATCH_filter_detected')
              ? JSON.parse(localStorage.getItem('BATCH_filter_detected')) === true
              : false,
            validationKo: localStorage.getItem('BATCH_filter_notDetected')
              ? JSON.parse(localStorage.getItem('BATCH_filter_notDetected')) === true
              : false,
            openConfirmReset: false,
          }
          this.onSelect = this.onSelect.bind(this)
          this.multiValidate = this.multiValidate.bind(this)
          this.onSearch = this.onSearch.bind(this)
          this.clearSearch = this.clearSearch.bind(this)
          this.handleReset = this.handleReset.bind(this)
          this.handleConfirmResetClose = this.handleConfirmResetClose.bind(this)
          this.searchRef = React.createRef()
          this.tableMultiGridRef = React.createRef()
        }

        async componentDidMount() {
          const { dispatch } = this.props
          const { filterSelected, untreatedChecked, validationOk, validationKo } = this.state

          dispatch(
            fetchUnitaryCalibrationsFiltered(
              this.props.match.params.batchId,
              filterSelected,
              untreatedChecked,
              validationOk,
              validationKo,
              localStorage.getItem('BATCH_sorter_calibration'),
            ),
          )
          dispatch(
            fetchUnitaryCalibrationsDistinct(this.props.match.params.batchId, untreatedChecked),
          )
        }

        onSelect(selected) {
          this.setState({
            selection: selected.map((i) => this.props.unitaryCalibrationsFiltered[i]),
          })
        }

        multiValidate(value) {
          const selection = this.state.selection
          const dispatch = this.props.dispatch
          callValidationDispatch(
            'unitary_calibration',
            dispatch,
            selection,
            value,
            null,
            this.props.match.params.batchId,
          )
          this.tableMultiGridRef.current.clearSelection()
        }

        onSearch(e) {
          this.tableMultiGridRef.current.clearSelection()
          const { timer } = this.state
          const newValue = e.target.value
          clearTimeout(timer)
          const newTimer = setTimeout(
            function () {
              this.setState({ search: newValue })
            }.bind(this),
            1500,
          )
          this.setState({ timer: newTimer, displayClear: newValue !== '' })
        }

        clearSearch() {
          this.searchRef.current.children[0].value = ''
          this.setState({ search: '', displayClear: false })
        }

        filterUnitaryCalibrations() {
          if (!this.props.unitaryCalibrationsFiltered) return []
          const search = this.state.search
          if (search === '') return this.props.unitaryCalibrationsFiltered

          return this.props.unitaryCalibrationsFiltered.filter(
            (uc) =>
              (uc.name && uc.name.toLowerCase().includes(search.toLowerCase())) ||
              (uc.state && uc.state.toLowerCase().includes(search.toLowerCase())) ||
              (uc.event && uc.event.toString().toLowerCase().includes(search.toLowerCase())) ||
              (uc.rt && uc.rt.toString().toLowerCase().includes(search.toLowerCase())) ||
              (uc.r2 && uc.r2.toString().toLowerCase().includes(search.toLowerCase())) ||
              (uc.istd && uc.istd.toString().toLowerCase().includes(search.toLowerCase())) ||
              (uc.ret_time &&
                uc.ret_time.toString().toLowerCase().includes(search.toLowerCase())) ||
              (uc.q1 && uc.q1.toString().toLowerCase().includes(search.toLowerCase())) ||
              (uc.q3 && uc.q3.toString().toLowerCase().includes(search.toLowerCase())) ||
              (uc.type && uc.type.toString().toLowerCase().includes(search.toLowerCase())),
          )
        }

        handleConfirmResetClose() {
          this.setState({ openConfirmReset: false })
        }

        handleReset(event) {
          event.stopPropagation()
          event.preventDefault()
          this.setState({ openConfirmReset: true })
        }

        render() {
          const {
            dispatch,
            classes,
            unitaryCalibrationsFiltered,
            isFetchingUnitaryCalibrationsFiltered,
            unitaryCalibrationsDistinct,
            isFetchingUnitaryCalibrationsDistinct,
            onDisplayCalib,
            tableItemSelectedForColor,
            batch,
            featuresConfig,
          } = this.props
          const {
            filterSelected,
            newISTDGroup,
            open,
            itemToUpdate,
            untreatedChecked,
            validationOk,
            validationKo,
            openConfirmReset,
            selection,
          } = this.state

          const tableMultiGridRef = this.tableMultiGridRef
          function canEditCalib() {
            const edit_calibration = featuresConfig?.content?.edit_calibration
            const param_exist = typeof edit_calibration === 'boolean'
            const getRights = canIModifyTheCalibration(batch)
            return param_exist ? edit_calibration && getRights : true
          }

          const applyFilter = async (event, newFilter) => {
            this.tableMultiGridRef.current.clearSelection()
            if (newFilter) {
              this.setState({
                validationOk: false,
                validationKo: false,
                untreatedChecked: false,
              })
              localStorage.setItem('BATCH_filter_detected', 'false')
              localStorage.setItem('BATCH_filter_notDetected', 'false')
              localStorage.setItem('BATCH_filter_untreated', 'false')
            }
            dispatch(
              fetchUnitaryCalibrationsFiltered(
                this.props.match.params.batchId,
                newFilter,
                newFilter ? false : untreatedChecked,
                newFilter ? false : this.state.validationOk,
                newFilter ? false : this.state.validationKo,
                localStorage.getItem('BATCH_sorter_calibration'),
              ),
            )
            localStorage.setItem('BATCH_filter_calibration', newFilter)
            this.setState({ filterSelected: newFilter })
          }

          const handleUntreatedChange = async (event, newFilter) => {
            this.tableMultiGridRef.current.clearSelection()
            const pressed = !this.state.untreatedChecked
            if (pressed) {
              this.setState({
                filterSelected: null,
                validationOk: false,
                validationKo: false,
              })
              localStorage.setItem('BATCH_filter_calibration', null)
              localStorage.setItem('BATCH_filter_detected', 'false')
              localStorage.setItem('BATCH_filter_notDetected', 'false')
            }
            dispatch(
              fetchUnitaryCalibrationsFiltered(
                this.props.match.params.batchId,
                pressed ? null : this.state.filterSelected,
                !this.state.untreatedChecked,
                pressed ? false : this.state.validationOk,
                pressed ? false : this.state.validationKo,
                localStorage.getItem('BATCH_sorter_calibration'),
              ),
            )
            localStorage.setItem(
              'BATCH_filter_untreated',
              (!this.state.untreatedChecked).toString(),
            )
            this.setState({ untreatedChecked: !this.state.untreatedChecked })
            // dispatch(
            //   fetchUnitaryCalibrationsDistinct(
            //     this.props.match.params.batchId,
            //     !this.state.untreatedChecked,
            //   ),
            // )
          }

          const handleChangeValidationOk = async (event, newFilter) => {
            this.tableMultiGridRef.current.clearSelection()
            const pressed = !this.state.validationOk
            if (pressed) {
              this.setState({
                untreatedChecked: false,
                filterSelected: null,
              })
              localStorage.setItem('BATCH_filter_untreated', 'false')
              localStorage.setItem('BATCH_filter_calibration', null)
            }

            dispatch(
              fetchUnitaryCalibrationsFiltered(
                this.props.match.params.batchId,
                pressed ? null : this.state.filterSelected,
                pressed ? false : this.state.untreatedChecked,
                !this.state.validationOk,
                this.state.validationKo,
                localStorage.getItem('BATCH_sorter_calibration'),
              ),
            )
            localStorage.setItem('BATCH_filter_detected', (!this.state.validationOk).toString())
            this.setState({ validationOk: !this.state.validationOk })
          }

          const handleChangeValidationKo = async (event, newFilter) => {
            this.tableMultiGridRef.current.clearSelection()
            const pressed = !this.state.validationKo
            if (pressed) {
              this.setState({
                untreatedChecked: false,
                filterSelected: null,
              })
              localStorage.setItem('BATCH_filter_untreated', 'false')
              localStorage.setItem('BATCH_filter_calibration', null)
            }

            dispatch(
              fetchUnitaryCalibrationsFiltered(
                this.props.match.params.batchId,
                pressed ? null : this.state.filterSelected,
                pressed ? false : this.state.untreatedChecked,
                this.state.validationOk,
                !this.state.validationKo,
                localStorage.getItem('BATCH_sorter_calibration'),
              ),
            )
            localStorage.setItem('BATCH_filter_detected', (!this.state.validationKo).toString())
            this.setState({ validationKo: !this.state.validationKo })
          }

          const moleculeDoubleClick = (item) => {
            localStorage.setItem('BATCH_previous_selection', item._id)
            this.props.history.push(
              '/?' + this.props.match.params.batchId + '/compound/' + item.event,
            )
          }
          const StyledToggleButton = withStyles((theme) => ({
            selected: {
              backgroundColor: theme.palette.secondary.light + ' !important',
              color: 'white !important',
            },
          }))(ToggleButton)

          const selectionEmpty = this.state.selection.length === 0

          const unitaryCalibrationsFilteredSearched = this.filterUnitaryCalibrations()

          // if (isFetchingUnitaryCalibrationsFiltered) {
          //   // if (!unitaryCalibrationsFiltered) {
          //   return <Loading mode="miniCircle" />
          // }

          const onWhichUpdateISTD = function (event, item) {
            this.setState({ open: true, newISTDGroup: event.target.value, itemToUpdate: item })
          }.bind(this)

          const handleClose = function (event, confirm) {
            this.setState({ open: false })
            if (typeof confirm === 'boolean' && confirm) {
              updateISTD(dispatch, newISTDGroup, itemToUpdate.event, batch, itemToUpdate, 'batch')
              // this.setState({ open: false, isFetchingUnitaryCalibrationsFiltered: true })
            }
          }.bind(this)

          return (
            <>
              <ErrorBoundaryGuard isDialog>
                <CalibrationResetDialog
                  open={openConfirmReset}
                  closeFct={this.handleConfirmResetClose}
                  calibration={selection}
                />
              </ErrorBoundaryGuard>
              <Dialog
                open={open}
                onClose={handleClose}
                TransitionComponent={TransitionTop}
                aria-labelledby="dialog-title"
              >
                <StyledDialogTitle>{t('view.batch.calibTable.istd_update')}</StyledDialogTitle>
                <DialogContent>
                  <DialogContentText>{t('view.batch.calibTable.sure')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={(event) => handleClose(event, true)} color="primary">
                    {t('view.batch.calibTable.ok')}
                  </Button>
                  <Button autoFocus onClick={(event) => handleClose(event, false)} color="primary">
                    {t('view.batch.calibTable.cancel')}
                  </Button>
                </DialogActions>
              </Dialog>
              <Stack
                direction="column"
                justifyContent="space-around"
                alignItems="stretch"
                spacing={0}
                style={{ height: '100%' }}
              >
                <Title>
                  {/*<Grid container spacing={3} direction="row" justify="space-between">*/}
                  {/*  <Grid item>*/}
                  {/*    <Box>Calibration</Box>*/}
                  {/*  </Grid>*/}
                  {/*</Grid>*/}
                  <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="space-between"
                    style={{ marginTop: 8 }}
                  >
                    <Grid item style={{ paddingTop: 0 }}>
                      <Box>{t('view.batch.calibTable.calibration')}</Box>
                    </Grid>
                    <Grid item className={classes.progress}>
                      <ToggleButtonGroup
                        value={filterSelected}
                        exclusive
                        onChange={applyFilter}
                        aria-label="text alignment"
                      >
                        <StyledToggleButton
                          className={classes.buttonCalib}
                          value={'istd'}
                          aria-label="ISTD"
                        >
                          <Tooltip
                            key={'tooltip_istd'}
                            arrow
                            title={
                              <div style={{ fontSize: 13, padding: 5 }}>
                                {t('view.batch.calibTable.all_istd')}
                              </div>
                            }
                          >
                            <Badge
                              className={classes.badgeCount}
                              max={10000}
                              showZero={!isFetchingUnitaryCalibrationsDistinct}
                              badgeContent={
                                !isFetchingUnitaryCalibrationsDistinct &&
                                unitaryCalibrationsDistinct.True
                                  ? unitaryCalibrationsDistinct.True.count
                                  : 0
                              }
                            >
                              <GroupWorkIcon style={{ marginRight: 5 }} />
                              {t('view.batch.calibTable.istd')}
                            </Badge>
                          </Tooltip>
                        </StyledToggleButton>
                        <StyledToggleButton
                          className={classes.buttonCalib}
                          value={'ok'}
                          aria-label="OK"
                        >
                          <Tooltip
                            key={'tooltip_filter_ok'}
                            arrow
                            title={
                              <div style={{ fontSize: 13, padding: 5 }}>
                                {t('view.batch.calibTable.all_ok')}
                              </div>
                            }
                          >
                            <Badge
                              className={classes.badgeCount}
                              max={10000}
                              showZero={!isFetchingUnitaryCalibrationsDistinct}
                              badgeContent={
                                !isFetchingUnitaryCalibrationsDistinct &&
                                unitaryCalibrationsDistinct.ok
                                  ? unitaryCalibrationsDistinct.ok.count
                                  : 0
                              }
                            >
                              <ThumbUpIcon style={{ marginRight: 5 }} />
                              {t('view.batch.calibTable.ok_button')}
                            </Badge>
                          </Tooltip>
                        </StyledToggleButton>
                        <StyledToggleButton
                          className={classes.buttonCalib}
                          value={'ko'}
                          aria-label="KO"
                        >
                          <Tooltip
                            key={'tooltip_filter_ko'}
                            arrow
                            title={
                              <div style={{ fontSize: 13, padding: 5 }}>
                                {t('view.batch.calibTable.all_ko')}
                              </div>
                            }
                          >
                            <Badge
                              className={classes.badgeCount}
                              max={10000}
                              showZero={!isFetchingUnitaryCalibrationsDistinct}
                              badgeContent={
                                !isFetchingUnitaryCalibrationsDistinct &&
                                unitaryCalibrationsDistinct.ko
                                  ? unitaryCalibrationsDistinct.ko.count
                                  : 0
                              }
                            >
                              <ThumbDownIcon style={{ marginRight: 5 }} />
                              {t('view.batch.calibTable.ko_button')}
                            </Badge>
                          </Tooltip>
                        </StyledToggleButton>
                      </ToggleButtonGroup>
                      <div style={{ display: 'inline-flex' }}>
                        <Tooltip
                          key={'tooltip_untreated'}
                          arrow
                          title={
                            <div style={{ fontSize: 13, padding: 5 }}>
                              {t('view.batch.calibTable.untreated')}
                            </div>
                          }
                        >
                          <Badge
                            className={clsx(
                              classes.badgeCountRect,
                              untreatedChecked ? classes.badgeCountRectSelected : null,
                            )}
                            max={10000}
                            showZero={!isFetchingUnitaryCalibrationsDistinct}
                            overlap="circle"
                            badgeContent={
                              !isFetchingUnitaryCalibrationsDistinct &&
                              unitaryCalibrationsDistinct[0]
                                ? unitaryCalibrationsDistinct[0].count
                                : 0
                            }
                          >
                            <IconButton
                              style={{ marginLeft: 50 }}
                              aria-label="untreated"
                              className={clsx(
                                classes.filterButton,
                                untreatedChecked
                                  ? classes.selectedButton
                                  : classes.notSelectedButton,
                              )}
                              onClick={handleUntreatedChange}
                            >
                              <NewReleasesIcon />
                            </IconButton>
                          </Badge>
                        </Tooltip>
                        <Tooltip
                          key={'tooltip_detected'}
                          arrow
                          title={
                            <div style={{ fontSize: 13, padding: 5 }}>
                              {t('view.batch.calibTable.filter_ok')}
                            </div>
                          }
                        >
                          <Badge
                            className={clsx(
                              classes.badgeCountRect,
                              validationOk ? classes.badgeCountRectSelected : null,
                            )}
                            max={10000}
                            showZero={!isFetchingUnitaryCalibrationsDistinct}
                            overlap="circle"
                            badgeContent={
                              !isFetchingUnitaryCalibrationsDistinct &&
                              unitaryCalibrationsDistinct[1]
                                ? unitaryCalibrationsDistinct[1].count
                                : 0
                            }
                          >
                            <IconButton
                              aria-label="detected"
                              className={clsx(
                                classes.filterButton,
                                validationOk ? classes.selectedButton : classes.notSelectedButton,
                              )}
                              onClick={handleChangeValidationOk}
                            >
                              <CheckIcon />
                            </IconButton>
                          </Badge>
                        </Tooltip>
                        <Tooltip
                          key={'tooltip_notdetected'}
                          arrow
                          title={
                            <div style={{ fontSize: 13, padding: 5 }}>
                              {t('view.batch.calibTable.filter_ko')}
                            </div>
                          }
                        >
                          <Badge
                            className={clsx(
                              classes.badgeCountRect,
                              validationKo ? classes.badgeCountRectSelected : null,
                            )}
                            max={10000}
                            showZero={!isFetchingUnitaryCalibrationsDistinct}
                            overlap="circle"
                            badgeContent={
                              !isFetchingUnitaryCalibrationsDistinct &&
                              unitaryCalibrationsDistinct[2]
                                ? unitaryCalibrationsDistinct[2].count
                                : 0
                            }
                          >
                            <IconButton
                              aria-label="notDetected"
                              className={clsx(
                                classes.filterButton,
                                validationKo ? classes.selectedButton : classes.notSelectedButton,
                              )}
                              onClick={handleChangeValidationKo}
                            >
                              <ClearIcon />
                            </IconButton>
                          </Badge>
                        </Tooltip>
                      </div>
                    </Grid>
                    <Grid item className={classes.progress}>
                      {/*<Divider orientation="vertical" />*/}
                    </Grid>
                    <Grid item className={classes.progress}>
                      <div className={classes.search}>
                        <div className={classes.searchIcon}>
                          <SearchIcon />
                        </div>
                        <InputBase
                          placeholder={t('view.batch.calibTable.search')}
                          classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                          }}
                          inputProps={{ 'aria-label': 'search' }}
                          onChange={this.onSearch}
                          ref={this.searchRef}
                        />
                        {this.state.displayClear && (
                          <IconButton
                            className={clsx(classes.clearIcon, classes.searchIcon)}
                            aria-label="clear"
                            onClick={this.clearSearch}
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Title>
                <div className={classes.tableContainer}>
                  <ErrorBoundaryGuard>
                    <TableMultiGrid
                      ref={this.tableMultiGridRef}
                      collection={'unitary_calibration'}
                      onMoleculeClick={(item) => onDisplayCalib(item)}
                      onMoleculeDoubleClick={(item) => moleculeDoubleClick(item)}
                      tableItemSelectedForColor={tableItemSelectedForColor}
                      idTable={'myCalibrationTable'}
                      selection={canEditCalib()}
                      onSelect={this.onSelect}
                      orderBy={
                        localStorage.getItem('BATCH_sorter_calibration')
                          ? localStorage.getItem('BATCH_sorter_calibration')
                          : 'asc$name'
                      }
                      data={unitaryCalibrationsFilteredSearched}
                      isFetchingData={isFetchingUnitaryCalibrationsFiltered}
                      sortAction={function (order, direction) {
                        tableMultiGridRef.current.clearSelection()
                        localStorage.setItem('BATCH_sorter_calibration', direction + '$' + order)
                        dispatch(sortUnitaryCalibrationFiltered(direction, order))
                      }}
                      columns={[
                        {
                          key: 'flags',
                          label: t('view.batch.calibTable.columns.flags'),
                          size: 70,
                          type: 'custom',
                        },
                        {
                          key: 'notes',
                          label: t('view.batch.calibTable.columns.notes'),
                          size: 60,
                          type: 'custom',
                        },
                        {
                          key: 'modified',
                          label: t('view.batch.calibTable.columns.modif'),
                          size: 75,
                          type: 'boolean',
                        },
                        {
                          key: 'name',
                          label: t('view.batch.calibTable.columns.name'),
                          size: 180,
                          type: 'text',
                          customDisplay: function (value) {
                            return (
                              <Tooltip
                                key={'tooltip_analyse'}
                                arrow
                                placement="top"
                                title={<div style={{ fontSize: 13, padding: 5 }}>{value}</div>}
                              >
                                <div
                                  style={{
                                    maxWidth: '100%',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    display: 'inline-block',
                                    width: 'fit-content',
                                  }}
                                >
                                  {value}
                                </div>
                              </Tooltip>
                            )
                          },
                        },
                        {
                          key: 'state',
                          label: t('view.batch.calibTable.columns.state'),
                          size: 50,
                          type: 'text',
                        },
                        {
                          key: 'validation',
                          label: t('view.batch.calibTable.columns.valid'),
                          size: 100,
                          type: 'custom',
                        },
                        {
                          key: 'event',
                          label: t('view.batch.calibTable.columns.event'),
                          size: 70,
                          type: 'number',
                        },
                        {
                          key: 'r2',
                          label: t('view.batch.calibTable.columns.r2'),
                          size: 80,
                          type: 'number',
                        },
                        {
                          key: 'rt',
                          label: t('view.batch.calibTable.columns.rt'),
                          size: 70,
                          type: 'number',
                        },
                        {
                          key: 'istd',
                          label: t('view.batch.calibTable.columns.istd'),
                          size: 150,
                          type: 'text',
                        },
                        {
                          key: 'ret_time',
                          label: t('view.batch.calibTable.columns.rt_meth'),
                          size: 70,
                          type: 'number',
                        },
                        {
                          key: 'q1',
                          label: t('view.batch.calibTable.columns.q1'),
                          size: 70,
                          type: 'number',
                        },
                        {
                          key: 'q3',
                          label: t('view.batch.calibTable.columns.q3'),
                          size: 70,
                          type: 'number',
                        },
                        {
                          key: 'type',
                          label: t('view.batch.calibTable.columns.type'),
                          size: 100,
                          type: 'text',
                        },
                        {
                          key: 'nb_points',
                          label: t('view.batch.calibTable.columns.nb_points'),
                          size: 70,
                          type: 'number',
                        },
                        {
                          key: 'curve_fit',
                          label: t('view.batch.calibTable.columns.curve_fit'),
                          size: 100,
                          type: 'text',
                        },
                        {
                          key: 'curve_weighting',
                          label: t('view.batch.calibTable.columns.curve_weighting'),
                          size: 100,
                          type: 'text',
                        },
                        {
                          key: 'curve_zero',
                          label: t('view.batch.calibTable.columns.curve_zero'),
                          size: 100,
                          type: 'text',
                        },
                        {
                          key: 'std_dev',
                          label: t('view.batch.calibTable.columns.std_dev'),
                          size: 80,
                          type: 'number',
                          customDisplay: function (value, item, columnInfos) {
                            if (value && value !== '' && value !== null) {
                              let v = value * 100
                              return (
                                customFormatDecimals(
                                  v,
                                  displayHumanReadable(v, 2, 2, true),
                                  columnInfos,
                                ) + ' %'
                              )
                            }
                            return value
                          },
                        },
                      ]}
                      fixedColumnCount={0}
                      fixedRowCount={1}
                      rowHeight={40}
                      getColumnRender={function (
                        columnIndex,
                        key,
                        rowIndex,
                        style,
                        item,
                        rowClassNames,
                        columnKey,
                        moleculeOverState,
                        moleculeOver,
                        selected,
                        onMoleculeClick,
                        onMoleculeDoubleClick,
                        columns,
                      ) {
                        const value = item[columnKey]

                        // if (columnKey === 'state') {
                        //   return (
                        //     <div
                        //       style={style}
                        //       key={key}
                        //       className={clsx(
                        //         rowClassNames,
                        //         moleculeOverState === rowIndex && classes.selectedRow,
                        //         selected === item._id ? classes.blink : classes.noBlink,
                        //       )}
                        //       onMouseEnter={(event) => moleculeOver(rowIndex, true)}
                        //       onMouseLeave={(event) => moleculeOver(rowIndex, false)}
                        //       onClick={(event) => onMoleculeClick(item.event)}
                        //       onDoubleClick={(event) => onMoleculeDoubleClick(item)}
                        //     >
                        //       {value === 'ok' ? (
                        //         <CheckIcon className={classes.success} />
                        //       ) : value === 'ko' ? (
                        //         <ClearIcon className={classes.error} />
                        //       ) : (
                        //         <HelpOutlineIcon className={classes.noInfo} />
                        //       )}
                        //     </div>
                        //   )
                        // }
                        if (columnKey === 'curve_zero') {
                          const displayValue =
                            value === 'none'
                              ? t('view.common.calibration.ignored').toLowerCase()
                              : value
                          return (
                            <div
                              style={style}
                              key={key}
                              className={clsx(
                                rowClassNames,
                                moleculeOverState === rowIndex && classes.selectedRow,
                                selected === item._id ? classes.blink : classes.noBlink,
                                moleculeOverState === rowIndex &&
                                  selected === item._id &&
                                  classes.selectedRowBlink,
                              )}
                              onMouseEnter={() =>
                                moleculeOver(rowIndex, columnIndex, true, item._id)
                              }
                              onMouseLeave={() => moleculeOver(rowIndex, columnIndex, false)}
                              // onClick={() => onMoleculeClick(item)}
                              onClick={function (event) {
                                const openFct = () => {
                                  onMoleculeClick(item)
                                }
                                getClickAction(
                                  dispatch,
                                  event,
                                  item,
                                  'unitary_calibration',
                                  openFct,
                                )
                              }}
                              onDoubleClick={() => onMoleculeDoubleClick(item)}
                            >
                              {displayValue}
                            </div>
                          )
                        }
                        if (columnKey === 'notes') {
                          return (
                            <div
                              style={style}
                              key={key}
                              className={clsx(
                                rowClassNames,
                                moleculeOverState === rowIndex && classes.selectedRow,
                                selected === item._id ? classes.blink : classes.noBlink,
                                moleculeOverState === rowIndex &&
                                  selected === item._id &&
                                  classes.selectedRowBlink,
                              )}
                              onMouseEnter={() =>
                                moleculeOver(rowIndex, columnIndex, true, item._id)
                              }
                              onMouseLeave={() => moleculeOver(rowIndex, columnIndex, false)}
                              // onClick={() => onMoleculeClick(item)}
                              onClick={function (event) {
                                const openFct = () => {
                                  onMoleculeClick(item)
                                }
                                getClickAction(
                                  dispatch,
                                  event,
                                  item,
                                  'unitary_calibration',
                                  openFct,
                                )
                              }}
                              onDoubleClick={() => onMoleculeDoubleClick(item)}
                            >
                              <ErrorBoundaryGuard isDialog>
                                <NoteButton row={item} collection={'unitary_calibration'} />
                              </ErrorBoundaryGuard>
                            </div>
                          )
                        }
                        if (columnKey === 'modified') {
                          return (
                            <div
                              style={style}
                              key={key}
                              className={clsx(
                                rowClassNames,
                                moleculeOverState === rowIndex && classes.selectedRow,
                                selected === item._id ? classes.blink : classes.noBlink,
                                moleculeOverState === rowIndex &&
                                  selected === item._id &&
                                  classes.selectedRowBlink,
                              )}
                              onMouseEnter={() =>
                                moleculeOver(rowIndex, columnIndex, true, item._id)
                              }
                              onMouseLeave={() => moleculeOver(rowIndex, columnIndex, false)}
                              // onClick={() => onMoleculeClick(item)}
                              onClick={function (event) {
                                const openFct = () => {
                                  onMoleculeClick(item)
                                }
                                getClickAction(
                                  dispatch,
                                  event,
                                  item,
                                  'unitary_calibration',
                                  openFct,
                                )
                              }}
                              onDoubleClick={() => onMoleculeDoubleClick(item)}
                            >
                              <ErrorBoundaryGuard isDialog>
                                <AlphacodModifiedButtonIcon item={item} />
                              </ErrorBoundaryGuard>
                            </div>
                          )
                        }
                        if (columnKey === 'validation') {
                          return (
                            <div
                              style={style}
                              key={key}
                              className={clsx(
                                rowClassNames,
                                moleculeOverState === rowIndex && classes.selectedRow,
                                selected === item._id ? classes.blink : classes.noBlink,
                                moleculeOverState === rowIndex &&
                                  selected === item._id &&
                                  classes.selectedRowBlink,
                              )}
                              onMouseEnter={() =>
                                moleculeOver(rowIndex, columnIndex, true, item._id)
                              }
                              onMouseLeave={() => moleculeOver(rowIndex, columnIndex, false)}
                              // onClick={(event) => onMoleculeClick(item)}
                              onClick={function (event) {
                                const openFct = () => {
                                  onMoleculeClick(item)
                                }
                                getClickAction(
                                  dispatch,
                                  event,
                                  item,
                                  'unitary_calibration',
                                  openFct,
                                )
                              }}
                              onDoubleClick={(event) => onMoleculeDoubleClick(item)}
                            >
                              <div className={classes.actionContainer}>
                                <IconButton
                                  disabled={!canEditCalib()}
                                  aria-label="detected"
                                  onClick={(event) =>
                                    handleValidation(
                                      event,
                                      item,
                                      '1',
                                      'unitary_calibration',
                                      dispatch,
                                    )
                                  }
                                >
                                  {value === '1' ? (
                                    <CheckIcon className={classes.selected} />
                                  ) : (
                                    <CheckIcon className={classes.notSelected} />
                                  )}
                                </IconButton>
                                <IconButton
                                  disabled={!canEditCalib()}
                                  aria-label="notDetected"
                                  onClick={(event) =>
                                    handleValidation(
                                      event,
                                      item,
                                      '2',
                                      'unitary_calibration',
                                      dispatch,
                                    )
                                  }
                                >
                                  {value === '2' ? (
                                    <ClearIcon className={classes.selected} />
                                  ) : (
                                    <ClearIcon className={classes.notSelected} />
                                  )}
                                </IconButton>
                              </div>
                            </div>
                          )
                        }
                        if (columnKey === 'flags') {
                          return (
                            <div
                              style={style}
                              key={key}
                              className={clsx(
                                'cellFlags',
                                rowClassNames,
                                moleculeOverState === rowIndex && classes.selectedRow,
                                selected === item._id ? classes.blink : classes.noBlink,
                                moleculeOverState === rowIndex &&
                                  selected === item._id &&
                                  classes.selectedRowBlink,
                              )}
                              onMouseEnter={() =>
                                moleculeOver(rowIndex, columnIndex, true, item._id)
                              }
                              onMouseLeave={() => moleculeOver(rowIndex, columnIndex, false)}
                              // onClick={(event) => onMoleculeClick(item)}
                              onClick={function (event) {
                                const openFct = () => {}
                                getClickAction(
                                  dispatch,
                                  event,
                                  item,
                                  'unitary_calibration',
                                  openFct,
                                )
                              }}
                            >
                              <ErrorBoundaryGuard isDialog>
                                <AlphacodFlagIcon item={item} hideGeneralTab={true} />
                              </ErrorBoundaryGuard>
                            </div>
                          )
                        }
                        if (columnKey === 'istd') {
                          if (item.type === 'ISTD') {
                            return (
                              <div
                                style={style}
                                key={key}
                                className={clsx(
                                  rowClassNames,
                                  moleculeOverState === rowIndex && classes.selectedRow,
                                  selected === item._id ? classes.blink : classes.noBlink,
                                  moleculeOverState === rowIndex &&
                                    selected === item._id &&
                                    classes.selectedRowBlink,
                                )}
                                onMouseEnter={() =>
                                  moleculeOver(rowIndex, columnIndex, true, item._id)
                                }
                                onMouseLeave={() => moleculeOver(rowIndex, columnIndex, false)}
                                // onClick={() => onMoleculeClick(item)}
                                onClick={function (event) {
                                  const openFct = () => {
                                    onMoleculeClick(item)
                                  }
                                  getClickAction(
                                    dispatch,
                                    event,
                                    item,
                                    'unitary_calibration',
                                    openFct,
                                  )
                                }}
                                onDoubleClick={() => onMoleculeDoubleClick(item)}
                              >
                                {item.istd_group}
                              </div>
                            )
                          } else {
                            return (
                              <div
                                style={style}
                                key={key}
                                className={clsx(
                                  rowClassNames,
                                  moleculeOverState === rowIndex && classes.selectedRow,
                                  selected === item._id ? classes.blink : classes.noBlink,
                                  moleculeOverState === rowIndex &&
                                    selected === item._id &&
                                    classes.selectedRowBlink,
                                )}
                                onMouseEnter={() =>
                                  moleculeOver(rowIndex, columnIndex, true, item._id)
                                }
                                onMouseLeave={() => moleculeOver(rowIndex, columnIndex, false)}
                                // onClick={() => onMoleculeClick(item)}
                                onClick={function (event) {
                                  const openFct = () => {
                                    onMoleculeClick(item)
                                  }
                                  getClickAction(
                                    dispatch,
                                    event,
                                    item,
                                    'unitary_calibration',
                                    openFct,
                                  )
                                }}
                              >
                                <FormControl className={classes.formControl}>
                                  <Select
                                    value={item.istd_group}
                                    name={'istd'}
                                    onChange={(event) => onWhichUpdateISTD(event, item)}
                                    displayEmpty
                                    autoWidth
                                    inputProps={{
                                      readOnly: !canEditCalib(),
                                      'aria-label': 'ISTD',
                                    }}
                                  >
                                    {Object.entries(
                                      batch && batch.content.internal_standards
                                        ? batch.content.internal_standards
                                        : [],
                                    ).map((internal_standard) => (
                                      <MenuItem
                                        key={internal_standard[1].name}
                                        value={internal_standard[1].istd}
                                      >
                                        {internal_standard[1].name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </div>
                            )
                          }
                        }
                        return null
                      }}
                    />
                  </ErrorBoundaryGuard>
                  {!selectionEmpty && (
                    <span
                      className={classes.toolbar}
                      style={{ visibility: selectionEmpty ? 'hidden' : 'visible' }}
                    >
                      <div>
                        <Tooltip
                          arrow
                          title={
                            <div className={classes.tooltip}>
                              {t('view.batch.calibTable.selection_ok')}
                            </div>
                          }
                        >
                          <IconButton
                            className={classes.toolbarButton}
                            aria-label="ok"
                            onClick={() => this.multiValidate(1)}
                          >
                            <CheckIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          arrow
                          title={
                            <div className={classes.tooltip}>
                              {t('view.batch.calibTable.selection_ko')}
                            </div>
                          }
                        >
                          <IconButton
                            className={classes.toolbarButton}
                            aria-label="ko"
                            onClick={() => this.multiValidate(2)}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Tooltip>
                        {/* Reset calibration not available on calibration table */}
                        {/*<span*/}
                        {/*style={{*/}
                        {/*border: '1px solid #e0e0e0',*/}
                        {/*padding: 0,*/}
                        {/*marginRight: 10,*/}
                        {/*marginLeft: 5,*/}
                        {/*}}*/}
                        {/*/>*/}
                        {/*<Tooltip*/}
                        {/*arrow*/}
                        {/*title={<div className={classes.tooltip}>Reset the selection</div>}*/}
                        {/*>*/}
                        {/*<IconButton*/}
                        {/*className={classes.toolbarButton}*/}
                        {/*aria-label="reset"*/}
                        {/*onClick={(event) => this.handleReset(event)}*/}
                        {/*>*/}
                        {/*<SettingsBackupRestoreIcon />*/}
                        {/*</IconButton>*/}
                        {/*</Tooltip>*/}
                      </div>
                    </span>
                  )}
                </div>
              </Stack>
            </>
          )
        }
      },
    ),
  ),
)
