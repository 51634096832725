import { REQUEST_SCREENING_FOR_BATCH, RECEIVE_SCREENING_FOR_BATCH } from './actions'
import React from 'react'

const initialState = {
  onlyLastFour: true,
  device: null,
  screenings: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case REQUEST_SCREENING_FOR_BATCH:
      return {
        ...state,
        device: action.device,
        onlyLastFour: action.onlyLastFour,
      }

    case RECEIVE_SCREENING_FOR_BATCH:
      return {
        ...state,
        screenings: action.screenings,
      }
    default:
      return state
  }
}
