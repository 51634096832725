import { find } from 'lodash'

export const getUnitaryAnalysisFilteredById = (store, id) =>
  find(store.unitaryAnalysesFiltered, { _id: id })
export const getUnitaryAnalysisCompoundById = (store, id) =>
  find(store.unitaryAnalysesCompound, { _id: id })
export const getUnitaryAnalysisCompoundISTDById = (store, id) =>
  find(store.allUnitaryAnalysesCompoundIstd, { _id: id })

// Search in the analysis view istd store
export const getUnitaryAnalysisISTDById = (store, id) =>
  find(store.allUnitaryAnalysesIstd, { _id: id })

// Search in the detail view istd store
export const getUnitaryAnalysisISTDFromDetailStore = (store, id) =>
  find(store.unitaryCompoundISTD, { _id: id })
