import { REQUEST_LOGS, RECEIVE_LOGS, REQUEST_LOGS_POLLING, CLEAN_LOGS } from './actions'
import React from 'react'

function findTopKObjects(objects, k) {
  // Créer une copie du tableau pour éviter de modifier l'original
  const copy = [...objects]

  // Utiliser un algorithme de sélection des k plus grands éléments
  const topKObjects = []
  const numObjects = Math.min(k, copy.length)
  for (let i = 0; i < numObjects; i++) {
    let maxIndex = i
    for (let j = i + 1; j < copy.length; j++) {
      if (new Date(copy[j].creationDate) > new Date(copy[maxIndex].creationDate)) {
        maxIndex = j
      }
    }
    // Échanger les valeurs de copy[i] et copy[maxIndex]
    const temp = copy[i] // Stocke la valeur de copy[i] temporairement
    copy[i] = copy[maxIndex] // Remplace la valeur de copy[i] par celle de copy[maxIndex]
    copy[maxIndex] = temp // Remplace la valeur de copy[maxIndex] par celle de temp, qui était initialement copy[i]
    // Ajouter l'élément le plus grand à la liste des k plus grands
    topKObjects.push(copy[i])
  }
  return topKObjects
}

const initialState = {
  limit: 0,
  logs: [],
  total: 0,
  batchId: null,
  isFetchingLogs: false,
  isPolling: false,
  logsLastDateRequest: null,
  codDriveId: null,
  lastLogCreationDate: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case CLEAN_LOGS:
      return {
        ...state,
        logs: [],
      }
    case REQUEST_LOGS:
      return {
        ...state,
        batchId: action.batchId,
        isFetchingLogs: true,
        logsLastDateRequest: action.dateLock,
        codDriveId: action.codDriveId,
        limit: action.limit,
      }
    case REQUEST_LOGS_POLLING:
      return {
        ...state,
        isPolling: true,
        logsLastDateRequest: action.dateLock,
        limit: action.limit,
      }

    case RECEIVE_LOGS:
      if (action.dateLock !== state.logsLastDateRequest) {
        return {
          ...state,
        }
      } else {
        return {
          ...state,
          logs: state.isPolling
            ? findTopKObjects(state.logs.concat(action.logs.result), state.limit)
            : action.logs.result,
          total: action.logs.size,
          isFetchingLogs: false,
          isPolling: false,
          lastLogCreationDate:
            action.logs.result.length > 0
              ? action.logs.result.reduce((max, obj) => {
                  return new Date(obj.creationDate) > new Date(max.creationDate) ? obj : max
                }, action.logs.result[0])?.creationDate
              : state.lastLogCreationDate,
        }
      }
    default:
      return state
  }
}
