import React, { Fragment, useEffect, useMemo } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import UpdateBatchDialog from '../dialog/UpdateBatchDialog'
import ReceiveAllBatchDialog from '../dialog/ReceiveAllBatchDialog'
import CreateTrainingBatchDialog from '../dialog/CreateTrainingBatchDialog'
import { fetchChangeSettings } from '../../redux/settings/actions'

const useStyles = makeStyles((theme) => ({}))

const ExtraDialogs = withRouter((props) => {
  const dispatch = useDispatch()
  const openConfirmReceiveAllBatch = useSelector(
    (state) => state.settings.openConfirmReceiveAllBatch,
  )
  const openConfirmUpdateBatch = useSelector((state) => state.settings.openConfirmUpdateBatch)
  const openCreateTrainingBatch = useSelector((state) => state.settings.openCreateTrainingBatch)
  const currentUpdateBatch = useSelector((state) => state.settings.currentBatchSelected)

  const handleConfirmReceiveAllBatch = function () {
    dispatch(fetchChangeSettings('openConfirmReceiveAllBatch', false))
  }
  const handleConfirmUpdateBatch = function () {
    dispatch(fetchChangeSettings('openConfirmUpdateBatch', false))
  }
  const handleCreateTrainingBatch = function () {
    dispatch(fetchChangeSettings('openCreateTrainingBatch', false))
  }

  return (
    <Fragment>
      <ErrorBoundaryGuard isDialog>
        <ReceiveAllBatchDialog
          open={openConfirmReceiveAllBatch}
          closeFct={handleConfirmReceiveAllBatch}
        />
      </ErrorBoundaryGuard>
      <ErrorBoundaryGuard isDialog>
        <UpdateBatchDialog
          open={openConfirmUpdateBatch}
          closeFct={handleConfirmUpdateBatch}
          batch={currentUpdateBatch}
        />
      </ErrorBoundaryGuard>
      <ErrorBoundaryGuard isDialog>
        <CreateTrainingBatchDialog
          open={openCreateTrainingBatch}
          closeFct={handleCreateTrainingBatch}
          batch={currentUpdateBatch}
        />
      </ErrorBoundaryGuard>
    </Fragment>
  )
})
export default ExtraDialogs
