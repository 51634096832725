import { API_BO_URL, FLASK_URL } from '../../utils/config'
import { getComparator, rebuildUnitaryCalibrations, stableSort } from '../../view/common/Utils'
import {
  receiveUpdateCalibrationForUA,
  receiveUpdateInterpolationForUA,
  receiveUpdateSTDForUA,
} from '../unitaryAnalyses/actions'
import { receiveCurrentBatchLease, receiveUpdateBatches } from '../batches/actions'
import { getBatchById } from '../batches/selectors'
import { convertErrorCodeToType } from '../tools'
import { getUnitaryCalibrationsFilteredById, getUnitaryCalibrationsRawById } from './selectors'
import { fetchChangeSettings } from '../settings/actions'
import { RECEIVE_UPDATE_ANALYSE } from '../analyses/actions'
import { displayPopupMessage, generateErrorPart } from '../../view/common/Message'
import React, { Fragment } from 'react'

export const RECEIVE_UNITARY_CALIBRATIONS_FILTERED = 'RECEIVE_UNITARY_CALIBRATIONS_FILTERED'
export const REQUEST_UNITARY_CALIBRATIONS_FILTERED = 'REQUEST_UNITARY_CALIBRATIONS_FILTERED'
export const RECEIVE_UNITARY_CALIBRATIONS_DISTINCT = 'RECEIVE_UNITARY_CALIBRATIONS_DISTINCT'
export const REQUEST_UNITARY_CALIBRATIONS_DISTINCT = 'REQUEST_UNITARY_CALIBRATIONS_DISTINCT'
export const RECEIVE_UNITARY_CALIBRATIONS_DISTINCT_VALIDATION =
  'RECEIVE_UNITARY_CALIBRATIONS_DISTINCT_VALIDATION'
export const REQUEST_UNITARY_CALIBRATIONS_DISTINCT_VALIDATION =
  'REQUEST_UNITARY_CALIBRATIONS_DISTINCT_VALIDATION'
export const AUTHENTICATION_FAILURE = 'AUTHENTICATION_FAILURE'
export const SORT_UNITARY_CALIBRATION = 'SORT_UNITARY_CALIBRATION'
export const UPDATE_VALIDATION_CALIBRATION = 'UPDATE_VALIDATION_CALIBRATION'
export const RECEIVE_UPDATE_UC_VALIDATION = 'RECEIVE_UPDATE_UC_VALIDATION'
export const RECEIVE_UPDATE_INTERPOLATION = 'RECEIVE_UPDATE_INTERPOLATION'
export const RECEIVE_UPDATE_STD_POINT = 'RECEIVE_UPDATE_STD_POINT'
export const RECEIVE_UPDATE_ISTD = 'RECEIVE_UPDATE_ISTD'
export const RECEIVE_UPDATE_UC_NOTE = 'RECEIVE_UPDATE_UC_NOTE'
export const REQUEST_ALL_UNITARY_CALIBRATIONS_NOTES = 'REQUEST_ALL_UNITARY_CALIBRATIONS_NOTES'
export const RECEIVE_ALL_UNITARY_CALIBRATIONS_NOTES = 'RECEIVE_ALL_UNITARY_CALIBRATIONS_NOTES'
export const RECEIVE_UPDATE_CALIBRATION = 'RECEIVE_UPDATE_CALIBRATION'
export const RECEIVE_UNITARY_CALIBRATION = 'RECEIVE_UNITARY_CALIBRATION'

export function fetchUnitaryCalibration(batchId, event) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      let filter = [
        {
          property: 'content.batch.id',
          value: batchId,
          filterType: 'string',
          operator: 'eq',
        },
        {
          property: 'content.molecule.event',
          value: event,
          filterType: 'number',
          operator: 'eq',
        },
      ]
      let op = encodeURIComponent(JSON.stringify(filter))
      fetch(`${API_BO_URL()}.unitary_calibration/page/?filter=${op}`, {
        method: 'GET',
        credentials: 'include',
      })
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  // JSON.parse(errorMessage),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((data) => {
          dispatch(receiveUnitaryCalibration(data?.result?.[0]))
          resolve(data?.result?.[0])
        })
        .catch((error) => {
          const authenticationError = error.status === 403
          if (authenticationError) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(error)
        })
    })
  }
}

export function receiveUnitaryCalibration(unitaryCalibration) {
  return {
    type: RECEIVE_UNITARY_CALIBRATION,
    unitaryCalibrationLoaded: unitaryCalibration,
  }
}

export function fetchUpdateCalibrationValidation(analyseId, validation) {
  return {
    type: UPDATE_VALIDATION_CALIBRATION,
    analyseId: analyseId,
    validation: validation,
  }
}

export function sortUnitaryCalibrationFiltered(sort, sortBy) {
  return {
    type: SORT_UNITARY_CALIBRATION,
    sort: sort,
    sortBy: sortBy,
  }
}

export function requestUnitaryCalibrationsFiltered(
  batchId,
  newFilter,
  untreated,
  sorter,
  dateLock,
) {
  return {
    type: REQUEST_UNITARY_CALIBRATIONS_FILTERED,
    batchId: batchId,
    filter: newFilter,
    sorter: sorter,
    untreated: untreated,
    dateLock: dateLock,
  }
}
export function receiveUnitaryCalibrationsFiltered(
  unitaryCalibrations,
  authenticationError,
  sorter,
  dateLock,
) {
  let unitaryCalibrationsFiltered = []
  if (unitaryCalibrations) {
    unitaryCalibrationsFiltered = unitaryCalibrations.map(function (row) {
      return rebuildUnitaryCalibrations(row)
    })

    if (sorter) {
      const sortTab = sorter.split('$')
      unitaryCalibrationsFiltered = stableSort(
        unitaryCalibrationsFiltered,
        getComparator(sortTab[0], sortTab[1]),
      )
    }
  }
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UNITARY_CALIBRATIONS_FILTERED,
    unitaryCalibrationsFiltered: unitaryCalibrationsFiltered,
    dateLock: dateLock,
  }
}

export function fetchAllUnitaryCalibrationsNotes(batchId) {
  return async (dispatch) => {
    dispatch(requestAllUnitaryCalibrationsNotes(batchId))

    const calibrationsFilter = [
      {
        property: 'content.batch.id',
        value: batchId,
        filterType: 'string',
        operator: 'eq',
      },
    ]

    const filters = encodeURIComponent(JSON.stringify(calibrationsFilter))
    return fetch(`${API_BO_URL()}.unitary_calibration/?filter=${filters}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((json) => {
        return dispatch(receiveAllUnitaryCalibrationsNotes(json, false))
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to get calibration notes',
          <Fragment>
            <div>An error does not allow the get calibration notes:</div>
            {generateErrorPart(error)}
            <div style={{ marginTop: 10 }}>Please contact your administrator.</div>
          </Fragment>,
        )
      })
  }
}

export function requestAllUnitaryCalibrationsNotes(batchId) {
  return {
    type: REQUEST_ALL_UNITARY_CALIBRATIONS_NOTES,
    batchId: batchId,
  }
}
export function receiveAllUnitaryCalibrationsNotes(unitaryCalibrations, authenticationError) {
  let unitaryCalibrationsNotes = unitaryCalibrations
    .filter((uc) => uc.content.notes && uc.content.notes.length > 0)
    .map(function (row) {
      return rebuildUnitaryCalibrations(row)
    })
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_ALL_UNITARY_CALIBRATIONS_NOTES,
    unitaryCalibrationsNotes: unitaryCalibrationsNotes,
  }
}

let controllerUnitaryCalibrationsFilteredSaved = null
export function fetchUnitaryCalibrationsFiltered(
  batchId,
  newFilter,
  untreated,
  validationOk,
  validationKo,
  sorter,
) {
  return async (dispatch) => {
    if (controllerUnitaryCalibrationsFilteredSaved) {
      controllerUnitaryCalibrationsFilteredSaved.abort()
    }
    const controller = new AbortController()
    controllerUnitaryCalibrationsFilteredSaved = controller
    const dateLock = new Date().getTime()
    dispatch(requestUnitaryCalibrationsFiltered(batchId, newFilter, untreated, sorter, dateLock))

    const calibrationsFilter = [
      {
        property: 'content.batch.id',
        value: batchId,
        filterType: 'string',
        operator: 'eq',
      },
    ]

    // Show/Hide unknown molecule
    if (
      localStorage.getItem('SETTINGS_show_unknown') &&
      localStorage.getItem('SETTINGS_show_unknown') === 'false'
    ) {
      calibrationsFilter.push({
        property: 'content.molecule.unknown',
        value: true,
        filterType: 'boolean',
        operator: 'ne',
      })
    }

    if (newFilter && newFilter !== 'null') {
      calibrationsFilter.push({
        property: 'content.istd',
        value: newFilter === 'istd' ? 'True' : 'False',
        filterType: 'string',
        operator: 'eq',
      })
      if (newFilter !== 'istd') {
        calibrationsFilter.push({
          property: 'content.classification',
          value: newFilter,
          filterType: 'string',
          operator: 'eq',
        })
      }
    }
    if (untreated) {
      calibrationsFilter.push({
        property: 'content.validation',
        value: '0',
        filterType: 'string',
        operator: 'eq',
      })
    }
    if (validationOk || validationKo) {
      if (!validationOk) {
        calibrationsFilter.push({
          property: 'content.validation',
          value: '1',
          filterType: 'string',
          operator: 'ne',
        })
      }
      if (!validationKo) {
        calibrationsFilter.push({
          property: 'content.validation',
          value: '2',
          filterType: 'string',
          operator: 'ne',
        })
      }
      calibrationsFilter.push({
        property: 'content.validation',
        value: '0',
        filterType: 'string',
        operator: 'ne',
      })
    }

    const filters = encodeURIComponent(JSON.stringify(calibrationsFilter))
    return fetch(`${API_BO_URL()}.unitary_calibration/?filter=${filters}`, {
      signal: controller.signal,
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((json) => {
        return dispatch(receiveUnitaryCalibrationsFiltered(json, false, sorter, dateLock))
      })
      .catch((error) => {
        // Do not do anything in case of an aborted request by a new one
        if (!(error instanceof DOMException && error.code === 20)) {
          if (error.status === 403) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          displayPopupMessage(
            dispatch,
            'error',
            'Failed to get unitary calibration',
            <Fragment>
              <div>An error does not allow to get unitary calibration:</div>
              {generateErrorPart(error)}
              <div style={{ marginTop: 10 }}>Please contact your administrator.</div>
            </Fragment>,
          )
        }
      })
      .finally(() => {
        if (controllerUnitaryCalibrationsFilteredSaved === controller) {
          controllerUnitaryCalibrationsFilteredSaved = null
        }
        controller.abort()
      })
  }
}

export function requestUnitaryCalibrationsDistinct(batchId, untreated) {
  return {
    type: REQUEST_UNITARY_CALIBRATIONS_DISTINCT,
    batchId: batchId,
    untreated: untreated,
  }
}
export function receiveUnitaryCalibrationsDistinct(
  unitaryCalibrationsDistinct,
  authenticationError,
) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UNITARY_CALIBRATIONS_DISTINCT,
    unitaryCalibrationsDistinct: unitaryCalibrationsDistinct,
  }
}

export function fetchUnitaryCalibrationsDistinct(batchId, untreated) {
  return async (dispatch) => {
    dispatch(requestUnitaryCalibrationsDistinct(batchId, untreated))

    const p1 = new Promise((resolve, reject) => {
      const batchFilter = [
        {
          property: 'content.batch.id',
          value: batchId,
          filterType: 'string',
          operator: 'eq',
        },
        {
          property: 'content.istd',
          value: 'False',
          filterType: 'string',
          operator: 'eq',
        },
      ]

      // Show/Hide unknown molecule
      if (
        localStorage.getItem('SETTINGS_show_unknown') &&
        localStorage.getItem('SETTINGS_show_unknown') === 'false'
      ) {
        batchFilter.push({
          property: 'content.molecule.unknown',
          value: true,
          filterType: 'boolean',
          operator: 'ne',
        })
      }

      // if (untreated) {
      //   batchFilter.push({
      //     property: 'content.validation',
      //     value: '0',
      //     filterType: 'string',
      //     operator: 'eq',
      //   })
      // }
      const op = encodeURIComponent(JSON.stringify(batchFilter))
      fetch(`${API_BO_URL()}.unitary_calibration/distinct/content.classification?filter=${op}`, {
        method: 'GET',
        credentials: 'include',
      })
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((json) => {
          resolve(json)
        })
        .catch((error) => {
          if (error.status === 403) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(error)
        })
    })

    const p2 = new Promise((resolve, reject) => {
      let istdfilter = [
        {
          property: 'content.batch.id',
          value: batchId,
          filterType: 'string',
          operator: 'eq',
        },
        {
          property: 'content.istd',
          value: 'True',
          filterType: 'string',
          operator: 'eq',
        },
      ]

      // Show/Hide unknown molecule
      if (
        localStorage.getItem('SETTINGS_show_unknown') &&
        localStorage.getItem('SETTINGS_show_unknown') === 'false'
      ) {
        istdfilter.push({
          property: 'content.molecule.unknown',
          value: true,
          filterType: 'boolean',
          operator: 'ne',
        })
      }
      // if (untreated) {
      //   istdfilter.push({
      //     property: 'content.validation',
      //     value: '0',
      //     filterType: 'string',
      //     operator: 'eq',
      //   })
      // }
      let f = encodeURIComponent(JSON.stringify(istdfilter))
      fetch(`${API_BO_URL()}.unitary_calibration/distinct/content.istd?filter=${f}`, {
        method: 'GET',
        credentials: 'include',
      })
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((json) => {
          resolve(json)
        })
        .catch((error) => {
          if (error.status === 403) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(error)
        })
    })

    const p3 = new Promise((resolve, reject) => {
      let filterValidation = [
        {
          property: 'content.batch.id',
          value: batchId,
          filterType: 'string',
          operator: 'eq',
        },
        // {
        //   property: 'content.istd',
        //   value: 'False',
        //   filterType: 'string',
        //   operator: 'eq',
        // },
      ]

      // Show/Hide unknown molecule
      if (
        localStorage.getItem('SETTINGS_show_unknown') &&
        localStorage.getItem('SETTINGS_show_unknown') === 'false'
      ) {
        filterValidation.push({
          property: 'content.molecule.unknown',
          value: true,
          filterType: 'boolean',
          operator: 'ne',
        })
      }

      let v = encodeURIComponent(JSON.stringify(filterValidation))
      fetch(`${API_BO_URL()}.unitary_calibration/distinct/content.validation?filter=${v}`, {
        method: 'GET',
        credentials: 'include',
      })
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((json) => {
          resolve(json)
        })
        .catch((error) => {
          if (error.status === 403) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(error)
        })
    })

    Promise.all([p1, p2, p3]).then(
      function (returns) {
        const json = Object.assign({}, returns[0], returns[1], returns[2])
        return dispatch(receiveUnitaryCalibrationsDistinct(json, false))
      },
      function (error) {
        return error
      },
    )
  }
}

export function requestUnitaryCalibrationsDistinctValidation(batchId) {
  return {
    type: REQUEST_UNITARY_CALIBRATIONS_DISTINCT_VALIDATION,
    batchId: batchId,
  }
}
export function receiveUnitaryCalibrationsDistinctValidation(
  unitaryCalibrationsDistinctValidation,
  authenticationError,
) {
  return {
    type: authenticationError
      ? AUTHENTICATION_FAILURE
      : RECEIVE_UNITARY_CALIBRATIONS_DISTINCT_VALIDATION,
    unitaryCalibrationsDistinctValidation: unitaryCalibrationsDistinctValidation,
  }
}

// export function fetchUnitaryCalibrationsDistinctValidation(batchId) {
//   return async (dispatch) => {
//     dispatch(requestUnitaryCalibrationsDistinctValidation(batchId))
//
//     const batchFilter = [
//       {
//         property: 'content.batch.id',
//         value: batchId,
//         filterType: 'string',
//         operator: 'eq',
//       },
//     ]
//     const op = encodeURIComponent(JSON.stringify(batchFilter))
//
//     const response = await fetch(
//       `${API_BO_URL()}.unitary_calibration/distinct/content.validation?filter=${op}`,
//       {
//         method: 'GET',
//         credentials: 'include',
//       },
//     )
//     const jsonFilt = await response.json()
//     const authenticationError = response.status === 403
//
//     return dispatch(receiveUnitaryCalibrationsDistinctValidation(jsonFilt, authenticationError))
//   }
// }

export function fetchUpdateUCValidation_old(data, unitaryAnalyse, multiAnalyse, batchId) {
  return (dispatch) => {
    const requestOptions = {
      method: 'PUT',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ batch_id: batchId, analysis_id: null, body: data }),
    }
    let url
    if (multiAnalyse) {
      url = `${FLASK_URL()}/unitary_calibration/validate`
    } else {
      url = `${FLASK_URL()}/unitary_calibration/${unitaryAnalyse._id}/validate`
    }
    // const response = await fetch(url, requestOptions)
    // const authenticationError = response.status === 403
    // if (authenticationError) {
    //   dispatch(fetchChangeSettings('loginPopup', true))
    //   throw new Error("You're not logged.")
    // }
    // if (response.status !== 200) {
    //   const error_msg = await response.text()
    //   const error = new Error(error_msg)
    //   error.code = response.status
    //   throw error
    // }
    // const json = await response.json()
    //
    // return dispatch(receiveUpdateValidation(json, null, authenticationError))
    fetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw new Error(response.statusText, { cause: response })
      })
      .then((json) => {
        return dispatch(receiveUpdateValidation(json, null, false))
      })
      .catch((response) => {
        if (response instanceof SyntaxError) {
          // Unexpected token < in JSON
          console.log('There was a SyntaxError', response)
          throw new Error('There was a SyntaxError', { cause: response })
        }
        console.log(response.message, response.cause)
        // 3. get error messages, if any
        // response.json().then((json) => {
        //   console.log(json)
        return response.message
        // })
      })
  }
}

export function fetchUpdateUCValidation(data, unitaryAnalyse, multiAnalyse, batchId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'PUT',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ batch_id: batchId, analysis_id: null, body: data }),
      }
      let url
      if (multiAnalyse) {
        url = `${FLASK_URL()}/unitary_calibration/validate`
      } else {
        url = `${FLASK_URL()}/unitary_calibration/${unitaryAnalyse._id}/validate`
      }
      fetch(url, requestOptions)
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((data) => {
          dispatch(receiveUpdateValidation(data, null, false))
          resolve(data)
        })
        .catch((error) => {
          const authenticationError = error.status === 403
          if (authenticationError) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          dispatch({ type: 'FETCH_ERROR', error })
          reject(error)
        })
    })
  }
}

function receiveUpdateValidation(json, collection, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UPDATE_UC_VALIDATION,
    objectsUpdated: json,
    collection: collection,
  }
}

export function fetchUpdateInterpolation(obj, pointsIncluded, calibrationID, collection, batchId) {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          batch_id: batchId,
          analysis_id: null,
          body: {
            points: pointsIncluded,
            type: obj.type,
            weights_policy: obj.weights_policy,
            zero_policy: obj.zero_policy,
            channel: obj.channel,
          },
        }),
      }

      fetch(`${FLASK_URL()}/unitary_calibration/${calibrationID}/update`, requestOptions)
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((data) => {
          // Return the last calibration loaded to check if the GUI must update it
          const { unitaryCalibrations } = getState()
          dispatch(receiveUpdateInterpolationForUA(data, collection, false))
          resolve(
            dispatch(
              receiveUpdateInterpolation(data, false, unitaryCalibrations.unitaryCalibrationLoaded),
            ),
          )
        })
        .catch((error) => {
          const authenticationError = error.status === 403
          if (authenticationError) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          dispatch({ type: 'FETCH_ERROR', error })
          reject(error)
        })
    })
  }
}
export function fetchResetCalibration(obj, pointsIncluded, calibrationID, collection, batchId) {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          batch_id: batchId,
          analysis_id: null,
          body: {
            points: pointsIncluded,
            type: obj.interpolation_type,
            weights_policy: obj.interpolation_weights_policy,
            zero_policy: obj.interpolation_zero_policy,
            channel: obj.channel,
            reset: true,
          },
        }),
      }
      fetch(`${FLASK_URL()}/unitary_calibration/${calibrationID}/update`, requestOptions)
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((data) => {
          // Return the last calibration loaded to check if the GUI must update it
          const { unitaryCalibrations } = getState()
          dispatch(receiveUpdateInterpolationForUA(data, collection, false))
          resolve(
            dispatch(
              receiveUpdateInterpolation(data, false, unitaryCalibrations.unitaryCalibrationLoaded),
            ),
          )
        })
        .catch((error) => {
          const authenticationError = error.status === 403
          if (authenticationError) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(error)
        })
    })
  }
}

function receiveUpdateInterpolation(json, authenticationError, unitaryCalibrationLoaded) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UPDATE_INTERPOLATION,
    objectsUpdated: json,
    unitaryCalibrationLoaded: unitaryCalibrationLoaded, // To test if it's the last calib displayed before update it in the GUI
  }
}

export function fetchUpdateStdPoints(obj, calibrationID, collection, batchId) {
  return async (dispatch) => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ batch_id: batchId, analysis_id: null, body: obj }),
      }
      fetch(`${FLASK_URL()}/unitary_calibration/${calibrationID}/update`, requestOptions)
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((data) => {
          dispatch(receiveUpdateSTDForUA(data, collection, false))
          resolve(dispatch(receiveUpdateSTD(data, false)))
        })
        .catch((error) => {
          const authenticationError = error.status === 403
          if (authenticationError) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(error)
        })
    })
  }
}
function receiveUpdateSTD(json, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UPDATE_STD_POINT,
    objectsUpdated: json,
  }
}

export function fetchUpdateISTD(istd_newGroup, istd_event, batchId) {
  return async (dispatch) => {
    return new Promise((resolve, reject) => {
      let data = {}
      data['event'] = istd_event
      data['new_istd_group'] = istd_newGroup

      const requestOptions = {
        method: 'PUT',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ batch_id: batchId, analysis_id: null, body: data }),
      }

      let url = `${FLASK_URL()}/batch/${batchId}/istd`
      fetch(url, requestOptions)
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((data) => {
          // Update batches store
          dispatch(receiveUpdateBatches(data.batches, false))
          resolve(dispatch(receiveUpdateISTD(data, false)))
        })
        .catch((error) => {
          const authenticationError = error.status === 403
          if (authenticationError) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(error)
        })
    })
  }
}
function receiveUpdateISTD(json, collection, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UPDATE_ISTD,
    objectsUpdated: json,
  }
}

export function fetchAddUCNote(uc, notes) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      let fullRaw = await getUnitaryCalibrationsRawById(uc._id)
      if (fullRaw) {
        let dataToUpdate = JSON.parse(JSON.stringify(fullRaw))
        dataToUpdate.content.notes = notes
        fetch(`${API_BO_URL()}.unitary_calibration/${uc._id}`, {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(dataToUpdate),
        })
          .then((response) => {
            if (!response.ok) {
              const statusText = response.statusText
              const status = response.status
              const url = response.url
              return response.text().then((errorMessage) => {
                const error = new Error(`${statusText} : ${errorMessage}`)
                if (response.headers.get('content-type') === 'application/json') {
                  error.stack = JSON.stringify(
                    JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                    null,
                    2,
                  )
                } else {
                  error.stack = new Error().stack
                }
                error.statusText = statusText
                error.status = status
                error.url = url
                throw error
              })
            }
            return response.json()
          })
          .then((data) => {
            resolve(dispatch(receiveUpdateNote(data, false)))
          })
          .catch((error) => {
            const authenticationError = error.status === 403
            if (authenticationError) {
              dispatch(fetchChangeSettings('loginPopup', true))
            }
            dispatch(receiveUpdateNote([], convertErrorCodeToType(error.status), error.statusText))
            reject(error)
          })
      }
    })
  }
}

export function fetchRemoveUCNote(uc, note) {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      const { unitaryCalibrations } = getState()

      // Get the same object of the note to get the good index
      //const data = getUnitaryCalibrationsFilteredById(unitaryCalibrations, uc._id)

      let fullRaw = await getUnitaryCalibrationsRawById(uc._id)

      const noteToRemove = fullRaw.content.notes.filter(
        (n) => n.operator === note.operator && n.date === note.date && n.message === note.message,
      )[0]

      if (fullRaw && noteToRemove) {
        let dataToUpdate = JSON.parse(JSON.stringify(fullRaw))

        let index = -1
        index = fullRaw.content.notes.indexOf(noteToRemove)
        if (index > -1) {
          dataToUpdate.content.notes.splice(index, 1)
          fetch(`${API_BO_URL()}.unitary_calibration/${uc._id}`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(dataToUpdate),
          })
            .then((response) => {
              if (!response.ok) {
                const statusText = response.statusText
                const status = response.status
                const url = response.url
                return response.text().then((errorMessage) => {
                  const error = new Error(`${statusText} : ${errorMessage}`)
                  if (response.headers.get('content-type') === 'application/json') {
                    error.stack = JSON.stringify(
                      JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                      null,
                      2,
                    )
                  } else {
                    error.stack = new Error().stack
                  }
                  error.statusText = statusText
                  error.status = status
                  error.url = url
                  throw error
                })
              }
              return response.json()
            })
            .then((data) => {
              resolve(dispatch(receiveUpdateNote(data, false)))
            })
            .catch((error) => {
              const authenticationError = error.status === 403
              if (authenticationError) {
                dispatch(fetchChangeSettings('loginPopup', true))
              }
              dispatch(
                receiveUpdateNote([], convertErrorCodeToType(error.status), error.statusText),
              )
              reject(error)
            })
        } else {
          return dispatch(
            receiveUpdateNote(
              [],
              convertErrorCodeToType(),
              'The note to removed was not founded in the Calibration',
            ),
          )
        }
      }
    })
  }
}

export function receiveUpdateNote(calibration, typeError, error) {
  return {
    type: typeError ? typeError : RECEIVE_UPDATE_UC_NOTE,
    objectsUpdated: calibration,
    error: error,
  }
}

export function fetchUpdateCalibration(calibrationID, collection, batchId) {
  return async (dispatch) => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ batch_id: batchId, analysis_id: null, body: {} }),
      }
      fetch(`${FLASK_URL()}/unitary_calibration/${calibrationID}/update`, requestOptions)
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((data) => {
          dispatch(receiveUpdateCalibrationForUA(data, collection, false))
          resolve(dispatch(receiveUpdateCalibration(data, false)))
        })
        .catch((error) => {
          const authenticationError = error.status === 403
          if (authenticationError) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(error)
        })
    })
  }
}

export function receiveUpdateCalibration(json, collection, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UPDATE_CALIBRATION,
    objectsUpdated: json,
  }
}
