import { API_BO_URL } from '../../utils/config'
import { fetchChangeSettings } from '../settings/actions'
import { displayPopupMessage, generateErrorPart } from '../../view/common/Message'
import React, { Fragment } from 'react'
import { receiveCurrentBatchLease } from '../batches/actions'

export const REQUEST_AUDIT_TRAIL = 'REQUEST_AUDIT_TRAIL'
export const DISPLAY_AUDIT_TRAIL = 'DISPLAY_AUDIT_TRAIL'
export const HIDE_AUDIT_TRAIL = 'HIDE_AUDIT_TRAIL'

export function fetchDisplayAuditTrail(id) {
  return async (dispatch) => {
    dispatch(requestDisplayAuditTrail(id))
    let filter = [
      {
        property: 'content.object.id',
        value: id,
        filterType: 'string',
        operator: 'eq',
      },
    ]
    let v = encodeURIComponent(JSON.stringify(filter))
    fetch(`${API_BO_URL()}.log/?filter=${v}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((json) => {
        return dispatch(displayAuditTrail(json))
      })
      .catch((error) => {
        dispatch(fetchHideAuditTrail())
        if (error.status === 403) {
          return dispatch(fetchChangeSettings('loginPopup', true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to display audit trail',
          <Fragment>
            <div>An error does not allow to display audit trail:</div>
            <div>{generateErrorPart(error)}</div>
          </Fragment>,
        )
      })
  }
}

export function requestDisplayAuditTrail(id) {
  return {
    type: REQUEST_AUDIT_TRAIL,
    refId: id,
  }
}
export function displayAuditTrail(auditTrail) {
  return {
    type: DISPLAY_AUDIT_TRAIL,
    auditTrail: auditTrail,
  }
}

export function fetchHideAuditTrail() {
  return async (dispatch) => {
    return dispatch({
      type: HIDE_AUDIT_TRAIL,
    })
  }
}
