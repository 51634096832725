import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import CardMedia from '@material-ui/core/CardMedia'
import Box from '@material-ui/core/Box'
import { PUBLIC_URL } from '../../utils/config'
import Popover from '@material-ui/core/Popover'
import { fetchHideJson } from '../../redux/json/actions'
import Dialog from '@material-ui/core/Dialog'
import Loading from '../Loading'
import JSONTree from 'react-json-tree'
import Message, { displayPopupMessage } from './Message'
import Button from '@mui/material/Button'
import { t } from 'i18next'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { Trans } from 'react-i18next'

const styles = (theme) => ({
  header: {
    boxShadow: 'inset 0px 0px 82px 10px rgba(0,0,0,1)',
  },
  searchCmp: {
    position: 'absolute',
    top: 65,
    left: 34,
    fontSize: 40,
    textShadow: '2px 1px 10px white',
    background: 'white',
    borderRadius: 13,
    boxShadow: '0 1px 6px 0 rgba(32, 33, 36, .28)',
    width: '40%',
  },
  cardImg: {
    opacity: 0.8,
  },
  titleCard: {
    position: 'absolute',
    top: 100,
    left: 34,
    fontSize: 40,
    color: theme.palette.secondary.main,
    textShadow: '2px 1px 10px white',
  },
  infoCard: {
    position: 'absolute',
    top: 140,
    right: 34,
    fontSize: 26,
    color: theme.palette.secondary.main,
    textShadow: '2px 1px 10px white',
  },
  popoverContainer: {
    background: '#00000021',
    '& > .MuiPopover-paper': {
      background: '#272822',
      height: '80%',
      width: '80%',
      // transform: 'translate(-50%, -50%) !important',
      top: '10% !important',
      left: '10% !important',
      // padding: 20,
      overflow: 'hidden',
    },
  },
  jsonTree: {
    height: 'calc(100% - 208px)',
    overflow: 'scroll',
    margin: 15,
  },
  bouton: {
    position: 'absolute',
    right: 20,
    bottom: 5,
    color: 'white',
  },
  clipboardButton: {
    position: 'absolute',
    right: 18,
    top: 192,
    color: 'white',
  },
})

const mapStateToProps = (state) => {
  const { json } = state
  return {
    display: json.visibility,
    json: json.json,
    fetching: json.isFetchingJson,
    collection: json.collection,
  }
}

const jsonTheme = {
  scheme: 'monokai',
  author: 'wimer hazenberg (http://www.monokai.nl)',
  base00: '#272822',
  base01: '#383830',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#f9f8f5',
  base08: '#f92672',
  base09: '#fd971f',
  base0A: '#f4bf75',
  base0B: '#a6e22e',
  base0C: '#a1efe4',
  base0D: '#66d9ef',
  base0E: '#ae81ff',
  base0F: '#cc6633',
}

export default withRouter(
  connect(mapStateToProps)(
    withStyles(styles)(
      class JsonView extends React.Component {
        constructor(props) {
          super(props)
          this.state = {
            // display: false,
          }
        }

        handleCloseJsonView = (event) => {
          const { dispatch } = this.props
          event.stopPropagation()
          event.preventDefault()
          dispatch(fetchHideJson())
        }

        render() {
          const { dispatch, classes, display, json, fetching, collection } = this.props

          const headerCmp = (
            <Box className={classes.header}>
              <CardMedia
                className={classes.cardImg}
                component="img"
                alt="header image"
                height="184"
                image={PUBLIC_URL + '/images/json_bkg.jpg'}
                title="Header image"
              />
              <span className={classes.titleCard}>
                {json ? `{ ${json.name} }` : '{ ' + t('view.common.jsonView.format') + ' }'}
              </span>
              <span className={classes.infoCard}>
                {collection ? `${collection}` : t('view.common.jsonView.collection')}
              </span>
            </Box>
          )
          const id = display ? 'simple-popover' : undefined

          function onClipboardButtonClick() {
            if (!navigator.clipboard) {
              let textArea = document.createElement('textarea')
              textArea.value = JSON.stringify(json, null, '\t')

              // Avoid scrolling to bottom
              textArea.style.top = '0'
              textArea.style.left = '0'
              textArea.style.position = 'fixed'

              document.body.appendChild(textArea)
              textArea.focus()
              textArea.select()

              try {
                const successful = document.execCommand('copy')
                const msg = successful
                  ? t('view.common.jsonView.successful')
                  : t('view.common.jsonView.unsuccessful')
                displayPopupMessage(
                  dispatch,
                  successful ? 'success' : 'error',
                  t('view.common.jsonView.title'),
                  <Fragment>
                    <div>
                      <Trans i18nKey="view.common.jsonView.success_msg1">
                        Copying text command was {{ val: msg }}.
                      </Trans>
                    </div>
                    <div>{t('view.common.jsonView.success_msg2')}</div>
                    {successful && <div>{t('view.common.jsonView.success_msg3')}</div>}
                  </Fragment>,
                )
              } catch (err) {
                console.error('Fallback: Oops, unable to copy', err)
                displayPopupMessage(
                  dispatch,
                  'error',
                  t('view.common.jsonView.title'),
                  <Fragment>
                    <div>{t('view.common.jsonView.error')}</div>
                    <div>{t('view.common.jsonView.success_msg2')}</div>
                    <div>{err}</div>
                  </Fragment>,
                )
              }

              document.body.removeChild(textArea)
              return
            }
            navigator.clipboard.writeText(JSON.stringify(json, null, '\t')).then(
              function () {
                displayPopupMessage(
                  dispatch,
                  'success',
                  t('view.common.jsonView.title'),
                  <Fragment>
                    <div>{t('view.common.jsonView.success_msg4')}</div>
                    <div>{t('view.common.jsonView.success_msg5')}</div>
                    <div>{t('view.common.jsonView.success_msg3')}</div>
                  </Fragment>,
                )
              },
              function (err) {
                displayPopupMessage(
                  dispatch,
                  'error',
                  t('view.common.jsonView.title'),
                  <Fragment>
                    <div>{t('view.common.jsonView.error_msg2')}</div>
                    <div>{t('view.common.jsonView.success_msg5')}</div>
                    <div>{err}</div>
                  </Fragment>,
                )
              },
            )
          }

          return (
            <Popover
              id={id}
              open={display}
              onClose={(event) => this.handleCloseJsonView(event)}
              className={classes.popoverContainer}
            >
              {fetching ? (
                <Fragment>
                  {headerCmp}
                  <Loading mode={'miniCircle'} />
                </Fragment>
              ) : (
                <Fragment>
                  {headerCmp}
                  <div className={classes.jsonTree}>
                    <ErrorBoundaryGuard>
                      <JSONTree
                        data={json}
                        theme={jsonTheme}
                        invertTheme={false}
                        hideRoot={true}
                        className={classes.jsonTree}
                      />
                    </ErrorBoundaryGuard>
                  </div>
                </Fragment>
              )}
              <Tooltip
                arrow
                title={
                  <div
                    style={{
                      fontSize: 13,
                      padding: 5,
                    }}
                  >
                    {t('view.common.jsonView.title')}
                  </div>
                }
              >
                <IconButton
                  aria-label="to_clipboard"
                  onClick={onClipboardButtonClick}
                  className={classes.clipboardButton}
                >
                  <ContentPasteIcon />
                </IconButton>
              </Tooltip>
              <Button
                size={'medium'}
                color="primary"
                className={classes.bouton}
                onClick={(event) => this.handleCloseJsonView(event)}
              >
                {t('view.common.jsonView.button')}
              </Button>
            </Popover>
          )
        }
      },
    ),
  ),
)
