import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { getComparator, stableSort } from './Utils'
import TableMultiGrid from './Table_MultiGrid'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import { t } from 'i18next'

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    height: 'calc(100% - 35px)',
    overflow: 'hidden',
    '& .ReactVirtualized__Grid:focus': {
      outline: 'none',
    },
  },
}))

const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 15,
    padding: '5px 0px',
    boxShadow: ' 0px 0px 10px 1px rgba(0,0,0,0.7)',
    width: 535,
    maxWidth: 535,
    '& .ReactVirtualized__Grid__innerScrollContainer > div': {
      // overflowWrap: 'break-word',
      // whiteSpace: 'normal',
    },
    '& .MuiTooltip-arrow': {
      color: 'white',
    },
  },
})(Tooltip)

export default function AIScoreTableTooltip({ item, value }) {
  const classes = useStyles()

  const [direction, setDirection] = React.useState('asc')
  const [order, setOrder] = React.useState('param')

  const rows =
    item && item.score_details
      ? stableSort(
          Object.entries(item.score_details).map((entry) => ({
            param: entry[0],
            value:
              typeof entry[1] === 'number' && !Number.isNaN(entry[1]) && entry[1] % 1 != 0
                ? entry[1].toFixed(2)
                : entry[1],
          })),
          getComparator(direction, order),
        )
      : []

  return (
    <CustomTooltip
      placement={'right'}
      interactive
      arrow
      style={{ width: 520 }}
      title={
        <div style={{ fontSize: 13, padding: 10, paddingTop: 0 }}>
          <div className={classes.tableContainer} style={{ width: 520, height: 400 }}>
            <TableMultiGrid
              orderBy={'param'}
              data={rows}
              sortAction={function (order, direction) {
                setDirection(direction)
                setOrder(order)
              }}
              columns={[
                { key: 'param', label: t('view.common.aiScoreTable.param'), size: 250 },
                {
                  key: 'value',
                  label: t('view.common.aiScoreTable.value'),
                  size: 250,
                },
              ]}
              fixedRowCount={1}
              rowHeight={40}
              getColumnRender={function () {
                return null
              }}
              onMoleculeClick={() => null}
              onMoleculeDoubleClick={() => null}
              settingsOption={false}
            />
          </div>
        </div>
      }
    >
      <div style={{ width: 'auto' }}>{value}</div>
    </CustomTooltip>
  )
}
