import React, { Fragment } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import PanToolIcon from '@material-ui/icons/PanTool'
import { fetchDisplayAuditTrail, fetchHideAuditTrail } from '../../redux/auditTrail/actions'
import { InfoTooltip, StyledDialogTitle, TransitionTop } from './Style'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Loading from '../Loading'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import Typography from '@material-ui/core/Typography'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineDot from '@material-ui/lab/TimelineDot'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import Paper from '@material-ui/core/Paper'
import Timeline from '@material-ui/lab/Timeline'
import InfoIcon from '@material-ui/icons/Info'
import { t } from 'i18next'

const styles = (theme) => ({
  modifiedButton: {
    width: 20,
    height: 20,
    // top: 10,
    // position: 'absolute',
    background: '#0f0f0f',
    borderRadius: 10,
    color: 'white',
    padding: 2,
    opacity: 0.6,
    '&:hover': {
      background: theme.palette.secondary.main,
      opacity: 1,
    },
  },
  separatorPoint: {
    width: 10,
    height: 10,
    background: theme.palette.secondary.main,
    borderRadius: 10,
    marginRight: 13,
  },
  dialogContent: {
    minWidth: 600,
    maxWidth: 800,
    minHeight: 400,
  },
  dialogCmp: {
    '& .MuiPaper-root': {
      maxWidth: 1000,
    },
  },
  paper: {
    padding: '8px 16px',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  info: {
    color: theme.palette.common.infoBlue,
  },
  tooltip: {
    maxWidth: 500,
  },
})

const mapStateToProps = (state) => {
  const { auditTrail } = state
  return {
    auditTrail: auditTrail.auditTrail,
    fetching: auditTrail.fetchingAuditTrail,
    display: auditTrail.display,
  }
}
export default connect(mapStateToProps)(
  withStyles(styles)(
    class AlphacodModifiedButtonIcon extends React.Component {
      render() {
        const { classes, dispatch, item, auditTrail, fetching, display } = this.props

        const timelineRef = React.createRef()

        const handleAuditTrail = (event, id) => {
          event.stopPropagation()
          event.preventDefault()
          dispatch(fetchDisplayAuditTrail(id))
        }

        return item.modified ? (
          <Fragment>
            <Dialog
              open={display === item._id}
              onClose={() => dispatch(fetchHideAuditTrail())}
              TransitionComponent={TransitionTop}
              aria-labelledby="dialog-auditTrail"
              className={classes.dialogCmp}
            >
              <StyledDialogTitle>
                {t('view.common.alphacod_modified_button.title')}
              </StyledDialogTitle>
              <DialogContent className={classes.dialogContent}>
                {fetching ? (
                  <Loading mode={'miniCircle'} />
                ) : (
                  <Timeline
                    align="left"
                    style={{ marginTop: 0, marginBottom: 0 }}
                    ref={timelineRef}
                  >
                    {auditTrail.map((at, index) => (
                      <TimelineItem key={at._id + '_' + index}>
                        <TimelineOppositeContent
                          style={{ flex: 'none', width: 160, padding: '0px 10px 0px 0px' }}
                        >
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{ marginTop: 6 }}
                          >
                            {new Date(at.creationDate * 1000).toLocaleString()}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {at.content.username}
                          </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot color="secondary" style={{ margin: 8 }}>
                            <PanToolIcon
                              style={{
                                color: 'white',
                                transform: 'rotate(36deg)',
                                borderRadius: 19,
                              }}
                            />
                          </TimelineDot>
                          {auditTrail.length !== index + 1 && <TimelineConnector />}
                        </TimelineSeparator>
                        <TimelineContent style={{ marginBottom: 15 }}>
                          <Paper elevation={3} className={classes.paper}>
                            <Typography variant="h6" component="h1">
                              <div style={{ float: 'left' }}>
                                <div>{at.content.type}</div>
                              </div>
                              <div style={{ float: 'right' }}>
                                <InfoTooltip
                                  placement="right"
                                  arrow
                                  title={
                                    <div style={{ fontSize: 13, padding: 5 }}>
                                      <table>
                                        <tbody>
                                          {at.content.object.analysis && (
                                            <tr>
                                              <td>
                                                {t('view.common.alphacod_modified_button.analysis')}
                                              </td>
                                              <td>{at.content.object.analysis.name}</td>
                                            </tr>
                                          )}
                                          {at.content.object.batch && (
                                            <tr>
                                              <td>
                                                {t('view.common.alphacod_modified_button.batch')}
                                              </td>
                                              <td>{at.content.object.batch.name}</td>
                                            </tr>
                                          )}
                                          <tr>
                                            <td style={{ paddingRight: 10 }}>
                                              {t('view.common.alphacod_modified_button.execution')}
                                            </td>
                                            <td>{at.content.execution_time.toFixed(4)} sec</td>
                                          </tr>
                                          <tr>
                                            <td style={{ verticalAlign: 'top' }}>
                                              {t('view.common.alphacod_modified_button.values')}
                                            </td>
                                            <td>
                                              <table>
                                                <tbody>
                                                  {Object.entries(at.content.values).map(
                                                    (value) => (
                                                      <tr>
                                                        <td style={{ paddingRight: 10 }}>
                                                          {value[0]} :
                                                        </td>
                                                        <td>{value[1].old}</td>
                                                        <td>→</td>
                                                        <td>{value[1].new}</td>
                                                      </tr>
                                                    ),
                                                  )}
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  }
                                >
                                  <InfoIcon className={classes.info} />
                                </InfoTooltip>
                              </div>
                            </Typography>
                            <Typography
                              style={{
                                maxHeight: 80,
                                overflow: 'auto',
                                fontWeight: 100,
                                fontStyle: 'italic',
                              }}
                            >
                              {at.content.title}
                            </Typography>
                          </Paper>
                        </TimelineContent>
                      </TimelineItem>
                    ))}
                  </Timeline>
                )}
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={() => dispatch(fetchHideAuditTrail())} color="primary">
                  {t('view.common.alphacod_modified_button.close')}
                </Button>
              </DialogActions>
            </Dialog>
            <Tooltip
              placement="top"
              arrow
              title={
                <div style={{ fontSize: 13, padding: 5 }}>
                  <div>{t('view.common.alphacod_modified_button.change_made')}</div>
                  <div style={{ marginTop: 15 }}>
                    <i>{t('view.common.alphacod_modified_button.click')}</i>
                  </div>
                </div>
              }
            >
              <IconButton
                className={classes.modifiedButton}
                onClick={(event) => {
                  handleAuditTrail(event, item._id)
                }}
              >
                <PanToolIcon
                  style={{
                    transform: 'rotate(36deg)',
                    borderRadius: 10,
                    width: 20,
                    height: 20,
                    padding: 2,
                  }}
                />
              </IconButton>
            </Tooltip>
          </Fragment>
        ) : (
          ''
        )
      }
    },
  ),
)
