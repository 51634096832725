import {
  REQUEST_UNITARY_CALIBRATIONS_FILTERED,
  RECEIVE_UNITARY_CALIBRATIONS_FILTERED,
  REQUEST_UNITARY_CALIBRATIONS_DISTINCT,
  RECEIVE_UNITARY_CALIBRATIONS_DISTINCT,
  SORT_UNITARY_CALIBRATION,
  UPDATE_VALIDATION_CALIBRATION,
  REQUEST_UNITARY_CALIBRATIONS_DISTINCT_VALIDATION,
  RECEIVE_UNITARY_CALIBRATIONS_DISTINCT_VALIDATION,
  RECEIVE_UPDATE_UC_VALIDATION,
  RECEIVE_UPDATE_INTERPOLATION,
  RECEIVE_UPDATE_STD_POINT,
  RECEIVE_UPDATE_ISTD,
  AUTHENTICATION_FAILURE,
  RECEIVE_UPDATE_CALIBRATION,
  RECEIVE_UPDATE_UC_NOTE,
  REQUEST_ALL_UNITARY_CALIBRATIONS_NOTES,
  RECEIVE_ALL_UNITARY_CALIBRATIONS_NOTES, RECEIVE_UNITARY_CALIBRATION,
} from './actions'
import {
  getComparator,
  rebuildUnitaryAnalyses,
  rebuildUnitaryCalibrations,
  rebuildUnitaryCompounds,
  stableSort,
} from '../../view/common/Utils'
import { RECEIVE_UPDATE_MAIN_CHANNEL } from '../unitaryAnalyses/actions'
import { AUTHENTICATION_ERROR, DEFAULT_ERROR, NOT_FONDED, PERMISSION_ERROR } from '../tools'

const initialState = {
  unitaryCalibrationsDistinct: [],
  unitaryCalibrationsFiltered: [],
  unitaryCalibrationsDistinctValidation: [],
  isFetchingUnitaryCalibrationsFiltered: false,
  isFetchingUnitaryCalibrationsDistinct: false,
  isFetchingUnitaryCalibrationsDistinctValidation: false,
  error: false,
  isFetchingUnitaryCalibrationsNote: false,
  unitaryCalibrationsNotes: [],
  unitaryCalibrationsFilteredLastDateRequest: null,
  unitaryCalibrationLoaded: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_VALIDATION_CALIBRATION:
      const unitaryCalibrationsFiltered = state.unitaryCalibrationsFiltered
      const index = unitaryCalibrationsFiltered.findIndex(
        (element) => element._id === action.analyseId,
      )

      const analyse = unitaryCalibrationsFiltered[index]

      const newDistinct = {
        ...state.unitaryCalibrationsDistinctValidation,
        [analyse.validation]: {
          count: --state.unitaryCalibrationsDistinctValidation[analyse.validation].count,
        },
        [action.validation]: state.unitaryCalibrationsDistinctValidation[action.validation]
          ? { count: ++state.unitaryCalibrationsDistinctValidation[action.validation].count }
          : { count: 1 },
      }

      analyse.validation = action.validation
      unitaryCalibrationsFiltered[index] = analyse

      return {
        ...state,
        // unitaryCalibrationsFiltered: unitaryCalibrationsFiltered,
        unitaryCalibrationsDistinctValidation: newDistinct,
      }
    case RECEIVE_UNITARY_CALIBRATION:
      return {
        ...state,
        unitaryCalibrationLoaded: action.unitaryCalibrationLoaded,
      }
    case SORT_UNITARY_CALIBRATION:
      return {
        ...state,
        unitaryCalibrationsFiltered: stableSort(
          state.unitaryCalibrationsFiltered,
          getComparator(action.sort, action.sortBy),
        ),
      }
    case REQUEST_UNITARY_CALIBRATIONS_FILTERED:
      return {
        ...state,
        isFetchingUnitaryCalibrationsFiltered: true,
        batchId: action.batchId,
        filter: action.filter,
        error: false,
        unitaryCalibrationsFilteredLastDateRequest: action.dateLock,
      }

    case RECEIVE_UNITARY_CALIBRATIONS_FILTERED:
      // Avoid displaying an old request after a more recent one which was faster.
      if (action.dateLock !== state.unitaryCalibrationsFilteredLastDateRequest) {
        return {
          ...state,
        }
      } else {
        return {
          ...state,
          error: false,
          isFetchingUnitaryCalibrationsFiltered: false,
          unitaryCalibrationsFiltered: action.unitaryCalibrationsFiltered,
        }
      }
    case REQUEST_UNITARY_CALIBRATIONS_DISTINCT:
      return {
        ...state,
        isFetchingUnitaryCalibrationsDistinct: true,
        batchId: action.batchId,
        error: false,
      }

    case RECEIVE_UNITARY_CALIBRATIONS_DISTINCT:
      return {
        ...state,
        error: false,
        isFetchingUnitaryCalibrationsDistinct: false,
        unitaryCalibrationsDistinct: action.unitaryCalibrationsDistinct,
      }
    case REQUEST_UNITARY_CALIBRATIONS_DISTINCT_VALIDATION:
      return {
        ...state,
        isFetchingUnitaryCalibrationsDistinctValidation: true,
        batchId: action.batchId,
        error: false,
      }

    case RECEIVE_UNITARY_CALIBRATIONS_DISTINCT_VALIDATION:
      return {
        ...state,
        error: false,
        isFetchingUnitaryCalibrationsDistinctValidation: false,
        unitaryCalibrationsDistinctValidation: action.unitaryCalibrationsDistinctValidation,
      }

    case AUTHENTICATION_FAILURE:
      return {
        ...state,
        isFetchingUnitaryCalibrationsFiltered: false,
        error: true,
      }
    case RECEIVE_UPDATE_UC_VALIDATION:
      const ucIdsV = action.objectsUpdated.ucs.reduce(function (map, obj) {
        map[obj._id] = obj
        return map
      }, {})
      return {
        ...state,
        isFetchingUnitaryCalibrationsFiltered: false,
        unitaryCalibrationsFiltered: state.unitaryCalibrationsFiltered.map(function (ua) {
          if (ucIdsV[ua._id]) {
            return rebuildUnitaryCalibrations(ucIdsV[ua._id])
          }
          return ua
        }),
      }
    case RECEIVE_UPDATE_INTERPOLATION:
      const ucIdsI = action.objectsUpdated.ucs.reduce(function (map, obj) {
        map[obj._id] = obj
        return map
      }, {})
      return {
        ...state,
        isFetchingUnitaryCalibrationsFiltered: false,
        unitaryCalibrationsFiltered: state.unitaryCalibrationsFiltered.map(function (ua) {
          if (ucIdsI[ua._id]) {
            return rebuildUnitaryCalibrations(ucIdsI[ua._id])
          }
          return ua
        }),
      }
    case RECEIVE_UPDATE_STD_POINT:
      const ucIdsP = action.objectsUpdated.ucs.reduce(function (map, obj) {
        map[obj._id] = obj
        return map
      }, {})
      return {
        ...state,
        isFetchingUnitaryCalibrationsFiltered: false,
        unitaryCalibrationsFiltered: state.unitaryCalibrationsFiltered.map(function (ua) {
          if (ucIdsP[ua._id]) {
            return rebuildUnitaryCalibrations(ucIdsP[ua._id])
          }
          return ua
        }),
      }
    case RECEIVE_UPDATE_ISTD:
      const ucIdsIstd = action.objectsUpdated.ucs.reduce(function (map, obj) {
        map[obj._id] = obj
        return map
      }, {})
      return {
        ...state,
        isFetchingUnitaryCalibrationsFiltered: false,
        unitaryCalibrationsFiltered: state.unitaryCalibrationsFiltered.map(function (uc) {
          if (ucIdsIstd[uc._id]) {
            return rebuildUnitaryCalibrations(ucIdsIstd[uc._id])
          }
          return uc
        }),
      }
    case RECEIVE_UPDATE_UC_NOTE:
      return {
        ...state,
        isFetchingUnitaryCalibrationsFiltered: false,
        unitaryCalibrationsFiltered: state.unitaryCalibrationsFiltered.map(function (uc) {
          if (action.objectsUpdated._id === uc._id) {
            return rebuildUnitaryCalibrations(action.objectsUpdated)
          }
          return uc
        }),
        unitaryCalibrationsNotes: state.unitaryCalibrationsNotes.map(function (uc) {
          if (action.objectsUpdated._id === uc._id) {
            return rebuildUnitaryCalibrations(action.objectsUpdated)
          }
          return uc
        }),
      }
    case REQUEST_ALL_UNITARY_CALIBRATIONS_NOTES:
      return {
        ...state,
        isFetchingUnitaryCalibrationsNote: true,
      }
    case RECEIVE_ALL_UNITARY_CALIBRATIONS_NOTES:
      return {
        ...state,
        isFetchingUnitaryCalibrationsNote: false,
        unitaryCalibrationsNotes: action.unitaryCalibrationsNotes,
      }
    case RECEIVE_UPDATE_CALIBRATION:
      const ucIdsC = action.objectsUpdated.ucs.reduce(function (map, obj) {
        map[obj._id] = obj
        return map
      }, {})
      return {
        ...state,
        isFetchingUnitaryCalibrationsFiltered: false,
        unitaryCalibrationsFiltered: state.unitaryCalibrationsFiltered.map(function (ua) {
          if (ucIdsC[ua._id]) {
            return rebuildUnitaryCalibrations(ucIdsC[ua._id])
          }
          return ua
        }),
      }
    case PERMISSION_ERROR:
    case NOT_FONDED:
    case AUTHENTICATION_ERROR:
    case DEFAULT_ERROR:
      return {
        ...state,
        error: action.error,
      }
    default:
      return state
  }
}
