/*   global unitaryAnalysesGlobal */
import React, { useRef, useState, useMemo, useEffect, Fragment } from 'react'
import { Scatter } from 'react-chartjs-2'
import 'chartjs-plugin-dragdata'
import '../../../utils/chartjs-zoom-plugin'
import * as ChartAnnotation from 'chartjs-plugin-annotation' //DO NOT REMOVE !!!!
import { resetPeak, updateGlobalPeak, updatePeak } from '../Utils'
import { Molecule, Nothing, Relance, ChromatoRecentred, ResetPeak, ResetZoom } from '../Style'
import { makeStyles, Tooltip, withStyles } from '@material-ui/core'
import AlphacodFlagIcon from '../AlphacodFlagIcon'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
import SpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { fade } from '@material-ui/core/styles'
import ArtTrackIcon from '@material-ui/icons/ArtTrack'
import { fetchDisplayDetail } from '../../../redux/detail/actions'
import ChromatoApplyAllDialog from '../../dialog/ChromatoApplyAllDialog'
import clsx from 'clsx'
import { fetchChangeLock } from '../../../redux/unitaryAnalyses/actions'
import { displayPopupMessage } from '../Message'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import LockCloseIcon from '@material-ui/icons/Lock'
import {
  GET_CHROMATO_COLORS,
  GET_CHROMATO_OPACITY,
  GET_CHROMATO_WEIGHT,
  GET_DEFAULT_WINDOW_CHROMATO_IS_PEAK,
  ZOOM_DEFAULT_FACTO,
} from '../../../utils/config'
import { displayHumanReadable } from '../../common/Utils'
import AspectRatioIcon from '@material-ui/icons/AspectRatio'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import CloseIcon from '@material-ui/icons/Close'
import ErrorBoundaryGuard from '../../ErrorBoundaryGuard'
import { t } from 'i18next'
import { Checkbox, Stack } from '@mui/material'
import ClearIcon from '@material-ui/icons/Clear'
import CheckIcon from '@material-ui/icons/Check'

const useStyles = makeStyles((theme) => ({
  resetZoom: {
    position: 'absolute',
    padding: 7,
    bottom: 37,
    right: 18,
    background: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      background: theme.palette.secondary.main,
    },
  },
  resetPeak: {
    position: 'absolute',
    padding: 7,
    bottom: 37,
    right: 54,
    background: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      background: theme.palette.secondary.main,
    },
  },
  chromatoScatterDiv: {
    width: '100%',
    height: '100%',
    paddingTop: 25,
    '&:hover': {
      // '& .MuiSpeedDial-root > button': {
      '& button': {
        transform: 'scale(1) !important',
        // transition: 'transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important',
      },
      '& .checkboxChromato': {
        transform: 'scale(1) !important',
        // transition: 'transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important',
      },
    },
  },
  chromatoTitle: {
    position: 'absolute',
    top: 7,
    width: 'calc(100% - 60px)',
    left: 28,
    textAlign: 'center',
    fontWeight: 500,
    fontSize: 'small',
    opacity: 0.9,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  checkboxChromato: {
    position: 'absolute',
    top: 4,
    left: 8,
    padding: 0,
    transform: 'scale(0) !important',
  },
  checkboxChromatoNotSelected: {},
  checkboxChromatoSelected: {
    transform: 'scale(1) !important',
  },
  speedDial: {
    position: 'absolute',
    top: 4,
    right: 8,
    padding: 0,
    // '& > button': {
    transform: 'scale(0) !important',
    // transition: 'transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important',
    // },
    '& .MuiSpeedDialIcon-root': {
      width: 30,
      height: 30,
    },
    '& .MuiSpeedDial-actions .MuiSpeedDial-actionsClosed': {
      paddingTop: 35,
    },
    '& .MuiSpeedDial-directionDown .MuiSpeedDial-actions': {
      paddingTop: 35,
    },
    '& .MuiFab-sizeSmall': {
      width: 30,
      height: 30,
      minHeight: 0,
      marginTop: 2,
      marginBottom: 2,
    },
    '& .MuiFab-primary': {
      color: fade(theme.palette.common.black, 0.65),
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    '& .MuiSpeedDialIcon-openIcon': {
      width: 30,
      height: 30,
      right: 0,
      borderRadius: '50%',
      color: 'white',
      backgroundColor: theme.palette.secondary.main,
      boxShadow:
        '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
    },
  },
  speedDialActionIcon: {
    width: 18,
    top: 3,
    position: 'absolute',
  },
  transitionButton: {
    cursor: 'auto',
    backgroundColor: fade('#ffffff', 0.5),
    // '&:hover': {
    //   backgroundColor: fade(theme.palette.secondary.main, 0.1),
    //   opacity: 1,
    //   // fontWeight: 'bolder',
    // },
  },
  transitionButtonSelected: {
    cursor: 'pointer',
    backgroundColor: fade('#ffffff', 0.5),
    '&:hover': {
      backgroundColor: fade(theme.palette.secondary.main, 0.1),
      opacity: 1,
      // fontWeight: 'bolder',
    },
  },
  selectedChannel: {
    fontWeight: 'bolder',
    opacity: 1,
  },
  notSelectedChannel: {
    fontWeight: 'lighter',
    opacity: 0.8,
  },
  transitionNumber: {
    border: '1px solid',
    borderRadius: 6,
    padding: '0px 2px',
    marginRight: 5,
  },
  selected: {
    color: theme.palette.primary.main,
    border: '2px solid ' + theme.palette.primary.main,
    borderRadius: 15,
    height: 20,
    width: 20,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
}))

const colorChromato = GET_CHROMATO_COLORS()

export default function ChromatoScatter({
  updateCalibrationAfterPeakUpdate,
  getSelectedChannel,
  unitaryAnalyse,
  dispatch,
  keyValue,
  collection,
  title = '',
  showLegend = false,
  showChannels = false,
  chromatoSize = 0,
  initPeaks,
  currentPeaks,
  isInitPeaks,
  onChangeChannel,
  checkSelected,
  onSelect,
  index,
  selection,
  canEdit,
  isSelected,
}) {
  const classes = useStyles()
  const chartRef = useRef(null)

  const [hasMoved, setHasMoved] = useState(false)
  const [hasChanged, setHasChanged] = useState(isInitPeaks)
  const [activePan, setActivePan] = useState(true)
  // const [ua, setUa] = useState(unitaryAnalyse)
  const [peaksCoord, setPeaksCoord] = useState(currentPeaks)
  const [updateInterpolation, setUpdateInterpolation] = useState(false)
  const [open, setOpen] = useState(false)
  const [openConfirm, setOpenConfirm] = useState(false)
  const [graphPosition, setGraphPosition] = useState({
    'x-axis-1': {},
    'y-axis-1': {},
  })
  const [anchorEl, setAnchorEl] = React.useState(null)

  const area_info = localStorage.getItem('CHROMATO_option_area')
    ? localStorage.getItem('CHROMATO_option_area') === 'true'
    : false
  const height_info = localStorage.getItem('CHROMATO_option_height')
    ? localStorage.getItem('CHROMATO_option_height') === 'true'
    : false
  const concVol_info = localStorage.getItem('CHROMATO_option_concVol')
    ? localStorage.getItem('CHROMATO_option_concVol') === 'true'
    : false
  const rt_info = localStorage.getItem('CHROMATO_option_rt')
    ? localStorage.getItem('CHROMATO_option_rt') === 'true'
    : false
  const flag_info = localStorage.getItem('CHROMATO_option_flag')
    ? localStorage.getItem('CHROMATO_option_flag') === 'true'
    : true
  const transitions_info = localStorage.getItem('CHROMATO_option_transitions')
    ? localStorage.getItem('CHROMATO_option_transitions') === 'true'
    : true
  const rtCal = localStorage.getItem('CHROMATO_option_rtCal')
    ? localStorage.getItem('CHROMATO_option_rtCal') === 'true'
    : true
  const rtCor = localStorage.getItem('CHROMATO_option_rtCor')
    ? localStorage.getItem('CHROMATO_option_rtCor') === 'true'
    : true
  const rtMth = localStorage.getItem('CHROMATO_option_rtMth')
    ? localStorage.getItem('CHROMATO_option_rtMth') === 'true'
    : true
  const validation = localStorage.getItem('CHROMATO_option_validation')
    ? localStorage.getItem('CHROMATO_option_validation') === 'true'
    : false
  const lockChromatoEdition = localStorage.getItem('CHROMATO_option_lockChromatoEdition')
    ? localStorage.getItem('CHROMATO_option_lockChromatoEdition') === 'true'
    : false

  global.unitaryAnalysesGlobal = useMemo(() => unitaryAnalyse, [unitaryAnalyse])

  useEffect(() => {
    // setUa(unitaryAnalyse)
    if (!updateInterpolation) {
      setPeaksCoord(currentPeaks)
    }
  }, [JSON.stringify(currentPeaks), unitaryAnalyse])

  // Reset saved axes values when analysis changed (only for 3rd layout)
  useEffect(() => {
    if (unitaryAnalyse) {
      setGraphPosition({
        'x-axis-1': {},
        'y-axis-1': {},
      })
    }
  }, [unitaryAnalyse._id])

  const datasets = useMemo(() => {
    let datasets = [
      {
        data: unitaryAnalyse.main_channel_data.data,
        label: unitaryAnalyse.name + '_' + unitaryAnalyse.analyse,
        backgroundColor: '#165c90',
        pointBorderColor: '#165c90',
        borderColor: '#165c90',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 0,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#e7aa70',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 0,
        showLine: true,
        borderWidth: GET_CHROMATO_WEIGHT(),
        dragData: false,
        fill: false,
        fillBetweenSet:
          unitaryAnalyse.selectedChannel === unitaryAnalyse.main_channel_data.channelNumber
            ? 0
            : undefined,
        fillBetweenColor:
          unitaryAnalyse.selectedChannel === unitaryAnalyse.main_channel_data.channelNumber
            ? 'rgba(225,149,66,' + GET_CHROMATO_OPACITY() + ')' //'#e19542', // Add transparency
            : undefined,
        tension: 0,
        // "#00dD0030"
        // fillBetweenSet: 1,
        // fillBetweenColor: 'rgba(255,0,0, 0.2)',
      },
    ]
    unitaryAnalyse.channels_data.forEach(function (channel, index) {
      datasets.push({
        data: channel.data,
        label: unitaryAnalyse.name + '_' + unitaryAnalyse.analyse + '_' + index,
        borderColor: colorChromato[parseInt(index)],
        pointBorderWidth: 0,
        pointHoverRadius: 5,
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 0,
        showLine: true,
        borderWidth: GET_CHROMATO_WEIGHT(),
        dragData: false,
        fill: false,
        tension: 0,
        fillBetweenSet: unitaryAnalyse.selectedChannel === channel.channelNumber ? 0 : undefined,
        fillBetweenColor:
          unitaryAnalyse.selectedChannel === channel.channelNumber
            ? 'rgba(225,149,66,' + GET_CHROMATO_OPACITY() + ')' //'#e19542', // Add transparency,
            : undefined,
      })
    })
    // Fix bug chromato size and sort
    // We fixed all chromato with 12 datasets
    if (!showLegend) {
      for (let i = datasets.length; i < 12; i++) {
        datasets.push({
          data: [],
          label: unitaryAnalyse.name + '_' + unitaryAnalyse.analyse + '_' + i,
          hidden: true,
        })
      }
    }
    return datasets
  }, [unitaryAnalyse, showLegend])

  const peaksChart = useMemo(
    () => ({
      data: peaksCoord,
      dragData: canIDrag(),
      label: unitaryAnalyse.name + '_' + unitaryAnalyse.analyse + '_Peak',
      pointStyle: 'triangle',
      backgroundColor: '#e19542',
      pointBorderColor: '#e19542',
      borderColor: '#e19542',
      pointBackgroundColor: '#e19542',
      pointBorderWidth: 2,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: '#165c90',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 5,
      pointHitRadius: 10,
      showLine: false,
      borderWidth: GET_CHROMATO_WEIGHT(),
      tension: 0,
      // fill: false,
      // fillBetweenSet: 0,
      // fillBetweenColor: 'rgba(5,5,255, 0.2)',
    }),
    [isSelected, peaksCoord, unitaryAnalyse.analyse, unitaryAnalyse.name],
  )

  const selectedChannel = useMemo(() => unitaryAnalyse.selectedChannel, [unitaryAnalyse])

  let annotations = []
  if (unitaryAnalyse.rt && rtCal) {
    annotations.push({
      type: 'line',
      mode: 'vertical',
      scaleID: 'x-axis-1',
      value: unitaryAnalyse.rt,
      borderColor: '#e19542',
      borderWidth: 1,
      label: {
        backgroundColor: '#ffffff55',
        fontColor: '#e19542',
        content: 'RT Cal',
        position: 'bottom',
        enabled: true,
        fontSize: 10,
      },
    })
  }
  if (unitaryAnalyse.type !== 'ISTD' && unitaryAnalyse.rtCorrected && rtCor) {
    annotations.push({
      type: 'line',
      mode: 'vertical',
      scaleID: 'x-axis-1',
      value: unitaryAnalyse.rtCorrected,
      borderColor: '#165c90',
      borderWidth: 1,
      label: {
        backgroundColor: '#ffffff55',
        fontColor: '#165c90',
        content: 'RT Cor',
        position: 'top',
        enabled: true,
        fontSize: 10,
      },
    })
  }
  if (unitaryAnalyse.type === 'ISTD' && unitaryAnalyse.ret_time && rtMth) {
    annotations.push({
      type: 'line',
      mode: 'vertical',
      scaleID: 'x-axis-1',
      value: unitaryAnalyse.ret_time,
      borderColor: '#737373',
      borderWidth: 1,
      label: {
        backgroundColor: '#ffffff55',
        fontColor: '#737373',
        content: 'RT Mth',
        position: 'top',
        enabled: true,
        fontSize: 10,
      },
    })
  }

  const onReset = (event, initPeaks) => {
    resetPeak(
      unitaryAnalyse,
      null,
      initPeaks,
      dispatch,
      collection,
      getSelectedChannel(unitaryAnalyse._id),
      updateCalibrationAfterPeakUpdate(unitaryAnalyse),
    ).then((response) => {
      const peakBase =
        response.unitaryAnalysesUpdated &&
        response.unitaryAnalysesUpdated.uas &&
        response.unitaryAnalysesUpdated.uas[0] &&
        response.unitaryAnalysesUpdated.uas[0].content.channels &&
        response.unitaryAnalysesUpdated.uas[0].content.channels[
          getSelectedChannel(unitaryAnalyse._id)
        ] &&
        response.unitaryAnalysesUpdated.uas[0].content.channels[
          getSelectedChannel(unitaryAnalyse._id)
        ].peak.base
          ? response.unitaryAnalysesUpdated.uas[0].content.channels[
              getSelectedChannel(unitaryAnalyse._id)
            ].peak.base
          : null
      if (peakBase) {
        setPeaksCoord([peakBase.left, peakBase.right])
      }
    })
    setUpdateInterpolation(true)
    setHasChanged(false)
    setOpen(false)
  }

  const onClear = (e) => {
    if (chartRef.current) {
      const chartInstance = chartRef.current.chartInstance
      chartInstance.resetZoom()
      setHasMoved(false)
      setOpen(false)
      setGraphPosition({
        'x-axis-1': {},
        'y-axis-1': {},
      })
    }
  }

  const onEntireChromato = (e) => {
    if (chartRef.current) {
      const chartInstance = chartRef.current.chartInstance
      chartInstance.resetZoom()
      setHasMoved(false)
      setOpen(false)
      const minY = Math.min(
        ...Object.keys(unitaryAnalyse.channels).map((k) =>
          Math.min(...unitaryAnalyse.channels[k].ampl),
        ),
      )
      const maxY = Math.max(
        ...Object.keys(unitaryAnalyse.channels).map((k) =>
          Math.max(...unitaryAnalyse.channels[k].ampl),
        ),
      )
      const minX = Math.min(...unitaryAnalyse.channels_data.map((channel) => channel.data[0].x))
      const maxX = Math.max(
        ...unitaryAnalyse.channels_data.map((channel) => channel.data[channel.data.length - 1].x),
      )
      setGraphPosition({
        'x-axis-1': {
          min: minX - (maxX - minX) * 0.05,
          max: maxX + (maxX - minX) * 0.05,
        },
        'y-axis-1': {
          min: minY - (maxY - minY) * 0.05,
          max: maxY + (maxY - minY) * 0.05,
        },
      })
    }
  }
  const onPeakFocus = (e) => {
    if (chartRef.current) {
      const chartInstance = chartRef.current.chartInstance
      chartInstance.resetZoom()
      setHasMoved(false)
      setOpen(false)

      const min_x = Math.min(
        ...['left', 'right'].map(
          (pt) => unitaryAnalyse?.channels?.[unitaryAnalyse.selectedChannel]?.peak?.base?.[pt]?.x,
        ),
      )
      const max_x = Math.max(
        ...['left', 'right'].map(
          (pt) => unitaryAnalyse?.channels?.[unitaryAnalyse.selectedChannel]?.peak?.base?.[pt]?.x,
        ),
      )

      const datas =
        unitaryAnalyse.selectedChannel === unitaryAnalyse.mainChannel
          ? unitaryAnalyse.main_channel_data?.data
          : unitaryAnalyse.channels_data.filter(
              (chdata) => chdata.channelNumber === unitaryAnalyse.selectedChannel,
            )[0]?.data
      const maxYInRange = (data, min, max) =>
        Math.max(
          ...data.filter((point) => point.x >= min && point.x <= max).map((point) => point.y),
        )

      const min_y = Math.min(
        ...['left', 'right'].map(
          (pt) => unitaryAnalyse?.channels?.[unitaryAnalyse.selectedChannel]?.peak?.base?.[pt]?.y,
        ),
      )
      const max_y = maxYInRange(datas, min_x, max_x)

      const marge = 0.1

      setGraphPosition({
        'x-axis-1': {
          min: min_x - (max_x - min_x) * marge,
          max: max_x + (max_x - min_x) * marge,
        },
        'y-axis-1': {
          min: min_y - (max_y - min_y) * marge,
          max: max_y + (max_y - min_y) * marge,
        },
      })
    }
  }

  const defaultWindowCoord = useMemo(() => {
    let marge = 0.01
    let min_x = unitaryAnalyse.main_channel_data.data[0].x
    let max_x =
      unitaryAnalyse.main_channel_data.data[unitaryAnalyse.main_channel_data.data.length - 1].x
    // Add negative marge of 10% under 0 if the lock under 0 is unlocked
    let min_y = unitaryAnalyse.unlocked
      ? (graphPosition['y-axis-1'].max
          ? graphPosition['y-axis-1'].max
          : Object.entries(unitaryAnalyse.channels)
              .map((c) => c[1].peak)
              .every((p) => p && p.ampl)
          ? Math.max(
              ...Object.keys(unitaryAnalyse.channels).map((k) =>
                Math.min(
                  unitaryAnalyse.channels[k].ampl[
                    unitaryAnalyse.channels[unitaryAnalyse.mainChannel].peak.pos
                  ] * ZOOM_DEFAULT_FACTO(),
                  unitaryAnalyse.channels[k].ampl_max * 1.1,
                ),
              ),
            )
          : 0) * -0.1
      : 0
    let max_y = Object.entries(unitaryAnalyse.channels)
      .map((c) => c[1].peak)
      .every((p) => p && p.ampl)
      ? Math.max(
          ...Object.keys(unitaryAnalyse.channels).map((k) =>
            Math.min(
              // unitaryAnalyse.channels[k].peak.ampl * ZOOM_DEFAULT_FACTO(),
              unitaryAnalyse.channels[k].ampl[
                unitaryAnalyse.channels[unitaryAnalyse.mainChannel].peak.pos
              ] * ZOOM_DEFAULT_FACTO(),
              unitaryAnalyse.channels[k].ampl_max * 1.1,
            ),
          ),
        )
      : undefined
    if (GET_DEFAULT_WINDOW_CHROMATO_IS_PEAK()) {
      marge = 0.1
      min_x = Math.min(
        ...['left', 'right'].map(
          (pt) => unitaryAnalyse?.channels?.[unitaryAnalyse.selectedChannel]?.peak?.base?.[pt]?.x,
        ),
      )
      max_x = Math.max(
        ...['left', 'right'].map(
          (pt) => unitaryAnalyse?.channels?.[unitaryAnalyse.selectedChannel]?.peak?.base?.[pt]?.x,
        ),
      )
      min_y = Math.min(
        ...['left', 'right'].map(
          (pt) => unitaryAnalyse?.channels?.[unitaryAnalyse.selectedChannel]?.peak?.base?.[pt]?.y,
        ),
      )
      const datas =
        unitaryAnalyse.selectedChannel === unitaryAnalyse.mainChannel
          ? unitaryAnalyse.main_channel_data?.data
          : unitaryAnalyse.channels_data.filter(
              (chdata) => chdata.channelNumber === unitaryAnalyse.selectedChannel,
            )[0]?.data
      const maxYInRange = (data, min, max) =>
        Math.max(
          ...data.filter((point) => point.x >= min && point.x <= max).map((point) => point.y),
        )
      max_y = maxYInRange(datas, min_x, max_x)
      return {
        min_x: min_x - (max_x - min_x) * marge,
        max_x: max_x + (max_x - min_x) * marge,
        min_y: min_y - (max_y - min_y) * marge,
        max_y: max_y + (max_y - min_y) * marge,
      }
    } else {
      return {
        min_x: min_x - (max_x - min_x) * marge,
        max_x: max_x - (max_x - min_x) * marge,
        min_y: min_y,
        max_y: max_y,
      }
    }
  }, [unitaryAnalyse.analyse, unitaryAnalyse.name, unitaryAnalyse.selectedChannel])

  const onLockUnlock = (e) => {
    dispatch(fetchChangeLock(unitaryAnalyse._id, collection))
    setOpen(false)
  }

  const onGraphClick = (event, x) => {
    if (!event.ctrlKey) return
    if (peaksCoord.length <= 1) {
      const selectedChannel = getSelectedChannel(unitaryAnalyse._id)
      let y = unitaryAnalyse.main_channel_data.data.find((e) => e.x >= x).y
      if (unitaryAnalyse.mainChannel !== selectedChannel) {
        y = unitaryAnalyse.channels_data
          .filter((chdata) => chdata.channelNumber === selectedChannel)[0]
          .data.find((e) => e.x >= x).y
      }
      if (y !== undefined) {
        const coord = { x: x, y: y }
        // Update peak only if we have left AND right
        if (peaksCoord.length === 1) {
          // sort the coord to have the left point always with the x min
          const coordinates = x > peaksCoord[0].x ? [...peaksCoord, coord] : [coord, ...peaksCoord]
          updatePeak(
            unitaryAnalyse,
            null,
            coordinates,
            dispatch,
            collection,
            selectedChannel,
            updateCalibrationAfterPeakUpdate(unitaryAnalyse),
          )
        }
        setUpdateInterpolation(true)
        setHasChanged(true)
        setPeaksCoord([...peaksCoord, coord])
        setUpdateInterpolation(false)
      }
    }
  }

  // const handleOpen = () => {
  //   setOpen(true)
  // }
  //
  // const handleClose = () => {
  //   setOpen(false)
  // }

  const handleConfirmClose = (event, unitaryanalysis, save) => {
    setOpenConfirm(false)
    if (save) {
      updateGlobalPeak(dispatch, unitaryanalysis)
    }
  }

  const handleGlobalReintegration = (event, unitaryAnalyse) => {
    event.stopPropagation()
    event.preventDefault()
    setOpenConfirm(true)
    setOpen(false)
  }

  // function handleChangeChannel(event, uaId, mainChannel) {
  //   dispatch(fetchChangeSelectedChannel(uaId, mainChannel))
  // }

  // function getUa() {
  //   console.log('Date:' + new Date())
  //   console.log('Drag:' + unitaryAnalyse.selectedChannel)
  //   console.log('DragMe:' + selectedChannel)
  //   console.log('DragTOto:' + ua.selectedChannel)
  //   return unitaryAnalyse
  // }

  function onScatterPan(event) {
    setHasMoved(true)
    const newPos = {
      'x-axis-1': {
        min: event.chart.scales['x-axis-1'].options.ticks.min,
        max: event.chart.scales['x-axis-1'].options.ticks.max,
      },
      'y-axis-1': {
        min: event.chart.scales['y-axis-1'].options.ticks.min,
        max: event.chart.scales['y-axis-1'].options.ticks.max,
      },
    }
    setGraphPosition(newPos)
  }

  function onScatterZoom(event) {
    setHasMoved(true)
    const newPos = {
      'x-axis-1': {
        min: event.chart.scales['x-axis-1'].options.ticks.min,
        max: event.chart.scales['x-axis-1'].options.ticks.max,
      },
      'y-axis-1': {
        min: event.chart.scales['y-axis-1'].options.ticks.min,
        max: event.chart.scales['y-axis-1'].options.ticks.max,
      },
    }
    setGraphPosition(newPos)
  }

  const handleClick = (event) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  function canIDrag() {
    return !!canEdit && (lockChromatoEdition ? isSelected : true)
  }

  function isAnimationEnabled() {
    return (
      (localStorage.getItem('SETTINGS_animation')
        ? localStorage.getItem('SETTINGS_animation')
        : 'false') === 'true'
    )
  }

  return (
    <div key={keyValue} className={classes.chromatoScatterDiv}>
      {showChannels && (
        <>
          {transitions_info && (
            <span style={{ position: 'absolute', top: '40px', left: '56px' }}>
              <Tooltip
                arrow
                disableHoverListener={lockChromatoEdition && !isSelected}
                placement="left-start"
                title={
                  <div style={{ fontSize: 12, padding: 5 }}>
                    {t('view.common.chromato.chromatoScatter.display_int')}
                  </div>
                }
              >
                <div
                  style={{
                    color: '#165c90',
                    fontSize: `${chromatoSize / 20 > 12 ? 12 : chromatoSize / 20}px`,
                  }}
                  key={`transition_main`}
                  className={clsx(
                    !lockChromatoEdition && isSelected
                      ? classes.transitionButtonSelected
                      : classes.transitionButton,
                    unitaryAnalyse.mainChannel === unitaryAnalyse.selectedChannel
                      ? classes.selectedChannel
                      : classes.notSelectedChannel,
                  )}
                  onClick={(event) =>
                    !lockChromatoEdition &&
                    isSelected &&
                    onChangeChannel(event, unitaryAnalyse, unitaryAnalyse.mainChannel)
                  }
                >
                  <span className={classes.transitionNumber}>
                    {unitaryAnalyse.main_channel_data.channelNumber}
                  </span>
                  {`Q: ${unitaryAnalyse.main_channel_data.q1} >  ${
                    unitaryAnalyse.main_channel_data.q3
                  }${unitaryAnalyse.mainChannel === unitaryAnalyse.selectedChannel ? ' ▲' : ''}`}
                </div>
              </Tooltip>
              {unitaryAnalyse.channels_data &&
                unitaryAnalyse.channels_data.map(function (channel, index) {
                  let color = colorChromato[index]
                  return (
                    <Tooltip
                      arrow
                      key={`tooltip_transition_${index}`}
                      disableHoverListener={lockChromatoEdition && !isSelected}
                      placement="left-start"
                      title={
                        <div style={{ fontSize: 12, padding: 5 }}>
                          {t('view.common.chromato.chromatoScatter.display_int')}
                        </div>
                      }
                    >
                      <div
                        style={{
                          color: color,
                          fontSize: chromatoSize / 20 > 12 ? 12 : chromatoSize / 20 + 'px',
                        }}
                        key={`transition_${index}`}
                        className={clsx(
                          !lockChromatoEdition && isSelected
                            ? classes.transitionButtonSelected
                            : classes.transitionButton,
                          channel.channelNumber === unitaryAnalyse.selectedChannel
                            ? classes.selectedChannel
                            : classes.notSelectedChannel,
                        )}
                        onClick={(event) =>
                          !lockChromatoEdition &&
                          isSelected &&
                          onChangeChannel(event, unitaryAnalyse, channel.channelNumber)
                        }
                      >
                        <span className={classes.transitionNumber}>{channel.channelNumber}</span>
                        {`Q: ${channel.q1} >  ${channel.q3}${
                          channel.channelNumber === unitaryAnalyse.selectedChannel ? ' ▲' : ''
                        }`}
                      </div>
                    </Tooltip>
                  )
                })}
            </span>
          )}
          <span
            // className={clsx('fullscreenMode', classes.fullscreen)}
            style={{
              position: 'absolute',
              top: '40px',
              right: '17px',
            }}
          >
            <span style={{ float: 'right', marginLeft: '100%', marginBottom: 2 }}>
              <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={0.5}>
                {validation && (
                  <Tooltip
                    arrow
                    disableHoverListener={lockChromatoEdition && !isSelected}
                    title={
                      <div style={{ fontSize: 13, padding: 5 }}>
                        {t('view.common.settingsView.chromatoTab.validation')}
                      </div>
                    }
                  >
                    {unitaryAnalyse.validation === '1' ? (
                      unitaryAnalyse.status === 'ok' || unitaryAnalyse.status === 'ko' ? (
                        <CheckIcon className={classes.selected} />
                      ) : (
                        <Molecule className={classes.selected} />
                      )
                    ) : unitaryAnalyse.validation === '2' ? (
                      unitaryAnalyse.status === 'ok' || unitaryAnalyse.status === 'ko' ? (
                        <ClearIcon className={classes.selected} />
                      ) : (
                        <Nothing className={classes.selected} />
                      )
                    ) : unitaryAnalyse.validation === '3' ? (
                      <Relance className={classes.selected} />
                    ) : (
                      <span></span>
                    )}
                  </Tooltip>
                )}
                {flag_info && (
                  <ErrorBoundaryGuard isDialog>
                    <AlphacodFlagIcon
                      item={unitaryAnalyse}
                      hideGeneralTab={false}
                      collection={collection}
                    />
                  </ErrorBoundaryGuard>
                )}
              </Stack>
            </span>
            <span
              style={{
                // position: 'absolute',
                // top: 65,
                // right: 14,
                float: 'right',
                fontSize: `${chromatoSize / 20 > 12 ? 12 : chromatoSize / 20}px`,
                background: 'rgb(255 255 255 / 44%)',
                borderRadius: 5,
                // padding: 2,
                cursor: 'auto',
              }}
            >
              {area_info && (
                <Tooltip
                  arrow
                  disableHoverListener={lockChromatoEdition && !isSelected}
                  placement="left-start"
                  title={
                    <div style={{ fontSize: 12, padding: 5 }}>
                      {t('view.common.chromato.chromatoScatter.area') + ' ' + unitaryAnalyse.area}
                    </div>
                  }
                >
                  <div>{`A: ${
                    unitaryAnalyse.area ? displayHumanReadable(unitaryAnalyse.area, 2, 2) : '--'
                  }`}</div>
                </Tooltip>
              )}
              {height_info && (
                <Tooltip
                  arrow
                  disableHoverListener={lockChromatoEdition && !isSelected}
                  placement="left-start"
                  title={
                    <div style={{ fontSize: 12, padding: 5 }}>
                      {t('view.common.chromato.chromatoScatter.height') +
                        ' ' +
                        unitaryAnalyse.height}
                    </div>
                  }
                >
                  <div>{`H: ${
                    unitaryAnalyse.height ? displayHumanReadable(unitaryAnalyse.height, 2, 2) : '--'
                  }`}</div>
                </Tooltip>
              )}
              {rt_info && (
                <Tooltip
                  arrow
                  disableHoverListener={lockChromatoEdition && !isSelected}
                  placement="left-start"
                  title={
                    <div style={{ fontSize: 12, padding: 5 }}>
                      {t('view.common.chromato.chromatoScatter.rtc') + ' ' + unitaryAnalyse.rt_peak}
                    </div>
                  }
                >
                  <div>{`RT: ${
                    unitaryAnalyse.rt_peak
                      ? displayHumanReadable(unitaryAnalyse.rt_peak, 2, 2)
                      : '--'
                  }`}</div>
                </Tooltip>
              )}
              {concVol_info && (
                <Tooltip
                  arrow
                  disableHoverListener={lockChromatoEdition && !isSelected}
                  placement="left-start"
                  title={
                    <div style={{ fontSize: 12, padding: 5 }}>
                      {t('view.common.chromato.chromatoScatter.vol') +
                        ' ' +
                        unitaryAnalyse.amt_conc}
                    </div>
                  }
                >
                  <div>{`C: ${
                    unitaryAnalyse.amt_conc
                      ? displayHumanReadable(unitaryAnalyse.amt_conc, 4)
                      : '--'
                  }`}</div>
                </Tooltip>
              )}
            </span>
          </span>
        </>
      )}
      <ErrorBoundaryGuard isDialog>
        <ChromatoApplyAllDialog
          open={openConfirm}
          closeFct={handleConfirmClose}
          unitaryAnalyse={unitaryAnalyse}
        />
      </ErrorBoundaryGuard>
      <Scatter
        //   key={`scatter_${uidKey}`}
        ref={chartRef}
        data={{
          datasets: [peaksChart, ...datasets],
        }}
        // key={`scatter_${uid(key)}`}
        options={{
          chartArea: {
            backgroundColor: 'rgba(255,255,255,0.5)',
          },
          animation: {
            duration: isAnimationEnabled() ? 1000 : 0,
          },
          responsiveAnimationDuration: 0, //Disable resize animation (for performance)
          responsive: true,
          maintainAspectRatio: false,
          dragData: true,
          dragX: true,
          elements: {
            line: {
              tension: 0, // disables bezier curves (for performance)
            },
          },
          // No need to block under 0
          // dragOptions: {
          //   magnet: {
          //     to: function (value) {
          //       if (value.y < 0)
          //         return {
          //           x: value.x,
          //           y: 0,
          //         }
          //       return value
          //     },
          //   },
          // },
          onClick: function (event) {
            if (onGraphClick) {
              var valueX = null,
                valueY = null
              for (var scaleName in this.scales) {
                var scale = this.scales[scaleName]
                if (scale.isHorizontal()) {
                  valueX = scale.getValueForPixel(event.offsetX)
                } else {
                  valueY = scale.getValueForPixel(event.offsetY)
                }
              }
              onGraphClick(event, valueX, valueY)
            }
          },
          onDrag: function (e) {
            e.preventDefault()
            setActivePan(false)
            e.target.style.cursor = 'grabbing'
          },
          onDragEnd: function (e, datasetIndex, index, value) {
            setHasChanged(true)
            e.target.style.cursor = 'default'
            e.preventDefault()

            // CAN NOT FIND AN OTHER WAY TO GET SELECTED CHANNEL HERE
            const selectedChannel = getSelectedChannel(unitaryAnalysesGlobal._id)
            let pt = unitaryAnalysesGlobal.main_channel_data.data.find((e) => e.x >= value.x)
            if (unitaryAnalysesGlobal.mainChannel !== selectedChannel) {
              pt = unitaryAnalysesGlobal.channels_data
                .filter((chdata) => chdata.channelNumber === selectedChannel)[0]
                .data.find((e) => e.x >= value.x)
            }
            if (pt && value.y > pt.y) {
              value.y = pt.y
            }
            setActivePan(true)
            updatePeak(
              unitaryAnalysesGlobal,
              index,
              value,
              dispatch,
              collection,
              selectedChannel,
              updateCalibrationAfterPeakUpdate(unitaryAnalysesGlobal),
            )
          },
          hover: {
            animationDuration: 0, //Disable hover animattion (for performance)
            onHover: function (e) {
              const point = this.getElementAtEvent(e)
              if (point.length && point[0]._datasetIndex === 1) e.target.style.cursor = 'grab'
              else e.target.style.cursor = 'default'
            },
          },
          title: {
            display: false,
            text: title,
            // fontSize: 20,
          },
          legend: {
            display: showLegend,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  min: graphPosition['x-axis-1'].min
                    ? graphPosition['x-axis-1'].min
                    : defaultWindowCoord.min_x,
                  max: graphPosition['x-axis-1'].max
                    ? graphPosition['x-axis-1'].max
                    : defaultWindowCoord.max_x,
                  // min: -2,
                  // maxTicksLimit: 5,
                  // beginAtZero: true,
                  // stepSize: 0.1,
                  // callback: function (value, index, list) {
                  //   return value.toFixed(1)
                  // },
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  maxTicksLimit: 4,
                  // min: unitaryAnalyse.main_channel_data.ampl_max * -0.3,
                  min: graphPosition['y-axis-1'].min
                    ? graphPosition['y-axis-1'].min
                    : defaultWindowCoord.min_y,
                  max: graphPosition['y-axis-1'].max
                    ? graphPosition['y-axis-1'].max
                    : defaultWindowCoord.max_y,
                  // beginAtZero: true,
                  callback: function (value) {
                    if (value < 0) {
                      return ''
                    }
                    if (value > 9999) {
                      return value.toExponential(1)
                    }
                    return value.toFixed(1)
                  },
                },
              },
            ],
          },
          annotation: {
            annotations: annotations,
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem) {
                var label =
                  '( ' +
                  parseFloat(tooltipItem.label).toFixed(2) +
                  ' : ' +
                  parseFloat(tooltipItem.value).toFixed(2) +
                  ' )'
                return label
              },
            },
          },
          plugins: {
            zoom: {
              // Container for pan options
              pan: {
                // Boolean to enable panning
                enabled: activePan,
                // Panning directions.
                mode: 'xy',
                // TODO Min and Max of range
                // rangeMin: {
                //     // Format of min zoom range depends on scale type
                //     x: null,
                //     y: null
                // },
                // rangeMax: {
                //     // Format of max zoom range depends on scale type
                //     x: null,
                //     y: null
                // }
                onPan: onScatterPan,
              },
              // Container for zoom options
              zoom: {
                // Boolean to enable zooming
                enabled: true,
                // Enable drag-to-zoom behavior
                drag: false,
                // Zooming directions.
                mode: 'xy',
                // TODO Min and Max of range
                // rangeMin: {
                //     // Format of min zoom range depends on scale type
                //     x: null,
                //     y: null
                // },
                // rangeMax: {
                //     // Format of max zoom range depends on scale type
                //     x: null,
                //     y: null
                // }
                onZoom: onScatterZoom,
              },
            },
          },
        }}
      />
      <Tooltip
        arrow
        placement="top"
        title={
          <div
            style={{
              fontSize: 13,
              padding: 5,
            }}
          >
            {title}
          </div>
        }
      >
        <div className={classes.chromatoTitle}>{title}</div>
      </Tooltip>
      {selection && !!canEdit && (
        <Checkbox
          className={clsx(
            'checkboxChromato',
            classes.checkboxChromato,
            checkSelected ? classes.checkboxChromatoSelected : classes.checkboxChromatoNotSelected,
          )}
          size="small"
          checked={checkSelected}
          onChange={(e, checked) => onSelect(e, checked, index)}
        />
      )}
      <IconButton className={classes.speedDial} onClick={handleClick}>
        <Tooltip
          arrow
          placement="top"
          title={
            <div
              style={{
                fontSize: 13,
                padding: 5,
              }}
            >
              {t('view.common.chromato.chromatoScatter.open')}
            </div>
          }
        >
          <MoreHorizIcon />
        </Tooltip>
      </IconButton>
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem
          onClick={function (event) {
            event.stopPropagation()
            event.preventDefault()
            setAnchorEl(null)
          }}
        >
          <Tooltip
            arrow
            placement="left-start"
            title={
              <div
                style={{
                  fontSize: 13,
                  padding: 5,
                }}
              >
                {t('view.common.chromato.chromatoScatter.close')}
              </div>
            }
          >
            <CloseIcon color={'secondary'} />
          </Tooltip>
        </StyledMenuItem>
        <StyledMenuItem
          onClick={function (event) {
            event.stopPropagation()
            event.preventDefault()
            setAnchorEl(null)
            dispatch(fetchDisplayDetail(unitaryAnalyse, 0, false, event.currentTarget, collection))
          }}
        >
          <Tooltip
            arrow
            placement="left-start"
            title={
              <div
                style={{
                  fontSize: 13,
                  padding: 5,
                }}
              >
                {t('view.common.chromato.chromatoScatter.more')}
              </div>
            }
          >
            <ArtTrackIcon />
          </Tooltip>
        </StyledMenuItem>
        <StyledMenuItem
          onClick={function (event) {
            event.stopPropagation()
            event.preventDefault()
            setAnchorEl(null)
            onEntireChromato()
          }}
        >
          <Tooltip
            arrow
            placement="left-start"
            title={
              <div
                style={{
                  fontSize: 13,
                  padding: 5,
                }}
              >
                {t('view.common.chromato.chromatoScatter.entire')}
              </div>
            }
          >
            <AspectRatioIcon />
          </Tooltip>
        </StyledMenuItem>
        <StyledMenuItem
          onClick={function (event) {
            event.stopPropagation()
            event.preventDefault()
            setAnchorEl(null)
            onPeakFocus()
          }}
        >
          <Tooltip
            arrow
            placement="left-start"
            title={
              <div
                style={{
                  fontSize: 13,
                  padding: 5,
                }}
              >
                {t('view.common.chromato.chromatoScatter.recentredWindow')}
              </div>
            }
          >
            <div style={{ width: 24, height: 24 }}>
              <ChromatoRecentred style={{ width: 24, height: 24 }} />
            </div>
          </Tooltip>
        </StyledMenuItem>
        <StyledMenuItem
          onClick={function (event) {
            event.stopPropagation()
            event.preventDefault()
            setAnchorEl(null)
            handleGlobalReintegration(event)
          }}
          disabled={!canEdit}
        >
          <Tooltip
            arrow
            placement="left-start"
            title={
              <div
                style={{
                  fontSize: 13,
                  padding: 5,
                }}
              >
                {t('view.common.chromato.chromatoScatter.apply')}
              </div>
            }
          >
            <DoneAllIcon />
          </Tooltip>
        </StyledMenuItem>

        {/*<Tooltip*/}
        {/*  arrow*/}
        {/*  placement="left-start"*/}
        {/*  title={*/}
        {/*    <div*/}
        {/*      style={{*/}
        {/*        fontSize: 13,*/}
        {/*        padding: 5,*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      {t('view.common.chromato.chromatoScatter.reset_int')}*/}
        {/*    </div>*/}
        {/*  }*/}
        {/*>*/}
        {/*  <StyledMenuItem*/}
        {/*    disabled={!canEdit}*/}
        {/*    onClick={function (event) {*/}
        {/*      event.stopPropagation()*/}
        {/*      event.preventDefault()*/}
        {/*      setAnchorEl(null)*/}
        {/*      onReset(event, initPeaks)*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <ResetPeak />*/}
        {/*  </StyledMenuItem>*/}
        {/*</Tooltip>*/}
        {hasMoved && (
          <StyledMenuItem
            onClick={function (event) {
              event.stopPropagation()
              event.preventDefault()
              setAnchorEl(null)
              onClear()
            }}
          >
            <Tooltip
              arrow
              placement="left-start"
              title={
                <div
                  style={{
                    fontSize: 13,
                    padding: 5,
                  }}
                >
                  {t('view.common.chromato.chromatoScatter.reset_zoom')}
                </div>
              }
            >
              <ResetZoom />
            </Tooltip>
          </StyledMenuItem>
        )}
        <StyledMenuItem
          disabled={!canEdit}
          onClick={function (event) {
            event.stopPropagation()
            event.preventDefault()
            setAnchorEl(null)
            onLockUnlock(event)
          }}
        >
          <Tooltip
            arrow
            placement="left-start"
            title={
              <div
                style={{
                  fontSize: 13,
                  padding: 5,
                }}
              >
                {unitaryAnalyse.unlocked
                  ? t('view.common.chromato.chromatoScatter.lock')
                  : t('view.common.chromato.chromatoScatter.unlock')}
              </div>
            }
          >
            {unitaryAnalyse.unlocked ? <LockOpenIcon /> : <LockCloseIcon />}
          </Tooltip>
        </StyledMenuItem>
      </StyledMenu>
    </div>
  )
}

const StyledMenu = withStyles({
  paper: {
    background: 'transparent',
    boxShadow: 'none',
    '& .MuiMenu-list': {
      paddingTop: 1,
    },
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))
const StyledMenuItem = withStyles((theme) => ({
  root: {
    background: 'white',
    padding: 5,
    margin: '0px 5px 7px',
    borderRadius: 20,
    boxShadow:
      '0px 3px 5px 0px rgb(0 0 0 / 25%), 0px -1px 5px 0px rgb(0 0 0 / 25%), 0px 3px 5px 0px rgb(0 0 0 / 0%)',
    color: '#0000008c',
    '&:hover': {
      background: '#efefef',
    },
  },
}))(MenuItem)
