import React, { Fragment, useEffect } from 'react'
import { StyledDialogTitle, TransitionTop } from '../common/Style'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { useDispatch, useSelector } from 'react-redux'
import { alpha, makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { fetchChangeSettings } from '../../redux/settings/actions'
import { t } from 'i18next'
import { Stack, TextField, Tooltip } from '@mui/material'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import IconButton from '@mui/material/IconButton'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import InputIcon from '@mui/icons-material/Input'
import SaveIcon from '@mui/icons-material/Save'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import SettingsIcon from '@mui/icons-material/Settings'
import { fetchGetTasks, fetchGetToolbarTasks } from '../../redux/tasks/actions'
import ReplayIcon from '@mui/icons-material/Replay'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import CloudQueueIcon from '@mui/icons-material/CloudQueue'
import CloudOffIcon from '@mui/icons-material/CloudOff'
import Checkbox from '@material-ui/core/Checkbox'
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined'
import Menu from '@material-ui/core/Menu'
import { API_URL, FLASK_BASE_URL } from '../../utils/config'
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined'
import { displayPopupMessage, generateErrorPart } from '../common/Message'

const useStyles = makeStyles((theme) => ({
  title: {
    // background: alpha(theme.palette.primary.main,0.05),
    background: '#f1f1f1',
  },
  informationContainer: {
    paddingLeft: 10,
    '& .MuiGrid-item': {
      paddingTop: 15,
    },
  },
  auditButton: {
    padding: 0,
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
    },
  },
  warning: {
    color: '#e68d09',
  },
  warning_bkg: {
    borderRadius: 5,
    padding: 10,
    background: alpha('#e68d09', 0.05),
  },
  info: {
    color: '#547fa8',
  },
  info_bkg: {
    borderRadius: 5,
    marginTop: 30,
    padding: 10,
    background: alpha('#547fa8', 0.05),
  },
  tooltip: {
    fontSize: 13,
    padding: 5,
  },
  online: {
    color: theme.palette.common.success,
  },
  offline: {
    color: theme.palette.common.error,
  },
  loading: {
    color: theme.palette.common.warning,
  },
}))

export default function ServerHelperDialog() {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [pythonServicesStatus, setPytonServicesStatus] = React.useState([])
  const [pythonServices, setPytonServices] = React.useState([])
  const [isLoadingPythonServices, setIsLoadingPythonServices] = React.useState(false)

  const [serviceTmp, setServiceTmp] = React.useState(
    localStorage.getItem('SERVICE_pythonService_name')
      ? localStorage.getItem('SERVICE_pythonService_name')
      : localStorage.getItem('__config.json__DEFAULT_PYTHON_SERVICE_NAME'),
  )
  const [serviceTmpStatus, setServiceTmpStatus] = React.useState(null)
  const [serviceUsed, setServiceUsed] = React.useState(
    localStorage.getItem('SERVICE_pythonService_name')
      ? localStorage.getItem('SERVICE_pythonService_name')
      : localStorage.getItem('__config.json__DEFAULT_PYTHON_SERVICE_NAME'),
  )
  const [anchorMenuEl, setAnchorMenuEl] = React.useState(null)
  const [timerTestServerStatus, setTimerTestServerStatus] = React.useState(null)

  const [shouldReload, setShouldReload] = React.useState(false)
  const open = useSelector((state) => state.settings.servicePopup)

  useEffect(
    function () {
      if (open) {
        loadServersState()
        setServiceTmp(serviceUsed)
        testCurrentServer(serviceTmp)
      }
    },
    [open],
  )

  // Update the server tmp status
  useEffect(
    function () {
      if (open) {
        clearTimeout(timerTestServerStatus)
        setServiceTmpStatus(null)
        const newTimer = setTimeout(
          function () {
            testCurrentServer(serviceTmp)
          }.bind(this),
          1000,
        )
        setTimerTestServerStatus(newTimer)
      }
    },
    [serviceTmp, open],
  )

  // Load the services status for the menu when it is opening
  useEffect(() => {
    if (pythonServices.length > 0 && anchorMenuEl !== null) {
      //Test if servers are online
      pythonServices.forEach(function (service) {
        fetch(`${FLASK_BASE_URL()}/${service.name}/version`, {
          method: 'GET',
          credentials: 'include',
        })
          .then((response) => {
            if (response.ok && response.status === 200) {
              return response.text()
            }
            throw new Error('Something went wrong')
          })
          .then((responseJson) => {
            // Service is online
            setPytonServicesStatus((oldStatus) => {
              return { ...oldStatus, [service.name]: true }
            })
          })
          .catch((error) => {
            setPytonServicesStatus((oldStatus) => {
              return { ...oldStatus, [service.name]: false }
            })
            console.log('The service ' + service.name + ' is offline.')
          })
      })
    }
  }, [pythonServices, anchorMenuEl])

  // Clear the service status for the next opening
  useEffect(() => {
    if (pythonServices.length > 0 && anchorMenuEl === null) {
      setPytonServicesStatus(
        Object.assign({}, ...pythonServices.map((x) => ({ [x.name]: undefined }))),
      )
    }
  }, [pythonServices, anchorMenuEl])

  // Method to test the tmp server status
  const testCurrentServer = (serviceTmp) => {
    fetch(`${FLASK_BASE_URL()}/${serviceTmp}/version`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok && response.status === 200) {
          return response.text()
        }
        throw new Error('Something went wrong')
      })
      .then((responseJson) => {
        // Service is online
        setServiceTmpStatus(true)
      })
      .catch(() => {
        setServiceTmpStatus(false)
      })
  }

  const handleClose = () => {
    dispatch(fetchChangeSettings('servicePopup', false))
  }

  function saveService() {
    localStorage.setItem('SERVICE_pythonService_name', serviceTmp)
    setServiceUsed(serviceTmp)
    setShouldReload(true)
  }

  function removeAzureKey() {
    localStorage.removeItem('__config.json__FLASK_URL')
    setShouldReload(true)
  }

  function reload() {
    window.location.reload()
  }

  function loadServersState() {
    setIsLoadingPythonServices(true)
    let filter = [
      {
        property: 'tags',
        value: localStorage.getItem('__config.json__ALPHACOD_SERVICE_TAG'),
        filterType: 'string',
        operator: 'like',
      },
    ]
    let f = encodeURIComponent(JSON.stringify(filter))
    fetch(`${API_URL()}/pythonServices?filter=${f}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then(function (json) {
        setPytonServicesStatus(Object.assign({}, ...json.map((x) => ({ [x.name]: undefined }))))
        setPytonServices(
          json.sort(function (a, b) {
            if (a.name < b.name) return -1
            if (a.name > b.name) return 1
            return 0
          }),
        )
      })
      .catch(function (error) {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          t('view.dashboardToolbar.failed_python_service.title'),
          <Fragment>
            <div>{t('view.dashboardToolbar.failed_python_service.msg')}</div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      })
      .finally(() => {
        setIsLoadingPythonServices(false)
      })
  }

  function openServices(event) {
    setAnchorMenuEl(event.currentTarget)
  }

  function handleCloseMenu() {
    setAnchorMenuEl(null)
  }

  function applyServiceName(serviceName) {
    setAnchorMenuEl(null)
    setServiceTmp(serviceName)
  }

  return (
    <Fragment>
      <Menu
        anchorEl={anchorMenuEl}
        open={anchorMenuEl !== null}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {pythonServices.map((service) => (
          <MenuItem
            key={service.name}
            value={service.name}
            onClick={() => applyServiceName(service.name)}
          >
            <Tooltip
              arrow
              disableHoverListener={!service.description}
              placement="left"
              title={<div style={{ fontSize: 13, padding: 5 }}>{service.description}</div>}
            >
              <div style={{ width: '100%' }}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <div>{service.name}</div>
                  {pythonServicesStatus &&
                  typeof pythonServicesStatus[service.name] === 'boolean' ? (
                    pythonServicesStatus[service.name] ? (
                      <CloudQueueIcon className={classes.online} />
                    ) : (
                      <CloudOffIcon className={classes.offline} />
                    )
                  ) : (
                    <CloudSyncOutlinedIcon className={classes.loading} />
                  )}
                </Stack>
              </div>
            </Tooltip>
          </MenuItem>
        ))}
      </Menu>
      <Dialog open={open} onClose={handleClose} TransitionComponent={TransitionTop}>
        <StyledDialogTitle className={classes.title}>
          <Typography component="h1" variant="h6" color="inherit" noWrap>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
              <SettingsIcon />
              <span>{t('view.dialogs.server.title')}</span>
            </Stack>
          </Typography>
        </StyledDialogTitle>
        <DialogContent style={{ marginTop: 20, maxWidth: 520, overflow: 'hidden' }}>
          <Stack
            className={classes.warning_bkg}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <WarningAmberIcon className={classes.warning} />
            <Typography variant="subtitle1" gutterBottom>
              {t('view.dialogs.server.msg')}
            </Typography>
          </Stack>
          <TextField
            required
            style={{ marginTop: 20 }}
            fullWidth
            label={t('view.dialogs.server.textfield')}
            variant="outlined"
            value={serviceTmp}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip
                    arrow
                    placement="top"
                    title={
                      <div className={classes.tooltip}>{t('view.dialogs.server.buttonload')}</div>
                    }
                  >
                    <IconButton color="primary" onClick={openServices}>
                      <MenuOpenOutlinedIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}
                    style={{ width: 80 }}
                  >
                    {typeof serviceTmpStatus === 'boolean' ? (
                      serviceTmpStatus ? (
                        <CloudQueueIcon className={classes.online} />
                      ) : (
                        <CloudOffIcon className={classes.offline} />
                      )
                    ) : (
                      <CloudSyncOutlinedIcon className={classes.loading} />
                    )}
                    {serviceTmp !== '' && serviceTmp !== serviceUsed && (
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          <div className={classes.tooltip}>{t('view.dialogs.server.use')}</div>
                        }
                      >
                        <IconButton color="primary" onClick={saveService}>
                          <SaveIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Stack>
                </InputAdornment>
              ),
            }}
            onChange={(event) => setServiceTmp(event.target.value)}
            error={serviceTmp === '' || serviceTmp === null || serviceTmp === undefined}
          />
          <Tooltip
            arrow
            placement="top"
            title={<div className={classes.tooltip}>{t('view.dialogs.server.tooltip')}</div>}
          >
            <Button
              color="primary"
              variant="outlined"
              onClick={removeAzureKey}
              style={{ width: '100%', marginTop: 20 }}
            >
              {t('view.dialogs.server.button')}
            </Button>
          </Tooltip>
          {shouldReload && (
            <Stack
              className={classes.info_bkg}
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <InfoOutlinedIcon className={classes.info} />
              <Typography variant="subtitle1">{t('view.dialogs.server.reload')}</Typography>
              <IconButton color="secondary" onClick={reload}>
                <ReplayIcon />
              </IconButton>
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('view.dialogs.config.helper.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
