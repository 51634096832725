import React, { Fragment } from 'react'
import { API_BO_URL } from '../../utils/config'
import { AUTHENTICATION_FAILURE } from '../unitaryAnalyses/actions'
import { getFilterRawById } from './selectors'
import { fetchChangeSettings } from '../settings/actions'
import { displayPopupMessage, generateErrorPart } from '../../view/common/Message'
import { receiveCurrentBatchLease } from '../batches/actions'

export const REQUEST_FILTERS = 'REQUEST_FILTERS'
export const RECEIVE_FILTERS = 'RECEIVE_FILTERS'
export const RECEIVE_CREATE_FILTER = 'RECEIVE_CREATE_FILTER'
export const RECEIVE_DELETE_FILTER = 'RECEIVE_DELETE_FILTER'

export function fetchFilters(deviceType) {
  return async (dispatch) => {
    dispatch(requestFilters(deviceType))

    let filter = {
      property: 'content.type',
      value: deviceType,
      filterType: 'string',
      operator: 'eq',
    }
    let f = encodeURIComponent(JSON.stringify(filter))

    return fetch(`${API_BO_URL()}.web.filter/?filter=%5B${f}%5D`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((json) => {
        return dispatch(receiveFilters(json, false))
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to get filters',
          <Fragment>
            <div>An error does not allow the get filters:</div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      })
  }
}
export function requestFilters(deviceType) {
  return {
    type: REQUEST_FILTERS,
    deviceType: deviceType,
  }
}
export function receiveFilters(filters, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_FILTERS,
    filters: filters,
  }
}

export function addOrEditFilter(name, deviceType, compounds, columnFilters, teams, editId) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      let body = {
        type: deviceType,
        name: name,
        compounds: compounds,
        columnFilters: columnFilters,
        team: teams,
      }
      if (editId) {
        let fullRaw = await getFilterRawById(editId)
        if (fullRaw) {
          body = JSON.parse(JSON.stringify(fullRaw))
          body.name = name
          body.teams = teams
          body.content.compounds = compounds
          body.content.columnFilters = columnFilters
        } else {
          throw 'Unable to find the filter with id ' + editId
        }
      }

      const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      }

      fetch(`${API_BO_URL()}.web.filter${editId ? '/' + editId : ''}`, requestOptions)
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((data) => {
          const resp = dispatch(receiveCreateFilter(data, editId))
          resolve(resp)
        })
        .catch((error) => {
          const authenticationError = error.status === 403
          if (authenticationError) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          // dispatch({ type: 'FETCH_ERROR', error })
          reject(error)
        })
    })
  }
}

function receiveCreateFilter(json, editId) {
  return {
    type: RECEIVE_CREATE_FILTER,
    newFilter: json,
    editId: editId,
  }
}

export function fetchRemoveFilter(filterId) {
  return async (dispatch) => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'DELETE',
        credentials: 'include',
      }
      fetch(`${API_BO_URL()}.web.filter/${filterId}`, requestOptions)
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return true
        })
        .then((data) => {
          resolve(dispatch(receiveDeletedFilter(data, filterId)))
        })
        .catch((error) => {
          const authenticationError = error.status === 403
          if (authenticationError) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          // dispatch({ type: 'FETCH_ERROR', error })
          reject(error)
        })
    })
  }
}

function receiveDeletedFilter(resultTxt, filterId) {
  return {
    type: RECEIVE_DELETE_FILTER,
    filterToDeleteId: filterId,
  }
}
