import { API_BO_URL, FLASK_URL } from '../../utils/config'
import { receiveUpdateUnitaryAnalyses } from '../unitaryAnalyses/actions'
import { convertErrorCodeToType } from '../tools'
import { getAnalysisById, getAnalysisRawById } from './selectors'
import { fetchChangeSettings } from '../settings/actions'
import { RECEIVE_CHANGE_ANALYSES_TYPE, receiveCurrentBatchLease } from '../batches/actions'
import { displayPopupMessage, generateErrorPart } from '../../view/common/Message'
import React, { Fragment } from 'react'

export const REQUEST_ANALYSES = 'REQUEST_ANALYSES'
export const RECEIVE_ANALYSES = 'RECEIVE_ANALYSES'
export const AUTHENTICATION_FAILURE = 'AUTHENTICATION_FAILURE'
export const REQUEST_SEARCH_ANALYSES = 'REQUEST_SEARCH_ANALYSES'
export const RECEIVE_SEARCH_ANALYSES = 'RECEIVE_SEARCH_ANALYSES'
export const REQUEST_UPDATE_ANALYSE = 'REQUEST_UPDATE_ANALYSE'
export const RECEIVE_UPDATE_ANALYSE = 'RECEIVE_UPDATE_ANALYSE'
export const RECEIVE_REDUCE_ANALYSES = 'RECEIVE_REDUCE_ANALYSES'
export const RECEIVE_UPDATE_ANALYSES = 'RECEIVE_UPDATE_ANALYSES'
export const RECEIVE_UPDATE_ANALYSIS_NOTE = 'RECEIVE_UPDATE_ANALYSIS_NOTE'
export const RECEIVE_UPDATE_ANALYSIS_TAG = 'RECEIVE_UPDATE_ANALYSIS_TAG'
export const RECEIVE_CREATE_SUBBATCH_REDUCE_ANALYSES = 'RECEIVE_CREATE_SUBBATCH_REDUCE_ANALYSES'
export const RECEIVE_MOVE_SUBBATCH_REDUCE_ANALYSES = 'RECEIVE_MOVE_SUBBATCH_REDUCE_ANALYSES'
export const REQUEST_ANALYSES_SENT_TO_LIMS = 'REQUEST_ANALYSES_SENT_TO_LIMS'
export const RECEIVE_ANALYSES_SENT_TO_LIMS = 'RECEIVE_ANALYSES_SENT_TO_LIMS'
export const REQUEST_CHANGE_ANALYSES_TYPE = 'REQUEST_CHANGE_ANALYSES_TYPE'
export const REQUEST_UNDO_ANALYSES_TYPE = 'REQUEST_UNDO_ANALYSES_TYPE'
export const RECEIVE_UPDATE_ANALYSES_FORMULA = 'RECEIVE_UPDATE_ANALYSES_FORMULA'
export const RECEIVE_COMPUTE_AI_ANALYSE = 'RECEIVE_COMPUTE_AI_ANALYSE'
export const RECEIVE_RENAME_ANALYSE = 'RECEIVE_RENAME_ANALYSE'
export const RECEIVE_DISABLE_ANALYSE = 'RECEIVE_DISABLE_ANALYSE'

export function requestAnalyses(batchId) {
  return {
    type: REQUEST_ANALYSES,
    batchId: batchId,
  }
}
export function requestSearchAnalyses(keyword, dateLock) {
  return {
    type: REQUEST_SEARCH_ANALYSES,
    keyword: keyword,
    dateLock: dateLock,
  }
}

export function receiveAnalyses(analyses, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_ANALYSES,
    analyses: analyses,
  }
}

export function receiveSearchAnalyses(analyses, authenticationError, dateLock) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_SEARCH_ANALYSES,
    searchedAnalyses: analyses,
    dateLock: dateLock,
  }
}

export function fetchAnalyses(batchId) {
  return async (dispatch) => {
    dispatch(requestAnalyses(batchId))
    return new Promise((resolve, reject) => {
      let filter = {
        property: 'content.batch.id',
        value: batchId,
        filterType: 'string',
        operator: 'eq',
      }
      let p = encodeURIComponent(JSON.stringify(filter))
      fetch(`${API_BO_URL()}.analysis/page/?filter=%5B${p}%5D`, {
        method: 'GET',
        credentials: 'include',
      })
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((json) => {
          resolve(dispatch(receiveAnalyses(json.result, false)))
        })
        .catch((error) => {
          if (error.status === 403) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(dispatch(receiveAnalyses([], error.status === 403)))
        })
    })
  }
}

let controllerSearchAnalysesSaved = null
export function searchAnalyses(keyword, filterService, filterType) {
  return async (dispatch) => {
    if (controllerSearchAnalysesSaved) {
      controllerSearchAnalysesSaved.abort()
    }
    const controller = new AbortController()
    controllerSearchAnalysesSaved = controller
    const dateLock = new Date().getTime()
    dispatch(requestSearchAnalyses(keyword, dateLock))

    let filter = [
      {
        property: 'name',
        value: keyword,
        filterType: 'string',
        operator: 'like',
      },
    ]
    if (filterType) {
      filter.push({
        property: 'content.chromatography_type',
        value: filterType,
        filterType: 'string',
        operator: 'eq',
      })
    }
    if (filterService) {
      filter.push({
        property: 'content.configuration',
        value: filterService,
        filterType: 'string',
        operator: 'eq',
      })
    }
    let p = encodeURIComponent(JSON.stringify(filter))
    // try {
    //   const response = await fetch(
    //     `${API_BO_URL()}.analysis/page/?filter=${p}&include=_id&include=name&include=content.batch.name&include=content.batch.id&include=content.type&include=content.file&include=content.folder&include=content.date_ts&include=content.retired`,
    //     {
    //       method: 'GET',
    //       credentials: 'include',
    //     },
    //   )
    //   const authenticationError = response.status === 403
    //   if (authenticationError) {
    //     return dispatch(fetchChangeSettings('loginPopup', true))
    //   }
    //   const json = await response.json()
    //   return dispatch(receiveSearchAnalyses(json.result, authenticationError))
    // } catch (e) {
    //   console.log(e)
    //   return dispatch(receiveSearchAnalyses([], false))
    // }
    const includes =
      '&include=' +
      [
        '_id',
        'name',
        'content.batch.name',
        'content.batch.id',
        'content.type',
        'content.file',
        'content.folder',
        'content.date_ts',
        'content.retired',
      ].join('&include=')
    fetch(`${API_BO_URL()}.analysis/page/?filter=${p}${includes}`, {
      signal: controller.signal,
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((json) => {
        return dispatch(receiveSearchAnalyses(json.result, false, dateLock))
      })
      .catch((error) => {
        // Do not do anything in case of an aborted request by a new one
        if (!(error instanceof DOMException && error.code === 20)) {
          if (error.status === 403) {
            dispatch(fetchChangeSettings('loginPopup', true))
            return dispatch(receiveSearchAnalyses([], true))
          }
          displayPopupMessage(
            dispatch,
            'error',
            'Failed to get analyses',
            <Fragment>
              <div>An error does not allow the get analyses:</div>
              <div>{generateErrorPart(error)}</div>
              <div style={{ marginTop: 10 }}>
                Try to reload with F5, if the problem persists, please contact your administrator.
              </div>
            </Fragment>,
          )
          return dispatch(receiveSearchAnalyses([], false))
        }
      })
      .finally(() => {
        if (controllerSearchAnalysesSaved === controller) {
          controllerSearchAnalysesSaved = null
        }
        controller.abort()
      })
  }
}

export function fetchUpdateAnalyse(newValue, analyse, paramValue, batchId) {
  return async (dispatch) => {
    return new Promise((resolve, reject) => {
      let data = {}
      data['value'] = newValue

      const requestOptions = {
        method: 'PUT',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ batch_id: batchId, analysis_id: analyse._id, body: data }),
      }
      let url = `${FLASK_URL()}/analysis/${analyse._id}/${paramValue}`

      fetch(url, requestOptions)
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((data) => {
          // Update uas store
          dispatch(receiveUpdateUnitaryAnalyses(data, false))
          resolve(dispatch(receiveUpdateAnalyse(data, false)))
        })
        .catch((error) => {
          const authenticationError = error.status === 403
          if (authenticationError) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          reject(error)
        })
    })
  }
}
export function receiveUpdateAnalyse(json, authenticationError, status, message) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UPDATE_ANALYSE,
    objectsUpdated: json,
    status: status,
    message: message,
  }
}

export function receiveMoveSampleToSubBatchReduceAnalyses(
  json,
  analysesSelected,
  option,
  batchId,
  destBatchId,
  authenticationError,
) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_MOVE_SUBBATCH_REDUCE_ANALYSES,
    objectsUpdated: json,
    analysesSelected: analysesSelected,
    option: option,
    searchedBatchId: batchId,
    destBatchId: destBatchId,
  }
}

export function receiveCreateSubBatchReduceAnalyses(
  json,
  analysesSelected,
  option,
  batchId,
  authenticationError,
) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_CREATE_SUBBATCH_REDUCE_ANALYSES,
    objectsUpdated: json,
    analysesSelected: analysesSelected,
    option: option,
    searchedBatchId: batchId,
  }
}

export function receiveUpdateAnalyses(json, analysesSelected, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UPDATE_ANALYSES,
    objectsUpdated: json,
    analysesSelected: analysesSelected,
  }
}

export function fetchAddAnalysisNote(analysisItem, notes) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      let fullRaw = await getAnalysisRawById(analysisItem._id, dispatch)
      if (fullRaw) {
        let dataToUpdate = JSON.parse(JSON.stringify(fullRaw))
        dataToUpdate.content.notes = notes
        fetch(`${API_BO_URL()}.analysis/${analysisItem._id}`, {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(dataToUpdate),
        })
          .then((response) => {
            if (!response.ok) {
              const statusText = response.statusText
              const status = response.status
              const url = response.url
              return response.text().then((errorMessage) => {
                const error = new Error(`${statusText} : ${errorMessage}`)
                if (response.headers.get('content-type') === 'application/json') {
                  error.stack = JSON.stringify(
                    JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                    null,
                    2,
                  )
                } else {
                  error.stack = new Error().stack
                }
                error.statusText = statusText
                error.status = status
                error.url = url
                throw error
              })
            }
            return response.json()
          })
          .then((data) => {
            dispatch(receiveUpdateNote(data, false))
            resolve(data)
          })
          .catch((error) => {
            const authenticationError = error.status === 403
            if (authenticationError) {
              dispatch(fetchChangeSettings('loginPopup', true))
            }
            dispatch(receiveUpdateNote([], convertErrorCodeToType(error.status), error.statusText))
            reject(error)
          })
      } else {
        reject(new Error("Can't get the analysis with id:" + analysisItem._id))
      }
    })
  }
}

export function fetchRemoveAnalysisNote(analysisItem, note) {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      const { analyses } = getState()
      // Get the same object of the note to get the good index
      const data = getAnalysisById(analyses, analysisItem._id)

      let fullRaw = await getAnalysisRawById(analysisItem._id, dispatch)
      if (fullRaw && data) {
        let dataToUpdate = JSON.parse(JSON.stringify(fullRaw))

        let index = -1
        index = data.content.notes.indexOf(note)
        if (index > -1) {
          dataToUpdate.content.notes.splice(index, 1)
          fetch(`${API_BO_URL()}.analysis/${analysisItem._id}`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(dataToUpdate),
          })
            .then((response) => {
              if (!response.ok) {
                const statusText = response.statusText
                const status = response.status
                const url = response.url
                return response.text().then((errorMessage) => {
                  const error = new Error(`${statusText} : ${errorMessage}`)
                  if (response.headers.get('content-type') === 'application/json') {
                    error.stack = JSON.stringify(
                      JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                      null,
                      2,
                    )
                  } else {
                    error.stack = new Error().stack
                  }
                  error.statusText = statusText
                  error.status = status
                  error.url = url
                  throw error
                })
              }
              return response.json()
            })
            .then((data) => {
              dispatch(receiveUpdateNote(data, false))
              resolve(data)
            })
            .catch((error) => {
              const authenticationError = error.status === 403
              if (authenticationError) {
                dispatch(fetchChangeSettings('loginPopup', true))
              }
              dispatch(receiveUpdateNote([], convertErrorCodeToType(error.status), error))
              reject(error)
            })
        } else {
          return dispatch(
            receiveUpdateNote(
              [],
              convertErrorCodeToType(),
              'The note to removed was not founded in the Analysis',
            ),
          )
        }
      } else {
        reject(new Error("Can't get the analysis with id:" + analysisItem._id))
      }
    })
  }
}

export function receiveUpdateNote(analysis, typeError, error) {
  return {
    type: typeError ? typeError : RECEIVE_UPDATE_ANALYSIS_NOTE,
    objectsUpdated: analysis,
    error: typeError === 'AUTHENTICATION_ERROR' ? error : null,
  }
}

export function fetchRemoveAnalysisVisibilityTag(analysisItem) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      let fullRaw = await getAnalysisRawById(analysisItem._id, dispatch)
      if (fullRaw) {
        let dataToUpdate = JSON.parse(JSON.stringify(fullRaw))
        dataToUpdate.tags.push('off_visibility')
        fetch(`${API_BO_URL()}.analysis/${analysisItem._id}`, {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(dataToUpdate),
        })
          .then((response) => {
            if (!response.ok) {
              const statusText = response.statusText
              const status = response.status
              const url = response.url
              return response.text().then((errorMessage) => {
                const error = new Error(`${statusText} : ${errorMessage}`)
                if (response.headers.get('content-type') === 'application/json') {
                  error.stack = JSON.stringify(
                    JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                    null,
                    2,
                  )
                } else {
                  error.stack = new Error().stack
                }
                error.statusText = statusText
                error.status = status
                error.url = url
                throw error
              })
            }
            return response.json()
          })
          .then((data) => {
            dispatch(receiveUpdateTag(data, false))
            resolve(data)
          })
          .catch((error) => {
            const authenticationError = error.status === 403
            if (authenticationError) {
              dispatch(fetchChangeSettings('loginPopup', true))
            }
            dispatch(receiveUpdateTag([], convertErrorCodeToType(error.status), error))
            reject(error)
          })
      } else {
        reject(new Error("Can't get the analysis with id:" + analysisItem._id))
      }
    })
  }
}

export function fetchAddAnalysisVisibilityTag(analysisItem) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      let fullRaw = await getAnalysisRawById(analysisItem._id, dispatch)
      if (fullRaw) {
        let dataToUpdate = JSON.parse(JSON.stringify(fullRaw))
        const index = dataToUpdate.tags.indexOf('off_visibility')
        if (index > -1) {
          dataToUpdate.tags.splice(index, 1)
        }
        fetch(`${API_BO_URL()}.analysis/${analysisItem._id}`, {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(dataToUpdate),
        })
          .then((response) => {
            if (!response.ok) {
              const statusText = response.statusText
              const status = response.status
              const url = response.url
              return response.text().then((errorMessage) => {
                const error = new Error(`${statusText} : ${errorMessage}`)
                if (response.headers.get('content-type') === 'application/json') {
                  error.stack = JSON.stringify(
                    JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                    null,
                    2,
                  )
                } else {
                  error.stack = new Error().stack
                }
                error.statusText = statusText
                error.status = status
                error.url = url
                throw error
              })
            }
            return response.json()
          })
          .then((data) => {
            dispatch(receiveUpdateTag(data, false))
            resolve(data)
          })
          .catch((error) => {
            const authenticationError = error.status === 403
            if (authenticationError) {
              dispatch(fetchChangeSettings('loginPopup', true))
            }
            dispatch(receiveUpdateTag([], convertErrorCodeToType(error.status), error))
            reject(error)
          })
      } else {
        reject(new Error("Can't get the analysis with id:" + analysisItem._id))
      }
    })
  }
}
export function receiveUpdateTag(analysis, typeError, error) {
  return {
    type: typeError ? typeError : RECEIVE_UPDATE_ANALYSIS_TAG,
    objectsUpdated: analysis,
    error: typeError === 'AUTHENTICATION_ERROR' ? error : null,
  }
}

export function fetchAnalysesSentToLims(batchId) {
  return async (dispatch) => {
    dispatch(requestAnalysesSentToLims(batchId))

    let filters = [
      {
        property: 'content.batch.id',
        value: batchId,
        filterType: 'string',
        operator: 'eq',
      },
      // {
      //   property: 'content.requested',
      //   value: true,
      //   filterType: 'boolean',
      //   operator: 'eq',
      // },
      {
        property: 'content.reported',
        value: true,
        filterType: 'boolean',
        operator: 'eq',
      },
    ]
    let filtersFailure = [
      {
        property: 'content.batch.id',
        value: batchId,
        filterType: 'string',
        operator: 'eq',
      },
      // {
      //   property: 'content.requested',
      //   value: true,
      //   filterType: 'boolean',
      //   operator: 'eq',
      // },
      {
        property: 'content.reported',
        value: false,
        filterType: 'boolean',
        operator: 'eq',
      },
      {
        property: 'content.reported_msg',
        value: '',
        filterType: 'string',
        operator: 'ne',
      },
    ]

    let p = encodeURIComponent(JSON.stringify(filters))
    let p2 = encodeURIComponent(JSON.stringify(filtersFailure))

    try {
      const response = await fetch(
        `${API_BO_URL()}.unitary_analysis/distinct/content.analysis.id/?filter=${p}`,
        {
          method: 'GET',
          credentials: 'include',
        },
      )
      const responseFailure = await fetch(
        `${API_BO_URL()}.unitary_analysis/distinct/content.analysis.id/?filter=${p2}`,
        {
          method: 'GET',
          credentials: 'include',
        },
      )
      const authenticationError = response.status === 403 || responseFailure.status === 403
      if (authenticationError) {
        return dispatch(fetchChangeSettings('loginPopup', true))
      }
      const jsonSent = await response.json()
      const jsonFailure = await responseFailure.json()
      return dispatch(receiveAnalysesSentToLims(jsonSent, jsonFailure, authenticationError))
    } catch (e) {
      console.log(e)
      return dispatch(receiveAnalysesSentToLims([], false))
    }
  }
}
export function requestAnalysesSentToLims(batchId) {
  return {
    type: REQUEST_ANALYSES_SENT_TO_LIMS,
    batchId: batchId,
  }
}

export function receiveAnalysesSentToLims(
  analysesSentToLims,
  analysesNotSentToLims,
  authenticationError,
) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_ANALYSES_SENT_TO_LIMS,
    analysesSentToLims: analysesSentToLims,
    analysesNotSentToLims: analysesNotSentToLims,
  }
}

export function requestChangeAnalysesType(selection) {
  return {
    type: REQUEST_CHANGE_ANALYSES_TYPE,
    objectsToChangeType: selection,
  }
}
export function requestUndoAnalysesType(selection) {
  return {
    type: REQUEST_UNDO_ANALYSES_TYPE,
    objectsToChangeType: selection,
  }
}

export function fetchUpdateAnalysesFormula(batchId, formula) {
  return async (dispatch) => {
    return new Promise((resolve, reject) => {
      // let data = {}
      // data['value'] = formula

      const requestOptions = {
        method: 'PUT',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formula),
      }
      let url = `${FLASK_URL()}/batch/${batchId}/analyses_formula_infos`

      fetch(url, requestOptions)
        .then((response) => {
          if (!response.ok) {
            const statusText = response.statusText
            const status = response.status
            const url = response.url
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((data) => {
          dispatch(receiveUpdateAnalysesFormula(data.analyses, false))
          resolve(data)
        })
        .catch((error) => {
          const authenticationError = error.status === 403
          if (authenticationError) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          dispatch({ type: 'FETCH_ERROR', error })
          reject(error)
        })
    })
  }
}
export function receiveUpdateAnalysesFormula(json, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_UPDATE_ANALYSES_FORMULA,
    objectsUpdated: json,
  }
}

export function fetchComputeAIAnalyse(analyse, batchId) {
  return async (dispatch) => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ batch_id: batchId, analysis_id: analyse._id, body: {} }),
      }
      let url = `${FLASK_URL()}/analysis/${analyse._id}/compute_ai`

      fetch(url, requestOptions)
        .then((response) => {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          if (!response.ok) {
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          // return [response.json(), response]
          return response.json()
        })
        .then((resp) => {
          // Update uas store
          dispatch(receiveUpdateUnitaryAnalyses(resp, false))
          dispatch(receiveUpdateAnalyse(resp, false))
          resolve(resp)
        })
        .catch((error) => {
          const authenticationError = error.status === 403
          if (authenticationError) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          dispatch({ type: 'FETCH_ERROR', error })
          reject(error)
        })
    })
  }
}
export function receiveComputeAIAnalyse(json, authenticationError, status, message) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_COMPUTE_AI_ANALYSE,
    objectsUpdated: json,
    status: status,
    message: message,
  }
}
export function fetchRenameAnalysis(analysisToRename, newName) {
  return async (dispatch) => {
    return new Promise((resolve, reject) => {
      const analysis = JSON.parse(JSON.stringify(analysisToRename))
      delete analysis['#']
      const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ...analysis,
          name: newName,
          content: { ...analysis.content, flags: analysis.content.flags.globals },
        }),
      }
      let url = `${API_BO_URL()}.analysis/${analysis._id}`

      fetch(url, requestOptions)
        .then((response) => {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          if (!response.ok) {
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((resp) => {
          dispatch(receiveRenameAnalyse(resp))
          resolve(resp)
        })
        .catch((error) => {
          const authenticationError = error.status === 403
          if (authenticationError) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          dispatch({ type: 'FETCH_ERROR', error })
          reject(error)
        })
    })
  }
}
export function receiveRenameAnalyse(resp) {
  return {
    type: RECEIVE_RENAME_ANALYSE,
    objectUpdated: resp,
  }
}

export function fetchDisableQC(data) {
  return async (dispatch) => {
    return new Promise((resolve, reject) => {
      const analysis = JSON.parse(JSON.stringify(data))
      delete analysis['#']
      delete analysis['disabled']
      const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ...analysis,
          content: {
            ...analysis.content,
            flags: analysis.content.flags.globals,
            disabled: !analysis.content.disabled,
          },
        }),
      }
      let url = `${API_BO_URL()}.analysis/${analysis._id}`

      fetch(url, requestOptions)
        .then((response) => {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          if (!response.ok) {
            return response.text().then((errorMessage) => {
              const error = new Error(`${statusText} : ${errorMessage}`)
              if (response.headers.get('content-type') === 'application/json') {
                error.stack = JSON.stringify(
                  JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                  null,
                  2,
                )
              } else {
                error.stack = new Error().stack
              }
              error.statusText = statusText
              error.status = status
              error.url = url
              throw error
            })
          }
          return response.json()
        })
        .then((resp) => {
          dispatch(disableQCAnalyse(resp))
          resolve(resp)
        })
        .catch((error) => {
          const authenticationError = error.status === 403
          if (authenticationError) {
            dispatch(fetchChangeSettings('loginPopup', true))
          }
          dispatch({ type: 'FETCH_ERROR', error })
          reject(error)
        })
    })
  }
}
export function disableQCAnalyse(resp) {
  return {
    type: RECEIVE_DISABLE_ANALYSE,
    objectUpdated: resp,
  }
}
