import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withStyles, fade } from '@material-ui/core/styles'
import Badge from '@material-ui/core/Badge/Badge'
import BubbleChartIcon from '@material-ui/icons/BubbleChart'
import Tooltip from '@material-ui/core/Tooltip'
import ErrorIcon from '@material-ui/icons/Error'
import TableCell from '@material-ui/core/TableCell'
import LinearProgress from '@material-ui/core/LinearProgress'
import clsx from 'clsx'
import { DivideByTwo } from '../common/Style'
import { getBatchById } from '../../redux/batches/selectors'
import { AUTHENTICATION_ERROR } from '../../redux/tools'
import { AUTHENTICATION_FAILURE } from '../../redux/batches/actions'
import { withRouter } from 'react-router-dom'
import { Trans } from 'react-i18next'

const styles = (theme) => ({
  badgeLims: {
    '& .MuiBadge-badge': {
      width: 16,
      height: 16,
      padding: 1,
      minWidth: 0,
      right: -8,
      color: fade(theme.palette.primary.main, 0.6),
      background: 'white',
      // boxShadow: '0px 2px 5px 1px rgba(0,0,0,0.15)',
      border: '1px solid lightgrey',
    },
  },
  iconLims: {
    width: 14,
    marginTop: 6.5,
    marginLeft: 6,
    position: 'relative',
    color: theme.palette.common.warning,
  },
})

export default withRouter(
  connect()(
    withStyles(styles)(
      class GC_Concentration_Badge extends React.Component {
        render() {
          const { classes, concentration, factor, row } = this.props

          return concentration !== '' &&
            factor !== undefined &&
            factor !== null &&
            factor !== '' &&
            factor !== 1 ? (
            <Badge
              className={clsx(classes.badgeLims)}
              badgeContent={
                <Tooltip
                  key={'tooltip_lims'}
                  placement="right"
                  arrow
                  title={
                    <div style={{ fontSize: 12, padding: 10 }}>
                      <Trans i18nKey="view.batch.GC_conc.tooltip">
                        The concentration has been divided by {{ val: factor }}
                      </Trans>
                    </div>
                  }
                >
                  <span>
                    <DivideByTwo className={classes.iconLims} />
                  </span>
                </Tooltip>
              }
            >
              {concentration}
            </Badge>
          ) : (
            concentration
          )
        }
      },
    ),
  ),
)
