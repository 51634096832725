import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { fade, withStyles } from '@material-ui/core/styles'
import { Molecule, Nothing, StyledDialogTitle, TransitionTop } from '../common/Style'
import { withRouter } from 'react-router-dom'
import Loading from '../Loading'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button/Button'
import { API_BO_URL } from '../../utils/config'
import { Card } from '@material-ui/core'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Badge from '@material-ui/core/Badge'
import Chip from '@material-ui/core/Chip'
import ErrorIcon from '@material-ui/icons/ErrorOutlineOutlined'
import HelpIcon from '@material-ui/icons/HelpOutlineOutlined'
import CancelIcon from '@material-ui/icons/CancelOutlined'
import ClearIcon from '@material-ui/icons/Clear'
import DoneIcon from '@material-ui/icons/Done'
import Grid from '@material-ui/core/Grid'
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import { fetchDisplayDetail } from '../../redux/detail/actions'
import { callValidationDispatch, rebuildUnitaryAnalyses } from './Utils'
import { t } from 'i18next'
import {
  fetchUnitaryAnalysesDistinct,
  fetchUnitaryCompoundDistinct,
  fetchUpdateUAValidation,
} from '../../redux/unitaryAnalyses/actions'
import { fetchAnalyses } from '../../redux/analyses/actions'
import { fetchAddTask, fetchFinishTask } from '../../redux/tasks/actions'
import { displayPopupMessage, generateErrorPart } from './Message'
import { Trans } from 'react-i18next'

const styles = (theme) => ({
  tooltip: {
    fontSize: 13,
    padding: 5,
  },
  pos: {
    marginBottom: 12,
  },
  dialogLims: {
    '& .MuiDialog-paperScrollPaper': {
      maxWidth: '50% !important',
    },
  },
  autoValidText: {
    color: theme.palette.secondary.main,
  },
  chip: {
    margin: '0px 5px 5px 0px',
    background: 'white',
    '&:hover': {
      background: theme.palette.secondary.main,
    },
  },
})

const mapStateToProps = (state) => {
  const { lims } = state
  return {
    uaValidated: {
      istd: lims.autoValidated
        .filter((ua) => ua.type === 'ISTD')
        .sort(function (a, b) {
          if (a.classification.global < b.classification.global) return 1
          if (a.classification.global > b.classification.global) return -1
          return 0
        }), //Molecule istd
      detected: lims.autoValidated
        .filter((ua) => ua.classification.global === 'detected' && ua.type !== 'ISTD')
        .sort(function (a, b) {
          if (a.global_score < b.global_score) return 1
          if (a.global_score > b.global_score) return -1
          return 0
        }), //Molecule detected -> ErrorIcon
      excluded: lims.autoValidated
        .filter((ua) => ua.classification.global === 'excluded' && ua.type !== 'ISTD')
        .sort(function (a, b) {
          if (a.global_score < b.global_score) return 1
          if (a.global_score > b.global_score) return -1
          return 0
        }), //Molecule excluded -> CancelIcon
      suspected: lims.autoValidated
        .filter((ua) => ua.classification.global === 'suspected' && ua.type !== 'ISTD')
        .sort(function (a, b) {
          if (a.global_score < b.global_score) return 1
          if (a.global_score > b.global_score) return -1
          return 0
        }), //Molecule suspected -> HelpIcon
    },
    isFetching: lims.isFetchingAutoValidation,
  }
}
const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -18,
    top: 5,
  },
}))(Badge)

export default withRouter(
  connect(mapStateToProps)(
    withStyles(styles)(
      class LimsAutoValidationConfirm extends React.Component {
        constructor(props) {
          super(props)
          this.state = {}
        }

        async componentDidMount() {}

        getCard(data, name) {
          const { classes, dispatch } = this.props
          return (
            <Grid style={{ marginBottom: 10 }} container spacing={3}>
              <Grid item xs>
                <Card variant="outlined">
                  <CardContent style={{ background: '#fbfbfb' }}>
                    <Typography
                      // variant="h5"
                      component="h2"
                      color="secondary"
                      className={classes.pos}
                    >
                      <StyledBadge max={999} badgeContent={data.length} color="secondary">
                        {name === 'Detected' && (
                          <ErrorIcon style={{ marginRight: 10, top: -2, position: 'relative' }} />
                        )}
                        {name === 'Suspected' && (
                          <HelpIcon style={{ marginRight: 10, top: -2, position: 'relative' }} />
                        )}
                        {name === 'Excluded' && (
                          <CancelIcon style={{ marginRight: 10, top: -2, position: 'relative' }} />
                        )}
                        {t('view.common.LimsAutoVal.' + name.toLowerCase())}
                      </StyledBadge>
                      {name === 'ISTD' && (
                        <span style={{ marginLeft: 40, fontSize: 'small', float: 'right' }}>
                          {t('view.common.LimsAutoVal.class_ok_1') + ' '}
                          {
                            <DoneIcon
                              style={{ color: 'green', width: 20, position: 'relative', top: 7 }}
                            />
                          }
                          / {t('view.common.LimsAutoVal.class_ok_2') + ' '}
                          {
                            <ClearIcon
                              style={{ color: 'red', width: 20, position: 'relative', top: 7 }}
                            />
                          }
                          )
                        </span>
                      )}
                      {name !== 'ISTD' && (
                        <span style={{ marginLeft: 40, fontSize: 'small', float: 'right' }}>
                          ({t('view.common.LimsAutoVal.score') + ': 0->70% '}
                          {
                            <ArrowDownwardIcon
                              style={{ color: 'red', width: 20, position: 'relative', top: 7 }}
                            />
                          }
                          / 70->100%{' '}
                          {
                            <ArrowUpwardIcon
                              style={{ color: 'green', width: 20, position: 'relative', top: 7 }}
                            />
                          }
                          )
                        </span>
                      )}
                    </Typography>
                    <Typography
                      variant={'body2'}
                      component={'p'}
                      style={{ maxHeight: 82, overflow: 'auto' }}
                    >
                      {data.map((uav, index) => (
                        <Chip
                          key={index}
                          icon={
                            uav.classification.global === 'ok' ? (
                              <DoneIcon style={{ color: 'green' }} />
                            ) : uav.classification.global === 'ko' ? (
                              <ClearIcon style={{ color: 'red' }} />
                            ) : uav.global_score < 0.7 ? (
                              <ArrowDownwardIcon style={{ color: 'red' }} />
                            ) : uav.global_score >= 0.7 ? (
                              <ArrowUpwardIcon style={{ color: 'green' }} />
                            ) : (
                              ''
                            )
                          }
                          size="small"
                          className={classes.chip}
                          label={uav.name}
                          variant="outlined"
                          onClick={(event) =>
                            dispatch(
                              fetchDisplayDetail(uav, 0, false, event.currentTarget, 'analyse'),
                            )
                          }
                        />
                      ))}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item style={{ width: 44, paddingTop: 82 }}>
                <DoubleArrowIcon color={'primary'} />
              </Grid>
              <Grid item style={{ width: 150 }}>
                <div
                  className={classes.autoValidText}
                  style={{
                    height: '100%',
                    padding: 16,
                    paddingTop: 64,
                    textAlign: 'center',
                    fontWeight: 500,
                  }}
                  variant="outlined"
                >
                  <div>
                    {name === 'ISTD'
                      ? t('view.common.LimsAutoVal.ok')
                      : name === 'Detected'
                      ? t('view.common.LimsAutoVal.detected')
                      : t('view.common.LimsAutoVal.not_detected')}
                  </div>
                  <div>
                    {name === 'ISTD' ? (
                      <DoneIcon />
                    ) : name === 'Detected' ? (
                      <Molecule />
                    ) : (
                      <Nothing />
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          )
        }

        render() {
          const { classes, open, closeFct, isFetching, uaValidated } = this.props

          const validationFct = (event) => {
            const { closeFct, uaValidated, dispatch } = this.props
            const istdUA = uaValidated.istd
            const detectedUA = uaValidated.detected
            const othersUA = uaValidated.excluded.concat(uaValidated.suspected)
            const allUA = uaValidated.istd
              .concat(uaValidated.detected)
              .concat(uaValidated.suspected)
              .concat(uaValidated.excluded)
            closeFct()

            const result = {}
            istdUA.forEach((ua) => (result[ua._id] = 1))
            detectedUA.forEach((ua) => (result[ua._id] = 1))
            othersUA.forEach((ua) => (result[ua._id] = 2))
            const taskId = new Date().getTime() + '_auto_validation_' + allUA[0]._id
            const task = {
              id: taskId,
              title: (
                <Trans i18nKey="view.common.utils.validation.title">
                  {{
                    val: t('view.common.utils.validation.analysis'),
                  }}
                  validation
                </Trans>
              ),
              operator: localStorage.getItem('SESSION_user_name'),
              date: new Date().getTime(),
              action: 'validation',
              percentage: 50,
              state: { value: 'running', message: '' },
              operation: 'auto validation',
              items: allUA.map((orData) => ({
                id: orData._id,
                type: 'analysis',
                name: orData.name,
                analysis: {
                  id: orData.analyseId,
                  type: orData.analysetype,
                  name: orData.analyse,
                },
                batch: {
                  id: orData.batchId,
                  name: orData.batch_name,
                },
              })),
            }
            dispatch(fetchAddTask(task))
            dispatch(
              fetchUpdateUAValidation(
                result,
                'unitary_analysis',
                null,
                true,
                this.props.match.params.batchId,
              ),
            )
              .then(function (resp) {
                // Update progress component by reload analyses
                dispatch(fetchAnalyses(this.props.match.params.batchId))
                dispatch(
                  fetchUnitaryAnalysesDistinct(
                    resp.objectsUpdated.uas[0].content.analysis.id,
                    JSON.parse(localStorage.getItem('ANALYSIS_filter_requested')) === true,
                  ),
                )
                dispatch(fetchFinishTask(taskId, 'success', t('view.common.utils.validation.fine')))
              })
              .catch(function (error) {
                if (error && error.status !== undefined && error.status === 401) {
                  error.statusText = t('view.common.utils.lease_locked.code')
                  displayPopupMessage(
                    dispatch,
                    'warning',
                    t('view.common.utils.validation.update_title'),
                    <Fragment>
                      <div>{t('view.common.utils.validation.update_msg')}</div>
                      {generateErrorPart(error)}
                    </Fragment>,
                  )
                } else {
                  displayPopupMessage(
                    dispatch,
                    'error',
                    t('view.common.utils.validation.update_title'),
                    <Fragment>
                      <div>{t('view.common.utils.validation.update_msg')}</div>
                      {generateErrorPart(error)}
                    </Fragment>,
                  )
                }
                dispatch(fetchFinishTask(taskId, 'error', error))
              })

            // if (istdUA.length > 0) {
            //   callValidationDispatch(
            //     'unitary_analysis',
            //     dispatch,
            //     istdUA,
            //     1,
            //     null,
            //     this.props.match.params.batchId,
            //   )
            // }
            // if (detectedUA.length > 0) {
            //   callValidationDispatch(
            //     'unitary_analysis',
            //     dispatch,
            //     detectedUA,
            //     1,
            //     null,
            //     this.props.match.params.batchId,
            //   )
            // }
            // if (othersUA.length > 0) {
            //   callValidationDispatch(
            //     'unitary_analysis',
            //     dispatch,
            //     othersUA,
            //     2,
            //     null,
            //     this.props.match.params.batchId,
            //   )
            // }
          }

          return (
            <Dialog
              open={open}
              onClose={closeFct}
              TransitionComponent={TransitionTop}
              aria-labelledby="draggable-dialog-title"
              className={classes.dialogLims}
            >
              <StyledDialogTitle>{t('view.common.LimsAutoVal.title')}</StyledDialogTitle>
              {isFetching ? (
                <div style={{ width: 800, height: 500 }}>
                  <Loading mode={'miniCircle'} />
                </div>
              ) : (
                <DialogContent>
                  <div style={{ marginTop: 0, marginBottom: 10 }}>
                    {t('view.common.LimsAutoVal.msg')}
                  </div>
                  {uaValidated.istd.length !== 0 && this.getCard(uaValidated.istd, 'ISTD')}
                  {uaValidated.detected.length !== 0 &&
                    this.getCard(uaValidated.detected, 'Detected')}
                  {uaValidated.suspected.length !== 0 &&
                    this.getCard(uaValidated.suspected, 'Suspected')}
                  {uaValidated.excluded.length !== 0 &&
                    this.getCard(uaValidated.excluded, 'Excluded')}
                  <div style={{ marginTop: 20, marginBottom: 0 }}>
                    {t('view.common.LimsAutoVal.continue')}
                  </div>
                </DialogContent>
              )}
              <DialogActions>
                <Button autoFocus onClick={(event) => validationFct(event)} color="primary">
                  {t('view.common.LimsAutoVal.yes')}
                </Button>
                <Button onClick={closeFct} color="primary">
                  {t('view.common.LimsAutoVal.no')}
                </Button>
              </DialogActions>
            </Dialog>
          )
        }
      },
    ),
  ),
)
