import React from 'react'
import { StyledDialogTitle, TransitionTop } from '../common/Style'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Badge from '@material-ui/core/Badge'
import { t } from 'i18next'

export default function AnalysisMultiSelectionDialog({ open, closeFct, selection, selectionType }) {
  const handleConfirmResetClose = (event, option) => {
    closeFct(event, option)
  }
  const types = selectionType.reduce(function (map, obj) {
    map[obj.name] = obj.value
    return map
  }, {})

  return (
    <Dialog
      open={open}
      onClose={(event) => handleConfirmResetClose(event, 0)}
      TransitionComponent={TransitionTop}
      aria-labelledby="dialog-reset-title"
    >
      <StyledDialogTitle>{t('view.dialogs.analysis_multi_selection.title')}</StyledDialogTitle>
      <DialogContent>
        <DialogContentText style={{ margin: 0 }}>
          <div>{t('view.dialogs.analysis_multi_selection.msg')}</div>
        </DialogContentText>
        <DialogContentText style={{ margin: 0, marginTop: 15 }}>
          <ul>
            {types['okKo'] && (
              <li>
                <Badge badgeContent={types['okKo']} color="secondary">
                  <div style={{ width: 82 }}>
                    {t('view.dialogs.analysis_multi_selection.ok_ko')}
                  </div>
                </Badge>
              </li>
            )}
            {types['detectedNotDetected'] && (
              <li>
                <Badge badgeContent={types['detectedNotDetected']} color="secondary">
                  <div style={{ width: 180 }}>
                    {t('view.dialogs.analysis_multi_selection.suspected_excluded')}
                  </div>
                </Badge>
              </li>
            )}
          </ul>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={(event) => handleConfirmResetClose(event, 1)} color="primary">
          {t('view.dialogs.analysis_multi_selection.all')}
        </Button>
        <Button onClick={(event) => handleConfirmResetClose(event, 3)} color="primary">
          {t('view.dialogs.analysis_multi_selection.ok_ko_2')}
        </Button>
        <Button onClick={(event) => handleConfirmResetClose(event, 2)} color="primary">
          {t('view.dialogs.analysis_multi_selection.suspected_excluded_2')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
