import React, { useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { alpha, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import Draggable from 'react-draggable'
import { t } from 'i18next'
import Button from '@material-ui/core/Button'
import { PUBLIC_URL } from '../../utils/config'
import Box from '@material-ui/core/Box'
import Stack from '@mui/material/Stack'
import { fetchChangeSettings } from '../../redux/settings/actions'
import { withRouter } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import { Checkbox } from '@mui/material'
import TextField from '@material-ui/core/TextField'
import { genericStyles } from '../common/Style'
import TerminalIcon from '@mui/icons-material/Terminal'
import { fetchGetLogs, FILTER_LIST } from '../../redux/logs/actions'
import Loading from '../Loading'
import { CustomNoRowsOverlay } from '../backlog/Tools'
import { copyToClipboard, hideWatermark } from '../common/Utils'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro'
import { useGridApiRef } from '@mui/x-data-grid'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import Typography from '@material-ui/core/Typography'
import ReplayIcon from '@mui/icons-material/Replay'
import InputAdornment from '@material-ui/core/InputAdornment'
import { useTheme } from '@mui/material/styles'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Chip from '@mui/material/Chip'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import Menu from '@material-ui/core/Menu'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import NewReleasesIcon from '@mui/icons-material/NewReleases'
import AdbIcon from '@mui/icons-material/Adb'
import SearchIcon from '@mui/icons-material/Search'
import InputIcon from '@mui/icons-material/Input'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import { Resizable, ResizableBox } from 'react-resizable'
import { Trans } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  customModal: {
    // display: 'none',
    position: 'fixed',
    // top: 'calc(15%)',
    // left: 'calc(50% - 300px)',
    // transform: 'translate(-50%, -50%)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    zIndex: 1290,
    borderRadius: 5,
    width: '100%',
    // minWidth: 765,
    height: '100%',
    // background: '#272822',
    background: 'white',
  },
  open: {
    display: 'block',
  },
  close: {
    display: 'none',
  },
  modalContent: {
    padding: 0,
    height: 'calc(100% - 176px)',
    /* Style de votre contenu modal */
  },
  header: {
    cursor: 'move',
    background: 'white',
    // height: 40,
    // position: 'absolute',
    // width: 'calc(100% - 120px)',
    // opacity: 0,
  },
  headerTitle: {
    boxShadow: 'inset 0px 0px 82px 10px rgba(0,0,0,1)',
    borderRadius: '5px 5px 0px 0px',
  },
  closeButton: {
    position: 'absolute',
    right: 22,
    top: 6,
  },
  cardImg: {
    // filter: 'blur(3px)',
    height: 130,
    userSelect: 'none',
    opacity: 0.5,
    borderRadius: '5px 5px 0px 0px',
    backgroundImage: 'url(' + PUBLIC_URL + '/images/logs_bkg.jpg' + ')',
    backgroundSize: 'cover',
  },
  titleCard: {
    position: 'absolute',
    top: 41,
    left: 34,
    fontSize: 40,
    // color: theme.palette.secondary.main,
    color: 'white',
    textShadow: '2px 1px 10px white',
  },
  info: {
    color: '#547fa8',
  },
  success: {
    color: '#20a91b',
  },
  warning: {
    color: '#e68d09',
  },
  error: {
    color: '#c70505',
  },
  debug: {
    color: '#287b2a',
  },
  critical: {
    color: '#ff0000',
  },
  search: {
    color: '#656565',
  },
  info_bkg: {
    background: alpha('#547fa8', 0.05),
  },
  success_bkg: {
    background: alpha('#20a91b', 0.05),
  },
  warning_bkg: {
    background: alpha('#e68d09', 0.05),
  },
  error_bkg: {
    background: alpha('#c70505', 0.05),
  },
  debug_bkg: {
    background: alpha('#287b2a', 0.05),
  },
  critical_bkg: {
    background: alpha('#ff0000', 0.05),
  },
  search_bkg: {
    background: alpha('#656565', 0.05),
  },
  filterSelector: {
    height: 40,
    '& > div': {
      paddingTop: 4,
      paddingBottom: 4,
      height: 40,
    },
  },
  filterCmp: {
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 13px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& .MuiButton-startIcon': {
      marginRight: 0,
    },
    '& .MuiChip-deleteIcon': {
      color: 'inherit',
      opacity: 0.3,
    },
    '& .MuiChip-icon': {
      color: 'inherit',
      // opacity: 0.8,
      maxWidth: 22,
      maxHeight: 22,
    },
    '& .MuiChip-deleteIcon:hover': {
      color: 'inherit',
      opacity: 1,
    },
  },
  searchTextfield: {
    '& .MuiInputBase-adornedEnd': {
      paddingRight: 0,
    },
  },
  errorButton: {
    border: '1px solid rgba(211, 47, 47, 0.5)',
    backgroundColor: 'rgba(211, 47, 47, 0.04)',
    color: 'rgb(211, 47, 47)',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'rgba(211, 47, 47, 0.04)',
      border: '1px solid rgb(211, 47, 47)',
    },
  },
  resizable: {
    zIndex: 1290,
    position: 'relative',
    '& .react-resizable-handle': {
      zIndex: 1291,
      position: 'absolute',
      width: 20,
      height: 20,
      background:
        "url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+')",
      backgroundPosition: 'bottom right',
      padding: 3,
      backgroundRepeat: 'no-repeat',
      backgroundOrigin: 'content-box',
      backgroundSize: 'cover',
      boxSizing: 'border-box',
    },
    '& .react-resizable-handle-sw': {
      bottom: 0,
      left: 0,
      cursor: 'sw-resize',
      transform: 'rotate(90deg)',
    },
    '& .react-resizable-handle-se': {
      bottom: 0,
      right: 0,
      cursor: 'se-resize',
      opacity: 0,
      transition: 'opacity 300ms ease-in',
      '&:hover': {
        opacity: 0.5,
        transition: 'opacity 300ms ease-in',
      },
    },
    '& .react-resizable-handle-nw': {
      top: 0,
      left: 0,
      cursor: 'nw-resize',
      transform: 'rotate(180deg)',
    },
    '& .react-resizable-handle-ne': {
      top: 0,
      right: 0,
      cursor: 'ne-resize',
      transform: 'rotate(270deg)',
    },
  },
}))

const filtersList = ['info', 'warning', 'error', 'debug', 'critical']
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}
const LogsDialog = withRouter((props) => {
  const dispatch = useDispatch()
  const genericClasses = genericStyles()
  const classes = useStyles()
  const theme = useTheme()
  const modalRef = useRef(null)
  const apiRef = useGridApiRef()

  const delta = useSelector((state) => state.settings.dateTimeDelta)
  const open = useSelector((state) => state.settings.logsPopup)
  const logs = useSelector((state) => state.logs.logs)
  const totalLogs = useSelector((state) => state.logs.total)
  const isFetchingLogs = useSelector((state) => state.logs.isFetchingLogs)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [searchFilterTmp, setSearchFilterTmp] = React.useState(
    localStorage.getItem('LOG_search') ? localStorage.getItem('LOG_search') : '',
  )
  const [logSize, setLogSize] = React.useState(
    localStorage.getItem('LOG_size')
      ? JSON.parse(localStorage.getItem('LOG_size'))
      : { width: 1000, height: 630 },
  )
  const [searchFilter, setSearchFilter] = React.useState(
    localStorage.getItem('LOG_search') ? localStorage.getItem('LOG_search') : '',
  )
  const [searchFilterActif, setSearchFilterActif] = React.useState(
    !!localStorage.getItem('LOG_search'),
  )
  const openMenu = Boolean(anchorEl)

  const [filters, setFilters] = React.useState(
    localStorage.getItem('LOG_filters')
      ? JSON.parse(localStorage.getItem('LOG_filters'))
      : FILTER_LIST,
  )

  const [limit, setLimit] = React.useState(
    localStorage.getItem('LOG_limit') ? parseFloat(localStorage.getItem('LOG_limit')) : 100,
  )
  const [polling, setPolling] = React.useState(
    localStorage.getItem('LOG_polling') ? parseFloat(localStorage.getItem('LOG_polling')) : 30,
  )

  const [contextMenu, setContextMenu] = React.useState(null)
  const [selectedRow, setSelectedRow] = React.useState(null)

  useEffect(() => {
    if (open) {
      dispatch(fetchGetLogs())
      // testPosition()
    }
  }, [open])

  const closeFct = () => {
    dispatch(fetchChangeSettings('logsPopup', false))
  }

  const saveSnapshot = React.useCallback(() => {
    const stateSnapshot = apiRef.current.exportState()
    if (Object.keys(stateSnapshot).length !== 0) {
      localStorage.setItem('LOG_state', JSON.stringify(stateSnapshot))
    }
  }, [apiRef])

  const getLevelIcon = (level) => {
    switch (level) {
      case 'info':
        return <InfoOutlinedIcon className={classes.info} />
      case 'error':
        return <ErrorOutlineOutlinedIcon className={classes.error} />
      case 'warning':
        return <WarningAmberOutlinedIcon className={classes.warning} />
      case 'debug':
        return <AdbIcon className={classes.debug} />
      case 'critical':
        return <NewReleasesIcon className={classes.critical} />
    }
  }

  const columns = useMemo(
    () => [
      {
        field: 'level',
        resizable: false,
        disableReorder: true,
        width: 50,
        headerName: t('view.dialogs.logs.header_names.level'),
        renderHeader: () => '',
        renderCell: ({ value }) =>
          // <Stack
          //   direction="row"
          //   justifyContent="flex-start"
          //   alignItems="center"
          //   spacing={1}
          //   className={classes[value]}
          // >
          //   {getLevelIcon(value)} <span>{value}</span>
          // </Stack>
          getLevelIcon(value),
      },
      {
        field: 'date',
        width: 180,
        type: 'dateTime',
        headerName: t('view.dialogs.logs.header_names.date'),
        // renderCell: ({ value }) => new Date((value - delta) * 1000).toLocaleString(),
        valueGetter: ({ value }) => value && new Date((value - delta) * 1000),
      },
      {
        field: 'msg',
        flex: 1,
        headerName: t('view.dialogs.logs.header_names.msg'),
      },
    ],
    [],
  )

  const cleanLogs = useMemo(
    () =>
      logs.map((log) => ({
        id: log._id,
        date: log.content.created,
        level: log.content.level,
        msg: log.content.msg,
      })),
    [logs],
  )

  function updateLimit(value) {
    setLimit(value)
    localStorage.setItem('LOG_limit', value)
  }

  function updatePolling(value) {
    setPolling(value)
    localStorage.setItem('LOG_polling', value)
  }
  const handleFilterDelete = (filter) => {
    const newFilters = filters.filter((f) => f !== filter)
    localStorage.setItem('LOG_filters', JSON.stringify(newFilters))
    setFilters(newFilters)
    dispatch(fetchGetLogs())
  }
  const handleOpenButton = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleFiltersChange = (filter) => {
    if (filters.indexOf(filter) > -1) {
      const newFilters = filters.filter((f) => f !== filter)
      localStorage.setItem('LOG_filters', JSON.stringify(newFilters))
      setFilters(newFilters)
    } else {
      const newFilters = JSON.parse(JSON.stringify(filters))
      newFilters.push(filter)
      localStorage.setItem('LOG_filters', JSON.stringify(newFilters))
      setFilters(newFilters)
    }
    dispatch(fetchGetLogs())
  }

  function applySearchFilter() {
    localStorage.setItem('LOG_search', searchFilterTmp)
    setSearchFilter(searchFilterTmp)
    setSearchFilterActif(true)
    dispatch(fetchGetLogs())
    setAnchorEl(null)
  }

  function clearSearchFilter() {
    localStorage.removeItem('LOG_search')
    setSearchFilterActif(false)
    setSearchFilterTmp('')
    setSearchFilter('')
    dispatch(fetchGetLogs())
  }

  const handleContextMenu = (event) => {
    event.preventDefault()
    const rowId = event.currentTarget.getAttribute('data-id')
    const row = cleanLogs.filter((l) => l.id === rowId)[0]
    setSelectedRow(row)
    setContextMenu(
      contextMenu === null ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : null,
    )
  }

  const handleCloseContextMenu = () => {
    setContextMenu(null)
  }

  function copyToClipboardMenu(event) {
    setContextMenu(null)
    copyToClipboard(
      event,
      new Date((selectedRow.date - delta) * 1000).toLocaleString() +
        ' : ' +
        selectedRow.level +
        '\n' +
        selectedRow.msg,
    )
  }

  function onStop(event, data) {
    localStorage.setItem('LOG_position', JSON.stringify({ x: data.x, y: data.y }))
    // testPosition()
  }

  function getDefaultPosition() {
    const defaultPosition = {
      x: 100,
      y: -(
        Math.max(
          document.body.scrollHeight,
          document.body.offsetHeight,
          document.documentElement.clientHeight,
          document.documentElement.scrollHeight,
          document.documentElement.offsetHeight,
        ) - 100
      ),
    }
    if (localStorage.getItem('LOG_position')) {
      const customPosition = JSON.parse(localStorage.getItem('LOG_position'))
      const screenHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight,
      )
      const screenWidth = Math.max(
        document.body.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.clientWidth,
        document.documentElement.scrollWidth,
        document.documentElement.offsetWidth,
      )
      if (
        customPosition.x < screenWidth - 50 &&
        customPosition.x + logSize.width > 50 &&
        -customPosition.y < screenHeight - 50 &&
        -customPosition.y > 50
      ) {
        return customPosition
      }
    }
    return defaultPosition
  }

  // Do not use this function, Draggable doesn't recognize the update
  function testPosition() {
    const defaultPos = getDefaultPosition()
    if (localStorage.getItem('LOG_position')) {
      const customPosition = JSON.parse(localStorage.getItem('LOG_position'))
      if (defaultPos.x !== customPosition.x || defaultPos.y !== customPosition.y) {
        // We should move the log window cause we can see it at the saved position
        const el = document.getElementsByClassName('react-draggable react-resizable')[0]
        el.style.transform = 'translate(' + defaultPos.x + 'px, ' + defaultPos.y + 'px)'
      }
    }
  }

  return (
    <Draggable
      handle=".modal-handle"
      style={{ zIndex: 1290 }}
      defaultPosition={getDefaultPosition()}
      onStop={onStop}
    >
      <ResizableBox
        className={clsx(classes.resizable, open ? classes.open : classes.close)}
        width={logSize.width}
        height={logSize.height}
        resizeHandles={['se']}
        minConstraints={[730, 380]}
        // maxConstraints={[1000, 1000]}
        onResize={(event, { node, size, handle }) => {
          localStorage.setItem(
            'LOG_size',
            JSON.stringify({ width: size.width, height: size.height }),
          )
          setLogSize({ width: size.width, height: size.height })
        }}
      >
        <div ref={modalRef} className={clsx(classes.customModal)}>
          <Box className={clsx('modal-handle', classes.title, classes.header, classes.headerTitle)}>
            <div className={classes.cardImg}></div>
            <span className={classes.titleCard}>{t('view.dialogs.logs.title')}</span>
            <TerminalIcon className={genericClasses.icon} />
          </Box>
          <div className={classes.modalContent}>
            <Menu
              open={contextMenu !== null}
              onClose={handleCloseContextMenu}
              anchorReference="anchorPosition"
              anchorPosition={
                contextMenu !== null
                  ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                  : undefined
              }
              slotProps={{
                root: {
                  onContextMenu: (e) => {
                    e.preventDefault()
                    handleCloseContextMenu()
                  },
                },
              }}
            >
              <MenuItem onClick={copyToClipboardMenu}>
                <ListItemIcon>
                  <FileCopyIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>{t('view.dialogs.logs.clipboard')}</ListItemText>
              </MenuItem>
            </Menu>
            <Stack
              direction="column"
              justifyContent="space-between"
              alignItems="stretch"
              spacing={0}
              style={{ height: '100%' }}
            >
              <Typography
                component="h2"
                variant="h6"
                color="primary"
                style={{ padding: '20px 20px 0px' }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  className={classes.filterCmp}
                >
                  <div>
                    <Button
                      className={
                        filters.length === 0 && !searchFilterActif
                          ? classes.errorButton
                          : classes.default
                      }
                      variant="outlined"
                      disableElevation
                      onClick={handleOpenButton}
                      startIcon={<FilterAltOutlinedIcon />}
                      endIcon={<KeyboardArrowDownIcon />}
                      style={{ height: 40 }}
                    ></Button>
                    <Menu
                      autoFocus={false}
                      disableAutoFocusItem={true}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      open={openMenu}
                      onClose={handleClose}
                    >
                      {filtersList.map((name) => (
                        <MenuItem
                          key={'_' + name}
                          value={name}
                          onClick={() => handleFiltersChange(name)}
                        >
                          <Checkbox checked={filters.indexOf(name) > -1} />
                          <ListItemText
                            style={{ margin: '0px 20px' }}
                            primary={t('view.dialogs.logs.filters.' + name)}
                          />
                        </MenuItem>
                      ))}
                      <TextField
                        onKeyDown={(e) => {
                          if (
                            e.key === 'Enter' &&
                            searchFilterTmp !== '' &&
                            searchFilter !== searchFilterTmp
                          ) {
                            // Valid with entry key from keyboard
                            applySearchFilter()
                          } else {
                            e.stopPropagation()
                          }
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              {searchFilterActif && searchFilter === searchFilterTmp ? (
                                <IconButton color="primary" onClick={clearSearchFilter}>
                                  <HighlightOffIcon fontSize="inherit" />
                                </IconButton>
                              ) : searchFilterTmp !== '' ? (
                                <IconButton color="primary" onClick={applySearchFilter}>
                                  <InputIcon fontSize="inherit" />
                                </IconButton>
                              ) : (
                                ''
                              )}
                            </InputAdornment>
                          ),
                        }}
                        size="small"
                        variant="outlined"
                        className={classes.searchTextfield}
                        style={{
                          margin: '10px 15px 8px',
                          borderTop: '1px solid lightgrey',
                          paddingTop: 17,
                          width: 260,
                        }}
                        value={searchFilterTmp}
                        onChange={(event) => {
                          setSearchFilterTmp(event.currentTarget.value)
                        }}
                      />
                    </Menu>
                  </div>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {FILTER_LIST.filter((f) => filters.indexOf(f) !== -1).map((value) => (
                      <Chip
                        icon={getLevelIcon(value)}
                        onDelete={() => handleFilterDelete(value)}
                        key={value}
                        label={t('view.dialogs.logs.filters.' + value).toLowerCase()}
                        style={{ height: 26, marginRight: 5 }}
                        className={clsx(classes[value], classes[value + '_bkg'])}
                      />
                    ))}
                    {searchFilterActif && (
                      <Chip
                        icon={<SearchIcon />}
                        onDelete={clearSearchFilter}
                        key={'searched'}
                        label={searchFilter}
                        style={{ height: 26, marginRight: 5 }}
                        className={clsx(classes.search, classes.search_bkg)}
                      />
                    )}
                  </Box>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    {/*<Grid item style={{ marginRight: 10, width: 100 }}>*/}
                    <TextField
                      label={t('view.dialogs.logs.limit')}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={limit}
                      onChange={(event) => {
                        updateLimit(event.target.value)
                      }}
                      variant="outlined"
                      size="small"
                      style={{ width: 100 }}
                    />
                    {/*</Grid>*/}
                    {/*<Grid item style={{ marginRight: 10, width: 100 }}>*/}
                    <TextField
                      label={t('view.dialogs.logs.refresh')}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">s</InputAdornment>,
                      }}
                      value={polling}
                      onChange={(event) => {
                        updatePolling(event.target.value)
                      }}
                      variant="outlined"
                      size="small"
                      style={{ width: 100 }}
                    />
                    {/*</Grid>*/}
                    {/*<Grid item style={{ marginRight: 10 }}>*/}
                    <Button
                      style={{
                        height: 40,
                        paddingRight: 0,
                        paddingLeft: 0,
                        width: 50,
                        minWith: 50,
                      }}
                      onClick={() => dispatch(fetchGetLogs(true))}
                      variant="outlined"
                      startIcon={<ReplayIcon />}
                    ></Button>
                    {/*</Grid>*/}
                  </Stack>
                </Stack>
              </Typography>
              <ErrorBoundaryGuard>
                <DataGridPro
                  initialState={{
                    pinnedColumns: {
                      // left: [GRID_TREE_DATA_GROUPING_FIELD],
                      right: ['actions'],
                    },
                    sorting: {
                      sortModel: [
                        {
                          field: 'date',
                          sort: 'desc',
                        },
                      ],
                    },
                    ...JSON.parse(localStorage.getItem('LOG_state')),
                  }}
                  onStateChange={saveSnapshot}
                  getRowClassName={(params) => classes[params.row.level + '_bkg']}
                  getRowHeight={() => 'auto'}
                  sx={{
                    [`& .${gridClasses.cell}`]: {
                      py: 1,
                      borderColor: '#e0e0e0a6',
                      alignItems: 'baseline',
                    },
                    [`& .${gridClasses.cell}:focus`]: {
                      outline: 'none',
                    },
                  }}
                  loading={isFetchingLogs}
                  slots={{
                    // moreActionsIcon: CustomActionIcon,
                    noRowsOverlay: CustomNoRowsOverlay,
                    loadingOverlay: () => (
                      <div
                        style={{ height: 'calc(100% - 232px)', width: '100%', position: 'fixed' }}
                      >
                        <Loading mode="alpha" size={10} />
                      </div>
                    ),
                  }}
                  slotProps={{
                    row: {
                      onContextMenu: handleContextMenu,
                      style: { cursor: 'context-menu' },
                    },
                  }}
                  apiRef={apiRef}
                  // getRowClassName={(params) =>
                  //   params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                  // }
                  // getCellClassName={(params) => {
                  //   return 'customCell'
                  // }}
                  // disableVirtualization={batches.length < 50}
                  rowBuffer={40}
                  rowThreshold={20}
                  columnBuffer={2}
                  columnThreshold={2}
                  // treeData
                  rows={cleanLogs}
                  columns={columns}
                  // getTreeDataPath={(row) => row.hierarchy}
                  // groupingColDef={{
                  //   headerName: (
                  //     <SubdirectoryArrowRightIcon
                  //       style={{ position: 'relative', fontSize: 20, top: 5, color: '#0000008A' }}
                  //     />
                  //   ),
                  //   hideDescendantCount: true,
                  //   valueFormatter: () => '',
                  //   width: 40,
                  // }}
                  pagination={false}
                  hideFooter={true}
                  style={{ border: 'none', height: 'calc(100% - 65px)' /*color: 'white'*/ }}
                  // Do not use autoSize: conflic with initialState
                  // autosizeOnMount
                  // autosizeOptions={autoSizeOptions}
                  disableRowSelectionOnClick
                  // onRowClick={(params, event, details) =>
                  //   onClick(event, params.row, delta, props.history, dispatch)
                  // }
                  // onResize={() => hideWatermark()} //TODO To be deleted after purchasing a license
                />
              </ErrorBoundaryGuard>
            </Stack>
          </div>
          <Stack
            style={{ padding: '5px 10px' }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="caption" display="block">
              <Trans i18nKey="view.dialogs.logs.info">
                {{ val1: cleanLogs.length }} most recent of the {{ val2: totalLogs }}
              </Trans>
            </Typography>
            <Button size={'medium'} color="primary" onClick={closeFct}>
              {t('view.common.jsonView.button')}
            </Button>
          </Stack>
        </div>
      </ResizableBox>
    </Draggable>
  )
})
export default LogsDialog
