import { REQUEST_COD_DRIVE_CONFIGS, RECEIVE_COD_DRIVE_CONFIGS } from './actions'
import React from 'react'

const initialState = {
  codDriveConfig: [],
  isFetchingCodDriveConfig: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case REQUEST_COD_DRIVE_CONFIGS:
      return {
        ...state,
        isFetchingCodDriveConfig: true,
      }

    case RECEIVE_COD_DRIVE_CONFIGS:
      return {
        ...state,
        codDriveConfig: action.codDriveConfig,
        isFetchingCodDriveConfig: false,
      }
    default:
      return state
  }
}
