import { DISPLAY_AUDIT_TRAIL, REQUEST_AUDIT_TRAIL, HIDE_AUDIT_TRAIL } from './actions'
import React from 'react'

const initialState = {
  auditTrail: [],
  fetchingAuditTrail: false,
  display: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case REQUEST_AUDIT_TRAIL:
      return {
        ...state,
        auditTrail: [],
        fetchingAuditTrail: true,
        display: action.refId,
      }

    case DISPLAY_AUDIT_TRAIL:
      return {
        ...state,
        auditTrail: action.auditTrail,
        fetchingAuditTrail: false,
      }

    case HIDE_AUDIT_TRAIL:
      return {
        ...state,
        display: null,
      }
    default:
      return state
  }
}
