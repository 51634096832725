import React, { Fragment, useEffect, useMemo } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  GRID_TREE_DATA_GROUPING_FIELD,
  GridActionsCellItem,
  GridColumnMenu,
  GridMoreVertIcon,
} from '@mui/x-data-grid-pro'
import {
  hideWatermark,
  isLeasePassed,
  isretiredAvailableForUser,
  isSetCalibRefAvailableForUser,
  openInNewTab,
  getHeaderTooltipCmp,
} from '../common/Utils'
import { t } from 'i18next'
import { useGridApiRef } from '@mui/x-data-grid'
import Tooltip from '@mui/material/Tooltip'
import clsx from 'clsx'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import NoteButton from '../common/NoteButton'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import { CustomTooltip, XLSFileInvert } from '../common/Style'
import LoopIcon from '@material-ui/icons/Loop'
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'
import {
  BorderLinearProgress,
  StyledBadge,
  OperatorTooltip,
  StripedDataGridPro,
  getFlagIcon,
  FlagItem,
  onUpdatePriority,
  openBatch,
  openBatchInNewTab,
  callBuildATrainingBatch,
  handleUpdateBatch,
  handleDownloadReport,
  handleStampBatch,
  exploreBatch,
  saveBatch,
  onClick,
  CustomNoRowsOverlay,
  handleRetiredBatch,
  handleSetBatchAsCalibRef,
} from './Tools'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import SchoolIcon from '@material-ui/icons/School'
import ReplayIcon from '@material-ui/icons/Replay'
import ApprovalIcon from '@mui/icons-material/Approval'
import SearchIcon from '@mui/icons-material/Search'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight'
import Loading from '../Loading'
import { displayPopupMessage } from '../common/Message'
import UpdateBatchDialog from '../dialog/UpdateBatchDialog'
import ViewColumnIcon from '@mui/icons-material/ViewColumn'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { Stack } from '@mui/material'
import FaceIcon from '@mui/icons-material/Face'
import FolderDeleteOutlinedIcon from '@mui/icons-material/FolderDeleteOutlined'
import AIScoreTableTooltip from '../common/AIScoreTableTooltip'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { fetchCurrentBatchLease } from '../../redux/batches/actions'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import TerminalIcon from '@mui/icons-material/Terminal'
import ListItemIcon from '@mui/material/ListItemIcon'
import ExpandIcon from '@mui/icons-material/Expand'
import ListItemText from '@mui/material/ListItemText'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import AlphacodFlagIcon from '../common/AlphacodFlagIcon'
import { getFlagName } from './Tools'
import { flagsFilterOperators } from '../common/ColumnFilters'

const useStyles = makeStyles((theme) => ({
  tableCmp: {
    top: 10,
    height: 'calc(100% - 66px)',
    '& .MuiDataGrid-cell': {
      cursor: 'pointer',
    },
  },
  tooltip: {
    fontSize: 14,
    fontWeight: 400,
    padding: 5,
    lineHeight: 1.5,
  },
  trainingButton: {
    width: 24,
    height: 24,
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.main,
      transform: 'scale(1.1)',
      transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
  },
  actionMenu: { justifyContent: 'left', alignItem: 'start' },
  actionDivider: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '0px !important',
    margin: '5px 0px',
  },
  formControlLoading: {
    '& .MuiSelect-select.Mui-disabled': {
      cursor: 'wait !important',
    },
  },
  retired: {
    color: '#bd170b',
  },
  loadingBatchIcon: {
    animation: 'circle 1s linear infinite',
    animationDirection: 'reverse',
    fontSize: '2rem',
  },
}))

const DUE_DATE_TEMPLATE_ERROR = '0000-00-00 00:00:00'

const defaultState = {
  pinnedColumns: {
    // left: [GRID_TREE_DATA_GROUPING_FIELD],
    right: ['actions'],
  },
  sorting: {
    sortModel: [
      {
        field: 'name',
        sort: 'asc',
      },
    ],
  },
}

function StatusInputValue(props) {
  const { item, applyValue } = props

  const handleFilterChange = (event, newValue) => {
    applyValue({
      ...item,
      value:
        newValue?.length !== 0
          ? newValue.indexOf('empty') !== -1
            ? ['empty']
            : newValue
          : undefined,
    })
  }

  const isEmpty = useMemo(() => {
    return item?.value?.indexOf('empty') !== -1 && item?.value?.indexOf('empty') !== undefined
  }, [item.value])

  return (
    <Stack
      style={{ height: 48 }}
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-end"
      spacing={2}
    >
      <ToggleButtonGroup
        variant="text"
        size="small"
        color="primary"
        value={item.value}
        onChange={handleFilterChange}
      >
        <ToggleButton value="calibRef" disabled={isEmpty}>
          <Tooltip
            arrow
            placement={'top'}
            title={<div style={{ fontSize: 13, padding: 5 }}>{t('view.backlog.calibRef')}</div>}
          >
            <BookmarkBorderIcon />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value="stamp" disabled={isEmpty}>
          <Tooltip
            arrow
            placement={'top'}
            title={<div style={{ fontSize: 13, padding: 5 }}>{t('view.backlog.stamped')}</div>}
          >
            <ApprovalIcon />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value="empty">
          <Tooltip
            arrow
            placement={'top'}
            title={<div style={{ fontSize: 13, padding: 5 }}>{t('view.backlog.empty')}</div>}
          >
            <CheckBoxOutlineBlankIcon />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  )
}

const TableCmp = withRouter((props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    style,
    setCurrentUpdateBatch,
    setOpenConfirmUpdateBatch,
    setOpenCreateTrainingBatch,
  } = props
  const apiRef = useGridApiRef()
  const autoSizeOptions = { includeHeaders: true, includeOutliers: true, expand: true }

  const delta = useSelector((state) => state.settings.dateTimeDelta)
  const items = useSelector((state) => state.batches.items)
  const isFetching = useSelector((state) => state.batches.isFetching)
  const wikiCODHelper = useSelector((state) => state.settings.wikiCODHelper)

  const showRetired = localStorage.getItem('SETTINGS_show_retired')
    ? localStorage.getItem('SETTINGS_show_retired') === 'true'
    : false

  const isLoading = function (row) {
    return !isLeasePassed(row.lease.date, delta) && row.lease.reason === 'treatment'
  }

  const batches = useMemo(() => {
    if (items) {
      const itemsPrepared = items.map((item) => ({
        hierarchy: [item._id],
        id: item._id,
        _id: item._id,
        name: item.name,
        path: item.content.folder,
        org_path: item.content.org_path,
        chromatoType: item.content.chromatography_type,
        configuration: item.content.configuration,
        batchDate: item.content.date_ts,
        dueDate:
          item.content.due_date && item.content.due_date !== DUE_DATE_TEMPLATE_ERROR
            ? item.content.due_date
            : null,
        device: item.content.device,
        priority: item.content.priority,
        operator: item.content.operator,
        creationDate: item.creationDate,
        analyseProgression: item.content.analyses_progression_requested
          ? item.content.analyses_progression_requested.toFixed(2)
          : 0,
        calibrationProgression: item.content.calibration_progression_requested
          ? item.content.calibration_progression_requested.toFixed(2)
          : 0,
        content: {
          progression_requested:
            ((item.content.analyses_progression_requested
              ? item.content.analyses_progression_requested
              : 0) +
              (item.content.calibration_progression_requested
                ? item.content.calibration_progression_requested
                : 0)) /
            2,
        },
        progression:
          ((item.content.analyses_progression_requested
            ? item.content.analyses_progression_requested
            : 0) +
            (item.content.calibration_progression_requested
              ? item.content.calibration_progression_requested
              : 0)) /
          2,
        flags: { globals: item.content.flags },
        subBatch:
          item.content.root_batch &&
          item.content.root_batch.id &&
          item.content.root_batch.id !== item._id
            ? item.content.root_batch
            : null,
        notes: item.content.notes ? item.content.notes : [],
        child_notes: item.content.child_notes
          ? item.content.child_notes
          : { calibrations: 0, analysis: 0 },
        lockUpdate:
          (item.content.root_batch && item.content.root_batch.id !== item._id) ||
          item.content.category === 'training',
        newUpdated: item.newUpdated,
        version: item.content.version,
        lease:
          item.content.lease && typeof item.content.lease === 'number'
            ? { date: item.content.lease, operator: null, reason: null }
            : item.content.lease &&
              item.content.lease !== null &&
              item.content.lease !== undefined &&
              typeof item.content.lease === 'object'
            ? item.content.lease
            : { date: 0, operator: null, reason: null },
        stamp: item.content.stamp,
        retired: item.content.retired,
        teams: item.teams,
        calibRef: item?.tags.indexOf('calibration_reference') !== -1,
      }))
      const batchesPrepared = itemsPrepared.filter((item) => !item.subBatch)
      // const newROws = stableSort(batchesPrepared, getComparator(sortModel.sort, sortModel.field))
      // return newROws
      return batchesPrepared
    } else {
      return []
    }
  }, [items])

  function autosizingColumns() {
    apiRef.current.autosizeColumns(autoSizeOptions)
  }

  function resetColumns() {
    const cols = columns.map((col) => col.field)
    const dim = apiRef.current.exportState().columns.dimensions
    const colNames = Object.keys(dim)
    colNames.forEach((col) => (dim[col].width = 90))

    apiRef.current.setFilterModel({
      items: [],
      logicOperator: 'and',
      quickFilterLogicOperator: 'and',
      quickFilterValues: [],
    })
    apiRef.current.restoreState({
      ...defaultState,
      columns: {
        dimensions: { ...dim, actions: { maxWidth: 50, minWidth: 50, width: 50 } },
        orderedFields: ['__tree_data_group__', cols].flat(),
        columnVisibilityModel: {},
      },
    })
  }

  const columns = useMemo(() => {
    const columnArray = [
      {
        field: 'flags',
        headerName: t('view.backlog.header_names.flags'),
        renderCell: ({ row }) => <FlagItem row={row} isLoading={isLoading(row)} />,
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        valueFormatter: (params) => {
          return getFlagName(params.api.getRow(params.id))
        },
        filterOperators: flagsFilterOperators,
        valueGetter: ({ value }) => {
          switch (getFlagIcon(value.globals.filter((f) => !f.bypass_backlog))) {
            case 'info':
              return 0
            case 'action':
              return 1
            case 'warning':
              return 2
            case 'error':
              return 3
            default:
              return -1
          }
        },
      },
      {
        field: 'name',
        headerName: t('view.backlog.header_names.name'),
        renderCell: ({ value, row }) =>
          row.newUpdated ? (
            <StyledBadge color="secondary" badgeContent=" " variant="dot">
              <span className={row.retired ? classes.retired : ''}>{value}</span>
            </StyledBadge>
          ) : (
            <span className={row.retired ? classes.retired : ''}>{value}</span>
          ),
        // valueFormatter: ({ value }) => value,
      },
    ]
    if (showRetired) {
      columnArray.push({
        field: 'retired',
        headerName: t('view.backlog.header_names.retired'),
        renderCell: ({ value }) =>
          value ? <FolderDeleteOutlinedIcon className={classes.retired} /> : <span></span>,
      })
    }
    columnArray.push([
      {
        field: 'status',
        headerName: t('view.backlog.header_names.status'),
        valueGetter: ({ row }) => {
          let res = []
          if (row.calibRef) res.push('calibRef')
          if (row.stamp) res.push('stamp')
          return res
        },
        sortComparator: (v1, v2) =>
          (v1.indexOf('calibRef') !== -1 ? 1 : 0) +
          (v1.indexOf('stamp') !== -1 ? 2 : 0) -
          ((v2.indexOf('calibRef') !== -1 ? 1 : 0) + (v2.indexOf('stamp') !== -1 ? 2 : 0)),
        renderCell: function ({ value, row }) {
          const calibRef = row.calibRef ? (
            <Tooltip
              disableInteractive
              placement={'top'}
              title={<div className={classes.tooltip}>{t('view.backlog.calibRef')}</div>}
              arrow
            >
              <BookmarkBorderIcon style={{ color: '#0000008a' }} />
            </Tooltip>
          ) : null
          const stamp = row.stamp ? (
            <Tooltip
              disableInteractive
              placement={'top'}
              title={
                <div className={classes.tooltip}>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                  >
                    <AccessTimeIcon style={{ width: '0.7em', marginRight: 10 }} />
                    {row?.stamp?.date ? new Date((value - delta) * 1000).toLocaleString() : '--'}
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                  >
                    <FaceIcon style={{ width: '0.7em', marginRight: 10 }} />
                    {row?.stamp?.operator?.name ? row.stamp.operator?.name : '--'}
                  </Stack>
                </div>
              }
              arrow
            >
              <ApprovalIcon style={{ color: '#0000008a' }} />
            </Tooltip>
          ) : null
          return (
            <span style={{ width: '100%' }}>
              <div style={{ width: 30, display: 'inline-block' }}>{stamp}</div>
              <div style={{ width: 30, display: 'inline-block' }}>{calibRef}</div>
            </span>
          )
        },
        filterOperators: [
          {
            label: 'is',
            value: 'is',
            getApplyFilterFn: (filterItem) => {
              if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null
              }
              return (params) => {
                if (filterItem.value.length === 1 && filterItem.value[0] === 'empty') {
                  return params.value.length === 0
                }
                return (
                  filterItem.value.length === params.value.length &&
                  filterItem.value.every((value, index) => value === params.value[index])
                )
              }
            },
            InputComponent: StatusInputValue,
          },
          {
            label: 'not',
            value: 'not',
            getApplyFilterFn: (filterItem) => {
              if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null
              }
              return (params) => {
                if (filterItem.value.length === 1 && filterItem.value[0] === 'empty') {
                  return params.value.length !== 0
                }
                return (
                  filterItem.value.length !== params.value.length ||
                  !filterItem.value.every((value, index) => value === params.value[index])
                )
              }
            },
            InputComponent: StatusInputValue,
          },
          {
            label: 'contains (or)',
            value: 'contains',
            getApplyFilterFn: (filterItem) => {
              if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null
              }
              return (params) => {
                if (filterItem.value.length === 1 && filterItem.value[0] === 'empty') {
                  return params.value.length === 0
                }
                return filterItem.value.some((value) => params.value.includes(value))
              }
            },
            InputComponent: StatusInputValue,
          },
          {
            label: 'not contains (or)',
            value: 'not_contains',
            getApplyFilterFn: (filterItem) => {
              if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null
              }
              return (params) => {
                if (filterItem.value.length === 1 && filterItem.value[0] === 'empty') {
                  return params.value.length !== 0
                }

                return !filterItem.value.some((value) => params.value.includes(value))
              }
            },
            InputComponent: StatusInputValue,
          },
        ],
      },
      {
        field: 'version',
        headerName: t('view.backlog.header_names.version'),
      },
      {
        field: 'configuration',
        headerName: t('view.backlog.header_names.configuration'),
      },
      {
        field: 'notes',
        type: 'number',
        headerName: t('view.backlog.header_names.note'),
        renderCell: ({ row }) => (
          <ErrorBoundaryGuard isDialog>
            <NoteButton row={row} collection={'batch'} isLoading={isLoading(row)} />
          </ErrorBoundaryGuard>
        ),
        valueGetter: ({ value }) => value.length,
      },
      {
        field: 'path',
        headerName: t('view.backlog.header_names.path'),
        renderCell: ({ value }) => (
          <Tooltip
            disableInteractive
            placement={'top'}
            title={<div className={classes.tooltip}>{value}</div>}
            arrow
          >
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                direction: 'rtl',
                textAlign: 'left',
              }}
            >
              {value.charAt(0) === '/' ? value.substring(1) + '/' : value}
            </div>
          </Tooltip>
        ),
      },
      {
        field: 'batchDate',
        type: 'dateTime',
        valueFormatter: ({ value }) =>
          value &&
          new Intl.DateTimeFormat('en-GB', {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
          }).format(new Date(value * 1000)),
        headerName: t('view.backlog.header_names.batch_date'),
      },
      {
        field: 'dueDate',
        type: 'dateTime',
        valueFormatter: ({ value }) =>
          value &&
          new Intl.DateTimeFormat('en-GB', {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
          }).format(new Date(value)),
        headerName: t('view.backlog.header_names.due_date'),
      },
      {
        field: 'device',
        headerName: t('view.backlog.header_names.device'),
      },
      {
        field: 'priority',
        headerName: t('view.backlog.header_names.priority'),
        renderCell: ({ value, row }) => (
          <FormControl
            className={clsx(classes.formControl, isLoading(row) ? classes.formControlLoading : '')}
          >
            <Select
              value={value}
              name={'priority'}
              onChange={(event) => onUpdatePriority(event, row, dispatch)}
              displayEmpty
              autoWidth
              disabled={isLoading(row)}
              inputProps={{ 'aria-label': 'Priority' }}
            >
              <MenuItem key={'normal'} value={'Normal'}>
                {t('view.backlog.normal')}
              </MenuItem>
              <MenuItem key={'urgent'} value={'Urgent'}>
                {t('view.backlog.urgent')}
              </MenuItem>
            </Select>
          </FormControl>
        ),
      },
      {
        field: 'operator',
        headerName: t('view.backlog.header_names.last'),
        renderCell: ({ value, row }) => (
          <Tooltip
            disableInteractive
            placement={'left'}
            title={
              !isLoading(row)
                ? value &&
                  value.length > 0 &&
                  value[value.length - 1].date && <OperatorTooltip row={row} />
                : ''
            }
            arrow
          >
            <span>{value && value.length > 0 ? value[value.length - 1].name : ''}</span>
          </Tooltip>
        ),
      },
      {
        field: 'progression',
        type: 'number',
        headerName: t('view.backlog.header_names.progress'),
        renderCell: ({ value, row }) => (
          <Tooltip
            disableInteractive
            title={
              !isLoading(row) ? (
                <div className={classes.tooltip}>
                  <div>{`${t('view.backlog.calib')}: ${row.calibrationProgression}%`}</div>
                  <div>{`${t('view.backlog.analysis')}: ${row.analyseProgression}%`}</div>
                </div>
              ) : (
                ''
              )
            }
            arrow
          >
            <BorderLinearProgress variant="determinate" value={value} />
          </Tooltip>
        ),
      },
      {
        field: 'actions',
        type: 'actions',

        hideable: false,
        resizable: false,
        sortable: false,
        filterable: false,
        disableColumnMenu: false,
        disableReorder: true,
        disableExport: true,
        pinnable: false,

        width: 50,
        minWidth: 50,
        maxWidth: 50,
        getActions: ({ row }) =>
          isLoading(row)
            ? [
                <GridActionsCellItem
                  icon={
                    <CustomTooltip
                      disableInteractive
                      placement="top"
                      arrow
                      title={
                        isLoading(row) ? (
                          <span>
                            {t('view.panel.batchesCmp.tooltip.loading')}
                            <div>
                              {t('view.panel.batchesCmp.tooltip.operator')}: {row.lease.operator}
                            </div>
                            <div>
                              {t('view.panel.batchesCmp.tooltip.reason')}: {row.lease.reason}
                            </div>
                          </span>
                        ) : (
                          t('view.panel.batchesCmp.tooltip.other')
                        )
                      }
                    >
                      <LoopIcon className={classes.loadingBatchIcon} />
                    </CustomTooltip>
                  }
                  label="Loading"
                />,
              ]
            : [
                // <GridActionsCellItem
                //   className={classes.actionMenu}
                //   showInMenu
                //   icon={<ViewColumnIcon />}
                //   label={t('view.backlog.menu.align')}
                //   onClick={autosizingColumns}
                // />,
                // <div disabled={true} className={classes.actionDivider} showInMenu label={''} />,
                <GridActionsCellItem
                  className={classes.actionMenu}
                  showInMenu
                  icon={<OpenInBrowserIcon />}
                  label={t('view.panel.batchesCmp.actions.open')}
                  onClick={() => openBatch(row, props.history)}
                />,
                <GridActionsCellItem
                  className={classes.actionMenu}
                  showInMenu
                  icon={<OpenInNewIcon />}
                  label={t('view.panel.batchesCmp.actions.openNew')}
                  onClick={() => openBatchInNewTab(row)}
                />,
                <div disabled={true} className={classes.actionDivider} showInMenu label={''} />,
                <GridActionsCellItem
                  disabled={
                    (!isLeasePassed(row.lease.date, delta) && row.lease.reason === 'treatment') ||
                    (row ? row.lockUpdate : true)
                  }
                  className={classes.actionMenu}
                  showInMenu
                  icon={<SchoolIcon />}
                  label={t('view.backlog.menu.training')}
                  onClick={(event) =>
                    callBuildATrainingBatch(
                      event,
                      row,
                      dispatch,
                      // setOpenCreateTrainingBatch,
                      // setCurrentUpdateBatch,
                    )
                  }
                />,
                <GridActionsCellItem
                  disabled={
                    (!isLeasePassed(row.lease.date, delta) && row.lease.reason === 'treatment') ||
                    (row ? row.lockUpdate : true)
                  }
                  onClick={(event) => {
                    handleUpdateBatch(
                      event,
                      row,
                      dispatch /*setOpenConfirmUpdateBatch, setCurrentUpdateBatch*/,
                    )
                  }}
                  className={classes.actionMenu}
                  showInMenu
                  icon={
                    !isLeasePassed(row.lease.date, delta) && row.lease.reason === 'treatment' ? (
                      <LoopIcon className={classes.loadingBatchIcon} />
                    ) : (
                      <ReplayIcon />
                    )
                  }
                  label={t('view.backlog.menu.replace')}
                />,
                <GridActionsCellItem
                  disabled={
                    (!isLeasePassed(row.lease.date, delta) && row.lease.reason === 'treatment') ||
                    (row ? row.lockUpdate : true)
                  }
                  onClick={(event) => {
                    handleDownloadReport(event, row, dispatch)
                  }}
                  className={classes.actionMenu}
                  showInMenu
                  icon={
                    <XLSFileInvert
                      style={{ width: 26, height: 21 }}
                      className={classes.colorBlue}
                    />
                  }
                  label={t('view.panel.batchesCmp.actions.download')}
                />,
                <div disabled={true} className={classes.actionDivider} showInMenu label={''} />,
                <GridActionsCellItem
                  onClick={(event) => {
                    handleStampBatch(event, row, dispatch)
                  }}
                  className={classes.actionMenu}
                  showInMenu
                  icon={<ApprovalIcon />}
                  label={t('view.backlog.menu.stamp')}
                />,
                isretiredAvailableForUser(row) ? (
                  <GridActionsCellItem
                    disabled={row.retired}
                    onClick={(event) => {
                      handleRetiredBatch(event, row, dispatch)
                    }}
                    className={classes.actionMenu}
                    showInMenu
                    icon={<FolderDeleteOutlinedIcon />}
                    label={t('view.backlog.menu.retired')}
                  />
                ) : (
                  <Fragment />
                ),
                isSetCalibRefAvailableForUser(row) ? (
                  <GridActionsCellItem
                    disabled={row.retired}
                    onClick={(event) => {
                      handleSetBatchAsCalibRef(event, row, dispatch)
                    }}
                    className={classes.actionMenu}
                    showInMenu
                    icon={<BookmarkBorderIcon />}
                    label={
                      row.calibRef
                        ? t('view.backlog.menu.removeCalibRef')
                        : t('view.backlog.menu.setCalibRef')
                    }
                  />
                ) : (
                  <Fragment />
                ),
                <div disabled={true} className={classes.actionDivider} showInMenu label={''} />,
                <GridActionsCellItem
                  onClick={() => {
                    openInNewTab(
                      `${window.location.origin}${window.location.pathname}?logs/batch/${row._id}`,
                    )
                  }}
                  className={classes.actionMenu}
                  showInMenu
                  icon={<TerminalIcon />}
                  label={t('view.panel.batchesCmp.actions.logs')}
                />,
                <GridActionsCellItem
                  onClick={() => {
                    exploreBatch(row, dispatch)
                  }}
                  className={classes.actionMenu}
                  showInMenu
                  icon={<SearchIcon />}
                  label={t('view.panel.batchesCmp.actions.explore')}
                />,
                <GridActionsCellItem
                  onClick={() => {
                    saveBatch(row)
                  }}
                  className={classes.actionMenu}
                  showInMenu
                  icon={<SaveAltIcon />}
                  label={t('view.panel.batchesCmp.actions.save')}
                />,
              ],
      },
    ])
    return columnArray.flat()
  }, [])

  // useEffect(() => {
  //   if (batches.length >= 100) {
  //     displayPopupMessage(
  //       dispatch,
  //       'info',
  //       t('view.backlog.info_date.title'),
  //       <Fragment>
  //         <div>{t('view.backlog.info_date.msg1')}</div>
  //         <div>{t('view.backlog.info_date.msg2')}</div>
  //       </Fragment>,
  //     )
  //   }
  // }, [batches])

  function CustomActionIcon() {
    return (
      <CustomTooltip
        disableInteractive
        placement="top"
        arrow
        title={t('view.panel.batchesCmp.tooltip.other')}
      >
        <GridMoreVertIcon />
      </CustomTooltip>
    )
  }
  function CustomLoading() {
    return (
      <div style={{ height: 'calc(100% - 96px)', position: 'relative' }}>
        <Loading mode="alpha" size={10} />
      </div>
    )
  }
  const saveSnapshot = React.useCallback(() => {
    const stateSnapshot = apiRef.current.exportState()
    if (Object.keys(stateSnapshot).length !== 0) {
      localStorage.setItem('BACKLOG_state', JSON.stringify(stateSnapshot))
    }
  }, [apiRef])

  function CustomAdaptSize(props) {
    const { myCustomLabel, myCustomHandler } = props
    return (
      <MenuItem style={{ padding: 2, opacity: 1 }} onClick={myCustomHandler}>
        <ListItemIcon style={{ minWidth: 36 }}>
          <ExpandIcon fontSize="small" style={{ rotate: '90deg' }} />
        </ListItemIcon>
        <ListItemText>{myCustomLabel}</ListItemText>
      </MenuItem>
    )
  }

  function CustomResetProps(props) {
    const { myCustomLabel, myCustomHandler } = props
    return (
      <MenuItem style={{ padding: 2, opacity: 1 }} onClick={myCustomHandler}>
        <ListItemIcon style={{ minWidth: 36 }}>
          <RestartAltIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{myCustomLabel}</ListItemText>
      </MenuItem>
    )
  }

  function CustomColumnMenu(props) {
    return (
      <GridColumnMenu
        {...props}
        slots={{
          columnMenuAdaptSize: props.colDef.type === 'actions' ? CustomAdaptSize : null,
          columnMenuResetProps: props.colDef.type === 'actions' ? CustomResetProps : null,
        }}
        slotProps={{
          columnMenuAdaptSize: {
            field: props.colDef.field,
            displayOrder: 10,
            myCustomLabel: t('view.backlog.menu.align'),
            myCustomHandler: autosizingColumns,
          },
          columnMenuResetProps: {
            field: props.colDef.field,
            displayOrder: 10,
            myCustomLabel: t('view.backlog.menu.reset'),
            myCustomHandler: resetColumns,
          },
        }}
      />
    )
  }

  return (
    <div className={classes.tableCmp}>
      <ErrorBoundaryGuard>
        <StripedDataGridPro
          initialState={{
            ...defaultState,
            ...JSON.parse(localStorage.getItem('BACKLOG_state')),
          }}
          onStateChange={saveSnapshot}
          rowHeight={64}
          loading={isFetching}
          slots={{
            moreActionsIcon: CustomActionIcon,
            noRowsOverlay: CustomNoRowsOverlay,
            noResultsOverlay: CustomNoRowsOverlay,
            loadingOverlay: CustomLoading,
            columnMenu: CustomColumnMenu,
          }}
          apiRef={apiRef}
          getRowClassName={(params) => {
            let classesAdded = params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            if (isLoading(params.row)) {
              classesAdded += ' loading'
            }
            return classesAdded
          }}
          getCellClassName={(params) => {
            return 'customCell'
          }}
          disableVirtualization={batches.length < 50}
          rowBuffer={3}
          rowThreshold={3}
          columnBuffer={3}
          columnThreshold={3}
          treeData
          rows={batches}
          columns={columns}
          getTreeDataPath={(row) => row.hierarchy}
          groupingColDef={{
            headerName: (
              <SubdirectoryArrowRightIcon
                style={{ position: 'relative', fontSize: 20, top: 5, color: '#0000008A' }}
              />
            ),
            hideDescendantCount: true,
            valueFormatter: () => '',
            width: 40,
            maxWidth: 40,
            minWidth: 40,
          }}
          pagination={false}
          hideFooter={true}
          style={{ border: 'none' }}
          // Do not use autoSize: conflic with initialState
          // autosizeOnMount
          // autosizeOptions={autoSizeOptions}
          disableRowSelectionOnClick
          onRowClick={(params, event, details) =>
            onClick(event, params.row, delta, props.history, dispatch)
          }
          // onResize={() => hideWatermark()} //TODO To be deleted after purchasing a license
        />
      </ErrorBoundaryGuard>
    </div>
  )
})
export default TableCmp
