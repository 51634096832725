import { REQUEST_TEAMS, RECEIVE_TEAMS } from './actions'
import React from 'react'

const initialState = {
  teams: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case REQUEST_TEAMS:
      return {
        ...state,
      }
    case RECEIVE_TEAMS:
      return {
        ...state,
        teams: action.teams,
      }
    default:
      return state
  }
}
