import React, { Fragment, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Trans, useTranslation } from 'react-i18next'
import { getBatchById } from '../../redux/batches/selectors'
import Loading from '../Loading'
import TableMultiGrid from '../common/Table_MultiGrid'
import {
  fetchGlobalUA,
  fetchGlobalUASelected,
  sortUnitaryCompounds,
} from '../../redux/unitaryAnalyses/actions'
import clsx from 'clsx'
import {
  convertMassConcentrationValue,
  convertVolumeConcentrationValue,
  customFormatDecimals,
  displayHumanReadable,
  getClickAction,
  getComparator,
  handleValidation,
  stableSort,
} from '../common/Utils'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import NoteButton from '../common/NoteButton'
import AlphacodModifiedButtonIcon from '../common/AlphacodModifiedButtonIcon'
import IconButton from '@material-ui/core/IconButton'
import CheckIcon from '@material-ui/icons/Check'
import { Molecule, Nothing, Relance } from '../common/Style'
import ClearIcon from '@material-ui/icons/Clear'
import AlphacodFlagIcon from '../common/AlphacodFlagIcon'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import i18n, { t } from 'i18next'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import PivotTableChartIcon from '@mui/icons-material/PivotTableChart'
import Title from '../Title'
import InputLabel from '@material-ui/core/InputLabel'
import CloseIcon from '@material-ui/icons/Close'
import { fetchChangeSettings } from '../../redux/settings/actions'
import PersonPinIcon from '@material-ui/icons/PersonPin'
import MetaboliteBadge from '../common/MetaboliteBadge'
import AIScoreTableTooltip from '../common/AIScoreTableTooltip'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Checkbox from '@material-ui/core/Checkbox'
import { palette } from '@material-ui/system'
import { alpha } from '@material-ui/core'
import SettingsIcon from '@mui/icons-material/Settings'
import Divider from '@material-ui/core/Divider'
import Stack from '@mui/material/Stack'
import { withStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    height: 'calc(100% - 60px)',
    // overflow: 'hidden',
    // '&:hover': {
    //   overflow: 'auto',
    // },
    '& .ReactVirtualized__Grid:focus': {
      outline: 'none',
    },
  },
  actionContainer: {
    '& button': {
      padding: 0,
      marginRight: 5,
      width: 24,
    },
  },
  selected: {
    color: theme.palette.primary.main,
  },
  notSelected: {
    opacity: 0.5,
    '&:hover': {
      opacity: 1,
    },
  },
  columnSizetextfield: {
    '& > div > input': {
      width: 40,
    },
    '& input': {
      padding: 8,
      background: 'white',
    },
    '& .MuiOutlinedInput-root': {
      height: 40,
    },
  },
  columnDecimals: {
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: 8,
    },
  },
  cellScore: {
    // padding: '2px 10px',
    borderRadius: 4,
  },
  lowerColor: {
    // background: alpha(theme.palette.error.main, 0.15),
    color: theme.palette.error.main,
  },
  midColor: {
    // background: alpha(theme.palette.warning.main, 0.15),
    color: theme.palette.warning.main,
  },
  heighColor: {
    // background: alpha(theme.palette.success.main, 0.15),
    color: theme.palette.success.main,
  },
  colorPickerCnt: {
    border: '1px solid #c4c4c4',
    width: 'calc(50% - 5px)',
    height: 40,
    display: 'inline-flex',
    borderRadius: 4,
  },
  colorPickerTitle: {
    background:
      'linear-gradient(0deg, rgb(255 255 255) 56%, rgba(255,255,255,0) 56%, rgba(255,255,255,0) 100%)',
    position: 'absolute',
    color: 'rgba(0, 0, 0, 0.6)',
    padding: '0px 7px',
    fontSize: '0.75rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.00938em',
    transform: 'translate(7px, -8px)',
  },
  colorRangeInput: {
    '& input': {
      paddingBottom: 2,
      paddingTop: 10,
    },
  },
}))
const Table = withRouter((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const tableMultiGridRef = useRef(null)
  const parentMultiGridRef = React.createRef()
  const idTableConst = 'myGlobalTable'

  const batch = useSelector((state) => getBatchById(state.batches, props.match.params.batchId))
  const globals = useSelector((state) => state.unitaryAnalyses.globalUnitaryAnalyses)
  const analyses = useSelector((state) => state.analyses.items.map((analysis) => analysis.name))
  const isFetchingGlobal = useSelector(
    (state) => state.unitaryAnalyses.isFetchingGlobalUnitaryAnalyses,
  )
  const isGlobalConfigsLoaded = useSelector((state) => state.global.isGlobalConfigsLoaded)
  const customerConfig = useSelector((state) => state.global.customerConfig)
  const currentLanguage = i18n.language.slice(0, 2)

  const [tableMode, setTableMode] = React.useState(
    localStorage.getItem('GLOBAL_table_mode')
      ? localStorage.getItem('GLOBAL_table_mode')
      : 'unitary_compound',
  )
  const [criteria, setCriteria] = React.useState(
    localStorage.getItem('GLOBAL_table_criteria')
      ? localStorage.getItem('GLOBAL_table_criteria')
      : 'content.global_score',
  )
  const [selectedChannel, setSelectedChannel] = React.useState(
    localStorage.getItem('GLOBAL_table_channel')
      ? localStorage.getItem('GLOBAL_table_channel')
      : 'main',
  )
  const [selectedFormat, setSelectedFormat] = React.useState(
    localStorage.getItem('GLOBAL_table_format')
      ? localStorage.getItem('GLOBAL_table_format')
      : 'auto',
  )
  const [selectedDecimal, setSelectedDecimal] = React.useState(
    localStorage.getItem('GLOBAL_table_decimal')
      ? Number(localStorage.getItem('GLOBAL_table_decimal'))
      : 3,
  )
  const [tableItemSelectedForColor, setTableItemSelectedForColor] = React.useState({
    row: null,
    column: null,
  })
  const [order, setOrder] = React.useState(
    localStorage.getItem('GLOBAL_sorter')
      ? localStorage.getItem('GLOBAL_sorter').split('$')[1]
      : 'name',
  )
  const [direction, setDirection] = React.useState(
    localStorage.getItem('GLOBAL_sorter')
      ? localStorage.getItem('GLOBAL_sorter').split('$')[0]
      : 'asc',
  )
  const [rangesColor, setRangesColor] = React.useState(
    localStorage.getItem('GLOBAL_ranges_color')
      ? localStorage.getItem('GLOBAL_ranges_color')
      : [
          { color: '#f44336', value: 10 },
          { color: '#ff9800', value: 90 },
          { color: '#4caf50', value: 1000 },
        ],
  )
  const [colorChecked, setColorChecked] = React.useState(
    localStorage.getItem('GLOBAL_table_color')
      ? localStorage.getItem('GLOBAL_table_color') === 'true'
      : true,
  )

  const optiGlobals = useMemo(() => {
    const lookupTable = {}
    for (const key in globals) {
      const item = globals[key]
      const keyName = `${item.name}_${item.analysisName}`
      lookupTable[keyName] = item.value
    }
    return lookupTable
  }, [globals])

  const optiGlobalsRaw = useMemo(() => {
    const lookupTable = {}
    for (const key in globals) {
      const item = globals[key]
      const keyName =
        tableMode === 'unitary_analysis'
          ? `${item.analysisName}_${item.name}`
          : `${item.name}_${item.analysisName}`
      lookupTable[keyName] = item.raw
    }
    return lookupTable
  }, [globals, tableMode])

  const configFeatures = useSelector((state) => state.configuration.configFeatures)
  const isFetchingConfigFeatures = useSelector(
    (state) => state.configuration.isFetchingConfigFeatures,
  )
  let settingsTimer = null

  const getGlobalScoreTitle = function () {
    if (
      isGlobalConfigsLoaded &&
      customerConfig &&
      customerConfig.content.hasOwnProperty('columnIA') &&
      currentLanguage &&
      customerConfig.content.columnIA.hasOwnProperty(currentLanguage)
    ) {
      return customerConfig.content.columnIA[currentLanguage]
    } else {
      return t('view.analysis.analysis.column.ai_score')
    }
  }

  const sortList = (a, b) => {
    if (a.toLowerCase() < b.toLowerCase()) {
      return -1
    }
    if (a.toLowerCase() > b.toLowerCase()) {
      return 1
    }
    return 0
  } //.sort(sortByName)

  // const criteriaList = [
  //   {
  //     name: 'Name',
  //     path: 'name',
  //   },
  //   {
  //     name: t('view.compound.compound.column.ai_score'),
  //     path: 'content.global_score',
  //     render: (val) => displayHumanReadable(val * 100, 2, 2, true) + '%',
  //     type: 'number',
  //   },
  // ]

  function applyFormatDecimals(rowValue, autoValue, columnInfos) {
    const decimal = localStorage.getItem('GLOBAL_table_decimal')
      ? Number(localStorage.getItem('GLOBAL_table_decimal'))
      : 3
    const format = localStorage.getItem('GLOBAL_table_format')
      ? localStorage.getItem('GLOBAL_table_format')
      : 'auto'
    let tmpVal = rowValue
    if (format === 'auto') {
      tmpVal = autoValue
    } else if (format === 'sci') {
      if (decimal !== -1) {
        tmpVal = tmpVal.toExponential(decimal)
      } else {
        tmpVal = tmpVal.toExponential()
      }
    } else if (typeof decimal === 'number' && decimal !== -1) {
      tmpVal = tmpVal.toFixed(decimal)
    } else {
      // Default format if no settings was present in localStorage
      tmpVal = autoValue ? autoValue : tmpVal.toFixed(3)
    }
    return tmpVal
  }

  const getListItems = function () {
    const massConcentrationUnit = localStorage.getItem('SETTINGS_mass_concentration_unit')
      ? localStorage.getItem('SETTINGS_mass_concentration_unit')
      : 'mg/kg'
    const volumeConcentrationUnit = localStorage.getItem('SETTINGS_volume_concentration_unit')
      ? localStorage.getItem('SETTINGS_volume_concentration_unit')
      : 'µg/l'
    const initList = [
      // {
      //   key: 'flags',
      //   name: t('view.analysis.analysis.column.flags'),
      //   path:
      //     'content.flags[&]content.channels.flags[&]content.main_channel[&]content.classification',
      //   type: 'custom',
      //   render: function (value) {
      //     return (
      //       <ErrorBoundaryGuard>
      //         <AlphacodFlagIcon
      //           item={{
      //             mainChannel: value.mainChannel,
      //             flags: {
      //               globals: value.flags
      //                 ? value.flags.sort(function (a, b) {
      //                     if (a.title < b.title) return -1
      //                     if (a.title > b.title) return 1
      //                     return 0
      //                   })
      //                 : value.flags,
      //               channels: {},
      //             },
      //             classification: value.classification,
      //           }}
      //           hideGeneralTab={false}
      //           collection={'analyse'}
      //         />
      //       </ErrorBoundaryGuard>
      //     )
      //   },
      // },
      {
        key: 'requested',
        path: 'content.requested',
        name: t('view.analysis.analysis.column.requested'),
        type: 'boolean',
        render: function (value) {
          return value ? <PersonPinIcon title="Requested" className={classes.requestedIcon} /> : ''
        },
      },
      {
        key: 'modified',
        name: t('view.analysis.analysis.column.modif'),
        path: '_id[&]creationDate[&]modificationDate',
        type: 'boolean',
        render: function (value, channel, optiGlobalsRaw, name, analysisName) {
          const row = findRawByNameAndAnalysisName(optiGlobalsRaw, name, analysisName)
          return (
            <ErrorBoundaryGuard>
              <AlphacodModifiedButtonIcon item={{ _id: row._id, modified: value }} />
            </ErrorBoundaryGuard>
          )
        },
      },
      {
        key: 'event',
        path: 'content.molecule.event',
        name: t('view.analysis.analysis.column.event'),
        type: 'number',
      },
      {
        key: 'peakName',
        path: 'content.molecule',
        name: t('view.analysis.analysis.column.peak'),
        type: 'text',
        render: function (value) {
          return (
            <ErrorBoundaryGuard>
              <MetaboliteBadge molecule={value} />
            </ErrorBoundaryGuard>
          )
        },
      },
      {
        key: 'status',
        type: 'text',
        path: 'content.classification',
        name: t('view.analysis.analysis.column.status'),
      },
      {
        key: 'global_score',
        path: 'content.global_score',
        name: getGlobalScoreTitle(),
        type: 'number',
        color: true,
        render: function (value) {
          const colorMode = localStorage.getItem('GLOBAL_table_color')
            ? localStorage.getItem('GLOBAL_table_color') === 'true'
            : true
          const result =
            value && value !== '' && value !== null && typeof value === 'number'
              ? applyFormatDecimals(
                  value * 100,
                  displayHumanReadable(value * 100, 2, 2, true),
                  'global_score',
                ) + '%'
              : null
          return colorMode ? (
            <div
              className={clsx(
                classes.cellScore,
                value < 0.1
                  ? classes.lowerColor
                  : value < 0.9
                  ? classes.midColor
                  : classes.heighColor,
              )}
            >
              {result}
            </div>
          ) : (
            result
          )
        },
      },
      {
        key: 'validation',
        path: 'content.validation[&]content.classification',
        name: t('view.analysis.analysis.column.validation'),
        type: 'custom',
        render: function (value, channel, optiGlobalsRaw, name, analysisName) {
          const row = findRawByNameAndAnalysisName(optiGlobalsRaw, name, analysisName)
          return (
            <div className={classes.actionContainer}>
              {row.content.validation === '1' &&
                (row.content.classification === 'ok' || row.content.classification === 'ko' ? (
                  <CheckIcon className={classes.selected} />
                ) : (
                  <Molecule className={classes.selected} />
                ))}
              {row.content.validation === '2' &&
                (row.content.classification === 'ok' || row.content.classification === 'ko' ? (
                  <ClearIcon className={classes.selected} />
                ) : (
                  <Nothing className={classes.selected} />
                ))}
              {row.content.validation === '3' && <Relance className={classes.selected} />}
            </div>
          )
        },
      },
    ]

    if (configFeatures && typeof configFeatures.lmr === 'boolean' && configFeatures.lmr) {
      initList.push({
        key: 'lmr',
        name: t('view.analysis.analysis.column.lmr'),
        type: 'text',
        path: 'content.molecule.eu_lmr',
        // render: function (value) {
        //   return value
        // },
      })
    }

    initList.push(
      ...[
        {
          key: 'rt',
          name: t('view.analysis.analysis.column.rt_cal'),
          channel: true,
          type: 'number',
          path: 'content.main_channel[&]content.channels.[CHANNEL].rt_calib',
          render: function (value) {
            if (value && value !== '' && value !== null && typeof value === 'number') {
              return applyFormatDecimals(value, value.toFixed(1), 'rt')
            }
            return null
          },
        },
        {
          key: 'dev_ion_ratio',
          name: t('view.analysis.analysis.column.dev_ion_ratio'),
          type: 'number',
          path: 'content.main_dev_ion_ratio',
          render: function (value) {
            if (value && value !== '' && value !== null && typeof value === 'number') {
              return (
                applyFormatDecimals(
                  value,
                  displayHumanReadable(value, 2, 2, true),
                  'dev_ion_ratio',
                ) + '%'
              )
            }
            return null
          },
        },
        {
          key: 'ion_ratio',
          name: t('view.analysis.analysis.column.ion_ratio'),
          path: 'content.ion_ratio[&]content.main_ion_ratio_key',
          type: 'number',
          render: function (result) {
            let value = result
            if (typeof result === 'object') {
              value = result.content.value
            }
            if (value && value !== '' && value !== null && typeof value === 'number') {
              return (
                applyFormatDecimals(
                  value,
                  displayHumanReadable(value, 2, 2, true),
                  'dev_ion_ratio',
                ) + '%'
              )
            }
            return null
          },
        },
        {
          key: 'mass_conc',
          channel: true,
          name: (
            <Trans i18nKey="view.analysis.analysis.column.conc">
              Conc({{ val: massConcentrationUnit }})
            </Trans>
          ),
          path: 'content.main_channel[&]content.channels.[CHANNEL].concentration_mass',
          type: 'number',
          render: function (value) {
            if (value && value !== '' && value !== null && typeof value === 'number') {
              return applyFormatDecimals(
                convertMassConcentrationValue(value),
                convertMassConcentrationValue(displayHumanReadable(value, 4)),
                'mass_conc',
              )
            }
            return null
          },
        },
        {
          key: 'amt_conc',
          channel: true,
          path:
            'content.main_channel[&]content.channels.[CHANNEL].concentration[&]content.channels.[CHANNEL].q1',
          name: (
            <Trans i18nKey="view.analysis.analysis.column.conc">
              Conc({{ val: volumeConcentrationUnit }})
            </Trans>
          ),
          type: 'number',
          render: function (value, channel, optiGlobalsRaw, name, analysisName) {
            const row = findRawByNameAndAnalysisName(optiGlobalsRaw, name, analysisName)
            const channelId =
              row && row.content.channels
                ? Object.keys(row.content.channels).filter(
                    (key) => parseInt(key) !== row.content.main_channel,
                  )[0]
                : null
            const item =
              row && channelId
                ? {
                    channels_data: [{ q1: row.content.channels[channelId].q1 }],
                  }
                : {
                    channels_data: [],
                  }
            if (value && value !== '' && value !== null && typeof value === 'number') {
              return applyFormatDecimals(
                convertVolumeConcentrationValue(value, item),
                convertVolumeConcentrationValue(displayHumanReadable(value, 4), item),
                'amt_conc',
              )
            }
            return null
          },
        },
        {
          key: 'mass_conc_rdt',
          channel: true,
          path: 'content.main_channel[&]content.channels.[CHANNEL].concentration_mass_rdt',
          name: (
            <Trans i18nKey="view.analysis.analysis.column.conc_rdt">
              Conc RDT({{ val: massConcentrationUnit }})
            </Trans>
          ),
          type: 'number',
          render: function (value) {
            if (value && value !== '' && value !== null && typeof value === 'number') {
              return applyFormatDecimals(
                convertMassConcentrationValue(value),
                convertMassConcentrationValue(displayHumanReadable(value, 4)),
                'mass_conc_rdt',
              )
            }
            return null
          },
        },
      ],
    )

    // for (
    //   let step = 1;
    //   step <=
    //   Number(
    //     localStorage.getItem('__config.json__NUMBER_CHANNEL_VISIBLE_FOR_VOLUMIC_CONCENTRATION'),
    //   );
    //   step++
    // ) {
    //   initList.push({
    //     key: `amt_conc_${step}`,
    //     name: (
    //       <Trans i18nKey="view.analysis.analysis.column.conc_ch">
    //         Conc ch.{{ val1: step }} ({{ val2: volumeConcentrationUnit }})
    //       </Trans>
    //     ),
    //     type: 'number',
    //     customDisplay: function (value, item, columnInfos) {
    //       if (value && value !== '' && value !== null) {
    //         return convertVolumeConcentrationValue(displayHumanReadable(value, 4), item)
    //       }
    //       return value
    //     },
    //   })
    // }

    initList.push(
      ...[
        {
          key: 'loq',
          path: 'content.parameters.loq[&]content.main_channel[&]content.channels.[CHANNEL].q1',
          name: (
            <Trans i18nKey="view.analysis.analysis.column.loq">
              LQ({{ val: volumeConcentrationUnit }})
            </Trans>
          ),
          type: 'number',
          render: function (value, channel, optiGlobalsRaw, name, analysisName) {
            const row = findRawByNameAndAnalysisName(optiGlobalsRaw, name, analysisName)
            const channelId =
              row && row.content.channels
                ? Object.keys(row.content.channels).filter(
                    (key) => parseInt(key) !== row.content.main_channel,
                  )[0]
                : null
            const item =
              row && channelId
                ? {
                    channels_data: [{ q1: row.content.channels[channelId].q1 }],
                  }
                : {
                    channels_data: [],
                  }
            if (value && value !== '' && value !== null && typeof value === 'number') {
              const con_val = convertVolumeConcentrationValue(value, item)
              return applyFormatDecimals(con_val, displayHumanReadable(con_val, 2, 2), 'loq')
            }
            return null
          },
        },
        {
          key: 'area',
          name: t('view.analysis.analysis.column.area'),
          channel: true,
          path: 'content.main_channel[&]content.channels.[CHANNEL].peak.area',
          type: 'number',
          render: function (value) {
            if (value && value !== '' && value !== null && typeof value === 'number') {
              return applyFormatDecimals(value, displayHumanReadable(value, 0), 'area')
            }
            return null
          },
        },
        {
          key: 'isArea',
          name: t('view.analysis.analysis.column.isArea'),
          channel: true,
          path: 'content.main_channel[&]content.channels.[CHANNEL].istd_area',
          type: 'number',
          render: function (value) {
            if (value && value !== '' && value !== null && typeof value === 'number') {
              return applyFormatDecimals(value, displayHumanReadable(value, 0), 'isArea')
            }
            return null
          },
        },
        {
          key: 'areaRatio',
          name: t('view.analysis.analysis.column.area_ratio'),
          channel: true,
          path: 'content.main_channel[&]content.channels.[CHANNEL].area_ratio',
          type: 'number',
          render: function (value) {
            if (value && value !== '' && value !== null && typeof value === 'number') {
              return applyFormatDecimals(value, displayHumanReadable(value, 2, 2), 'areaRatio')
            }
            return null
          },
        },
        {
          key: 'isPeak_istdGroup',
          path: 'content.molecule.istd_name',
          name: t('view.analysis.analysis.column.istd'),
          type: 'text',
        },
        {
          key: 's_n',
          name: t('view.analysis.analysis.column.s_n'),
          channel: true,
          path: 'content.main_channel[&]content.channels.[CHANNEL].peak.snr.rms',
          type: 'number',
          render: function (value) {
            if (value && value !== '' && value !== null && typeof value === 'number') {
              if (value < 100) {
                return applyFormatDecimals(value, displayHumanReadable(value, 1, 1), 's_n')
              } else {
                return '> 100'
              }
            }
            return null
          },
        },
        {
          key: 'level',
          path: 'content.level',
          name: t('view.analysis.analysis.column.level'),
          // type: 'number',
        },
        {
          key: 'conc_target',
          path: 'content.gt_concentration[&]content.main_channel[&]content.channels.[CHANNEL].q1',
          name: (
            <Trans i18nKey="view.analysis.analysis.column.conc_target">
              Conc target({{ val: volumeConcentrationUnit }})
            </Trans>
          ),
          type: 'number',
          render: function (value, channel, optiGlobalsRaw, name, analysisName) {
            const row = findRawByNameAndAnalysisName(optiGlobalsRaw, name, analysisName)
            const channelId =
              row && row.content.channels
                ? Object.keys(row.content.channels).filter(
                    (key) => parseInt(key) !== row.content.main_channel,
                  )[0]
                : null
            const item =
              row && channelId
                ? {
                    channels_data: [{ q1: row.content.channels[channelId].q1 }],
                  }
                : {
                    channels_data: [],
                  }
            if (value && value !== '' && value !== null && typeof value === 'number') {
              return applyFormatDecimals(
                convertVolumeConcentrationValue(value, item),
                displayHumanReadable(convertVolumeConcentrationValue(value, item), 4),
                'conc_target',
              )
            }
            return null
          },
        },
        {
          key: 'height',
          name: t('view.analysis.analysis.column.height'),
          channel: true,
          path: 'content.main_channel[&]content.channels.[CHANNEL].peak.ampl',
          type: 'number',
          render: function (value) {
            if (value && value !== '' && value !== null && typeof value === 'number') {
              return applyFormatDecimals(value, displayHumanReadable(value, 2, 2), 'height')
            }
            return null
          },
        },
        {
          key: 'type',
          path: 'content.istd',
          name: t('view.analysis.analysis.column.type'),
          type: 'text',
          render: function (value) {
            if (value && value !== '' && value !== null && value === 'True') {
              return 'ISTD'
            }
            return 'Target'
          },
        },
        {
          key: 'm_z',
          path: 'content.main_channel',
          name: t('view.analysis.analysis.column.m_z'),
          // type: 'custom',
        },
        {
          key: 'refIons',
          channel: true,
          path:
            'content.main_channel[&]content.channels.[CHANNEL].q1[&]content.channels.[CHANNEL].q3[&]content.molecule.channels.[CHANNEL].q1[&]content.molecule.channels.[CHANNEL].q3',
          name: t('view.analysis.analysis.column.refIons'),
          type: 'custom',
        },
        {
          key: 'rt_peak',
          channel: true,
          path: 'content.main_channel[&]content.channels.[CHANNEL].peak.time',
          name: t('view.analysis.analysis.column.rt_peak'),
          type: 'number',
          render: function (value) {
            if (value && value !== '' && value !== null && typeof value === 'number') {
              return applyFormatDecimals(value, displayHumanReadable(value, 2, 2), 'rt_peak')
            }
            return null
          },
        },
        {
          key: 'devRrt',
          name: t('view.analysis.analysis.column.dev_ret'),
          channel: true,
          path: 'content.main_channel[&]content.channels.[CHANNEL].dev_rrt_calibration',
          type: 'number',
          render: function (value) {
            if (value && value !== '' && value !== null && typeof value === 'number') {
              return (
                applyFormatDecimals(value, displayHumanReadable(value, 2, 2, true), 'devRrt') + ' %'
              )
            }
            return null
          },
        },
        {
          key: 'devAccuracy',
          name: t('view.analysis.analysis.column.dev_accu'),
          channel: true,
          path: 'content.main_channel[&]content.channels.[CHANNEL].dev_accuracy',
          type: 'number',
          render: function (value) {
            if (value && value !== '' && value !== null && typeof value === 'number') {
              return (
                applyFormatDecimals(value, displayHumanReadable(value, 2, 2, true), 'devAccuracy') +
                ' %'
              )
            }
            return null
          },
        },
      ],
    )
    return initList
  }
  // const criteriaList = useMemo(() => {
  //   const list = getListItems()
  //   if (!isFetchingConfigFeatures && configFeatures && list.length > 0) {
  //     return list
  //   } else {
  //     return []
  //   }
  // }, [configFeatures, isFetchingConfigFeatures])

  // const criteriaList = getListItems()
  const criteriaList = useMemo(() => {
    return getListItems()
  }, [configFeatures])

  const criteriaSelected = useMemo(() => {
    if (criteriaList.length > 0) {
      return criteriaList.filter((cl) => cl.path === criteria)[0]
    }
    return { type: null }
  }, [criteria, criteriaList])

  useEffect(() => {
    // dispatch(fetchGlobalUA(props.match.params.batchId, tableMode, 'content.rt'))
    dispatch(fetchGlobalUA(props.match.params.batchId, tableMode, criteria, selectedChannel))
  }, [criteria, selectedChannel])

  const compoundsNameList = useMemo(() => {
    if (batch) {
      return Object.values(batch.content.molecules).map((m) => m.name)
    }
    return []
  }, [batch])

  function getColumns() {
    let columns = [
      {
        key: 'name',
        label: 'Name',
        size: 150,
        customTitle: function () {
          return ''
        },
      },
    ]
    let listToUse = analyses
    if (tableMode === 'unitary_analysis') {
      listToUse = compoundsNameList
    }
    if (criteriaSelected) {
      columns.push(
        listToUse.sort(sortList).map((cnl) => ({
          key: cnl,
          label: cnl,
          size: 150,
          type: criteriaSelected.type,
          customDisplay: function (value, item, column) {
            if (criteriaSelected.render) {
              return criteriaSelected.render(
                value,
                selectedChannel,
                optiGlobalsRaw,
                item.name,
                column.label,
              )
            }
            // test if value is not an object before the render:
            // an object should by used with a render method
            // just above.
            return typeof value !== 'object' ? value : null
          },
        })),
      )
    }
    return columns.flat()
  }

  // const columns = useMemo(() => {
  //   return getColumns()
  // }, [selectedFormat, selectedDecimal])

  const scrollToGrid = function (item) {
    setTableItemSelectedForColor({
      row: tableMode === 'unitary_analysis' ? item.analysisName : item.name,
      column: tableMode === 'unitary_analysis' ? item.name : item.analysisName,
    })
    dispatch(fetchGlobalUASelected(item._id))
  }
  const moleculeDoubleClick = function (item) {
    if (tableMode === 'unitary_analysis') {
      props.history.push('/?' + item.batchId + '/analysis/' + item.analyseId)
    } else {
      props.history.push('/?' + item.batchId + '/compound/' + item.event)
    }
  }

  function findValueByNameAndAnalysisName(name, analysisName) {
    // for (const key in globals) {
    //   const item = globals[key]
    //   if (item.name === name && item.analysisName === analysisName) {
    //     return item.value
    //   }
    // }
    // return null // Retourne null si aucune correspondance n'est trouvée
    const keyName = `${name}_${analysisName}`
    return optiGlobals[keyName] || null
  }

  function findRawByNameAndAnalysisName(optiGlobalsRaw, name, analysisName) {
    // for (const key in globals) {
    //   const item = globals[key]
    //   if (item.name === name && item.analysisName === analysisName) {
    //     return item.raw
    //   }
    // }
    // return null // Retourne null si aucune correspondance n'est trouvée
    const keyName = `${name}_${analysisName}`
    return optiGlobalsRaw[keyName] || null
  }

  const datas = useMemo(() => {
    if (analyses.length > 0 && compoundsNameList.length > 0 && globals.length > 0) {
      let notSortedData = []
      if (tableMode === 'unitary_compound') {
        notSortedData = compoundsNameList.map((compoundName) => ({
          name: compoundName,
          ...Object.assign(
            {},
            ...Object.values(
              analyses.map(function (analysisName) {
                const val = findValueByNameAndAnalysisName(compoundName, analysisName)
                return {
                  [analysisName]: val === undefined || val === null ? '' : val,
                }
              }),
            ),
          ),
        }))
      } else {
        notSortedData = analyses.map((analysisName) => ({
          name: analysisName,
          ...Object.assign(
            {},
            ...Object.values(
              compoundsNameList.map(function (compoundName) {
                const val = findValueByNameAndAnalysisName(compoundName, analysisName)
                return {
                  [compoundName]: val === undefined || val === null ? '' : val,
                }
              }),
            ),
          ),
        }))
      }
      return stableSort(notSortedData, getComparator(direction, order))
    }
    return []
  }, [analyses, compoundsNameList, globals, order, direction, tableMode])

  function handleInvertTable() {
    // Adapte the information to Table_MultiGrid
    setTableItemSelectedForColor({
      row: tableItemSelectedForColor.column,
      column: tableItemSelectedForColor.row,
    })
    // Store the new mode
    localStorage.setItem(
      'GLOBAL_table_mode',
      tableMode === 'unitary_compound' ? 'unitary_analysis' : 'unitary_compound',
    )
    setTableMode(tableMode === 'unitary_compound' ? 'unitary_analysis' : 'unitary_compound')
  }

  function handleChangeCriteria(event) {
    localStorage.setItem('GLOBAL_table_criteria', event.target.value)
    setCriteria(event.target.value)
  }

  function handleChangeChannel(event) {
    localStorage.setItem('GLOBAL_table_channel', event.target.value)
    setSelectedChannel(event.target.value)
  }
  function handleChangeFormat(event) {
    localStorage.setItem('GLOBAL_table_format', event.target.value)
    setSelectedFormat(event.target.value)
    // tableMultiGridRef.current.reloadGrid()
  }

  function onDecimalsEnd(event, forceSave, checkbox) {
    const value = checkbox ? (event.target.checked ? 0 : -1) : parseFloat(event.currentTarget.value)
    clearTimeout(settingsTimer)
    if (forceSave) {
      localStorage.setItem('GLOBAL_table_decimal', value)
      setSelectedDecimal(value)
      // tableMultiGridRef.current.reloadGrid()
    } else {
      settingsTimer = setTimeout(function () {
        localStorage.setItem('GLOBAL_table_decimal', value)
        setSelectedDecimal(value)
        // tableMultiGridRef.current.reloadGrid()
      }, 1000)
    }
  }

  // Function to convert the global item to a complete one to be able to
  // open JSON or a new tab from Table_MultiGrid
  function convertGlobalItem(entry, column) {
    let result = entry
    if (tableMode === 'unitary_analysis') {
      for (const key in globals) {
        const item = globals[key]
        if (item.name === column.key && item.analysisName === entry.name) {
          result = item
        }
      }
    } else {
      for (const key in globals) {
        const item = globals[key]
        if (item.name === entry.name && item.analysisName === column.key) {
          result = item
        }
      }
    }
    return result
  }

  function handleColorChecked(event) {
    localStorage.setItem('GLOBAL_table_color', event.target.checked)
    setColorChecked(event.target.checked)
  }

  return !batch || analyses.length === 0 ? (
    <Loading mode="alpha" size={10} />
  ) : (
    <Fragment>
      <ErrorBoundaryGuard>
        <Title>
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="flex-start"
            style={{ marginTop: 0, marginBottom: 8 }}
          >
            <Grid item style={{ paddingBottom: 0, height: 50 }}>
              <Button
                style={{ height: 40 }}
                onClick={handleInvertTable}
                variant="outlined"
                color="primary"
                startIcon={<PivotTableChartIcon />}
              >
                {t('view.global.table.invert')}
              </Button>
            </Grid>
            <Grid item style={{ paddingBottom: 0, height: 50 }}>
              <FormControl variant="outlined" size="small">
                <InputLabel>{t('view.global.table.criteria')}</InputLabel>
                <Select
                  size="small"
                  variant="outlined"
                  value={criteria}
                  label={t('view.global.table.criteria')}
                  onChange={handleChangeCriteria}
                >
                  {criteriaList.map((criteria) => (
                    <MenuItem value={criteria.path}>{criteria.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {criteriaSelected && criteriaSelected.channel && (
              <Grid item style={{ paddingBottom: 0, height: 50 }}>
                <FormControl variant="outlined" size="small">
                  <InputLabel>{t('view.global.table.channel')}</InputLabel>
                  <Select
                    size="small"
                    variant="outlined"
                    value={selectedChannel}
                    label={t('view.global.table.channel')}
                    onChange={handleChangeChannel}
                  >
                    {['main', '1', '2', '3', '4', '5', '6', '7', '8'].map((channel) => (
                      <MenuItem value={channel}>
                        {channel === 'main' ? t('view.global.table.main') : channel}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {criteriaSelected && criteriaSelected.type === 'number' && (
              <Fragment>
                <Grid item style={{ paddingBottom: 0, height: 50 }}>
                  <FormControl size="small" style={{}} variant="outlined">
                    <InputLabel>{t('view.common.settingsView.tableTab.format')}</InputLabel>
                    <Select
                      variant="outlined"
                      // className={classes.selectFormat}
                      value={selectedFormat}
                      label={t('view.common.settingsView.tableTab.format')}
                      // open={open[columnKey]}
                      // onClose={() => handleClose(columnKey)}
                      // onOpen={() => handleOpen(columnKey)}
                      onChange={handleChangeFormat}
                    >
                      <MenuItem key={'auto'} value={'auto'}>
                        {t('view.common.settingsView.tableTab.auto')}
                      </MenuItem>
                      <MenuItem key={'std'} value={'std'}>
                        {t('view.common.settingsView.tableTab.std')}
                      </MenuItem>
                      <MenuItem key={'sci'} value={'sci'}>
                        {t('view.common.settingsView.tableTab.sci')}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item style={{ paddingBottom: 0, height: 50 }}>
                  <TextField
                    className={clsx(classes.columnSizetextfield, classes.columnDecimals)}
                    // style={{ marginTop: 14, marginBottom: 8, background: 'white' }}
                    label={t('view.common.settingsView.tableTab.decimals')}
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: { min: 0 },
                      startAdornment: (
                        <InputAdornment position="start" style={{ width: 10 }}>
                          <Checkbox
                            checked={selectedDecimal !== -1 && selectedFormat !== 'auto'}
                            disabled={selectedFormat === 'auto'}
                            style={{ padding: 0 }}
                            size="small"
                            onChange={(event) => onDecimalsEnd(event, true, true)}
                          />
                        </InputAdornment>
                      ),
                    }}
                    disabled={selectedDecimal === -1 || selectedFormat === 'auto'}
                    variant="outlined"
                    value={
                      selectedDecimal === -1 || selectedFormat === 'auto' ? null : selectedDecimal
                    }
                    onChange={(event) => onDecimalsEnd(event)}
                    onBlur={(e) => onDecimalsEnd(e, true)}
                  />
                </Grid>
              </Fragment>
            )}
            {criteriaSelected && criteriaSelected.color && (
              <Fragment>
                <Grid item style={{ paddingBottom: 0, height: 50 }}>
                  <div
                    style={{
                      width: '100%',
                      height: 40,
                      background: 'white',
                      position: 'relative',
                    }}
                  >
                    <div className={classes.colorPickerCnt} style={{ width: '100%' }}>
                      <Grid
                        container
                        spacing={2}
                        justify="center"
                        alignItems="center"
                        className={classes.root}
                        style={{ margin: 0 }}
                      >
                        <Stack
                          direction="row"
                          divider={
                            <Divider style={{ marginLeft: 0 }} orientation="vertical" flexItem />
                          }
                          spacing={1}
                        >
                          <Checkbox
                            checked={colorChecked}
                            onChange={handleColorChecked}
                            size="small"
                            style={{ borderRadius: 0, width: 20, height: 20, margin: 9 }}
                          />
                          <Stack direction="row" spacing={1} style={{ marginRight: 9 }}>
                            {rangesColor.map((rc, index) => (
                              <Fragment>
                                <div
                                  style={{
                                    background: rc.color,
                                    width: 20,
                                    height: 20,
                                    marginTop: 9,
                                    borderRadius: 3,
                                    opacity: colorChecked ? 1 : 0.5,
                                  }}
                                ></div>
                                {index !== rangesColor.length - 1 && (
                                  <TextField
                                    disabled={!colorChecked}
                                    variant="standard"
                                    className={classes.colorRangeInput}
                                    style={{ width: 50 }}
                                    type="number"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    // disabled={selectedDecimal === -1 || selectedFormat === 'auto'}
                                    value={rc.value}
                                    // onChange={(event) => onDecimalsEnd(event)}
                                    // onBlur={(e) => onDecimalsEnd(e, true)}
                                  />
                                )}
                              </Fragment>
                            ))}
                          </Stack>
                          <IconButton
                            disabled
                            aria-label="delete"
                            size="small"
                            style={{ padding: 9, margin: 0, borderRadius: 0 }}
                          >
                            <SettingsIcon fontSize="inherit" />
                          </IconButton>
                        </Stack>
                      </Grid>
                      <div className={classes.colorPickerTitle}>
                        {t('view.common.settingsView.tableTab.rangeColor')}
                      </div>
                    </div>
                  </div>
                </Grid>
              </Fragment>
            )}
          </Grid>
        </Title>
      </ErrorBoundaryGuard>
      <div className={classes.tableContainer}>
        <ErrorBoundaryGuard>
          <TableMultiGrid
            global
            convertGlobalItem={convertGlobalItem}
            ref={tableMultiGridRef}
            parentMultiGridRef={parentMultiGridRef}
            collection={tableMode}
            batch={batch}
            data={datas}
            isFetchingData={isFetchingGlobal}
            columns={getColumns()}
            selection={false}
            settingsOption={false}
            // onSelect={this.onSelect}
            // onOpenMultiSelectionDialog={this.openSelectionMultipleDialog}
            // scrollTo={this.state.scrollTableId}
            tableItemSelectedForColor={tableItemSelectedForColor}
            onMoleculeClick={(item) => scrollToGrid(item)}
            onMoleculeDoubleClick={(item) => moleculeDoubleClick(item)}
            idTable={idTableConst}
            orderBy={direction + '$' + order}
            // sortAction={function (order, direction) {
            //   // tableMultiGridRef.current.clearSelection()
            //   // dispatch(sortUnitaryCompounds(direction, order))
            // }}
            sortAction={function (order, direction) {
              localStorage.setItem('GLOBAL_sorter', direction + '$' + order)
              setOrder(order)
              setDirection(direction)
            }}
            fixedColumnCount={1}
            fixedRowCount={1}
            rowHeight={40}
            getColumnRender={function (
              columnIndex,
              key,
              rowIndex,
              style,
              item,
              rowClassNames,
              columnKey,
              moleculeOverState,
              moleculeOver,
              selected,
              onMoleculeClick,
            ) {
              const value = item[columnKey]
              if (columnKey === 'notes') {
              }
              return null
            }}
          />
        </ErrorBoundaryGuard>
      </div>
    </Fragment>
  )
})
export default Table
