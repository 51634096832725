export const PIN_UNPIN_DRAWER = 'PIN_UNPIN_DRAWER'

export function pinUnpinDrawer(isUnpin) {
  return {
    type: PIN_UNPIN_DRAWER,
    pin: isUnpin,
  }
}

export function fetchPinUnpinDrawer(isPin) {
  return async (dispatch) => {
    return dispatch(pinUnpinDrawer(isPin))
  }
}
