import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import background from '../resources/images/404.gif'
import IconButton from '@material-ui/core/IconButton'
import { displayPopupMessage } from './common/Message'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import Typography from '@material-ui/core/Typography'
import { t } from 'i18next'

const styles = (theme) => ({
  backgroundImg: {
    position: 'fixed',
    marginLeft: 'calc(50% - 400px)',
  },
  container: {
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
    position: 'fixed',
  },
  notFound: {
    textAlign: 'center',
    position: 'relative',
    fontFamily: 'monospace',
    fontSize: 135,
    height: 220,
  },
  notFound1: {
    textAlign: 'center',
    position: 'relative',
    fontFamily: 'monospace',
    fontSize: 40,
    marginTop: 270,
  },
  notFound2: {
    textAlign: 'center',
    position: 'relative',
    fontFamily: 'monospace',
    fontSize: 20,
    marginBottom: 100,
  },
  buttonHome: {
    animation: 'heartbeat 1.5s infinite',
    marginLeft: 'calc(50% - 73px)',
    width: 150,
    height: 150,
    borderRadius: 75,
    backgroundColor: '#bfbfbf',
    '&:hover': {
      animation: 'none',
      backgroundColor: '#6d6c6c',
    },
    '& .MuiButton-startIcon': {
      margin: 0,
    },
    '& .heartEmpty': {
      opacity: 1,
      color: 'white',
      position: 'absolute',
      transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    '& .heartFull': {
      opacity: 0,
      transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    '&:hover .heartEmpty': {
      opacity: 0,
    },
    '&:hover .heartFull': {
      opacity: 1,
      color: 'red',
    },
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: 64,
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    position: 'absolute',
    zIndex: 1000000,
  },
  title: {
    marginLeft: 24,
    marginTop: 16,
    color: 'white',
  },
})

export default withRouter(
  connect()(
    withStyles(styles)(
      class Clean extends React.Component {
        constructor(props, context) {
          super(props, context)
          this.state = {}
          this.cleanUserInfos = this.cleanUserInfos.bind(this)
        }
        componentWillMount() {}
        componentDidMount() {}

        cleanUserInfos() {
          const { dispatch } = this.props
          Object.keys(localStorage).forEach(function (key) {
            if (
              // key !== 'SESSION_user_name' &&
              // key !== 'SESSION_user_json' &&
              // key !== 'SERVICE_pythonService_name' &&
              // key !== 'SERVICE_dataBase_default_name' &&
              key.indexOf('__config.json__') === -1
            ) {
              localStorage.removeItem(key)
            }
          })
          displayPopupMessage(
            dispatch,
            'success',
            t('view.clean.success_popup.title'),
            t('view.clean.success_popup.msg'),
          )
          this.props.history.push('/')
        }

        render() {
          const { classes } = this.props
          return (
            <div className={classes.container}>
              <div className={classes.header}>
                {/*<Typography*/}
                {/*  component="h1"*/}
                {/*  variant="h6"*/}
                {/*  color="inherit"*/}
                {/*  noWrap*/}
                {/*  className={classes.title}*/}
                {/*>*/}
                {/*  {localStorage.getItem('__config.json__APPLICATION_TITLE')}*/}
                {/*</Typography>*/}
                {localStorage.getItem('__config.json__APPLICATION_SUBTITLE') === '' ? (
                  <Typography
                    className={classes.title}
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                  >
                    {localStorage.getItem('__config.json__APPLICATION_TITLE')}
                  </Typography>
                ) : (
                  <div className={classes.title} style={{ marginTop: 11 }}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <Typography
                              component="h1"
                              variant="h6"
                              color="inherit"
                              noWrap
                              style={{ lineHeight: '20px' }}
                            >
                              {localStorage.getItem('__config.json__APPLICATION_TITLE')}
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography
                              variant="caption"
                              display="block"
                              gutterBottom
                              style={{ fontSize: '0.6em', float: 'right', lineHeight: '10px' }}
                            >
                              {localStorage.getItem('__config.json__APPLICATION_SUBTITLE')}
                            </Typography>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              <img src={background} alt="Background" className={classes.backgroundImg} />
              <div className={classes.notFound}></div>
              <div className={classes.notFound1}>{t('view.clean.msg1')}</div>
              <div className={classes.notFound2}>{t('view.clean.msg2')}</div>
              <IconButton
                variant="contained"
                size="large"
                className={classes.buttonHome}
                // startIcon={
                //
                // }
                onClick={() => this.cleanUserInfos()}
              >
                <span style={{ paddingTop: 15 }}>
                  <FavoriteBorderIcon className={'heartEmpty'} style={{ fontSize: 90 }} />
                  <FavoriteIcon className={'heartFull'} style={{ fontSize: 90 }} />
                </span>
              </IconButton>
            </div>
          )
        }
      },
    ),
  ),
)
