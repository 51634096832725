import React, { Fragment } from 'react'
import { Scatter } from 'react-chartjs-2'
import Paper from '@material-ui/core/Paper'
import { connect, useDispatch } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { API_BO_URL } from '../../utils/config'
import { withRouter } from 'react-router-dom'
import Loading from '../Loading'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import Menu from '@material-ui/core/Menu'
import Typography from '@material-ui/core/Typography'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import IconButton from '@material-ui/core/IconButton'
import {
  canIModifyTheCalibration,
  openInNewTab,
  rebuildUnitaryCalibrations,
  resetUnitaryAnalyses,
} from './Utils'
import { Nothing, ResetZoom } from './Style'
import { StyledDialogTitle, TransitionTop } from './Style'
import SelectAllIcon from '@material-ui/icons/SelectAll'
import Box from '@material-ui/core/Box'
import { timer } from 'redux-logger/src/helpers'
import AlphacodFlagIcon from './AlphacodFlagIcon'
import {
  fetchUnitaryCalibration,
  fetchUpdateInterpolation,
  fetchUpdateStdPoints,
  fetchResetCalibration,
  fetchUpdateCalibration,
} from '../../redux/unitaryCalibrations/actions'
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import { fetchAddTask, fetchFinishTask } from '../../redux/tasks/actions'
import { fade, Tooltip } from '@material-ui/core'
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore'
import Chip from '@material-ui/core/Chip'
import WarningIcon from '@material-ui/icons/Warning'
import CalibrationResetDialog from '../dialog/CalibrationResetDialog'
import { fetchChangeSelectedChannel } from '../../redux/unitaryAnalyses/actions'
import { displayPopupMessage, generateErrorPart } from './Message'
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent'
import { getBatchById } from '../../redux/batches/selectors'
import { isNumber } from 'lodash'
import { fetchChangeSettings } from '../../redux/settings/actions'
import Divider from '@material-ui/core/Divider'
import GpsFixedIcon from '@material-ui/icons/GpsFixed'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { t } from 'i18next'
import { Trans } from 'react-i18next'
import { receiveCurrentBatchLease } from '../../redux/batches/actions'
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye'
import { Stack } from '@mui/material'

const styles = (theme) => ({
  paper: {
    // boxShadow:
    //   '0px 2px 7px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    padding: theme.spacing(2),
    height: '100%',
    overflow: 'hidden',
  },
  formControl: {
    minWidth: 55,
    '& .MuiSelect-selectMenu': {
      padding: 6,
      paddingRight: 26,
      fontSize: 11,
      paddingTop: 4,
      paddingBottom: 4,
    },
    '& .MuiSelect-iconOutlined': {
      right: 3,
    },
  },
  optionCtn: {
    marginTop: 12,
    marginBottom: 5,
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(5px, -10px) scale(0.55)',
    },
  },
  resetZoom: {
    position: 'absolute',
    padding: 7,
    bottom: 70,
    right: 50,
    background: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      background: theme.palette.secondary.main,
    },
  },
  loadingUpdate: {
    position: 'absolute',
    width: 30,
    height: 30,
    left: 15,
    top: 15,
  },
  noItem: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    top: 'calc(50% - 24px)',
    // position: 'relative',
    position: 'sticky', //New version of Chrome doesnt work with "relative".....
  },
  title: {
    fontSize: 15,
  },
  settingsIcon: {
    width: 18,
    color: '#00000099',
  },

  settingsButton: {
    border: '1px solid',
    borderColor: '#c4c4c4',
    borderRadius: 4,
    height: 27,
    width: 27,
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: 'black',
    },
  },
  calibrateIcon: {
    width: 18,
    color: 'rgb(255,255,255)',
  },
  calibrateButton: {
    background: theme.palette.secondary.main,
    border: '1px solid',
    borderColor: '#c4c4c4',
    borderRadius: 4,
    height: 27,
    width: 27,
    '&:hover': {
      background: theme.palette.secondary.dark,
      // borderColor: 'black',
    },
  },
  moveChip: {
    margin: 3,
    marginBottom: 15,
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
  },
  warning: {
    color: theme.palette.common.warning,
    position: 'relative',
    left: -30,
  },
  calibMenu: {
    '& .MuiListItemIcon-root': {
      minWidth: 30,
    },
  },
})

const mapStateToProps = (state) => {
  const { unitaryAnalyses, global } = state
  return {
    compounds: unitaryAnalyses.unitaryAnalysesCompoundQC,
    isFetchingCompounds: unitaryAnalyses.isFetchingUnitaryAnalysesCompoundQC,
    unitaryAnalyses: unitaryAnalyses.unitaryAnalysesQC,
    isFetchingUnitaryAnalyses: unitaryAnalyses.isFetchingUnitaryAnalysesQC,
    featuresConfig: global.featuresConfig,
  }
}

export default withRouter(
  connect(mapStateToProps, null, null, { forwardRef: true })(
    withStyles(styles)(
      class Calibration extends React.Component {
        constructor(props) {
          super(props)
          this.state = {
            defaultValues: {
              curve: null,
              weighting: null,
              zero: null,
            },
            isLoaded: false,
            isFetching: false,
            calibration: null,
            mainChannel: null,
            sampleRefPoint: null,
            istd: false,
            open: false,
            openDialog: false,
            mouseX: null,
            mouseY: null,
            mode: null,
            std: null,
            hasMoved: false,
            waiting: false,
            displayTitle:
              props.options && props.options.displayTitle !== undefined
                ? props.options.displayTitle
                : true,
            displayPaper:
              props.options && props.options.displayPaper !== undefined
                ? props.options.displayPaper
                : true,
            openConfirmReset: false,
            hasChanged: false,
            savedItem: null,
            event: null,
            hover: null,
            menu: null,
          }
          this.chartRef = React.createRef()
          this.onClear = this.onClear.bind(this)
          this.interpolationChange = this.interpolationChange.bind(this)
          this.channelChange = this.channelChange.bind(this)
          this.handleReset = this.handleReset.bind(this)
          this.handleConfirmResetClose = this.handleConfirmResetClose.bind(this)
          this.resetCalibration = this.resetCalibration.bind(this)
        }

        displayISTDMessage() {
          this.setState({
            isLoaded: true,
            mainChannel: null,
            calibration: [],
            istd: true,
          })
        }

        getSampleRefPoint(item, selectedChannel, calibration) {
          if (item && item.channels[selectedChannel]) {
            const use_istd =
              typeof calibration?.content?.parameters?.use_istd === 'boolean'
                ? calibration.content.parameters.use_istd
                : true

            const conc = item.channels[selectedChannel].concentration
            const area = item.channels[selectedChannel].peak
              ? item.channels[selectedChannel].peak.area
              : ''
            const area_ratio = item.channels[selectedChannel].area_ratio
            if (!isNaN(parseFloat(conc)) && !isNaN(parseFloat(area))) {
              return { x: conc, y: use_istd ? area_ratio : area }
            }
          }
          return null
        }

        async displayCalib(event, mainChannel, fromUpdate, item) {
          // const event = item.event
          const { dispatch, batch } = this.props
          const me = this
          if (fromUpdate) this.setState({ isFetching: true })
          dispatch(fetchUnitaryCalibration(batch._id, event))
            .then(function (calibration) {
              function hasChanged() {
                const calibParam = calibration?.content?.parameters
                const channel =
                  calibration?.content?.channels?.[
                    mainChannel ? mainChannel : calibration?.content?.main_channel
                  ]
                const interpolation = channel?.interpolation
                return (
                  calibParam?.interpolation_type !== interpolation?.type ||
                  calibParam?.interpolation_weights_policy !== interpolation?.weights_policy ||
                  calibParam?.interpolation_zero_policy !== interpolation?.zero_policy ||
                  JSON.stringify(channel?.included) !== JSON.stringify(channel?.initial_included)
                )
              }
              me.onClear()
              me.setState({
                event: event,
                savedItem: item,
                isLoaded: true,
                mainChannel: mainChannel ? mainChannel : calibration.content.main_channel,
                calibration: [calibration],
                istd: calibration ? calibration.content.istd === 'True' : false,
                isFetching: false,
                sampleRefPoint: item ? me.getSampleRefPoint(item, mainChannel, calibration) : null,
                defaultValues: {
                  curve:
                    calibration.content.channels[
                      mainChannel ? mainChannel : calibration.content.main_channel
                    ].interpolation.type,
                  weighting:
                    calibration.content.channels[
                      mainChannel ? mainChannel : calibration.content.main_channel
                    ].interpolation.weights_policy,
                  zero:
                    calibration.content.channels[
                      mainChannel ? mainChannel : calibration.content.main_channel
                    ].interpolation.zero_policy,
                },
                hasChanged: hasChanged(),
              })
            })
            .catch(function (error) {
              if (error.status === 403) {
                dispatch(fetchChangeSettings('loginPopup', true))
              }
              displayPopupMessage(
                dispatch,
                'error',
                t('view.common.calibration.error_get_title'),
                <Fragment>
                  <div>{t('view.common.calibration.error_get_title')}</div>
                  {generateErrorPart(error)}
                </Fragment>,
              )
            })
        }

        onClear(e) {
          if (this.chartRef.current) {
            const chartInstance = this.chartRef.current.chartInstance
            chartInstance.resetZoom()
            this.setState({ hasMoved: false })
          }
        }

        updateStdPoints(std) {
          const { dispatch, batch, collection, compounds } = this.props
          const { calibration, mode, mainChannel, sampleRefPoint, hover, menu } = this.state
          const me = this

          const calibItems = compounds.filter(
            (item) => item.content.analysis_type === 'calibration',
          )

          const mainChannelObj = calibration[0].content.channels[mainChannel]
          let pointsIncluded = mainChannelObj.included
          // pointsIncluded[std] = mode === 'include'

          const interpolationParam = mainChannelObj.interpolation

          let newPointsParam = {}
          Object.keys(pointsIncluded).forEach(
            (point) => (newPointsParam[point] = pointsIncluded[point]),
          )
          if (std === -1) {
            // We want to update all calibration pts
            Object.keys(newPointsParam).forEach((key) => (newPointsParam[key] = mode === 'include'))
          } else {
            // We want to update only one calibration point
            newPointsParam[std] = mode === 'include'
          }

          const pointName =
            menu && menu.data && menu.data.type
              ? menu.data.type
              : me.renderLabelPoint(std, calibItems)[0]

          let json = {
            channel: mainChannel,
            points: newPointsParam,
            type: interpolationParam.type,
            weights_policy: interpolationParam.weights_policy,
            zero_policy: interpolationParam.zero_policy,
          }

          this.setState({ open: false, mode: null, std: null })

          const taskId = new Date().getTime() + '_update_standard_points_' + calibration[0]._id
          const task = {
            id: taskId,
            title: t('view.common.calibration.update_std_pt'),
            operator: localStorage.getItem('SESSION_user_name'),
            date: new Date().getTime(),
            action: 'update_standard_points',
            percentage: 50,
            state: { value: 'running', message: '' },
            operation: mode + ' ' + (pointName === -1 ? 'all' : pointName),
            items: [
              {
                id: calibration[0]._id,
                type: 'calibration',
                name: calibration[0].name,
                compound: {
                  id: calibration[0]._id,
                  name: calibration[0].name,
                },
                batch: {
                  id: batch._id,
                  name: batch.name,
                },
              },
            ],
          }
          dispatch(fetchAddTask(task))

          displayPopupMessage(
            dispatch,
            'info',
            t('view.common.calibration.interpol_update'),
            <Fragment>
              {pointName === -1 ? (
                <Trans i18nKey="view.common.calibration.will_update_all">
                  You will update all the points to <i>{{ val2: mode }}</i>
                </Trans>
              ) : (
                <Trans i18nKey="view.common.calibration.will_update">
                  You will update the point <i>{{ val1: pointName }}</i> to <i>{{ val2: mode }}</i>
                </Trans>
              )}
              <div style={{ marginTop: 10 }}>
                <i>{t('view.common.calibration.time')}</i>
              </div>
            </Fragment>,
          )

          dispatch(fetchUpdateStdPoints(json, calibration[0]._id, collection, batch._id))
            .then(function (resp) {
              me.displayCalib(
                calibration[0].content.molecule.event,
                mainChannel,
                true,
                calibration[0].content.molecule,
              )
              displayPopupMessage(
                dispatch,
                'success',
                t('view.common.calibration.interpol_update'),
                pointName === -1 ? (
                  <Trans i18nKey="view.common.calibration.pt_now_all">
                    All the points are now '{{ val2: mode }}'
                  </Trans>
                ) : (
                  <Trans i18nKey="view.common.calibration.pt_now">
                    The point '{{ val1: pointName }}' is now '{{ val2: mode }}'
                  </Trans>
                ),
              )
              dispatch(fetchFinishTask(taskId, 'success', t('view.common.calibration.fine')))
              me.setState({ hasChanged: true })
            })
            .catch(function (error) {
              if (error && error.status !== undefined && error.status === 401) {
                error.statusText = t('view.common.utils.lease_locked.code')
                displayPopupMessage(
                  dispatch,
                  'warning',
                  t('view.common.calibration.interpol_update'),
                  <Fragment>
                    <div>
                      {pointName === -1 ? (
                        <Trans i18nKey="view.common.calibration.cannot_update_all">
                          Can not update all the points.
                        </Trans>
                      ) : (
                        <Trans i18nKey="view.common.calibration.cannot_update">
                          Can not update the point <i>{{ val: pointName }}</i>.
                        </Trans>
                      )}
                    </div>
                    {generateErrorPart(error)}
                  </Fragment>,
                )
              } else {
                displayPopupMessage(
                  dispatch,
                  'error',
                  t('view.common.calibration.interpol_update'),
                  <Fragment>
                    <div>
                      {pointName === -1 ? (
                        <Trans i18nKey="view.common.calibration.cannot_update_all_to">
                          Can not update all the points to
                          <i>{{ val2: mode }}</i>.
                        </Trans>
                      ) : (
                        <Trans i18nKey="view.common.calibration.cannot_update_to">
                          Can not update the point <i>{{ val1: pointName }}</i> to
                          <i>{{ val2: mode }}</i>.
                        </Trans>
                      )}
                    </div>
                    {generateErrorPart(error)}
                  </Fragment>,
                )
              }
              dispatch(fetchFinishTask(taskId, 'error', error))
            })
        }

        renderLabelPoint(index, calibItems) {
          const foundCalib = calibItems.filter(
            (item) => item.content.level !== undefined && item.content.level.toString() == index,
          )
          let label = [index, null]
          if (foundCalib && foundCalib[0] && foundCalib[0].content.analysis.name) {
            label = [foundCalib[0].content.analysis.name, foundCalib[0]._id]
          }
          return label
        }

        channelChange(e, collection) {
          const { dispatch } = this.props
          const { savedItem, event } = this.state
          const newChannel = parseFloat(e.target.value)
          if (savedItem) {
            dispatch(fetchChangeSelectedChannel(savedItem._id, newChannel, collection)).then(
              this.displayCalib(event, newChannel, null, savedItem),
            )
          } else {
            this.displayCalib(event, newChannel)
          }
        }

        interpolationChange(e, collection) {
          let messageDict = new Map()
          messageDict.set('ignore', t('view.common.calibration.message_dict.ignore'))
          messageDict.set('include', t('view.common.calibration.message_dict.include'))
          messageDict.set('force', t('view.common.calibration.message_dict.force'))

          let messageType = new Map()
          messageDict.set('weights_policy', t('view.common.calibration.message_dict.weights'))
          messageDict.set('type', t('view.common.calibration.message_dict.type'))
          messageDict.set('zero_policy', t('view.common.calibration.message_dict.zero'))

          const { dispatch, batch } = this.props
          const { calibration, mainChannel, sampleRefPoint } = this.state
          const newValue = e.target.value
          const newMessage =
            messageDict.get(newValue) !== undefined ? messageDict.get(newValue) : newValue
          const interpolationParam = e.target.name
          const paramMessage =
            messageDict.get(interpolationParam) !== undefined
              ? messageDict.get(interpolationParam)
              : interpolationParam
          const me = this

          const mainChannelObj = calibration[0].content.channels[mainChannel]
          let obj = mainChannelObj.interpolation
          obj[interpolationParam] = newValue
          obj['channel'] = mainChannel
          let pointsIncluded = mainChannelObj.included

          const operationMsg = (
            <Trans i18nKey="view.common.calibration.operation">
              {{ val1: paramMessage }} parameter set to {{ val2: newMessage }}
            </Trans>
          )
          const taskId = new Date().getTime() + '_update_interpolation_' + calibration[0]._id
          const task = {
            id: taskId,
            title: t('view.common.calibration.title'),
            operator: localStorage.getItem('SESSION_user_name'),
            date: new Date().getTime(),
            action: 'update_interpolation',
            percentage: 50,
            state: { value: 'running', message: '' },
            operation: operationMsg,
            items: [
              {
                id: calibration[0]._id,
                type: 'calibration',
                name: calibration[0].name,
                compound: {
                  id: calibration[0]._id,
                  name: calibration[0].name,
                },
                batch: {
                  id: batch._id,
                  name: batch.name,
                },
              },
            ],
          }
          dispatch(fetchAddTask(task))

          displayPopupMessage(
            dispatch,
            'info',
            t('view.common.calibration.title'),
            <Fragment>
              <Trans i18nKey="view.common.calibration.info_msg">
                {{ val1: calibration[0].name }} interpolation parameter
                <i>{{ val2: paramMessage }}</i> will be updated to <i>{{ val3: newMessage }}</i>
              </Trans>
              <div style={{ marginTop: 10 }}>
                <i>{t('view.common.calibration.time')}</i>
              </div>
            </Fragment>,
          )

          dispatch(
            fetchUpdateInterpolation(
              obj,
              pointsIncluded,
              calibration[0]._id,
              collection,
              batch._id,
            ),
          )
            .then(function (resp) {
              if (calibration[0]._id === resp?.unitaryCalibrationLoaded?._id) {
                // Reload the calibration only if it's still the same calib loaded at this moment
                me.displayCalib(
                  calibration[0].content.molecule.event,
                  mainChannel,
                  true,
                  calibration[0].content.molecule,
                )
              }
              displayPopupMessage(
                dispatch,
                'success',
                t('view.common.calibration.title'),
                <Trans i18nKey="view.common.calibration.success">
                  {{ val1: calibration[0].name }} interpolation parameter '{{ val2: paramMessage }}'
                  is updated to '{{ val3: newMessage }}'`
                </Trans>,
              )
              dispatch(fetchFinishTask(taskId, 'success', t('view.common.calibration.fine')))
              if (calibration[0]._id === resp._id) {
                // Update the hasChanged only if it's still the same calib loaded at this moment
                me.setState({ hasChanged: true })
              }
            })
            .catch(function (error) {
              if (error && error.status !== undefined && error.status === 401) {
                error.statusText = t('view.common.utils.lease_locked.code')
                displayPopupMessage(
                  dispatch,
                  'warning',
                  t('view.common.calibration.title'),
                  <Fragment>
                    <div>
                      <Trans i18nKey="view.common.calibration.cannot">
                        Can not update the point <i>{{ val: calibration[0].name }}</i>.
                      </Trans>
                    </div>
                    {generateErrorPart(error)}
                  </Fragment>,
                )
              } else {
                displayPopupMessage(
                  dispatch,
                  'error',
                  t('view.common.calibration.title'),
                  <Fragment>
                    <div>
                      <Trans i18nKey="view.common.calibration.cannot_inter">
                        Can not update interpolation for <i>{{ val: calibration[0].name }}</i>.
                      </Trans>
                    </div>
                    {generateErrorPart(error)}
                  </Fragment>,
                )
              }
              dispatch(fetchFinishTask(taskId, 'error', error))
            })
        }

        resetCalibration() {
          const { dispatch, batch, collection } = this.props
          const { calibration, mainChannel, sampleRefPoint } = this.state
          const me = this

          // Retro compatibility with old version with parameters & initial_included
          const mainChannelObj = calibration[0].content.channels[mainChannel]
          const parametersObj = calibration[0].content.parameters
          const calibration_name = calibration[0].name
          if (!parametersObj || !mainChannelObj.initial_included) {
            displayPopupMessage(
              dispatch,
              'warning',
              t('view.common.calibration.reset_title'),
              <Trans i18nKey="view.common.calibration.reset_warning_msg">
                Cannot find initial parameters for calibration {{ val1: calibration_name }} in batch
                {{ val2: batch.name }}...
              </Trans>,
            )
          } else {
            let obj = {
              interpolation_type: parametersObj.interpolation_type,
              interpolation_weights_policy: parametersObj.interpolation_weights_policy,
              interpolation_zero_policy: parametersObj.interpolation_zero_policy,
              channel: mainChannel,
            }
            let pointsIncluded = mainChannelObj.initial_included

            const taskId = new Date().getTime() + '_reset_uas_' + calibration[0]._id
            const task = {
              id: taskId,
              title: t('view.common.calibration.reset_title'),
              operator: localStorage.getItem('SESSION_user_name'),
              date: new Date().getTime(),
              action: 'reset_calibration',
              percentage: 50,
              state: { value: 'running', message: '' },
              operation: t('view.common.calibration.reset_op'),
              items: [
                {
                  id: calibration[0]._id,
                  type: 'calibration',
                  name: calibration_name,
                  compound: {
                    id: calibration[0]._id,
                    name: calibration_name,
                  },
                  batch: {
                    id: batch._id,
                    name: batch.name,
                  },
                },
              ],
            }
            dispatch(fetchAddTask(task))

            displayPopupMessage(
              dispatch,
              'info',
              t('view.common.calibration.reset_title'),
              <Fragment>
                <Trans i18nKey="view.common.calibration.will_reset">
                  You will reset the calibration for <i>{calibration_name}</i>
                </Trans>
                <div style={{ marginTop: 10 }}>
                  <i>{t('view.common.calibration.time')}</i>
                </div>
              </Fragment>,
            )

            dispatch(
              fetchResetCalibration(obj, pointsIncluded, calibration[0]._id, collection, batch._id),
            )
              .then(function (resp) {
                if (calibration[0]._id === resp?.unitaryCalibrationLoaded?._id) {
                  // Reload the calibration only if it's still the same calib loaded at this moment
                  me.displayCalib(
                    calibration[0].content.molecule.event,
                    mainChannel,
                    true,
                    calibration[0].content.molecule,
                  )
                }
                displayPopupMessage(
                  dispatch,
                  'success',
                  t('view.common.calibration.reset_title'),
                  <Fragment>
                    <Trans i18nKey="view.common.calibration.reset_success">
                      The Calibration has been reset for <i>{{ val: calibration_name }}</i>
                    </Trans>
                  </Fragment>,
                )
                if (calibration[0]._id === resp._id) {
                  // Update the hasChanged only if it's still the same calib loaded at this moment
                  me.setState({ hasChanged: true })
                }
                dispatch(fetchFinishTask(taskId, 'success', t('view.common.calibration.fine')))
              })
              .catch(function (error) {
                if (error && error.status !== undefined && error.status === 401) {
                  error.statusText = t('view.common.utils.lease_locked.code')
                  displayPopupMessage(
                    dispatch,
                    'warning',
                    t('view.common.calibration.reset_title'),
                    <Fragment>
                      <div>{t('view.common.calibration.cannot_reset')}</div>
                      {generateErrorPart(error)}
                    </Fragment>,
                  )
                } else {
                  displayPopupMessage(
                    dispatch,
                    'warning',
                    t('view.common.calibration.reset_title'),
                    <Fragment>
                      <div>
                        <Trans i18nKey="view.common.calibration.warning_reset_cannot">
                          The reset for for <i>{{ val: calibration_name }}</i> calibration can not
                          be applied.
                        </Trans>
                      </div>
                      {generateErrorPart(error)}
                    </Fragment>,
                  )
                }
                dispatch(fetchFinishTask(taskId, 'error', error))
              })
          }
        }

        handleConfirmResetClose(confirm) {
          this.setState({ openConfirmReset: false })
          if (confirm) {
            this.resetCalibration()
          }
        }

        handleReset(event) {
          event.stopPropagation()
          event.preventDefault()
          this.setState({ openConfirmReset: true })
        }

        handleCalibrate(event) {
          const { dispatch, batch, collection } = this.props
          const { calibration, mainChannel, sampleRefPoint } = this.state
          event.stopPropagation()
          event.preventDefault()

          const me = this

          // const mainChannelObj = calibration[0].content.channels[mainChannel]
          // let obj = mainChannelObj.interpolation
          // obj[interpolationParam] = newValue
          // obj['channel'] = mainChannel
          // let pointsIncluded = mainChannelObj.included

          const operationMsg = t('view.common.calibration.recompute_operation')
          const taskId = new Date().getTime() + '_update_calibration_' + calibration[0]._id
          const task = {
            id: taskId,
            title: t('view.common.calibration.recompute_title'),
            operator: localStorage.getItem('SESSION_user_name'),
            date: new Date().getTime(),
            action: 'update_calibration',
            percentage: 50,
            state: { value: 'running', message: '' },
            operation: operationMsg,
            items: [
              {
                id: calibration[0]._id,
                type: 'calibration',
                name: calibration[0].name,
                compound: {
                  id: calibration[0]._id,
                  name: calibration[0].name,
                },
                batch: {
                  id: batch._id,
                  name: batch.name,
                },
              },
            ],
          }
          dispatch(fetchAddTask(task))

          displayPopupMessage(
            dispatch,
            'info',
            t('view.common.calibration.recompute_title'),
            <Fragment>
              {t('view.common.calibration.calib_will_update')}
              <div style={{ marginTop: 10 }}>
                <i>{t('view.common.calibration.time')}</i>
              </div>
            </Fragment>,
          )

          dispatch(fetchUpdateCalibration(calibration[0]._id, collection, batch._id))
            .then(function (resp) {
              me.displayCalib(
                calibration[0].content.molecule.event,
                mainChannel,
                true,
                calibration[0].content.molecule,
              )
              displayPopupMessage(
                dispatch,
                'success',
                t('view.common.calibration.recompute_title'),
                <Trans i18nKey="view.common.calibration.calib_updated">
                  {{ val: calibration[0].name }} is updated
                </Trans>,
              )
              dispatch(fetchFinishTask(taskId, 'success', t('view.common.calibration.fine')))
              me.setState({ hasChanged: true })
            })
            .catch(function (error) {
              if (error && error.status !== undefined && error.status === 401) {
                error.statusText = t('view.common.utils.lease_locked.code')
                displayPopupMessage(
                  dispatch,
                  'warning',
                  t('view.common.calibration.recompute_title'),
                  <Fragment>
                    <div>{t('view.common.calibration.cannot_calib')}</div>
                    {generateErrorPart(error)}
                  </Fragment>,
                )
              } else {
                displayPopupMessage(
                  dispatch,
                  'error',
                  t('view.common.calibration.recompute_title'),
                  <Fragment>
                    <div>
                      <Trans i18nKey="view.common.calibration.cannot_calib2">
                        Can not update <i>{{ val: calibration[0].name }}</i>.
                      </Trans>
                    </div>
                    {generateErrorPart(error)}
                  </Fragment>,
                )
              }
              dispatch(fetchFinishTask(taskId, 'error', error))
            })
        }

        render() {
          const {
            batch,
            classes,
            collection,
            compounds,
            unitaryAnalyses,
            onCalibrationPointClick,
            featuresConfig,
          } = this.props
          const {
            isLoaded,
            isFetching,
            calibration,
            open,
            openDialog,
            mouseX,
            mouseY,
            mode,
            std,
            hasMoved,
            mainChannel,
            displayTitle,
            displayPaper,
            istd,
            sampleRefPoint,
            defaultValues,
            openConfirmReset,
            hasChanged,
            hover,
            menu,
          } = this.state

          var me = this
          const label_rejected_name = t('view.common.calibration.rejected')
          const label_included_name = t('view.common.calibration.included')

          function canEditCalib() {
            const edit_calibration = featuresConfig?.content?.edit_calibration
            const param_exist = typeof edit_calibration === 'boolean'
            const getRights = canIModifyTheCalibration(batch)
            return param_exist ? edit_calibration && getRights : true
          }
          function isAnimationEnabled() {
            return (
              (localStorage.getItem('SETTINGS_animation')
                ? localStorage.getItem('SETTINGS_animation')
                : 'false') === 'true'
            )
          }

          const eventItems = compounds
          // collection === 'compound' || collection === 'batch'
          //   ? compounds
          //   : calibration
          //   ? unitaryAnalyses.filter(
          //       (ua) => ua.content.event === calibration[0].content.molecule.event,
          //     )
          //   : []
          const qcItems = eventItems.filter(
            (item) =>
              item.content.analysis_type === 'matrix_bio_doped' ||
              item.content.analysis_type === 'std_end',
          )
          const calibItems = eventItems.filter(
            (item) => item.content.analysis_type === 'calibration',
          )

          if (!isLoaded) {
            return displayPaper ? (
              <Paper elevation={0} className={classes.paper}>
                <Typography className={classes.noItem} variant="h5" noWrap>
                  {t('view.common.calibration.no_item')}
                  <Box>
                    <SelectAllIcon style={{ fontSize: 40 }} />
                  </Box>
                </Typography>
              </Paper>
            ) : (
              <Typography className={classes.noItem} variant="h5" noWrap>
                {t('view.common.calibration.no_item')}
                <Box>
                  <SelectAllIcon style={{ fontSize: 40 }} />
                </Box>
              </Typography>
            )
          }

          const recomputeButton = (
            <span
              style={{
                position: 'absolute',
                width: 30,
                height: 30,
                left: 15,
                top: 10,
                background: 'white',
              }}
            >
              <Tooltip
                key={'tooltip_reset'}
                placement="top"
                arrow
                title={
                  <div style={{ fontSize: 13, padding: 5 }}>
                    {t('view.common.calibration.launch_new')}
                  </div>
                }
              >
                <IconButton
                  className={classes.calibrateButton}
                  style={{ width: 30 }}
                  aria-label="reset"
                  onClick={(event) => {
                    me.handleCalibrate(event)
                  }}
                >
                  <SettingsInputComponentIcon className={classes.calibrateIcon} />
                </IconButton>
              </Tooltip>
            </span>
          )

          // if (isFetching) {
          //   return displayPaper ? (
          //     <Paper className={classes.paper}>
          //       <Loading mode="miniCircle" />
          //     </Paper>
          //   ) : (
          //     <Loading mode="miniCircle" />
          //   )
          // }
          // Case of ISTD selected
          if (isLoaded && istd) {
            return displayPaper ? (
              <Paper className={classes.paper}>
                {calibration[0] && calibration[0].content.wip && recomputeButton}
                <Typography className={classes.noItem} variant="h5" noWrap>
                  {t('view.common.calibration.no_graph')}
                  <Box>
                    <Nothing style={{ width: 35, height: 35 }} />
                  </Box>
                </Typography>
              </Paper>
            ) : (
              <span>
                {calibration[0] && calibration[0].content.wip && recomputeButton}
                <Typography className={classes.noItem} variant="h5" noWrap>
                  {t('view.common.calibration.no_graph')}
                  <Box>
                    <Nothing style={{ width: 35, height: 35 }} />
                  </Box>
                </Typography>
              </span>
            )
          }
          let datasets = [
            {
              // data:
              //   calibration?.[0]?.content?.graph?.concentration_line &&
              //   calibration?.[0]?.content?.channels?.[mainChannel]?.graph?.area_line
              //     ? calibration[0].content.graph.concentration_line.map((v, i) => ({
              //         x: v,
              //         y: calibration[0].content.channels[mainChannel].graph.area_line[i],
              //       }))
              //     : [],
              data:
                calibration?.[0]?.content?.channels?.[mainChannel]?.interpolation?.params &&
                calibration?.[0]?.content?.channels?.[mainChannel]?.graph?.points
                  ? Array.from({ length: 10 }, (_, i) => {
                      const max =
                        Math.max(
                          ...Object.values(
                            calibration[0].content.channels[mainChannel].graph.points || {},
                          ).map((arr) => arr[1]),
                        ) * 1.1
                      const [a, b, c] = calibration[0].content.channels[mainChannel].interpolation
                        .params || [0, 0, 0]
                      const interpo =
                        calibration[0].content.channels[mainChannel].interpolation.type
                      const x = (i * max) / 9
                      const y = interpo === 'linear' ? a * x + b : a * Math.pow(x, 2) + b * x + c
                      return { x, y }
                    })
                  : [],
              label: t('view.common.calibration.calib'),
              backgroundColor: '#165c90',
              pointBorderColor: '#165c90',
              borderColor: '#165c90',
              pointBackgroundColor: '#fff',
              pointBorderWidth: 0,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: '#e7aa70',
              pointHoverBorderColor: 'rgba(220,220,220,1)',
              pointHoverBorderWidth: 2,
              pointRadius: 0,
              pointHitRadius: 0,
              showLine: true,
              borderWidth: 0.5,
              fill: false,
              dragData: false,
            },
            {
              data:
                calibration &&
                calibration[0] &&
                calibration[0].content &&
                calibration[0].content.graph &&
                calibration[0].content.graph.concentration_line &&
                calibration[0].content.channels[mainChannel] &&
                calibration[0].content.channels[mainChannel].graph &&
                calibration[0].content.channels[mainChannel].included &&
                calibration[0].content.channels[mainChannel].graph.points
                  ? Object.entries(calibration[0].content.channels[mainChannel].included)
                      .filter((point) => point[1])
                      .map(function (point) {
                        const pt =
                          calibration[0].content.channels[mainChannel].graph.points[point[0]]
                        const [label, ua_id] = me.renderLabelPoint(point[0], calibItems)
                        if (Array.isArray(pt)) {
                          return {
                            x: pt[1],
                            y: pt[0],
                            type: label,
                            ua_id: ua_id,
                          }
                        } else {
                          return {
                            x: parseFloat(point[0]),
                            y: pt,
                            type: label,
                            ua_id: ua_id,
                          }
                        }
                      })
                  : [],
              label: label_included_name,
              pointStyle: 'rectRot',
              backgroundColor: '#165c90',
              pointBorderColor: '#ffffff00',
              borderColor: '#165c90',
              pointBackgroundColor: '#e7aa70',
              pointBorderWidth: 0,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: '#e7aa70',
              pointHoverBorderColor: 'rgba(220,220,220,1)',
              pointHoverBorderWidth: 2,
              pointRadius: 4,
              pointHitRadius: 5,
              showLine: false,
              borderWidth: 0.5,
              fill: false,
              dragData: false,
            },
            {
              data:
                calibration &&
                calibration[0] &&
                calibration[0].content &&
                calibration[0].content.graph &&
                calibration[0].content.graph.concentration_line &&
                calibration[0].content.channels[mainChannel] &&
                calibration[0].content.channels[mainChannel].graph
                  ? Object.entries(calibration[0].content.channels[mainChannel].included)
                      .filter((point) => !point[1])
                      .map(function (point) {
                        const pt =
                          calibration[0].content.channels[mainChannel].graph.points[point[0]]
                        const [label, ua_id] = me.renderLabelPoint(point[0], calibItems)
                        if (Array.isArray(pt)) {
                          return { x: pt[1], y: pt[0], type: label, ua_id: ua_id }
                        } else {
                          return { x: parseFloat(point[0]), y: pt, type: label, ua_id: ua_id }
                        }
                      })
                  : [],
              label: label_rejected_name,
              pointStyle: 'crossRot',
              backgroundColor: '#c70505',
              pointBorderColor: '#c70505',
              borderColor: '#c70505',
              pointBackgroundColor: '#c70505',
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: '#c70505',
              pointHoverBorderColor: '#c70505',
              pointHoverBorderWidth: 2,
              pointRadius: 4,
              pointHitRadius: 5,
              showLine: false,
              borderWidth: 0.5,
              fill: false,
              dragData: false,
            },
            {
              data: qcItems
                ? qcItems
                    .filter(
                      (qc) =>
                        isNumber(qc.content.gt_concentration) &&
                        qc.content.channels &&
                        qc.content.channels[mainChannel] &&
                        calibration &&
                        calibration[0] &&
                        calibration[0].content &&
                        calibration[0].content.parameters &&
                        isNumber(
                          typeof calibration[0].content.parameters.use_istd === 'boolean' &&
                            !calibration[0].content.parameters.use_istd
                            ? qc.content.channels[mainChannel].peak
                              ? qc.content.channels[mainChannel].peak.area
                              : null
                            : qc.content.channels[mainChannel].area_ratio,
                        ),
                    )
                    .map((qc) => ({
                      x: qc.content.gt_concentration,
                      y:
                        typeof calibration[0].content.parameters.use_istd === 'boolean' &&
                        !calibration[0].content.parameters.use_istd
                          ? qc.content.channels[mainChannel].peak.area
                          : qc.content.channels[mainChannel].area_ratio,
                      type: qc.content.analysis.name + ' (' + qc.content.analysis_type + ')',
                    }))
                : [],
              label: t('view.common.calibration.qc_pt'),
              pointStyle: 'triangle',
              backgroundColor: '#708ee7',
              pointBorderColor: '#708ee7',
              borderColor: '#708ee7',
              pointBackgroundColor: '#708ee7',
              pointBorderWidth: 0,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: '#708ee7',
              pointHoverBorderColor: '#708ee7',
              pointHoverBorderWidth: 2,
              pointRadius: 4,
              pointHitRadius: 5,
              showLine: false,
              borderWidth: 0.5,
              fill: false,
              dragData: false,
            },
          ]

          if (sampleRefPoint) {
            const samplePoint = {
              data: [sampleRefPoint],
              label: t('view.common.calibration.sample_ref'),
              pointStyle: 'circle',
              backgroundColor: '#e1424200',
              pointBorderColor: '#e14242',
              borderColor: '#e14242',
              pointBackgroundColor: '#e1424200',
              pointBorderWidth: 2,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: '#e14242',
              pointHoverBorderColor: 'rgba(220,220,220,0)',
              pointHoverBorderWidth: 2,
              pointRadius: 4,
              pointHitRadius: 10,
              showLine: false,
              borderWidth: 0,
              tension: 0,
            }
            const samplePointLine = {
              data: [{ x: 0, y: sampleRefPoint.y }, sampleRefPoint, { x: sampleRefPoint.x, y: 0 }],
              label: t('view.common.calibration.sample_line'),
              backgroundColor: '#e1424200',
              pointBorderColor: '#e14242',
              borderColor: '#e1424280',
              pointBackgroundColor: '#fff',
              pointBorderWidth: 0,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: '#e7aa70',
              pointHoverBorderColor: 'rgba(220,220,220,1)',
              pointHoverBorderWidth: 2,
              pointRadius: 0,
              pointHitRadius: 0,
              showLine: true,
              borderWidth: 1.5,
              fill: false,
              tension: 0,
            }
            datasets = [...datasets, samplePointLine, samplePoint]
          }

          const displayStd = (mode) => {
            this.setState({ mode: mode })
          }

          const handleSelectPoint = (ua_id) => {
            onCalibrationPointClick(ua_id)
            this.setState({ open: false, mode: null, std: null })
          }

          const handleOpenPoint = (realIndex, calibItems) => {
            const calib = calibItems.filter(
              (item) =>
                item.content.level !== undefined && item.content.level.toString() == realIndex,
            )[0]
            const id = calib ? calib.content.analysis.id : null
            if (id) {
              const url = `${window.location.origin}${window.location.pathname}?${batch._id}/analysis/${id}`
              openInNewTab(url)
              this.setState({ open: false, mode: null, std: null })
            }
          }

          const handleStd = (std, reject) => {
            const { calibration, mainChannel, mode } = this.state
            const modeUpdated =
              typeof reject === 'boolean' ? (reject ? 'include' : 'exclude') : mode
            if (modeUpdated === 'exclude') {
              const ptsInt = Object.keys(
                calibration[0] &&
                  calibration[0].content.channels &&
                  calibration[0].content.channels[mainChannel] &&
                  calibration[0].content.channels[mainChannel].included
                  ? calibration[0].content.channels[mainChannel].included
                  : [],
              ).map((item) => {
                return parseFloat(item)
              })
              if (Math.min(...ptsInt) === std || Math.max(...ptsInt) === std) {
                // console.log('Pts is min or max: ' + std)
                this.setState({ open: false, openDialog: true, std: std })
                return
              }
            }
            this.updateStdPoints(std)
          }

          const handleClose = () => {
            this.setState({ open: false, mode: null, std: null })
          }

          // Click on point and select it on table / grid
          const handleClick = (event) => {
            const { hover } = this.state
            event.preventDefault()
            if (hover && onCalibrationPointClick) {
              const dataset = datasets[hover.datasetIndex]
              const data = dataset.data[hover.index]
              onCalibrationPointClick(data.ua_id)
            }
          }

          const handleOpen = (event) => {
            const { hover } = this.state
            event.preventDefault()

            const reject =
              hover && hover.datasetIndex && datasets[hover.datasetIndex]
                ? datasets[hover.datasetIndex].label === label_rejected_name
                : false

            const isDatasetStd =
              hover && hover.datasetIndex && datasets[hover.datasetIndex]
                ? datasets[hover.datasetIndex].label === label_rejected_name ||
                  datasets[hover.datasetIndex].label === label_included_name
                : false

            if (hover && isDatasetStd) {
              const dataset = datasets[hover.datasetIndex]
              const data = dataset.data[hover.index]
              this.setState({
                mode: reject ? 'include' : 'exclude',
                open: true,
                mouseX: event.clientX - 2,
                mouseY: event.clientY - 4,
                menu: {
                  index: hover.index,
                  datasetIndex: hover.datasetIndex,
                  data: data,
                  dataset: hover.index,
                  mouseX: event.clientX - 2,
                  mouseY: event.clientY - 4,
                },
              })
            } else {
              this.setState({
                open: true,
                mouseX: event.clientX - 2,
                mouseY: event.clientY - 4,
                menu: {
                  index: null,
                  datasetIndex: null,
                  data: null,
                  dataset: null,
                  mouseX: event.clientX - 2,
                  mouseY: event.clientY - 4,
                },
              })
            }
          }

          const handleCloseDialog = (event, confirm) => {
            const { std } = this.state
            this.setState({ openDialog: false, mode: null })
            if (typeof confirm === 'boolean' && confirm) {
              this.updateStdPoints(std)
            }
          }

          const shouldDisplay = (m, std) => {
            // Afficher un message au mode inclusion/exclusion si 5 ou 500
            const list = calibration[0].content.channels[mainChannel].included
            if (typeof std !== 'number') {
              const allTrue = Object.keys(list).every((key) => list[key])
              const allFalse = Object.keys(list).every((key) => !list[key])
              // return { display: mode !== null ? 'none' : 'flex' }
              const display =
                (m === 'exclude' && allTrue) ||
                (m === 'include' && allFalse) ||
                (!allTrue && !allFalse)
              return { display: display && mode === null ? 'flex' : 'none' }
            }
            if (m === 'exclude') {
              return { display: list[std] ? 'flex' : 'none' }
            } else if (m === 'include') {
              return { display: list[std] ? 'none' : 'flex' }
            }
            return { display: 'none' }
          }

          const createMenu = () => {
            let realIndex = null
            const reject =
              menu && menu.datasetIndex && datasets[menu.datasetIndex]
                ? datasets[menu.datasetIndex].label === label_rejected_name
                : false
            if (
              menu &&
              menu.index !== null &&
              calibration[0] &&
              calibration[0].content.channels &&
              calibration[0].content.channels[mainChannel] &&
              calibration[0].content.channels[mainChannel].included
            ) {
              const filteredItems = Object.entries(
                calibration[0].content.channels[mainChannel].included,
              ).filter((e) => {
                return reject ? !e[1] : e[1]
              })
              realIndex = filteredItems[menu.index] ? parseInt(filteredItems[menu.index][0]) : null
            }

            if (menu) {
              if (
                realIndex !== null &&
                menu.index !== null &&
                menu.datasetIndex !== null &&
                menu.data !== null &&
                menu.dataset !== null
              ) {
                return (
                  <Fragment>
                    <MenuItem key={realIndex + '0'} disabled={true}>
                      <Typography variant="inherit">
                        {me.renderLabelPoint(realIndex, calibItems)[0]}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      key={realIndex + '1'}
                      onClick={() => handleOpenPoint(realIndex, calibItems)}
                    >
                      <ListItemIcon>
                        <OpenInNewIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">
                        {t('view.common.calibration.open_tab')}
                      </Typography>
                    </MenuItem>
                    {canEditCalib() && (
                      <Fragment>
                        {onCalibrationPointClick && (
                          <MenuItem
                            key={realIndex + '2'}
                            onClick={() =>
                              handleSelectPoint(me.renderLabelPoint(realIndex, calibItems)[1])
                            }
                          >
                            <ListItemIcon>
                              <GpsFixedIcon fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit">
                              {t('view.common.calibration.select')}
                            </Typography>
                          </MenuItem>
                        )}
                        <MenuItem
                          key={realIndex + '3'}
                          onClick={() => handleStd(parseInt(realIndex), reject)}
                        >
                          <ListItemIcon>
                            {reject ? (
                              <ThumbUpIcon fontSize="small" />
                            ) : (
                              <ThumbDownIcon fontSize="small" />
                            )}
                          </ListItemIcon>
                          <Typography variant="inherit">
                            {reject
                              ? t('view.common.calibration.include')
                              : t('view.common.calibration.exclude')}
                          </Typography>
                        </MenuItem>
                      </Fragment>
                    )}
                  </Fragment>
                )
              } else if (canEditCalib()) {
                // return [
                //   <MenuItem key={-1} onClick={() => handleStd(-1, true)}>
                //     <ListItemIcon>
                //       <ThumbUpIcon fontSize="small" />
                //     </ListItemIcon>
                //     <Typography variant="inherit">All excluded</Typography>
                //   </MenuItem>,
                //   Object.keys(
                //     calibration[0] &&
                //       calibration[0].content.channels &&
                //       calibration[0].content.channels[mainChannel] &&
                //       calibration[0].content.channels[mainChannel].included
                //       ? calibration[0].content.channels[mainChannel].included
                //       : [],
                //   ).map((key) => (
                //     <MenuItem
                //       key={key}
                //       style={shouldDisplay('include', parseInt(key))}
                //       onClick={() => handleStd(parseInt(key), true)}
                //     >
                //       <ListItemIcon>
                //         <ThumbUpIcon fontSize="small" />
                //       </ListItemIcon>
                //       <Typography variant="inherit">
                //         {me.renderLabelPoint(key, calibItems)}
                //       </Typography>
                //     </MenuItem>
                //   )),
                // ]
                return (
                  <Fragment>
                    <MenuItem
                      onClick={() => displayStd('include')}
                      style={shouldDisplay('include')}
                    >
                      <ListItemIcon>
                        <ThumbUpIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">
                        {t('view.common.calibration.include')}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={() => displayStd('exclude')}
                      style={shouldDisplay('exclude')}
                    >
                      <ListItemIcon>
                        <ThumbDownIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">
                        {t('view.common.calibration.exclude')}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      key={-1}
                      onClick={() => handleStd(-1, mode)}
                      style={{ display: mode === null ? 'none' : 'flex' }}
                    >
                      <ListItemIcon>
                        {mode === 'include' && <ThumbUpIcon fontSize="small" />}
                        {mode === 'exclude' && <ThumbDownIcon fontSize="small" />}
                      </ListItemIcon>
                      <Typography variant="inherit">
                        {mode === 'include'
                          ? t('view.common.calibration.include')
                          : t('view.common.calibration.exclude')}{' '}
                        {t('view.common.calibration.all')}
                      </Typography>
                    </MenuItem>
                    <Divider style={{ display: mode === null ? 'none' : 'flex' }} />
                    {Object.keys(
                      calibration[0] &&
                        calibration[0].content.channels &&
                        calibration[0].content.channels[mainChannel] &&
                        calibration[0].content.channels[mainChannel].included
                        ? calibration[0].content.channels[mainChannel].included
                        : [],
                    ).map((key) => (
                      <MenuItem
                        key={key}
                        onClick={() => handleStd(parseInt(key))}
                        style={shouldDisplay(mode, parseInt(key))}
                      >
                        <ListItemIcon>
                          {mode === 'include' && <ThumbUpIcon fontSize="small" />}
                          {mode === 'exclude' && <ThumbDownIcon fontSize="small" />}
                        </ListItemIcon>
                        <Typography variant="inherit">
                          {me.renderLabelPoint(key, calibItems)[0]}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Fragment>
                )
              } else {
                return (
                  <Fragment>
                    <MenuItem onClick={() => this.setState({ open: false })} key={'no_right'}>
                      <ListItemIcon>
                        <PanoramaFishEyeIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">
                        {t('view.common.calibration.no_available')}
                      </Typography>
                    </MenuItem>
                  </Fragment>
                )
              }
            } else {
              return null
            }
          }

          const chartContent = (
            <Fragment>
              <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                TransitionComponent={TransitionTop}
                aria-labelledby="dialog-title"
              >
                <StyledDialogTitle>{t('view.common.calibration.exclude_pt')}</StyledDialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {t('view.common.calibration.exclude_confirm')}{' '}
                    {menu && menu.data && menu.data.type
                      ? menu.data.type
                      : me.renderLabelPoint(std, calibItems)[0]}{' '}
                    ?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={(event) => handleCloseDialog(event, true)} color="primary">
                    {t('view.common.calibration.ok')}
                  </Button>
                  <Button
                    autoFocus
                    onClick={(event) => handleCloseDialog(event, false)}
                    color="primary"
                  >
                    {t('view.common.calibration.cancel')}
                  </Button>
                </DialogActions>
              </Dialog>
              {/*<Menu*/}
              {/*  keepMounted*/}
              {/*  open={open}*/}
              {/*  onClose={handleClose}*/}
              {/*  anchorReference="anchorPosition"*/}
              {/*  anchorPosition={*/}
              {/*    mouseY !== null && mouseX !== null ? { top: mouseY, left: mouseX } : undefined*/}
              {/*  }*/}
              {/*>*/}
              {/*  <MenuItem onClick={() => displayStd('include')} style={shouldDisplay('include')}>*/}
              {/*    <ListItemIcon>*/}
              {/*      <ThumbUpIcon fontSize="small" />*/}
              {/*    </ListItemIcon>*/}
              {/*    <Typography variant="inherit">Include</Typography>*/}
              {/*  </MenuItem>*/}
              {/*  <MenuItem onClick={() => displayStd('exclude')} style={shouldDisplay('exclude')}>*/}
              {/*    <ListItemIcon>*/}
              {/*      <ThumbDownIcon fontSize="small" />*/}
              {/*    </ListItemIcon>*/}
              {/*    <Typography variant="inherit">Exclude</Typography>*/}
              {/*  </MenuItem>*/}
              {/*  {Object.keys(*/}
              {/*    calibration[0] &&*/}
              {/*      calibration[0].content.channels &&*/}
              {/*      calibration[0].content.channels[mainChannel] &&*/}
              {/*      calibration[0].content.channels[mainChannel].included*/}
              {/*      ? calibration[0].content.channels[mainChannel].included*/}
              {/*      : [],*/}
              {/*  ).map((key) => (*/}
              {/*    <MenuItem*/}
              {/*      key={key}*/}
              {/*      onClick={() => handleStd(parseInt(key))}*/}
              {/*      style={shouldDisplay(mode, parseInt(key))}*/}
              {/*    >*/}
              {/*      <ListItemIcon>*/}
              {/*        {mode === 'include' && <ThumbUpIcon fontSize="small" />}*/}
              {/*        {mode === 'exclude' && <ThumbDownIcon fontSize="small" />}*/}
              {/*      </ListItemIcon>*/}
              {/*      <Typography variant="inherit">*/}
              {/*        {me.renderLabelPoint(key, calibItems)}*/}
              {/*      </Typography>*/}
              {/*    </MenuItem>*/}
              {/*  ))}*/}
              {/*</Menu>*/}
              <Menu
                keepMounted
                open={open}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                  mouseY !== null && mouseX !== null ? { top: mouseY, left: mouseX } : undefined
                }
                className={classes.calibMenu}
              >
                {open && createMenu()}
              </Menu>
              {this.state.waiting && (
                <div className={classes.loadingUpdate}>
                  <Loading mode="microCircle" />
                </div>
              )}
              <span
                // className={clsx('fullscreenMode', classes.fullscreen)}
                style={{
                  position: 'absolute',
                  width: displayTitle ? 30 : 25,
                  height: displayTitle ? 30 : 25,
                  right: displayTitle ? 16 : 44,
                  top: displayTitle ? 15 : 65,
                  background: 'white',
                  borderRadius: displayTitle ? 0 : 17,
                }}
              >
                <ErrorBoundaryGuard isDialog>
                  <AlphacodFlagIcon
                    item={rebuildUnitaryCalibrations(calibration[0])}
                    hideGeneralTab={true}
                    collection={collection}
                  />
                </ErrorBoundaryGuard>
              </span>
              {displayTitle && calibration[0].content.wip && recomputeButton}
              <Stack
                direction="column"
                justifyContent="space-between"
                alignItems="stretch"
                spacing={0}
                style={{ height: '100%' }}
              >
                {displayTitle && (
                  <Grid container justify="center">
                    <Tooltip
                      key={'tooltip_calibName'}
                      placement="top"
                      arrow
                      title={
                        <div style={{ fontSize: 13, padding: 5 }}>
                          {t('view.common.calibration.calibration')}{' '}
                          {calibration[0].content.molecule.name}
                        </div>
                      }
                    >
                      <Typography className={classes.title} noWrap>
                        {calibration[0].content.molecule.name}
                      </Typography>
                    </Tooltip>
                  </Grid>
                )}
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justify="center"
                  className={classes.optionCtn}
                >
                  <Grid item>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel shrink id="demo-simple-select-placeholder-label-label1">
                        {t('view.common.calibration.channel')}
                      </InputLabel>
                      <Select
                        value={mainChannel}
                        name={'channel'}
                        onChange={(event) => this.channelChange(event, collection)}
                        displayEmpty
                        autoWidth
                        inputProps={{
                          readOnly: !canEditCalib(),
                          'aria-label': 'Channel',
                        }}
                      >
                        <MenuItem value="" disabled>
                          {t('view.common.calibration.channel')}
                        </MenuItem>
                        {Object.keys(
                          calibration[0] && calibration[0].content.channels
                            ? calibration[0].content.channels
                            : [],
                        ).map((channel) => (
                          <MenuItem key={channel} value={channel}>
                            {channel}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel shrink id="demo-simple-select-placeholder-label-label1">
                        {t('view.common.calibration.curve')}
                      </InputLabel>
                      <Select
                        value={defaultValues.curve}
                        name={'type'}
                        onChange={(event) => this.interpolationChange(event, collection)}
                        displayEmpty
                        autoWidth
                        inputProps={{
                          readOnly: !canEditCalib(),
                          'aria-label': 'Curve fit',
                        }}
                      >
                        <MenuItem value="" disabled>
                          {t('view.common.calibration.curve')}
                        </MenuItem>
                        <MenuItem value={'linear'}>{t('view.common.calibration.linear')}</MenuItem>
                        <MenuItem value={'quadra'}>{t('view.common.calibration.quadra')}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel shrink id="demo-simple-select-placeholder-label-label2">
                        {t('view.common.calibration.weighting')}
                      </InputLabel>
                      <Select
                        value={defaultValues.weighting}
                        name={'weights_policy'}
                        onChange={(event) => this.interpolationChange(event, collection)}
                        displayEmpty
                        autoWidth
                        inputProps={{
                          readOnly: !canEditCalib(),
                          'aria-label': 'Weighting',
                        }}
                      >
                        <MenuItem value="" disabled>
                          {t('view.common.calibration.weighting')}
                        </MenuItem>
                        <MenuItem value={'none'}>1</MenuItem>
                        <MenuItem value={'1/C'}>1/C</MenuItem>
                        <MenuItem value={'1/C²'}>1/C²</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel shrink id="demo-simple-select-placeholder-label-label3">
                        {t('view.common.calibration.zero')}
                      </InputLabel>
                      <Select
                        value={defaultValues.zero}
                        name={'zero_policy'}
                        onChange={(event) => this.interpolationChange(event, collection)}
                        displayEmpty
                        autoWidth
                        inputProps={{
                          readOnly: !canEditCalib(),
                          'aria-label': 'Zero',
                        }}
                      >
                        <MenuItem value="" disabled>
                          {t('view.common.calibration.zero')}
                        </MenuItem>
                        <MenuItem value={'none'}>{t('view.common.calibration.ignored')}</MenuItem>
                        <MenuItem value={'included'}>
                          {t('view.common.calibration.included_menu')}
                        </MenuItem>
                        <MenuItem value={'force'}>{t('view.common.calibration.force')}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    {hasChanged && canEditCalib() && (
                      <Tooltip
                        key={'tooltip_reset'}
                        placement="top"
                        arrow
                        title={
                          <div style={{ fontSize: 13, padding: 5 }}>
                            {t('view.common.calibration.reset_calib')}
                          </div>
                        }
                      >
                        <IconButton
                          className={classes.settingsButton}
                          aria-label="reset"
                          onClick={(event) => {
                            me.handleReset(event)
                          }}
                        >
                          <SettingsBackupRestoreIcon className={classes.settingsIcon} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
                {/*<Scatter data={this.state.data} options={{ maintainAspectRatio: false }} />*/}
                <div
                  // style={{ height: displayTitle ? 'calc(100% - 65px)' : 'calc(100% - 47px)' }}
                  style={{ height: '100%', position: 'relative', width: '100%' }}
                  onContextMenu={handleOpen}
                  onClick={handleClick}
                >
                  <ErrorBoundaryGuard>
                    <div style={{ position: 'absolute', height: '100%', width: '100%' }}>
                      <Scatter
                        ref={this.chartRef}
                        data={{
                          datasets: datasets,
                        }}
                        updateMode={'none'}
                        options={{
                          // onClick: function (evt, element) {
                          //   if (
                          //     this.getElementAtEvent(evt) &&
                          //     this.getElementAtEvent(evt)[0] &&
                          //     this.getElementAtEvent(evt)[0]._index
                          //   ) {
                          //     debugger
                          //   }
                          //   if (element.length > 0) {
                          //     var ind = element[0]._index
                          //     debugger
                          //   }
                          // },
                          animation: {
                            duration: isAnimationEnabled() ? 1000 : 0,
                          },
                          onHover: function (e, fields) {
                            let hover = null
                            if (fields.length > 0) {
                              hover = {
                                index: fields[0]._index,
                                datasetIndex: fields[0]._datasetIndex,
                              }
                            }
                            me.setState({ hover: hover })
                          },
                          responsive: true,
                          maintainAspectRatio: false,
                          dragData: true,
                          dragX: true,
                          title: {
                            display: false,
                            text: t('view.common.calibration.calibration'),
                            // fontSize: 20,
                          },
                          legend: {
                            display: false,
                          },
                          scales: {
                            xAxes: [
                              {
                                ticks: {
                                  maxTicksLimit: 4,
                                  callback: function (value) {
                                    if (value < 0) {
                                      return ''
                                    }
                                    if (value > 9999) {
                                      return value.toExponential(1)
                                    }
                                    return value.toFixed(1)
                                  },
                                },
                              },
                            ],
                            yAxes: [
                              {
                                ticks: {
                                  // min: -2,
                                  // maxTicksLimit: 5,
                                  // beginAtZero: true,
                                  callback: function (value) {
                                    // return value.toFixed(1)
                                    return value.toExponential(1)
                                  },
                                },
                              },
                            ],
                          },
                          tooltips: {
                            callbacks: {
                              title: function (tooltipItem, data) {
                                // if (
                                //   data.datasets[tooltipItem[0].datasetIndex].label ===
                                //   'Quality control points'
                                // ) {
                                return data.datasets[tooltipItem[0].datasetIndex].data[
                                  tooltipItem[0].index
                                ].type
                                // } else {
                                //   return null
                                // }
                              },
                              label: function (tooltipItem) {
                                const label =
                                  '( ' +
                                  parseFloat(tooltipItem.label).toFixed(2) +
                                  ' : ' +
                                  parseFloat(tooltipItem.value).toFixed(4) +
                                  ' )'
                                return label
                              },
                            },
                          },
                          plugins: {
                            zoom: {
                              // Container for pan options
                              pan: {
                                // Boolean to enable panning
                                enabled: true,
                                // Panning directions.
                                mode: 'xy',
                                onPan: () => this.setState({ hasMoved: true }),
                              },
                              // Container for zoom options
                              zoom: {
                                // Boolean to enable zooming
                                enabled: true,
                                // Zooming directions.
                                mode: 'xy',
                                onZoom: () => this.setState({ hasMoved: true }),
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </ErrorBoundaryGuard>
                  {hasMoved ? (
                    <IconButton
                      aria-label="clear"
                      onClick={this.onClear}
                      className={classes.resetZoom}
                    >
                      <ResetZoom />
                    </IconButton>
                  ) : null}
                  <div style={{ position: 'absolute', left: 60, top: 10 }}>
                    {/*<div*/}
                    {/*style={{ color: '#165c90', fontSize: '10px' }}*/}
                    {/*>{`Event: ${calibration[0].content.molecule.event}`}</div>*/}
                    <div
                      style={{ color: '#165c90', fontSize: '10px' }}
                    >{`Q: ${calibration[0].content.molecule.channels[mainChannel].q1} >  ${calibration[0].content.molecule.channels[mainChannel].q3}`}</div>
                    <div style={{ color: '#165c90', fontSize: '10px' }}>{`${
                      calibration[0].content.channels[mainChannel] &&
                      calibration[0].content.channels[mainChannel].coefficient_of_determination
                        ? 'R²: ' +
                          calibration[0].content.channels[
                            mainChannel
                          ].coefficient_of_determination.toFixed(6)
                        : ''
                    }`}</div>
                    <div style={{ color: '#165c90', fontSize: '10px' }}>{`${
                      calibration[0].content.channels[mainChannel] &&
                      calibration[0].content.channels[mainChannel].interpolation &&
                      calibration[0].content.channels[mainChannel].interpolation.params
                        ? 'y = ' +
                          (calibration[0].content.channels[mainChannel].interpolation.params
                            .length === 3
                            ? calibration[0].content.channels[
                                mainChannel
                              ].interpolation.params[0].toExponential(2) +
                              'x²' +
                              (calibration[0].content.channels[mainChannel].interpolation
                                .params[1] < 0
                                ? ' - '
                                : ' + ') +
                              Math.abs(
                                calibration[0].content.channels[mainChannel].interpolation
                                  .params[1],
                              ).toExponential(2) +
                              'x' +
                              (calibration[0].content.channels[mainChannel].interpolation
                                .params[2] < 0
                                ? ' - '
                                : ' + ') +
                              Math.abs(
                                calibration[0].content.channels[mainChannel].interpolation
                                  .params[2],
                              ).toExponential(2)
                            : calibration[0].content.channels[
                                mainChannel
                              ].interpolation.params[0].toExponential(2) +
                              'x' +
                              (calibration[0].content.channels[mainChannel].interpolation
                                .params[1] < 0
                                ? ' - '
                                : ' + ') +
                              Math.abs(
                                calibration[0].content.channels[mainChannel].interpolation
                                  .params[1],
                              ).toExponential(2))
                        : ''
                    }`}</div>
                  </div>
                </div>
              </Stack>
            </Fragment>
          )

          return (
            <div
              style={
                displayPaper
                  ? {
                      height: '100%',
                      overflow: 'hidden',
                      borderRadius: 4,
                      // boxShadow:
                      //   '0px 2px 7px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                    }
                  : {
                      height: '100%',
                      overflow: 'hidden',
                    }
              }
              onContextMenu={(e) => e.preventDefault()}
            >
              <ErrorBoundaryGuard isDialog>
                <CalibrationResetDialog
                  open={openConfirmReset}
                  closeFct={this.handleConfirmResetClose}
                  calibration={[rebuildUnitaryCalibrations(calibration[0])]}
                  from={'calibration'}
                />
              </ErrorBoundaryGuard>
              {displayPaper ? (
                <Paper
                  className={classes.paper}
                  style={{
                    boxShadow: 'none',
                  }}
                >
                  {chartContent}
                </Paper>
              ) : (
                chartContent
              )}
            </div>
          )
        }
      },
    ),
  ),
)
