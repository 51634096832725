import { find } from 'lodash'
import { API_BO_URL } from '../../utils/config'
import { convertErrorCodeToType } from '../tools'
import { receiveUpdateNote } from './actions'
import { fetchChangeSettings } from '../settings/actions'

export const getAnalysisById = (store, id) => find(store.items, { _id: id })

export async function getAnalysisRawById(id, dispatch) {
  try {
    const response = await fetch(`${API_BO_URL()}.analysis/${id}`, {
      method: 'GET',
      credentials: 'include',
    })
    const json = await response.json()
    const error = response.status !== 200
    if (error) {
      const authenticationError = response.status === 403
      if (authenticationError) {
        dispatch(fetchChangeSettings('loginPopup', true))
      }
      console.log(response.statusText)
      return null
    } else {
      return json
    }
  } catch (e) {
    console.log(e)
    return null
  }
}
