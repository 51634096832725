import React from 'react'
import styled from 'styled-components'
import { GET_PRIMARY_COLOR, GET_SECONDARY_COLOR } from '../utils/config'

export default (class Loading extends React.Component {
  render() {
    const { mode, style, customBackgroundColor, size } = this.props
    const primaryColor = GET_PRIMARY_COLOR()
    const secondaryColor = GET_SECONDARY_COLOR()

    const LoadingAlphacod = styled.div`
      width: 200px;
      height: 200px;
      background-color: ${secondaryColor};
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      #box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.5), 0 4px 10px 0 rgba(0, 0, 0, 0.5);
      overflow: hidden;
      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 200px;
        height: 200px;
        top: 100px;
        left: 85px;
        background-color: #fff;
      }
      &:before {
        border-radius: 40%;
        background: #ffffff;
        animation: wave 6s linear infinite;
        width: 240px;
        height: 240px;
        top: 90px;
        left: 75px;
      }
      &:after {
        border-radius: 45%;
        background: ${primaryColor};
        animation: wave 5s linear infinite;
      }
      @keyframes wave {
        0% {
          transform: rotate(0);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `
    const LoadingDots = styled.div`
      width: 300px;
      height: 100px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #000;
      filter: blur(10px) contrast(20);
      &:before,
      &:after {
        content: '';
        width: 70px;
        height: 70px;
        position: absolute;
        background: #fff;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &:before {
        left: 20%;
        animation: osc-l 2.5s ease infinite;
      }
      &:after {
        left: 80%;
        animation: osc-r 2.5s ease infinite;
        background: #0ff;
      }
      @keyframes osc-l {
        0% {
          left: 20%;
        }
        50% {
          left: 50%;
        }
        100% {
          left: 20%;
        }
      }
      @keyframes osc-r {
        0% {
          left: 80%;
        }
        50% {
          left: 50%;
        }
        100% {
          left: 80%;
        }
      }
    `
    const Loading3Dots = styled.div`
      width: 300px;
      height: 100px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    `
    const Loading3Dots1 = styled.div`
      position: absolute;
      width: 2vw;
      height: 2vw;
      background: ${primaryColor};
      top: 50%;
      left: 0%;
      border-radius: 50%;
      z-index: 1500;
      -webkit-animation: forward 2.3s linear infinite;
      -moz-animation: forward 2.3s linear infinite;
      -o-animation: forward 2.3s linear infinite;
      animation: forward 2.3s linear infinite;
      animation-delay: -0.46s;
      @keyframes forward {
        0% {
          left: 0%;
          opacity: 0;
          background: ${secondaryColor};
        }
        10% {
          left: 33%;
          opacity: 1;
        }
        90% {
          left: 66%;
          opacity: 1;
        }
        100% {
          left: 100%;
          opacity: 0;
        }
      }
    `
    const Loading3Dots2 = styled.div`
      position: absolute;
      width: 2vw;
      height: 2vw;
      background: ${primaryColor};
      top: 50%;
      left: 0%;
      border-radius: 50%;
      z-index: 1500;
      -webkit-animation: forward 2.3s linear infinite;
      -moz-animation: forward 2.3s linear infinite;
      -o-animation: forward 2.3s linear infinite;
      animation: forward 2.3s linear infinite;
      animation-delay: -0.92s;
      @keyframes forward {
        0% {
          left: 0%;
          opacity: 0;
          background: ${secondaryColor};
        }
        10% {
          left: 33%;
          opacity: 1;
        }
        90% {
          left: 66%;
          opacity: 1;
        }
        100% {
          left: 100%;
          opacity: 0;
        }
      }
    `
    const Loading3Dots3 = styled.div`
      position: absolute;
      width: 2vw;
      height: 2vw;
      background: ${primaryColor};
      top: 50%;
      left: 0%;
      border-radius: 50%;
      z-index: 1500;
      -webkit-animation: forward 2.3s linear infinite;
      -moz-animation: forward 2.3s linear infinite;
      -o-animation: forward 2.3s linear infinite;
      animation: forward 2.3s linear infinite;
      animation-delay: -1.38s;
      @keyframes forward {
        0% {
          left: 0%;
          opacity: 0;
          background: ${secondaryColor};
        }
        10% {
          left: 33%;
          opacity: 1;
        }
        90% {
          left: 66%;
          opacity: 1;
        }
        100% {
          left: 100%;
          opacity: 0;
        }
      }
    `
    const Loading3Dots4 = styled.div`
      position: absolute;
      width: 2vw;
      height: 2vw;
      background: ${primaryColor};
      top: 50%;
      left: 0%;
      border-radius: 50%;
      z-index: 1500;
      -webkit-animation: forward 2.3s linear infinite;
      -moz-animation: forward 2.3s linear infinite;
      -o-animation: forward 2.3s linear infinite;
      animation: forward 2.3s linear infinite;
      animation-delay: -1.84s;
      @keyframes forward {
        0% {
          left: 0%;
          opacity: 0;
          background: ${secondaryColor};
        }
        10% {
          left: 33%;
          opacity: 1;
        }
        90% {
          left: 66%;
          opacity: 1;
        }
        100% {
          left: 100%;
          opacity: 0;
        }
      }
    `
    const Loading3Dots5 = styled.div`
      position: absolute;
      width: 2vw;
      height: 2vw;
      background: ${primaryColor};
      top: 50%;
      left: 0%;
      border-radius: 50%;
      z-index: 1500;
      -webkit-animation: forward 2.3s linear infinite;
      -moz-animation: forward 2.3s linear infinite;
      -o-animation: forward 2.3s linear infinite;
      animation: forward 2.3s linear infinite;
      @keyframes forward {
        0% {
          left: 0%;
          opacity: 0;
          background: ${secondaryColor};
        }
        10% {
          left: 33%;
          opacity: 1;
        }
        90% {
          left: 66%;
          opacity: 1;
        }
        100% {
          left: 100%;
          opacity: 0;
        }
      }
    `

    const Circle = styled.div`
      position: relative;
      left: 50%;
      top: 50%;
      height: 20vw;
      width: 20vw;
      margin: -10vw 0 0 -10vw;
      border: 3px solid transparent;
      border-top-color: ${secondaryColor};
      border-bottom-color: ${secondaryColor};
      border-radius: 50%;
      z-index: 2;
      animation: spin 2s linear infinite;
      &:before {
        content: '';
        position: absolute;
        top: 2%;
        bottom: 2%;
        left: 2%;
        right: 2%;
        border: 3px solid transparent;
        z-index: 2;
        border-top-color: #ffffff;
        border-radius: 50%;
        animation: spin 3s linear infinite;
      }
      &:after {
        content: '';
        position: absolute;
        top: 5%;
        bottom: 5%;
        left: 5%;
        right: 5%;
        border: 3px solid transparent;
        border-top-color: ${primaryColor};
        z-index: 2;
        border-radius: 50%;
        animation: spin 1.5s linear infinite;
      }
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }

        50% {
          transform: rotate(180deg);
        }
        100% {
          transform: rotate(360deg);l
        }
      }
    `

    const MiniCircle = styled.div`
      position: absolute;
      left: calc(50% - 50px);
      top: calc(50% - 50px);
      height: 100px;
      width: 100px;
      border: 3px solid transparent;
      border-top-color: ${secondaryColor};
      border-bottom-color: ${secondaryColor};
      border-radius: 50%;
      z-index: 2;
      animation: spin 2s linear infinite;
      &:before {
        content: '';
        position: absolute;
        top: 2%;
        bottom: 2%;
        left: 2%;
        right: 2%;
        border: 3px solid transparent;
        z-index: 2;
        border-top-color: #ffffff;
        border-radius: 50%;
        animation: spin 3s linear infinite;
      }
      &:after {
        content: '';
        position: absolute;
        top: 5%;
        bottom: 5%;
        left: 5%;
        right: 5%;
        border: 3px solid transparent;
        border-top-color: ${primaryColor};
        z-index: 2;
        border-radius: 50%;
        animation: spin 1.5s linear infinite;
      }
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }

        50% {
          transform: rotate(180deg);
        }
        100% {
          transform: rotate(360deg);l
        }
      }
    `
    const MicroCircle = styled.div`
      position: relative;
      height: 100%;
      width: 100%;
      border: 2px solid transparent;
      border-top-color: ${secondaryColor};
      border-bottom-color: ${secondaryColor};
      border-radius: 50%;
      z-index: 2;
      animation: spin 2s linear infinite;
      &:before {
        content: '';
        position: absolute;
        top: 2%;
        bottom: 2%;
        left: 2%;
        right: 2%;
        border: 2px solid transparent;
        z-index: 2;
        border-top-color: #ffffff;
        border-radius: 50%;
        animation: spin 3s linear infinite;
      }
      &:after {
        content: '';
        position: absolute;
        top: 5%;
        bottom: 5%;
        left: 5%;
        right: 5%;
        border: 2px solid transparent;
        border-top-color: ${primaryColor};
        z-index: 2;
        border-radius: 50%;
        animation: spin 1.5s linear infinite;
      }
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }

        50% {
          transform: rotate(180deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `

    const MiniLoading3Dots = styled.div`
      width: 300px;
      height: 75px;
      position: relative;
      top: 40px;
      left: 45%;
      transform: translate(-50%, -50%);
      z-index: 1;
    `
    const MiniLoading3Dots1 = styled.div`
      position: absolute;
      width: 1vw;
      height: 1vw;
      background: ${primaryColor};
      filter: brightness(2);
      top: 50%;
      left: 0%;
      border-radius: 50%;
      z-index: 1500;
      -webkit-animation: forward 2.3s linear infinite;
      -moz-animation: forward 2.3s linear infinite;
      -o-animation: forward 2.3s linear infinite;
      animation: forward 2.3s linear infinite;
      animation-delay: -0.46s;
      @keyframes forward {
        0% {
          left: 0%;
          opacity: 0;
          background: ${primaryColor};
          filter: brightness(1);
        }
        10% {
          left: 33%;
          opacity: 1;
        }
        90% {
          left: 66%;
          opacity: 1;
        }
        100% {
          left: 100%;
          opacity: 0;
        }
      }
    `
    const MiniLoading3Dots2 = styled.div`
      position: absolute;
      width: 1vw;
      height: 1vw;
      background: ${primaryColor};
      filter: brightness(2);
      top: 50%;
      left: 0%;
      border-radius: 50%;
      z-index: 1500;
      -webkit-animation: forward 2.3s linear infinite;
      -moz-animation: forward 2.3s linear infinite;
      -o-animation: forward 2.3s linear infinite;
      animation: forward 2.3s linear infinite;
      animation-delay: -0.92s;
      @keyframes forward {
        0% {
          left: 0%;
          opacity: 0;
          background: ${primaryColor};
          filter: brightness(1);
        }
        10% {
          left: 33%;
          opacity: 1;
        }
        90% {
          left: 66%;
          opacity: 1;
        }
        100% {
          left: 100%;
          opacity: 0;
        }
      }
    `
    const MiniLoading3Dots3 = styled.div`
      position: absolute;
      width: 1vw;
      height: 1vw;
      background: ${primaryColor};
      filter: brightness(2);
      top: 50%;
      left: 0%;
      border-radius: 50%;
      z-index: 1500;
      -webkit-animation: forward 2.3s linear infinite;
      -moz-animation: forward 2.3s linear infinite;
      -o-animation: forward 2.3s linear infinite;
      animation: forward 2.3s linear infinite;
      animation-delay: -1.38s;
      @keyframes forward {
        0% {
          left: 0%;
          opacity: 0;
          background: ${primaryColor};
          filter: brightness(1);
        }
        10% {
          left: 33%;
          opacity: 1;
        }
        90% {
          left: 66%;
          opacity: 1;
        }
        100% {
          left: 100%;
          opacity: 0;
        }
      }
    `
    const MiniLoading3Dots4 = styled.div`
      position: absolute;
      width: 1vw;
      height: 1vw;
      background: ${primaryColor};
      filter: brightness(2);
      top: 50%;
      left: 0%;
      border-radius: 50%;
      z-index: 1500;
      -webkit-animation: forward 2.3s linear infinite;
      -moz-animation: forward 2.3s linear infinite;
      -o-animation: forward 2.3s linear infinite;
      animation: forward 2.3s linear infinite;
      animation-delay: -1.84s;
      @keyframes forward {
        0% {
          left: 0%;
          opacity: 0;
          background: ${primaryColor};
          filter: brightness(1);
        }
        10% {
          left: 33%;
          opacity: 1;
        }
        90% {
          left: 66%;
          opacity: 1;
        }
        100% {
          left: 100%;
          opacity: 0;
        }
      }
    `
    const MiniLoading3Dots5 = styled.div`
      position: absolute;
      width: 1vw;
      height: 1vw;
      background: ${primaryColor};
      filter: brightness(2);
      top: 50%;
      left: 0%;
      border-radius: 50%;
      z-index: 1500;
      -webkit-animation: forward 2.3s linear infinite;
      -moz-animation: forward 2.3s linear infinite;
      -o-animation: forward 2.3s linear infinite;
      animation: forward 2.3s linear infinite;
      @keyframes forward {
        0% {
          left: 0%;
          opacity: 0;
          background: ${primaryColor};
          filter: brightness(1);
        }
        10% {
          left: 33%;
          opacity: 1;
        }
        90% {
          left: 66%;
          opacity: 1;
        }
        100% {
          left: 100%;
          opacity: 0;
        }
      }
    `

    const Bubbles = styled.div`
      --n: 5;
      background: currentcolor;
      color: transparent;
      text-align: center;
      margin: 0 auto;
      width: 240px;
      height: 40px;
      text-align: center;
      position: absolute;
      top: 50%;
      //left: 50%;
      //-webkit-transform: translateY(-50%) translateX(-50%);

      > .dot {
        background: ${secondaryColor};

        &,
        &:after {
          display: inline-block;
          width: 2em;
          height: 2em;
          border-radius: 50%;
          animation: a 1.5s calc(((var(--i) + var(--o, 0)) / var(--n) - 1) * 1.5s) infinite;
        }

        &:after {
          --o: 1;
          background: ${customBackgroundColor ? customBackgroundColor : 'white'};
          content: '';
        }
      }
      @keyframes a {
        0%,
        50% {
          transform: scale(0);
        }
      }
    `

    const Alpha = styled.div`
      //position: relative;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: ${size}rem;
      height: ${size}rem;
      transform-style: preserve-3d;
      perspective: 800px;
      > .arc {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border-bottom: ${size}px solid;
        border-color: ${secondaryColor};

        //@for $i from 1 through 3 {
        //  &:nth-child(#{$i}) {
        //    animation: rotate#{$i} 1.15s linear infinite;
        //  }
        //}

        &:nth-child(1) {
          animation: rotate1 1.15s linear infinite;
          animation-delay: -0.8s;
        }

        &:nth-child(2) {
          animation: rotate2 1.15s linear infinite;
          animation-delay: -0.4s;
        }

        &:nth-child(3) {
          animation: rotate3 1.15s linear infinite;
          animation-delay: 0s;
        }
      }
      @keyframes rotate1 {
        from {
          transform: rotateX(35deg) rotateY(-45deg) rotateZ(0);
        }

        to {
          transform: rotateX(35deg) rotateY(-45deg) rotateZ(1turn);
        }
      }

      @keyframes rotate2 {
        from {
          transform: rotateX(50deg) rotateY(10deg) rotateZ(0);
        }

        to {
          transform: rotateX(50deg) rotateY(10deg) rotateZ(1turn);
        }
      }

      @keyframes rotate3 {
        from {
          transform: rotateX(35deg) rotateY(55deg) rotateZ(0);
        }

        to {
          transform: rotateX(35deg) rotateY(55deg) rotateZ(1turn);
        }
      }
    `

    switch (mode) {
      case 'bubbles':
        return (
          <Bubbles style={{ ...style }}>
            <div className="dot" style={{ '--i': 0 }} />
            <div className="dot" style={{ '--i': 1 }} />
            <div className="dot" style={{ '--i': 2 }} />
            <div className="dot" style={{ '--i': 3 }} />
            <div className="dot" style={{ '--i': 4 }} />
          </Bubbles>
        )
      case 'alphacod':
        return <LoadingAlphacod />
      case 'dots':
        return <LoadingDots />
      case '3dots':
        return (
          <Loading3Dots>
            <Loading3Dots1 />
            <Loading3Dots2 />
            <Loading3Dots3 />
            <Loading3Dots4 />
            <Loading3Dots5 />
          </Loading3Dots>
        )
      case 'mini3dots':
        return (
          <MiniLoading3Dots style={{ ...style }}>
            <MiniLoading3Dots1 />
            <MiniLoading3Dots2 />
            <MiniLoading3Dots3 />
            <MiniLoading3Dots4 />
            <MiniLoading3Dots5 />
          </MiniLoading3Dots>
        )
      case 'circle':
        return <Circle />
      case 'miniCircle':
        return <MiniCircle />
      case 'microCircle':
        return <MicroCircle />
      case 'alpha':
        return (
          <Alpha>
            <div className="arc"></div>
            <div className="arc"></div>
            <div className="arc"></div>
          </Alpha>
        )
      default:
        return <LoadingAlphacod />
    }
  }
})
