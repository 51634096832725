import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import Loading from '../Loading'
import CardMedia from '@material-ui/core/CardMedia'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { PUBLIC_URL } from '../../utils/config'
import Popover from '@material-ui/core/Popover'
import { fetchHideDetail } from '../../redux/detail/actions'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'
import {
  fetchBatch,
  fetchBatches,
  fetchBatchesDevices,
  fetchBatchesServices,
  fetchBatchesTypes,
  searchBatches,
} from '../../redux/batches/actions'
import { fetchAnalyses, searchAnalyses } from '../../redux/analyses/actions'
import { getBatchById } from '../../redux/batches/selectors'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import IconButton from '@material-ui/core/IconButton'
import { getClickAction, openInNewTab } from './Utils'
import { Analyses, Analysis } from './Style'
import { palette } from '@material-ui/system'
import { Tooltip } from '@material-ui/core'
import batches from '../../redux/batches/reducers'
import FolderIcon from '@material-ui/icons/Folder'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import PersonIcon from '@material-ui/icons/Person'
import StorageIcon from '@material-ui/icons/Storage'
import DnsIcon from '@material-ui/icons/Dns'
import { displayPopupMessage } from './Message'
import { fetchChangeSettings } from '../../redux/settings/actions'
import { t } from 'i18next'
import FolderDeleteOutlinedIcon from '@mui/icons-material/FolderDeleteOutlined'

const styles = (theme) => ({
  header: {
    boxShadow: 'inset 0px 0px 82px 10px rgba(0,0,0,1)',
  },
  searchCmp: {
    position: 'absolute',
    top: 65,
    left: 34,
    fontSize: 40,
    textShadow: '2px 1px 10px white',
    background: 'white',
    borderRadius: 13,
    boxShadow: '0 1px 6px 0 rgba(32, 33, 36, .28)',
    width: '40%',
  },
  searchFilter: {
    position: 'absolute',
    top: 65,
    right: 34,
  },
  cardImg: {
    opacity: 0.8,
  },
  popoverContainer: {
    background: '#00000021',
    '& > .MuiPopover-paper': {
      height: '80%',
      width: '80%',
      // transform: 'translate(-50%, -50%) !important',
      top: '10% !important',
      left: '10% !important',
      // padding: 20,
      overflow: 'hidden',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    // pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
    display: 'flex',
  },
  inputInput: {
    padding: '7px 16px',
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    fontSize: 25,
  },
  tableRow: {
    cursor: 'pointer',
  },
  container: {
    height: 'calc(100% - 180px)',
    top: 128,
    position: 'absolute',
  },
  openInNewTabButton: {
    padding: 0,
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
    },
  },
  retiredLogo: {
    color: theme.palette.common.error,
  },
  buttonCalib: {
    borderRadius: '18px !important',
    backgroundColor: '#e0e0e0',
    padding: '3px 12px 3px 14px',
    marginRight: theme.spacing(1),
    marginTop: 5,
    height: 32,
    border: '1px solid rgba(0, 0, 0, 0.12) !important',
    '& .MuiBox-root': {
      marginRight: 10,
    },
  },
  deviceFormControl: {
    '& .MuiSelect-select': {
      padding: '12px 36px 12px 14px',
    },
  },
})

const mapStateToProps = (state) => {
  const { analyses, batches } = state
  const { services, types } = batches
  return {
    services: services,
    types: types,
    searchedAnalyses: analyses.searchedAnalyses,
    searchedBatches: batches.searchedBatches,
    isSearching: analyses.isSearchingAnalyses || batches.isSearchingBatches,
  }
}

const columns = [
  { id: 'icon', label: '', minWidth: 0, width: 60 },
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'date', label: 'Date', minWidth: 170 },
  { id: 'batchName', label: 'Batch name', minWidth: 170 },
  {
    id: 'type',
    label: 'Type',
    minWidth: 80,
    // align: 'right',
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'file',
    label: 'Path',
    minWidth: 170,
    // align: 'right',
    // format: (value) => value.toLocaleString('en-US'),
  },
  // {
  //   id: 'open',
  //   minWidth: 0,
  //   width: 60,
  // },
]

const sortByName = (a, b) => {
  if (a.name < b.name) {
    return -1
  }
  if (a.name > b.name) {
    return 1
  }
  return 0
}

const createData = (searchedAnalyses, searchedBatches) => {
  if (searchedAnalyses || searchedBatches) {
    const analysisUpdated = searchedAnalyses.map((analysis) => ({ ...analysis, icon: 'analysis' }))
    const batchesUpdated = searchedBatches.map((bath) => ({ ...bath, icon: 'batch' }))
    const results = analysisUpdated.concat(batchesUpdated).sort(sortByName)
    return results.map(function (item) {
      const isAnalysis = item.icon === 'analysis'
      return {
        icon: item.icon,
        name: item.name,
        id: item._id,
        batchName: isAnalysis ? item.content.batch.name : '',
        batchId: isAnalysis ? item.content.batch.id : item._id,
        type: isAnalysis ? item.content.type : 'batch',
        file: isAnalysis ? item.content.file : item.content.folder,
        date: isAnalysis ? '' : item.content.date_ts,
        retired:
          isAnalysis && item.content.retired && item.content.retired !== true
            ? false
            : item.content.retired,
      }
    })
  }
  return []
}

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: 'rgb(0 0 0 / 2%)',
    },
  },
}))(TableRow)
const StyledTableCell = withStyles((theme) => ({
  root: {
    border: 'none',
  },
  head: {
    backgroundColor: 'rgb(0 0 0 / 0.50)',
    color: theme.palette.common.white,
    height: 54,
    fontSize: 'larger',
  },
}))(TableCell)

export default withRouter(
  connect(mapStateToProps)(
    withStyles(styles)(
      class Search extends React.Component {
        constructor(props) {
          super(props)
          this.state = {
            timer: null,
            filterAnalysisService: localStorage.getItem('SEARCH_filter_service')
              ? localStorage.getItem('SEARCH_filter_service') === 'null'
                ? null
                : localStorage.getItem('SEARCH_filter_service')
              : null,
            filterAnalysisType: localStorage.getItem('SEARCH_filter_type')
              ? localStorage.getItem('SEARCH_filter_type') === 'null'
                ? null
                : localStorage.getItem('SEARCH_filter_type')
              : null,
            searchedValue: props.researched,
            searchTimer: null,
          }
        }

        async componentDidMount() {
          const { dispatch } = this.props
          dispatch(fetchBatchesServices())
          dispatch(fetchBatchesTypes())
        }

        openSearch(event, row) {
          event.stopPropagation()
          event.preventDefault()
          let url = ''
          if (row.icon === 'analysis') {
            url = `${window.location.origin}${window.location.pathname}?${row.batchId}/analysis/${row.id}`
          } else {
            url = `${window.location.origin}${window.location.pathname}?batch/${row.id}`
          }
          openInNewTab(url)
        }

        displayAnalysis(event, analysis) {
          const { onClose } = this.props
          onClose(event)

          if (analysis.icon === 'analysis') {
            this.props.history.push('/?' + analysis.batchId + '/analysis/' + analysis.id)
          } else {
            this.props.history.push('/?batch/' + analysis.id)
          }
        }

        // applyTypeFilter(newFilter) {
        //   const { dispatch } = this.props
        //   const { searchedValue } = this.state
        //
        //   localStorage.setItem('SEARCH_filter_type', newFilter)
        //   this.setState({ filterAnalysisType: newFilter })
        //   dispatch(searchAnalyses(searchedValue, newFilter))
        //   dispatch(searchBatches(searchedValue, newFilter))
        // }

        handleServiceChange(event) {
          const { dispatch } = this.props
          const { filterAnalysisType, searchedValue } = this.state
          const service =
            event.target.value.toLowerCase() === 'all' ? null : event.target.value.toLowerCase() //pesticide /myco/spe/hydro
          dispatch(searchAnalyses(searchedValue, service, filterAnalysisType))
          dispatch(searchBatches(searchedValue, service, filterAnalysisType))
          localStorage.setItem('SEARCH_filter_service', service)
          this.setState({ filterAnalysisService: service })
        }

        handleTypeChange(event) {
          const { dispatch } = this.props
          const { filterAnalysisService, searchedValue } = this.state
          const type =
            event.target.value.toLowerCase() === 'all' ? null : event.target.value.toLowerCase() //lc / gc / fluo
          dispatch(searchAnalyses(searchedValue, filterAnalysisService, type))
          dispatch(searchBatches(searchedValue, filterAnalysisService, type))
          localStorage.setItem('SEARCH_filter_type', type)
          this.setState({ filterAnalysisType: type })
        }

        onSearch(e) {
          const { dispatch } = this.props
          const { searchTimer, filterAnalysisService, filterAnalysisType } = this.state
          const newValue = e.target.value
          clearTimeout(searchTimer)
          const newSearchTimer = setTimeout(function () {
            if (newValue !== '') {
              dispatch(searchAnalyses(newValue, filterAnalysisService, filterAnalysisType))
              dispatch(searchBatches(newValue, filterAnalysisService, filterAnalysisType))
            }
          }, 1500)
          this.setState({ searchedValue: newValue, searchTimer: newSearchTimer })
        }

        render() {
          const {
            dispatch,
            classes,
            researched,
            searchedAnalyses,
            searchedBatches,
            isSearching,
            devices,
            services,
            types,
          } = this.props

          const { filterAnalysisType, filterAnalysisDevice, filterAnalysisService } = this.state

          const me = this

          const StyledToggleButton = withStyles((theme) => ({
            selected: {
              backgroundColor: theme.palette.secondary.light + ' !important',
              color: 'white !important',
            },
          }))(ToggleButton)

          const headerCmp = (
            <Box className={classes.header}>
              <CardMedia
                className={classes.cardImg}
                component="img"
                alt="header image"
                height="184"
                image={PUBLIC_URL + '/images/search_bkg.jpg'}
                title="Header image"
              />
              <span className={classes.searchCmp}>
                <div className={classes.searchIcon}>
                  <SearchIcon style={{ fontSize: 30 }} />
                </div>
                <InputBase
                  autoFocus
                  placeholder={t('view.common.search.search')}
                  // autoComplete="new-password"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                  defaultValue={researched}
                  onChange={(e) => this.onSearch(e)}
                  // ref={searchGlobalTitleRef}
                />
              </span>
              <span className={classes.searchFilter}>
                {/*<ToggleButtonGroup*/}
                {/*  value={filterAnalysisType}*/}
                {/*  exclusive*/}
                {/*  onChange={(event, filter) => this.applyTypeFilter(filter)}*/}
                {/*  aria-label="text alignment"*/}
                {/*>*/}
                {/*  <StyledToggleButton className={classes.buttonCalib} value={'gc'} aria-label="GC">*/}
                {/*    <Analysis />*/}
                {/*    <Box mr={4}>GC</Box>*/}
                {/*  </StyledToggleButton>*/}
                {/*  <StyledToggleButton className={classes.buttonCalib} value={'lc'} aria-label="LC">*/}
                {/*    <Analysis />*/}
                {/*    <Box mr={4}>LC</Box>*/}
                {/*  </StyledToggleButton>*/}
                {/*</ToggleButtonGroup>*/}
                <FormControl
                  variant="outlined"
                  className={classes.deviceFormControl}
                  style={{ marginLeft: 50, background: 'white', borderRadius: 5 }}
                >
                  <InputLabel
                    id="service-select-outlined-label"
                    style={{ background: 'white', boxShadow: '0px 1px 8px 7px #ffffff' }}
                  >
                    {t('view.common.search.service')}
                  </InputLabel>
                  <Select
                    labelId="service-select-outlined-label"
                    id="service-simple-select-outlined"
                    value={filterAnalysisService === null ? 'all' : filterAnalysisService}
                    onChange={(e) => this.handleServiceChange(e)}
                    label={t('view.common.search.service')}
                  >
                    <MenuItem value="all">
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <PeopleAltIcon style={{ marginRight: 12, color: '#737373', height: 19 }} />
                        <em>{t('view.common.search.all')}</em>
                      </Grid>
                    </MenuItem>
                    {Object.keys(services)
                      .sort(function (a, b) {
                        if (a < b) return -1
                        if (a > b) return 1
                        return 0
                      })
                      .map((service, index) => (
                        <MenuItem value={service.toLowerCase()} key={service + '_key_' + index}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <PersonIcon style={{ marginRight: 10, color: '#737373', height: 19 }} />
                            <span>{service}</span>
                          </Grid>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  className={classes.deviceFormControl}
                  style={{ marginLeft: 30, background: 'white', borderRadius: 5 }}
                >
                  <InputLabel
                    id="type-select-outlined-label"
                    style={{ background: 'white', boxShadow: '0px 1px 8px 7px #ffffff' }}
                  >
                    {t('view.common.search.type')}
                  </InputLabel>
                  <Select
                    labelId="type-select-outlined-label"
                    id="type-simple-select-outlined"
                    value={filterAnalysisType === null ? 'all' : filterAnalysisType}
                    onChange={(e) => this.handleTypeChange(e)}
                    label={t('view.common.search.type')}
                  >
                    <MenuItem value="all">
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Analyses style={{ marginRight: 12, color: '#737373', height: 16 }} />
                        <em>{t('view.common.search.all')}</em>
                      </Grid>
                    </MenuItem>
                    {Object.keys(types)
                      .sort(function (a, b) {
                        if (a < b) return -1
                        if (a > b) return 1
                        return 0
                      })
                      .map((type, index) => (
                        <MenuItem value={type.toLowerCase()} key={type + '_key_' + index}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Analysis style={{ marginRight: 10, color: '#737373', height: 16 }} />
                            <span>{type}</span>
                          </Grid>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </span>
            </Box>
          )

          if (isSearching)
            return (
              <Fragment>
                {headerCmp}
                <Loading mode={'miniCircle'} />
              </Fragment>
            )

          return (
            <Fragment>
              {headerCmp}
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column, index) => (
                        <StyledTableCell
                          key={column.id + '_' + index}
                          align={column.align}
                          style={{ minWidth: column.minWidth, width: column.width }}
                        >
                          {column.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {createData(searchedAnalyses, searchedBatches).map((row, index) => {
                      return (
                        <StyledTableRow
                          className={classes.tableRow}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code + '_' + index}
                          // onClick={(e) => this.displayAnalysis(e, row)}
                          onClick={function (event) {
                            const openFct = () => {
                              me.displayAnalysis(event, row)
                            }
                            getClickAction(
                              dispatch,
                              event,
                              { ...row, _id: row.id, content: { batch: { id: row.batchId } } },
                              row.icon,
                              openFct,
                            )
                          }}
                        >
                          {columns.map((column, index) => {
                            if (column.id === 'open') {
                              return (
                                <StyledTableCell key={column.id + '_' + index} align={column.align}>
                                  <Tooltip
                                    key={'tooltip_newTab'}
                                    arrow
                                    title={
                                      <div style={{ fontSize: 13, padding: 5 }}>
                                        {t('view.common.search.new_tab')}
                                      </div>
                                    }
                                  >
                                    <IconButton
                                      aria-label="open"
                                      className={classes.openInNewTabButton}
                                      onClick={(event) => this.openSearch(event, row)}
                                    >
                                      <OpenInNewIcon />
                                    </IconButton>
                                  </Tooltip>
                                </StyledTableCell>
                              )
                            } else if (column.id === 'icon') {
                              return (
                                <StyledTableCell key={column.id + '_' + index} align={column.align}>
                                  <Tooltip
                                    key={'tooltip_newTab'}
                                    arrow
                                    title={
                                      <div style={{ fontSize: 13, padding: 5 }}>
                                        {row.retired
                                          ? t('view.common.search.batch_retired')
                                          : row.icon === 'batch'
                                          ? t('view.common.search.batch')
                                          : t('view.common.search.analysis')}
                                      </div>
                                    }
                                  >
                                    {row.retired ? (
                                      <FolderDeleteOutlinedIcon className={classes.retiredLogo} />
                                    ) : row.icon === 'batch' ? (
                                      <FolderIcon />
                                    ) : (
                                      <Analysis style={{ marginLeft: 4 }} />
                                    )}
                                  </Tooltip>
                                </StyledTableCell>
                              )
                            } else if (column.id === 'date') {
                              return (
                                <StyledTableCell key={column.id + '_' + index} align={column.align}>
                                  {row[column.id]
                                    ? new Intl.DateTimeFormat('en-GB', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: '2-digit',
                                      }).format(new Date(row[column.id] * 1000))
                                    : ''}
                                </StyledTableCell>
                              )
                            } else if (column.id === 'name') {
                              const value = row[column.id]
                              return (
                                <StyledTableCell key={column.id + '_' + index} align={column.align}>
                                  {row.retired ? (
                                    <span className={classes.retiredLogo}>{value}</span>
                                  ) : (
                                    value
                                  )}
                                </StyledTableCell>
                              )
                            } else {
                              const value = row[column.id]
                              return (
                                <StyledTableCell key={column.id + '_' + index} align={column.align}>
                                  {column.format && typeof value === 'number'
                                    ? column.format(value)
                                    : value}
                                </StyledTableCell>
                              )
                            }
                          })}
                        </StyledTableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <div style={{ left: 17, bottom: 14, position: 'absolute' }}>
                {t('view.common.search.nbr_result') +
                  ' ' +
                  (searchedAnalyses.length + searchedBatches.length)}
              </div>
            </Fragment>
          )
        }
      },
    ),
  ),
)
