import React, { Fragment } from 'react'
import { API_BO_URL, API_URL } from '../../utils/config'
import { AUTHENTICATION_FAILURE } from '../unitaryAnalyses/actions'
import { fetchChangeSettings } from '../settings/actions'
import { displayPopupMessage, generateErrorPart } from '../../view/common/Message'
import { receiveCurrentBatchLease } from '../batches/actions'

export const REQUEST_TEAMS = 'REQUEST_TEAMS'
export const RECEIVE_TEAMS = 'RECEIVE_TEAMS'

export function fetchTeams() {
  return async (dispatch) => {
    dispatch(requestTeams())

    // const response = await fetch(`${API_URL()}/teams`, {
    //   method: 'GET',
    //   credentials: 'include',
    // })
    // const authenticationError = response.status === 403
    // if (authenticationError) {
    //   return dispatch(fetchChangeSettings('loginPopup', true))
    // }
    // const json = await response.json()
    // return dispatch(receiveTeams(json, authenticationError))
    return fetch(`${API_URL()}/teams`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((json) => {
        return dispatch(receiveTeams(json, false))
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
          return dispatch(receiveTeams([], true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to get teams',
          <Fragment>
            <div>An error does not allow to get teams:</div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      })
  }
}
export function requestTeams() {
  return {
    type: REQUEST_TEAMS,
  }
}
export function receiveTeams(teams, authenticationError) {
  return {
    type: authenticationError ? AUTHENTICATION_FAILURE : RECEIVE_TEAMS,
    teams: teams,
  }
}
