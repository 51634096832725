import React from 'react'
import { makeStyles } from '@material-ui/core'
import { resetUnitaryCalibration } from '../common/Utils'
import { StyledDialogTitle, TransitionTop } from '../common/Style'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText'
import Chip from '@material-ui/core/Chip'
import WarningIcon from '@material-ui/icons/Warning'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { useDispatch } from 'react-redux'
import { Trans } from 'react-i18next'
import { t } from 'i18next'

const useStyles = makeStyles((theme) => ({
  moveChip: {
    margin: 3,
    marginBottom: 15,
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
  },
  warning: {
    color: theme.palette.common.warning,
    position: 'relative',
    left: -30,
  },
}))

export default function CalibrationResetDialog({ open, closeFct, calibration, from }) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleConfirmResetClose = (event, save) => {
    closeFct(save)
    // Not call in calibration view
    if (save && from !== 'calibration') {
      resetUnitaryCalibration(event, calibration, dispatch)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={(event) => handleConfirmResetClose(event, false)}
      TransitionComponent={TransitionTop}
      aria-labelledby="dialog-reset-title"
    >
      <StyledDialogTitle>{t('view.dialogs.calibration_reset.title')}</StyledDialogTitle>
      <DialogContent>
        <DialogContentText style={{ margin: 0 }}>
          <Trans i18nKey="view.dialogs.calibration_reset.msg">
            Are you sure you want to reset the unitary
            {{
              val:
                calibration.length > 1
                  ? t('view.dialogs.calibration_reset.calibrations')
                  : t('view.dialogs.calibration_reset.calibration'),
            }}
            :
          </Trans>
        </DialogContentText>
        <div style={{ maxWidth: 600, maxHeight: 400, overflow: 'scroll' }}>
          {calibration.map((item) => (
            <Chip
              key={item.name}
              className={classes.moveChip}
              style={{ margin: 3 }}
              label={item.name}
            />
          ))}
        </div>
        <DialogContentText style={{ margin: 0, marginTop: 15, paddingLeft: 34 }}>
          <WarningIcon className={classes.warning} />
          <Trans i18nKey="view.dialogs.calibration_reset.warning_msg">
            This action will be reset the <i>curve fit</i>, the <i>weighting</i>, the <i>zero</i>
            and all <i>range points</i>.
          </Trans>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={(event) => handleConfirmResetClose(event, false)}
          color="primary"
        >
          {t('view.dialogs.calibration_reset.cancel')}
        </Button>
        <Button onClick={(event) => handleConfirmResetClose(event, true, from)} color="primary">
          {t('view.dialogs.calibration_reset.yes')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
