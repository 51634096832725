import {
  DISPLAY_LIMS,
  HIDE_LIMS,
  RECEIVE_UNITARY_ANALYSES_LIMS,
  REQUEST_LIMS,
  REQUEST_AUTO_VALIDATION,
  RECEIVE_AUTO_VALIDATION,
  REQUEST_IGNORE_FLAGS,
  RECEIVE_IGNORED_FLAGS,
} from './actions'
import React from 'react'
import { AUTHENTICATION_FAILURE } from '../analyses/actions'
import { rebuildUnitaryAnalyses } from '../../view/common/Utils'

const initialState = {
  lims: {
    selection: [],
    hideGeneral: false,
    unitaryAnalysesLims: [],
    nonDetectedUA: [],

    // anchorEl: null,
  },
  visibility: false,
  isFetchingLims: false,
  isFetchingAutoValidation: false,
  autoValidated: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case REQUEST_LIMS:
      return {
        ...state,
        isFetchingLims: true,
      }

    case DISPLAY_LIMS:
      return {
        ...state,
        isFetchingLims: false,
        visibility: action.visibility,
        lims: action.lims,
      }

    case RECEIVE_UNITARY_ANALYSES_LIMS:
      return {
        ...state,
        isFetchingLims: false,
        visibility: action.visibility,
        lims: action.lims,
      }

    case HIDE_LIMS:
      return {
        ...state,
        isFetchingLims: false,
        visibility: action.visibility,
      }

    case AUTHENTICATION_FAILURE:
      return {
        ...state,
        isFetchingLims: false,
        error: true,
      }

    case REQUEST_AUTO_VALIDATION:
      return {
        ...state,
        isFetchingAutoValidation: true,
      }

    case RECEIVE_AUTO_VALIDATION:
      return {
        ...state,
        isFetchingAutoValidation: false,
        autoValidated: action.autoValidated.map((ua) => rebuildUnitaryAnalyses(ua)),
      }
    case REQUEST_IGNORE_FLAGS:
      return {
        ...state,
        isFetchingIgnoreFlags: true,
      }
    case RECEIVE_IGNORED_FLAGS:
      return {
        ...state,
        isFetchingIgnoreFlags: false,
        lims: {
          ...state.lims,
          unitaryAnalysesLims: state.lims.unitaryAnalysesLims.map((ual) => {
            if (ual._id === action.uaIgnoredFlags.content.analysis.id) {
              return {
                ...ual,
                limsUA: ual.limsUA.map((lua) => {
                  // Update only the good ua (it could be available in undetected list too),
                  // and to detect it, we search the presence of batchId field
                  if (lua._id === action.uaIgnoredFlags._id && lua.hasOwnProperty('batchId')) {
                    return {
                      ...lua,
                      flags: { ...lua.flags, globals: action.uaIgnoredFlags.content.flags },
                      extraFlags: {
                        ...lua.extraFlags,
                        ignored: true,
                      },
                    }
                  } else {
                    return lua
                  }
                }),
              }
            } else {
              return ual
            }
          }),
        },
      }
    default:
      return state
  }
}
