import React, { useEffect, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Detail from './common/Detail'
import 'react-colorful/dist/index.css'
import Polling from './common/Polling'
import JsonView from './common/JsonView'
import LoginDialog from './dialog/LoginDialog'
import ConfigurationDialog from './dialog/ConfigurationDialog'
import DropZone from './DropZone'
import RouterComponent from './RouterComponent'
import ErrorBoundaryGuard from './ErrorBoundaryGuard'
import DashboardToolbar from './DashboardToolbar'
import Loading from './Loading'
import { saveDateTimeDelta } from './common/Utils'
import { fetchCurrentBatchLease } from '../redux/batches/actions'
import { t } from 'i18next'
import { DATABASE } from '../utils/config'
import { fetchConfigurations } from '../redux/configuration/actions'
import { fetchGlobalConfigurations } from '../redux/global/actions'
import { fetchAddMessage } from '../redux/message/actions'
import HelperDialog from './dialog/HelperDialog'
import { fetchChangeSettings } from '../redux/settings/actions'
import WikiDialog from './dialog/WikiDialog'
import LogsDialog from './dialog/LogsDialog'
import ServerHelperDialog from './dialog/ServerHelperDialog'
import logo from '../resources/images/logo.svg'

const useStyles = makeStyles((theme) => ({
  '& .MuiContainer-maxWidthLg': {
    maxWidth: '100%',
  },
  root: {
    display: 'flex',
    background: 'rgba(250,250,250,0.80)',
    // backgroundColor: 'pink',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
  },
}))

const Dashboard = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const isGlobalConfigsLoaded = useSelector((state) => state.global.isGlobalConfigsLoaded)
  const featuresConfig = useSelector((state) => state.global.featuresConfig)
  const customerConfig = useSelector((state) => state.global.customerConfig)
  const isDatabaseOffline = useSelector((state) => state.settings.configurationDatabase.length > 0)
  const dataBaseLoaded = useSelector((state) => state.settings.dataBaseLoaded)
  const isWelcomeMessagePresent = useSelector(
    (state) => state.message.messages.filter((msg) => msg.connexionMessage).length > 0,
  )
  const openHelper = useSelector(function (state) {
    return state.settings.helperPopup
  })

  // Load the config for the upload mechanism
  const upload_mechanism = useMemo(() => {
    if (featuresConfig) {
      return featuresConfig.content.upload_mechanism
    } else {
      return false
    }
  }, [featuresConfig])

  useEffect(() => {
    // Function to open the helper is CTRL+H is triggered
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === 'h') {
        event.preventDefault()
        dispatch(fetchChangeSettings('helperPopup', !openHelper))
      }
    }
    // Add a specific listener to open an helper in case of CTRL+H is triggered
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [dispatch, openHelper])

  // I don't understand why this useEffect is called each time user change URL
  // even the value of dataBaseLoaded doesn't change...
  // So a connexion message is added each time we change for a new page
  useEffect(() => {
    if (dataBaseLoaded) {
      // Test if user is signed in to load configurations
      const cookies = Object.fromEntries(
        document.cookie.split('; ').map((v) => v.split(/=(.*)/s).map(decodeURIComponent)),
      )
      if (cookies.ToriiSessionId != null && cookies.ToriiSessionId !== '') {
        if (!isDatabaseOffline) {
          // Load global configs
          dispatch(fetchGlobalConfigurations()).then((response) => {
            const primaryColor = response.globalConfigs.filter((gc) => gc.name === 'customer')[0]
              ?.content?.primaryColor
            const secondaryColor = response.globalConfigs.filter((gc) => gc.name === 'customer')[0]
              ?.content?.secondaryColor
            if (primaryColor) {
              localStorage.setItem('SETTINGS_primary_color_from_db', primaryColor)
            } else {
              localStorage.removeItem('SETTINGS_primary_color_from_db')
            }
            if (secondaryColor) {
              localStorage.setItem('SETTINGS_secondary_color_from_db', secondaryColor)
            } else {
              localStorage.removeItem('SETTINGS_secondary_color_from_db')
            }
          })
          // Load configurations from DB
          dispatch(fetchConfigurations(DATABASE()))
        }
        if (localStorage.getItem('SESSION_user_name')) {
          // Add the hello msg only if it doesn't exist
          if (!isWelcomeMessagePresent) {
            dispatch(
              fetchAddMessage({
                title: localStorage.getItem('SESSION_user_name'),
                message: t('view.panel.toolbar.message.connexion'),
                // message: 'Bonjour',
                type: 'success',
                date: new Date().getTime(),
                isNew: false,
                connexionMessage: true,
              }),
            )
          }
        }
      }
    }
  }, [dataBaseLoaded])

  useEffect(() => {
    // Apply the customer favicon
    if (customerConfig && customerConfig.content.hasOwnProperty('favicon')) {
      // Build link element
      const linkElement = document.createElement('link')
      linkElement.rel = 'icon'
      linkElement.href = customerConfig.content.favicon
      // Replace link element if there is an existing one in DB
      const existingFavicon = document.querySelector('link[rel="icon"]')
      if (existingFavicon) {
        document.head.removeChild(existingFavicon)
      }
      // Add link element into the HTML head part
      document.head.appendChild(linkElement)
      // // Clean the link when component is removed
      // return () => {
      //   document.head.removeChild(linkElement)
      // }
    }
    // Apply the customer background
    if (customerConfig && customerConfig.content.hasOwnProperty('background')) {
      const existingBackground = document.getElementById('APPLICATION_BACKGROUND_ID')
      if (existingBackground) {
        existingBackground.src = customerConfig.content.background
      }
    }
    // Apply the customer logo
    const existingLogo = document.getElementById('APPLICATION_LOGO_ID')
    if (existingLogo && isGlobalConfigsLoaded) {
      if (customerConfig && customerConfig.content.hasOwnProperty('logo')) {
        existingLogo.src = customerConfig.content.logo
      } else {
        existingLogo.src = logo
      }
    }
    // Apply the customer title
    const existingTitle = document.getElementById('APPLICATION_TITLE_ID')
    if (existingTitle && isGlobalConfigsLoaded) {
      if (customerConfig && customerConfig.content.hasOwnProperty('title')) {
        localStorage.setItem('__config.json__APPLICATION_TITLE', customerConfig.content.title)
        existingTitle.innerHTML = customerConfig.content.title
      } else {
        existingTitle.innerHTML = localStorage.getItem('__config.json__APPLICATION_TITLE')
      }
    }
    // Apply the customer subtitle
    const existingSubtitle = document.getElementById('APPLICATION_SUBTITLE_ID')
    if (existingSubtitle && isGlobalConfigsLoaded) {
      if (customerConfig && customerConfig.content.hasOwnProperty('subtitle')) {
        localStorage.setItem('__config.json__APPLICATION_SUBTITLE', customerConfig.content.subtitle)
        existingSubtitle.innerHTML = customerConfig.content.subtitle
      } else {
        existingSubtitle.innerHTML = localStorage.getItem('__config.json__APPLICATION_SUBTITLE')
      }
    }
    // Apply the customer watermark
    const existingWatermark = document.getElementById('APPLICATION_WATERMARK_ID')
    if (existingWatermark && isGlobalConfigsLoaded) {
      if (customerConfig && customerConfig.content.hasOwnProperty('watermark')) {
        localStorage.setItem(
          '__config.json__APPLICATION_WATERMARK',
          customerConfig.content.watermark,
        )
        existingWatermark.innerHTML = customerConfig.content.watermark
      } else {
        existingWatermark.innerHTML = localStorage.getItem('__config.json__APPLICATION_WATERMARK')
      }
    }
  }, [customerConfig, isGlobalConfigsLoaded])

  const componentToDisplay = useMemo(() => {
    if (!dataBaseLoaded || (dataBaseLoaded && !isDatabaseOffline && !isGlobalConfigsLoaded)) {
      return (
        // Display loading is the database is not finished to be loaded (with success or not)
        // and if the global configuration is not finished to be loaded (in case of it should be loaded)
        <div style={{ height: 'calc(100% - 96px)' }}>
          <Loading mode="alpha" size={10} />
        </div>
      )
    } else if (upload_mechanism) {
      // Display the rest of the GUI with the upload mechanism
      return (
        <ErrorBoundaryGuard style={{ height: 'calc(100% - 64px)', position: 'absolute' }}>
          <DropZone />
        </ErrorBoundaryGuard>
      )
    } else {
      // Display the rest of the GUI without the upload mechanism
      return (
        <ErrorBoundaryGuard style={{ height: 'calc(100% - 64px)', position: 'absolute' }}>
          <RouterComponent />
        </ErrorBoundaryGuard>
      )
    }
  }, [dataBaseLoaded, isDatabaseOffline, isGlobalConfigsLoaded, upload_mechanism])

  return (
    <div className={classes.root} height="100%">
      <CssBaseline />
      <ErrorBoundaryGuard style={{ height: 60, position: 'absolute' }}>
        <DashboardToolbar />
      </ErrorBoundaryGuard>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {componentToDisplay}
        <div id="APPLICATION_WATERMARK_ID" style={{ textAlign: 'center', fontSize: '0.8em' }}>
          {/*{localStorage.getItem('__config.json__APPLICATION_WATERMARK')}*/}
        </div>
        <ErrorBoundaryGuard isDialog>
          <Polling />
        </ErrorBoundaryGuard>
        <ErrorBoundaryGuard isDialog>
          <Detail />
        </ErrorBoundaryGuard>
        <ErrorBoundaryGuard isDialog>
          <JsonView />
        </ErrorBoundaryGuard>
        <ErrorBoundaryGuard isDialog>
          <LoginDialog />
        </ErrorBoundaryGuard>
        <ErrorBoundaryGuard isDialog>
          <ConfigurationDialog />
        </ErrorBoundaryGuard>
        <ErrorBoundaryGuard isDialog>
          <HelperDialog />
        </ErrorBoundaryGuard>
        <ErrorBoundaryGuard isDialog>
          <ServerHelperDialog />
        </ErrorBoundaryGuard>
        <ErrorBoundaryGuard isDialog>
          <LogsDialog />
        </ErrorBoundaryGuard>
        <ErrorBoundaryGuard isDialog>
          <WikiDialog />
        </ErrorBoundaryGuard>
      </main>
    </div>
  )
}
export default withRouter(Dashboard)
