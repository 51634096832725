import React, { Fragment, useEffect, useMemo } from 'react'
import { Stack, Step, StepLabel, Stepper } from '@mui/material'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import SendIcon from '@mui/icons-material/Send'
import DescriptionIcon from '@mui/icons-material/Description'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import * as I18n from 'i18next'
import { humanFileSize } from '../../common/Utils'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { alpha } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  settingsInfoText: {
    paddingTop: 15,
    paddingBottom: 20,
    fontSize: '1rem',
  },
  chiplevel: {
    height: 20,
    marginLeft: 10,
    '& span': {
      padding: 5,
    },
  },
  datagrid: {
    height: 300,
    display: 'inline-flex',
    width: '100%',
    marginTop: 30,
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
    },
    '& .MuiDataGrid-row:nth-child(even):hover': {
      backgroundColor: '#0000000F',
    },
    '& .MuiDataGrid-row:nth-child(odd)': {
      backgroundColor: '#00000008',
    },
    '& .MuiDataGrid-row:nth-child(odd):hover': {
      backgroundColor: '#00000012',
    },
  },
  dialogTitle: {
    backgroundImage: 'linear-gradient(' + alpha(theme.palette.primary.main, 0.12) + ', white)',
    backgroundColor: 'white',
    borderTop: '4px solid ' + theme.palette.primary.main,
  },
  iconStatus: {
    width: '30%',
    height: 'auto',
    marginLeft: '33%',
    color: '#666666',
    marginBottom: 56,
    bottom: 0,
    position: 'absolute',
  },
  progressColor: {
    backgroundColor: theme.palette.secondary.main,
  },
}))

export default function SendPanel({ sendMessage, uploadProgress }) {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const [activeStep, setActiveStep] = React.useState(0)
  const [errorTask, setErrorTask] = React.useState(false)

  const task = useSelector(function (state) {
    return state.tasks.task
  })

  const taskStatus = useMemo(() => {
    return task ? task.status : null
  }, [task])

  const statusMap = {
    PASSIVE: null,
    REGISTERED: 0,
    SCHEDULING: 1,
    SUBMITTING: 2,
    WAITING: null,
    FINISHED: 5,
    FAILED: 'error',
    CANCELLED: 'error',
    PAUSED: null,
    RUNNING: 3,
    UNKNOWN: null,
  }

  useEffect(() => {
    const newStep = statusMap[taskStatus]
    if (newStep !== null && newStep !== 'error') {
      setActiveStep(newStep)
    } else if (newStep === 'error') {
      setErrorTask(true)
    }
  }, [taskStatus])

  return (
    <Fragment>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        style={{ marginTop: 25, color: 'rgba(0, 0, 0, 0.6)' }}
      >
        <SendIcon />
        <span>:</span>
        <span>
          {sendMessage ? sendMessage.toUpperCase() : t('view.dialogs.sendPanel.send_default')}
        </span>
      </Stack>
      <div style={{ width: 'calc(100% - 44px)', marginLeft: 44, marginTop: 5 }}>
        <div style={{ width: '100%', height: 40, backgroundColor: '#e2e2e2', borderRadius: 5 }}>
          <div style={{ position: 'relative' }}>
            <div
              className={classes.progressColor}
              style={{
                height: 40,
                width: uploadProgress.percentage + '%',
                position: 'absolute',
                transition: 'width 150ms ease-in',
                borderRadius: 5,
              }}
            ></div>
            <div
              style={{
                position: 'absolute',
                width: '100%',
                textAlign: 'center',
                marginTop: 10,
              }}
            >
              {Math.round(uploadProgress.percentage)}%
            </div>
          </div>
        </div>
        <div style={{ float: 'right', fontStyle: 'italic', fontSize: 'small', marginTop: 5 }}>
          ( {humanFileSize(uploadProgress.uploaded, true)} /{' '}
          {humanFileSize(uploadProgress.total, true)} )
        </div>
      </div>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
        style={{
          marginTop: 68,
          color: 'rgba(0, 0, 0, 0.6)',
          opacity: uploadProgress.percentage === 100 ? 1 : 0.5,
        }}
      >
        <DescriptionIcon />
        <span>:</span>
        {/*<span style={{ overflow: 'hidden', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>*/}
        {/*  <div>{task ? task.status : t('view.dialogs.sendPanel.task_default')}</div>*/}
        {/*  {task && ['FAILED', 'CANCELLED'].indexOf(task.status) !== -1 && (*/}
        {/*    <div style={{ marginTop: 20 }}>{task.message}</div>*/}
        {/*  )}*/}
        {/*</span>*/}
        {task ? (
          <Stepper activeStep={activeStep} alternativeLabel>
            {['REGISTERED', 'SCHEDULING', 'SUBMITTING', 'RUNNING', 'FINISHED'].map(
              (label, index) => {
                return (
                  <Step key={label}>
                    <StepLabel error={errorTask && activeStep === index}>{label}</StepLabel>
                  </Step>
                )
              },
            )}
          </Stepper>
        ) : (
          <span style={{ overflow: 'hidden', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
            {t('view.dialogs.sendPanel.task_default')}
          </span>
        )}
      </Stack>
      {task && ['FAILED', 'CANCELLED'].indexOf(task.status) !== -1 && (
        <div style={{ marginTop: 20, maxHeight: 150, overflow: 'auto' }}>{task.message}</div>
      )}
      {(sendMessage === I18n.t('view.panel.dropzone.sent_ko') ||
        (task && ['FAILED', 'CANCELLED'].indexOf(taskStatus) !== -1)) && (
        <SentimentVeryDissatisfiedIcon className={classes.iconStatus} />
      )}
      {task && ['FINISHED'].indexOf(taskStatus) !== -1 && (
        <ThumbUpOffAltIcon className={classes.iconStatus} />
      )}
    </Fragment>
  )
}
