import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { StyledDialogTitle, TransitionTop } from '../common/Style'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, TextField } from '@material-ui/core'
import { fetchChangeSettings } from '../../redux/settings/actions'
import { t } from 'i18next'
import DialogContentText from '@material-ui/core/DialogContentText'
import { withRouter } from 'react-router-dom'
import { displayPopupMessage, generateErrorPart } from '../common/Message'
import { fetchFinishTask } from '../../redux/tasks/actions'
import { fetchRenameAnalysis } from '../../redux/analyses/actions'
import { Trans } from 'react-i18next'

const useStyles = makeStyles((theme) => ({}))

const RenameDialog = withRouter((props) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [newName, setNewName] = useState('newName')

  const analysisToRename = useSelector((state) => state.settings.analysisToRename)

  const open = useMemo(() => {
    return analysisToRename !== null
  }, [analysisToRename])

  useEffect(() => {
    if (open) {
      setNewName(analysisToRename.name)
    }
  }, [open])

  function handleClose(event, confirm) {
    if (typeof confirm === 'boolean' && confirm) {
      dispatch(fetchRenameAnalysis(analysisToRename, newName))
        .then(function () {
          dispatch(fetchChangeSettings('analysisToRename', null))
        })
        .catch(function (error) {
          displayPopupMessage(
            dispatch,
            'error',
            t('view.batch.renameDialog.title'),
            <Fragment>
              <div>
                <Trans i18nKey="view.batch.renameDialog.error">
                  Can not rename the analysis <i>{{ val1: analysisToRename.name }}</i> to
                  <i>{{ val2: newName }}</i>.
                </Trans>
              </div>
              {generateErrorPart(error)}
            </Fragment>,
          )
        })
    } else {
      dispatch(fetchChangeSettings('analysisToRename', null))
    }
  }

  function _keyPress(e) {
    if (e.keyCode === 13 && newName !== '' && newName !== analysisToRename?.name) {
      handleClose(null, true)
    }
  }

  return (
    <Dialog maxWidth={'md'} open={open} onClose={handleClose} TransitionComponent={TransitionTop}>
      <StyledDialogTitle>{t('view.batch.renameDialog.title')}</StyledDialogTitle>
      <DialogContent>
        <DialogContentText style={{ marginBottom: 5 }}>
          {t('view.batch.renameDialog.msg')}
        </DialogContentText>
        <TextField
          fullWidth
          error={newName === ''}
          label={''}
          value={newName}
          autoFocus
          required
          variant="outlined"
          onChange={(event) => setNewName(event.target.value)}
          onKeyDown={_keyPress}
        />
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={(event) => handleClose(event, true)}
          color="primary"
          disabled={newName === '' || newName === analysisToRename?.name}
        >
          {t('view.batch.renameDialog.rename')}
        </Button>
        <Button onClick={handleClose} color="primary">
          {t('view.batch.renameDialog.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
})
export default RenameDialog
