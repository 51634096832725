import { API_BO_URL, API_URL } from '../../utils/config'
import { displayPopupMessage, generateErrorPart } from '../../view/common/Message'
import React, { Fragment } from 'react'
import { fetchChangeSettings } from '../settings/actions'
import { receiveCurrentBatchLease } from '../batches/actions'
import { CLEAN_NEW_MESSAGES } from '../message/actions'

export const REQUEST_GLOBAL_CONFIGURATIONS = 'REQUEST_GLOBAL_CONFIGURATIONS'
export const RECEIVE_GLOBAL_CONFIGURATIONS = 'RECEIVE_GLOBAL_CONFIGURATIONS'
export const FAILED_RECEIVE_GLOBAL_CONFIGURATIONS = 'FAILED_RECEIVE_GLOBAL_CONFIGURATIONS'

export function fetchGlobalConfigurations() {
  return async (dispatch) => {
    dispatch(requestGlobalConfigurations())
    // let filter = [
    //   {
    //     property: 'name',
    //     value: 'features',
    //     filterType: 'string',
    //     operator: 'eq',
    //   },
    // ]
    // let v = encodeURIComponent(JSON.stringify(filter))
    // fetch(`${API_BO_URL()}.global/?filter=${v}`, {
    return fetch(`${API_BO_URL()}.global/`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((json) => {
        return dispatch(receiveGlobalConfigurations(json))
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to get global configuration ',
          <Fragment>
            <div>An error does not allow the get the global configuration:</div>
            {generateErrorPart(error)}
          </Fragment>,
        )
        return dispatch(faildedToReceiveGlobalConfigurations())
      })
  }
}
export function requestGlobalConfigurations() {
  return {
    type: REQUEST_GLOBAL_CONFIGURATIONS,
  }
}
export function receiveGlobalConfigurations(global) {
  return {
    type: RECEIVE_GLOBAL_CONFIGURATIONS,
    globalConfigs: global,
  }
}
export function faildedToReceiveGlobalConfigurations() {
  return {
    type: FAILED_RECEIVE_GLOBAL_CONFIGURATIONS,
  }
}
