import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import cache from './cache/reducers'
import batches from './batches/reducers'
import analyses from './analyses/reducers'
import unitaryAnalyses from './unitaryAnalyses/reducers'
import unitaryCalibrations from './unitaryCalibrations/reducers'
import message from './message/reducers'
import detail from './detail/reducers'
import drawer from './drawer/reducers'
import lims from './lims/reducers'
import tasks from './tasks/reducers'
import auditTrail from './auditTrail/reducers'
import screening from './screening/reducers'
import json from './json/reducers'
import filters from './filters/reducers'
import test from './test/reducers'
import teams from './teams/reducers'
import settings from './settings/reducers'
import configuration from './configuration/reducers'
import global from './global/reducers'
import logs from './logs/reducers'
import { composeWithDevTools } from 'redux-devtools-extension'
import accountings from './accountings/reducers'
import codDriveConfiguration from './codDriveConfiguration/reducers'

const rootReducer = combineReducers({
  cache,
  batches,
  analyses,
  unitaryAnalyses,
  unitaryCalibrations,
  message,
  settings,
  detail,
  drawer,
  lims,
  tasks,
  teams,
  auditTrail,
  screening,
  json,
  filters,
  test,
  configuration,
  global,
  logs,
  accountings,
  codDriveConfiguration,
})
const loggerMiddleware = createLogger()

const middlewares = [
  thunkMiddleware,
  // loggerMiddleware,
]

export default function configureStore(preloadedState) {
  return createStore(
    rootReducer,
    preloadedState,
    composeWithDevTools(applyMiddleware(...middlewares)),
  )
}
