import React from 'react'
import { TransitionBottom } from '../common/Style'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import LoginView from '../common/LoginView'
import { Dialog, DialogContent } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'

const useStyles = makeStyles((theme) => ({
  customRootDialog: {
    // height: isMobile ? '100%' : '70%',
    width: 396,
    backgroundColor: 'transparent',
  },
}))
const LoginDialog = withRouter((props) => {
  const classes = useStyles()
  const { history } = props

  const openDialog = useSelector(function (state) {
    return state.settings.loginPopup
  })

  function reload() {
    const current = '/' + props.location.search
    history.replace(`/reload`)
    setTimeout(() => {
      history.push(current)
    })
  }

  return (
    <Dialog
      open={openDialog}
      TransitionComponent={TransitionBottom}
      aria-labelledby="dialog-title-user"
      classes={{
        paper: classes.customRootDialog,
      }}
    >
      <DialogContent style={{ padding: 0 }}>
        <ErrorBoundaryGuard>
          <LoginView onValidSuccess={reload} fromDialog={true} />
        </ErrorBoundaryGuard>
      </DialogContent>
    </Dialog>
  )
})
export default LoginDialog
