import React, { Fragment, useMemo } from 'react'
import Paper from '@material-ui/core/Paper'
import { connect, useDispatch, useSelector } from 'react-redux'
import { fade, withStyles } from '@material-ui/core/styles'
import Title from '../Title'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'
import TableMultiGrid from '../common/Table_MultiGrid'
import clsx from 'clsx'
import AlphacodFlagIcon from '../common/AlphacodFlagIcon'
import { withRouter } from 'react-router-dom'
import { CONFIGURATION_PROFILE, FLASK_URL } from '../../utils/config'
import Tooltip from '@material-ui/core/Tooltip'
import {
  getComparator,
  stableSort,
  getClickAction,
  moveSampleToSubBatch,
  createSubBatch,
  getTimeEndDay,
  customFormatDecimals,
  displayHumanReadable,
  getHeaderTooltipCmp,
} from '../common/Utils'
import { StyledDialogTitle, TransitionTop } from '../common/Style'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Chip from '@material-ui/core/Chip'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import InputLabel from '@material-ui/core/InputLabel'
import AlphacodModifiedButtonIcon from '../common/AlphacodModifiedButtonIcon'
import NoteButton from '../common/NoteButton'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import {
  fetchAddAnalysisVisibilityTag,
  fetchRemoveAnalysisVisibilityTag,
} from '../../redux/analyses/actions'
import { displayPopupMessage, generateErrorPart } from '../common/Message'
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder'
import Switch from '@material-ui/core/Switch'
import FolderIcon from '@material-ui/icons/Folder'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { fetchChangeSettings } from '../../redux/settings/actions'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import { t } from 'i18next'
import { Trans } from 'react-i18next'
import MoveDialog from './MoveDialog'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import { Stack } from '@mui/material'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import { StripedDataGridPro, CustomNoRowsOverlay, onClick } from './Tools'
import ExtraDialogs from '../backlog/ExtraDialogs'
import { makeStyles } from '@material-ui/core'
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight'
import { useGridApiRef } from '@mui/x-data-grid'
import Loading from '../Loading'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import SettingsIcon from '@material-ui/icons/Settings'
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'
import { getFlagName, openCompoundInNewTab } from '../analysis/Tools'
import { fetchDisplayJson } from '../../redux/json/actions'
import SearchIcon from '@material-ui/icons/Search'
import { flagsFilterOperators } from '../common/ColumnFilters'
import { getFlagIcon } from '../backlog/Tools'
import { openAnalysisInNewTab } from '../compound/Tools'

const useStyles = makeStyles((theme) => ({
  paper: {
    // boxShadow:
    //   '0px 2px 7px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    padding: theme.spacing(2),
    height: '100%',
    overflow: 'hidden',
  },
  tableContainer: {
    height: 'calc(100% - 35px)',
    overflow: 'hidden',
    '& .ReactVirtualized__Grid:focus': {
      outline: 'none',
    },
  },
  selected: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  notSelected: {
    opacity: 0.5,
    '&:hover': {
      opacity: 1,
    },
  },
  selectedRow: {
    cursor: 'pointer',
    background: 'rgba(0, 0, 0, 0.1)',
    '& .nameAdorment': {
      display: 'flex !important',
    },
  },
  blink: {
    background: fade(theme.palette.secondary.light, 0.19),
  },
  selectedRowBlink: {
    background: fade(theme.palette.secondary.light, 0.37),
  },
  noBlink: {
    cursor: 'pointer',
  },
  cellFlags: {
    padding: 7,
  },
  tooltip: {
    fontSize: 13,
    padding: 5,
  },
  toolbarButton: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.03),
    marginRight: 5,
    width: 34,
    height: 34,
    padding: 8,
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: fade(theme.palette.common.black, 0.03),
    },
  },
  toolbar: {
    position: 'absolute',
    top: 48,
    left: 70,
    background: '#fff',
    padding: 5,
    paddingRight: 1,
    border: '2px solid',
    borderColor: theme.palette.secondary.main,
    borderRadius: 8,
  },
  formControlMove: {
    marginTop: 15,
  },
  container: {
    '& .MuiOutlinedInput-input': {
      padding: 10,
    },
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      paddingRight: 32,
    },
  },
  moveChip: {
    margin: 3,
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
  },
  visibilityButton: {
    padding: 0,
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
    },
  },
  optionLabel: {
    color: fade(theme.palette.common.black, 0.54),
    fontSize: '1rem',
    marginRight: 20,
  },
  optionSelected: {
    color: theme.palette.secondary.main,
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  visibilityDisabled: {
    opacity: 0.5,
  },
  helperTooltip: {
    fontSize: 13,
    padding: 5,
    fontWeight: 100,
    lineHeight: '1.3em',
  },
  actionMenu: { justifyContent: 'left', alignItem: 'start', paddingTop: 6, paddingBottom: 6 },
  actionDivider: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '0px !important',
    margin: '5px 0px',
  },
}))

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    borderRadius: 10,
    width: '100%',
  },
  bar: {
    borderRadius: 10,
  },
})(LinearProgress)

const CustomDialog = withStyles((theme) => ({
  paper: {
    height: '100%',
  },
}))(Dialog)

const defaultState = {
  sorting: {
    sortModel: [
      {
        field: 'progression',
        sort: 'asc',
      },
    ],
  },
}

const OthersTable = withRouter((props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const apiRef = useGridApiRef()

  const tableMultiGridRef = React.createRef()

  const [error, setError] = React.useState(null)
  const [batch, setBatch] = React.useState(null)
  const [rowSelectionModel, setRowSelectionModel] = React.useState([])
  const [selection, setSelection] = React.useState([])
  const [open, setOpen] = React.useState(false)
  const [moveQuality, setMoveQuality] = React.useState({})
  const [direction, setDirection] = React.useState('asc')
  const [order, setOrder] = React.useState('name')
  const [openBatchMove, setOpenBatchMove] = React.useState(false)
  const [familyBatches, setFamilyBatches] = React.useState(null)
  const [option, setOption] = React.useState('move')
  const [moveBatch, setMoveBatch] = React.useState(null)
  const [openBatchCreate, setOpenBatchCreate] = React.useState(false)
  const [createBatch, setCreateBatch] = React.useState(null)
  const [openExtentedView, setOpenExtentedView] = React.useState(false)
  const [recomputeCalibrationCheckbox, setRecomputeCalibrationCheckbox] = React.useState(false)

  const delta = useSelector((state) => state.settings.dateTimeDelta)
  const wikiCODHelper = useSelector((state) => state.settings.wikiCODHelper)
  const analysis = useSelector((state) => state.analyses.items)
  const isFetching = useSelector((state) => state.analyses.isFetching)
  const analysesSentToLims = useSelector((state) => state.analyses.analysesSentToLims)
  const analysesNotSentToLims = useSelector((state) => state.analyses.analysesNotSentToLims)
  const configSelected = useSelector(
    (state) =>
      state.configuration.configurations.filter(
        (config) => config._id === CONFIGURATION_PROFILE(),
      )[0],
  )
  const echClient = useMemo(() => {
    return getEchClient()
  }, [JSON.stringify(analysis)])

  const selectionEmpty = useMemo(() => {
    return selection.length === 0
  }, [JSON.stringify(selection)])

  const saveSnapshot = React.useCallback(
    (fullscreenMode) => {
      if (apiRef?.current?.exportState) {
        const stateSnapshot = apiRef.current.exportState()
        if (Object.keys(stateSnapshot).length !== 0) {
          localStorage.setItem('BATCH_others_state', JSON.stringify(stateSnapshot))
        }
      }
    },
    [apiRef],
  )

  function getEchClient() {
    // const { analysis, analysesSentToLims, analysesNotSentToLims } = this.props
    // const { direction, order } = this.state
    const analysisFiltered = analysis
      .filter((analyse) => analyse.content.type === 'other' && !analyse.content.inProgress)
      .map((ech) => ({
        ...ech.content,
        original_row: ech,
        id: ech._id,
        _id: ech._id,
        batchId: ech.content.batch.id,
        analyseId: ech._id,
        name: ech.name,
        sentToLims:
          analysesSentToLims && analysesSentToLims.indexOf(ech._id) !== -1
            ? true
            : analysesNotSentToLims && analysesNotSentToLims.indexOf(ech._id) !== -1
            ? false
            : null,
        visibility: !(ech.tags && ech.tags.toString().indexOf('off_visibility') !== -1),
      }))
    return stableSort(analysisFiltered, getComparator(direction, order))
  }

  const clearSelection = () => {
    setRowSelectionModel([])
    if (onSelect) onSelect([])
  }

  const onSelect = (selected) => {
    setSelection(selected.map((i) => getEchClient()[i]))
  }

  function onChangeMoveBatch(event) {
    // const { familyBatches } = this.state
    const name = familyBatches[event.target.value].name
    setMoveBatch({ value: event.target.value, name: name })
  }

  function onChangeOption(event) {
    setOption(event.target.checked ? 'copy' : 'move')
  }

  function onChangeCreateBatch(event) {
    setCreateBatch(event.target.value)
  }

  async function multiValidate(value) {
    switch (value) {
      case 0:
        // const selection = this.state.selection
        const moveQuality = selection.reduce(function (map, obj) {
          map[obj._id] = {
            value: obj.name.match(/^std ([0-9]*) .*$/i)
              ? obj.name.match(/^std ([0-9]*) .*$/i)[1]
              : obj.name.match(/^([0-9]{6}).*$/i)
              ? obj.name.match(/^([0-9]{6}).*$/i)[1]
              : undefined,
            type: obj.name.match(/^([0-9]{6}).*$/i) ? 'sample' : 'calibration',
          }
          if (!obj.name.match(/^([0-9]{6}).*$/i)) {
            map[obj._id]['suffix'] = 'default'
          }
          return map
        }, {})
        setOpen(true)
        setMoveQuality(moveQuality)
        break
      case 1:
        setOpenBatchCreate(true)
        setCreateBatch(null)
        break
      case 2:
        setOpenBatchMove(true)
        setMoveBatch(null)
        fetch(`${FLASK_URL()}/batch/${props.match.params.batchId}/family`, {
          method: 'GET',
          credentials: 'include',
        })
          .then((response) => {
            if (!response.ok) {
              const statusText = response.statusText
              const status = response.status
              const url = response.url
              return response.text().then((errorMessage) => {
                const error = new Error(`${statusText} : ${errorMessage}`)
                if (response.headers.get('content-type') === 'application/json') {
                  error.stack = JSON.stringify(
                    JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                    null,
                    2,
                  )
                } else {
                  error.stack = new Error().stack
                }
                error.statusText = statusText
                error.status = status
                error.url = url
                throw error
              })
            }
            return response.json()
          })
          .then((json) => {
            setFamilyBatches(json)
          })
          .catch((error) => {
            if (error.status === 403) {
              dispatch(fetchChangeSettings('loginPopup', true))
            }
            displayPopupMessage(
              dispatch,
              'error',
              t('view.batch.othersTable.error_family_title'),
              <Fragment>
                <div>{t('view.batch.othersTable.error_family_msg')}</div>
                {generateErrorPart(error)}
              </Fragment>,
            )
          })
        break
      default:
        break
    }
  }

  function handleClose(event, save) {
    setOpen(false)
    if (typeof save === 'boolean' && save) {
      clearSelection()
    }
  }

  function onChangeVisibility(event, item) {
    event.stopPropagation()
    event.preventDefault()

    const displayMessage = (mode, error) => {
      displayPopupMessage(
        dispatch,
        error ? 'error' : 'success',
        t('view.batch.othersTable.visibility_title'),
        <Fragment>
          {error ? (
            <Fragment>
              <div>
                <Trans i18nKey="view.batch.othersTable.visibility_msg1">
                  The visibility for the analysis <i>{{ val: item.name }}</i> can't be updated:
                </Trans>
              </div>
              {generateErrorPart(error)}
            </Fragment>
          ) : (
            <div>
              <Trans i18nKey="view.batch.othersTable.visibility_msg2">
                The analysis <i>{{ val1: item.name }}</i> is now
                {{
                  val2:
                    mode === 'add'
                      ? t('view.batch.othersTable.visible')
                      : t('view.batch.othersTable.hidden'),
                }}{' '}
                in the navigation panel.
              </Trans>
            </div>
          )}
        </Fragment>,
      )
    }
    // Add or Remove tag off_visibility on analysis object
    if (item.visibility) {
      dispatch(fetchRemoveAnalysisVisibilityTag(item))
        .then(function (resp) {
          displayMessage('remove')
        })
        .catch(function (error) {
          displayMessage('remove', error)
        })
    } else {
      dispatch(fetchAddAnalysisVisibilityTag(item))
        .then(function (resp) {
          if (resp.error) {
            displayMessage('add', resp.error)
          } else {
            displayMessage('add')
          }
        })
        .catch(function (error) {
          displayMessage('add', error)
        })
    }
  }

  function onDisplayClient(id) {
    localStorage.setItem('BATCH_previous_selection', id)
    props.history.push('/?' + props.match.params.batchId + '/analysis/' + id)
  }

  function handleOpenRenameDialog(event, row) {
    event.stopPropagation()
    event.preventDefault()
    dispatch(fetchChangeSettings('analysisToRename', row))
  }

  function handleCloseBatchMove(event, confirm) {
    setOpenBatchMove(false)
    if (typeof confirm === 'boolean' && confirm) {
      clearSelection()
      moveSampleToSubBatch(dispatch, moveBatch.value, moveBatch.name, selection, option)
    }
  }

  function handleCloseBatchCreate(event, confirm) {
    setOpenBatchCreate(false)
    if (typeof confirm === 'boolean' && confirm) {
      clearSelection()
      createSubBatch(dispatch, props.match.params.batchId, createBatch, selection, option)
    }
  }

  function CustomLoading() {
    return (
      <div style={{ height: 'calc(100% - 96px)', position: 'relative' }}>
        <Loading mode="miniCircle" />
      </div>
    )
  }

  const columnsMemoized = useMemo(() => {
    return [
      {
        field: 'visibility',
        headerName: t('view.batch.othersTable.visibility'),
        type: 'boolean',
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
          return (
            <Tooltip
              arrow
              title={<div className={classes.tooltip}>{t('view.batch.othersTable.show_hide')}</div>}
            >
              <IconButton
                aria-label="changeVisibility"
                className={classes.visibilityButton}
                onClick={(event) => onChangeVisibility(event, row)}
              >
                {!row.visibility ? (
                  <VisibilityOffIcon
                    // style={{ color: '#AF0000' }}
                    className={'visibilityOffIcon'}
                  />
                ) : (
                  <VisibilityIcon className={'visibilityIcon'} />
                )}
              </IconButton>
            </Tooltip>
          )
        },
      },
      {
        field: 'flags',
        type: 'custom',
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        headerName: t('view.batch.othersTable.flags'),
        // renderCell: ({ row }) => (
        //   <ErrorBoundaryGuard isDialog>
        //     <AlphacodFlagIcon item={row} hideGeneralTab={false} collection={'analyse'} />
        //   </ErrorBoundaryGuard>
        // ),
        renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
          return (
            <ErrorBoundaryGuard isDialog>
              <AlphacodFlagIcon item={row} hideGeneralTab={true} />
            </ErrorBoundaryGuard>
          )
        },
        valueFormatter: (params) => {
          return getFlagName(params.api.getRow(params.id))
        },
        filterOperators: flagsFilterOperators,
        valueGetter: ({ value }) => {
          switch (getFlagIcon(value.globals.filter((f) => !f.bypass_backlog))) {
            case 'info':
              return 0
            case 'action':
              return 1
            case 'warning':
              return 2
            case 'error':
              return 3
            default:
              return -1
          }
        },
      },
      {
        field: 'sentToLims',
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        type: 'boolean',
        headerName: t('view.batch.samplesTable.column.reported'),
        renderCell: function ({ value }) {
          return value ? (
            <CheckIcon style={{ color: '#0000008a' }} />
          ) : value === false ? (
            <CloseIcon style={{ color: '#0000008a' }} />
          ) : null
        },
        // customTitle: function () {
        //   return (
        //     <Tooltip
        //       title={
        //         <div className={classes.tooltip}>
        //           {t('view.batch.samplesTable.column.reported_lims')}
        //         </div>
        //       }
        //     >
        //       <span>{t('view.batch.samplesTable.column.reported')}</span>
        //     </Tooltip>
        //   )
        // },
      },
      {
        field: 'notes',
        type: 'number',
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        headerName: t('view.batch.othersTable.notes'),
        valueGetter: ({ value }) => value?.length,
        renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
          return (
            <ErrorBoundaryGuard isDialog>
              <NoteButton
                row={{
                  _id: row.id,
                  notes: row.notes ? row.notes : [],
                }}
                collection={'analysis'}
              />
            </ErrorBoundaryGuard>
          )
        },
      },
      {
        field: 'name',
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        headerName: t('view.batch.othersTable.name'),
        renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
          return (
            <Tooltip arrow title={<div className={classes.tooltip}>{row.file}</div>}>
              <Stack
                style={{ overflow: 'hidden' }}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={0}
              >
                <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                  {row.name}
                </div>
                <div
                  style={{ display: 'none', height: 20, marginBottom: 7, marginLeft: 5 }}
                  className={'nameAdorment'}
                >
                  <IconButton
                    style={{ padding: 0 }}
                    onClick={(event) => handleOpenRenameDialog(event, row.original_row)}
                    onMouseDown={(event) => event.preventDefault()}
                  >
                    <Tooltip
                      arrow
                      placement={'top'}
                      title={
                        <div className={classes.tooltip}>
                          {t('view.batch.qualityControlTable.rename')}
                        </div>
                      }
                    >
                      <DriveFileRenameOutlineIcon />
                    </Tooltip>
                  </IconButton>
                </div>
              </Stack>
            </Tooltip>
          )
        },
      },
      {
        field: 'progression',
        minWidth: 120,
        type: 'number',
        renderHeader: (params) => getHeaderTooltipCmp(params, wikiCODHelper),
        headerName: t('view.batch.othersTable.progression'),
        renderCell: function ({ value, row, colDef: { columnInfos }, ...otherParams }) {
          return (
            <Tooltip
              key={row.id}
              arrow
              title={
                <div className={classes.tooltip}>
                  <div>
                    {t('view.batch.othersTable.progression') +
                      `: ${
                        row.progression_requested ? row.progression_requested.toFixed(2) : '0.00'
                      } %`}
                  </div>
                  <div style={{ marginTop: 10 }}>
                    {t('view.batch.othersTable.detected') +
                      `: ${row.nb_detected_validated ? row.nb_detected_validated : '0'}`}
                  </div>
                  <div>
                    {t('view.batch.othersTable.suspected') +
                      `: ${row.nb_suspected_validated ? row.nb_suspected_validated : '0'}`}
                  </div>
                  <div>
                    {t('view.batch.othersTable.excluded') +
                      `: ${row.nb_excluded_validated ? row.nb_excluded_validated : '0'}`}
                  </div>
                  <div style={{ marginTop: 10 }}>{`OK: ${
                    row.nb_ok_validated ? row.nb_ok_validated : '0'
                  }`}</div>
                  <div>{`KO: ${row.nb_ko_validated ? row.nb_ko_validated : '0'}`}</div>
                </div>
              }
            >
              <BorderLinearProgress
                variant="determinate"
                value={row.progression_requested ? row.progression_requested : 0}
              />
            </Tooltip>
          )
        },
      },
      {
        field: 'actions',
        type: 'actions',
        hideable: false,
        resizable: false,
        sortable: false,
        filterable: false,
        disableColumnMenu: false,
        disableReorder: true,
        disableExport: true,
        width: 50,
        minWidth: 50,
        maxWidth: 50,
        getActions: ({ row, ...otherParams }) => [
          <GridActionsCellItem
            className={classes.actionMenu}
            showInMenu
            icon={<OpenInBrowserIcon />}
            label={t('view.batch.othersTable.actions.open')}
            onClick={() => props.history.push('/?' + row.batchId + '/analysis/' + row._id)}
          />,
          <GridActionsCellItem
            className={classes.actionMenu}
            showInMenu
            icon={<OpenInNewIcon />}
            label={t('view.batch.othersTable.actions.openNew')}
            onClick={() => openAnalysisInNewTab(row)}
          />,
          <div disabled={true} className={classes.actionDivider} showInMenu label={''} />,
          <GridActionsCellItem
            onClick={() => {
              dispatch(fetchDisplayJson(row._id, 'analysis'))
            }}
            className={classes.actionMenu}
            showInMenu
            icon={<SearchIcon />}
            label={t('view.batch.othersTable.actions.explore')}
          />,
        ],
      },
    ]
  }, [])

  const getRenderCmp = (fullscreenMode) => {
    return (
      <Paper className={classes.paper} elevation={0}>
        <Dialog
          open={openBatchCreate}
          onClose={handleCloseBatchCreate}
          TransitionComponent={TransitionTop}
          aria-labelledby="draggable-dialog-title"
        >
          <StyledDialogTitle>{t('view.batch.othersTable.move_copy')}</StyledDialogTitle>
          <DialogContent>
            <DialogContentText>{t('view.batch.othersTable.selected_samples')}</DialogContentText>
            <div
              style={{
                maxWidth: 600,
                maxHeight: 400,
                overflow: 'scroll',
                marginTop: 6,
                marginBottom: 20,
              }}
            >
              {selection.map((item, index) => (
                <Chip
                  key={item.name + '_' + index}
                  className={classes.moveChip}
                  label={item.name}
                />
              ))}
            </div>
            <Grid
              component="label"
              container
              alignItems="center"
              spacing={1}
              style={{ marginBottom: 4 }}
            >
              <Grid item className={classes.optionLabel}>
                {t('view.batch.othersTable.option')}
              </Grid>
              <Grid item className={option === 'move' ? classes.optionSelected : null}>
                {t('view.batch.othersTable.move')}
              </Grid>
              <Grid item>
                <Switch
                  checked={option === 'copy'}
                  color="default"
                  inputProps={{ 'aria-label': 'checkbox with default color' }}
                  onChange={(event) => onChangeOption(event)}
                />
              </Grid>
              <Grid item className={option === 'copy' ? classes.optionSelected : null}>
                {t('view.batch.othersTable.copy')}
              </Grid>
            </Grid>
            <TextField
              required
              fullWidth
              id="create-sub-batch"
              label={t('view.batch.othersTable.sub_batch')}
              variant="outlined"
              onChange={(event) => onChangeCreateBatch(event)}
              error={createBatch === '' || createBatch === null || createBatch === undefined}
              style={{ marginLeft: 75, marginBottom: 10, width: 'calc(100% - 75px)' }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={(event) => handleCloseBatchCreate(event, true)}
              disabled={createBatch === '' || createBatch === null || createBatch === undefined}
              color="primary"
            >
              {t('view.batch.othersTable.create')}
            </Button>
            <Button onClick={(event) => handleCloseBatchCreate(event, false)} color="primary">
              {t('view.batch.othersTable.cancel')}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openBatchMove}
          onClose={handleCloseBatchMove}
          TransitionComponent={TransitionTop}
          aria-labelledby="draggable-dialog-title"
          style={{ minWidth: 400 }}
        >
          <StyledDialogTitle>{t('view.batch.othersTable.move_copy_existing')}</StyledDialogTitle>
          {familyBatches ? (
            <DialogContent>
              <DialogContentText>{t('view.batch.othersTable.sure')}</DialogContentText>
              <DialogContentText>
                {t('view.batch.othersTable.will_moved')}
                <div style={{ marginTop: 5 }}>
                  {selection.map((item) => (
                    <Chip key={item.name} className={classes.moveChip} label={item.name} />
                  ))}
                </div>
              </DialogContentText>
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item className={classes.optionLabel}>
                  {t('view.batch.othersTable.option')}
                </Grid>
                <Grid item className={option === 'move' ? classes.optionSelected : null}>
                  {t('view.batch.othersTable.move')}
                </Grid>
                <Grid item>
                  <Switch
                    checked={option === 'copy'}
                    color="default"
                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                    onChange={(event) => onChangeOption(event)}
                  />
                </Grid>
                <Grid item className={option === 'copy' ? classes.optionSelected : null}>
                  {t('view.batch.othersTable.copy')}
                </Grid>
              </Grid>
              <FormControl
                required
                fullWidth
                variant="outlined"
                className={classes.formControlMove}
                error={moveBatch === '' || moveBatch === null || moveBatch === undefined}
                style={{ marginLeft: 75, width: 'calc(100% - 75px)' }}
              >
                <InputLabel>{t('view.batch.othersTable.batch')}</InputLabel>
                <Select
                  variant="outlined"
                  onChange={onChangeMoveBatch}
                  label={t('view.batch.othersTable.batch')}
                >
                  {Object.entries(familyBatches).map((batch) => (
                    <MenuItem key={batch[0]} value={batch[0]}>
                      {batch[1].name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>
          ) : (
            <DialogContent>
              <DialogContentText>{t('view.batch.othersTable.no_sub_batch')}</DialogContentText>
              <div style={{ marginBottom: 20 }}>
                {selection.map((item) => (
                  <Chip key={item.name} className={classes.moveChip} label={item.name} />
                ))}
              </div>
              <DialogContentText>
                {t('view.batch.othersTable.create_destination')}
              </DialogContentText>
            </DialogContent>
          )}

          <DialogActions>
            <Button
              autoFocus
              onClick={(event) => handleCloseBatchMove(event, true)}
              color="primary"
              disabled={moveBatch === '' || moveBatch === null || moveBatch === undefined}
            >
              {t('view.batch.othersTable.move')}
            </Button>
            <Button onClick={(event) => handleCloseBatchMove(event, false)} color="primary">
              {t('view.batch.othersTable.cancel')}
            </Button>
          </DialogActions>
        </Dialog>
        <ErrorBoundaryGuard isDialog>
          <MoveDialog selection={selection} open={open} closeFct={handleClose} origin={'other'} />
        </ErrorBoundaryGuard>
        <Title>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            {/*<Grid item>*/}
            <Box>{t('view.batch.othersTable.others')}</Box>
            {!fullscreenMode && (
              <Tooltip
                arrow
                placement="top"
                title={
                  <div className={classes.tooltip}>{t('view.batch.othersTable.fullscreen')}</div>
                }
              >
                <IconButton
                  size="small"
                  aria-label="extend"
                  onClick={() => {
                    setOpenExtentedView(true)
                  }}
                >
                  <OpenInNewIcon />
                </IconButton>
              </Tooltip>
            )}
            {/*</Grid>*/}
          </Grid>
        </Title>
        <div className={classes.tableContainer}>
          <ErrorBoundaryGuard>
            <StripedDataGridPro
              initialState={{
                ...defaultState,
                ...JSON.parse(localStorage.getItem('BATCH_others_state')),
              }}
              checkboxSelection
              disableRowSelectionOnClick
              onRowSelectionModelChange={(newRowSelectionModel, details) => {
                setRowSelectionModel(newRowSelectionModel)
                // const tabIndexes = newRowSelectionModel.map((rsm) =>
                //   details.api.getRowIndexRelativeToVisibleRows(rsm),
                // )
                // onSelect(tabIndexes)
                setSelection(
                  echClient
                    .filter((objet) => newRowSelectionModel.includes(objet._id))
                    .map((tmpRow) => tmpRow.original_row),
                )
              }}
              rowSelectionModel={rowSelectionModel}
              onStateChange={() => saveSnapshot(fullscreenMode)}
              rowHeight={40}
              loading={isFetching}
              slots={{
                // moreActionsIcon: CustomActionIcon,
                noRowsOverlay: CustomNoRowsOverlay,
                noResultsOverlay: CustomNoRowsOverlay,
                loadingOverlay: CustomLoading,
                // columnMenu: CustomColumnMenu,
              }}
              apiRef={apiRef}
              getRowClassName={(params) => {
                let classesAdded = params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                // if (isLoading(params.row)) {
                //   classesAdded += ' loading'
                // }
                return classesAdded
              }}
              getCellClassName={(params) => {
                return 'customCell'
              }}
              // disableVirtualization={batches.length < 50}
              rowBuffer={3}
              rowThreshold={3}
              columnBuffer={3}
              columnThreshold={3}
              rows={echClient}
              columns={columnsMemoized}
              pagination={false}
              hideFooter={true}
              style={{ border: 'none' }}
              // Do not use autoSize: conflic with initialState
              // autosizeOnMount
              // autosizeOptions={autoSizeOptions}
              disableRowSelectionOnClick
              onRowClick={(params, event, details) => {
                const openFct = () => {
                  onDisplayClient(params.row.id)
                }
                getClickAction(dispatch, event, params.row, 'analysis', openFct)
              }}
              // onResize={() => hideWatermark()} //TODO To be deleted after purchasing a license
            />
          </ErrorBoundaryGuard>
        </div>
        {!selectionEmpty && (
          <span
            className={classes.toolbar}
            style={{ visibility: selectionEmpty ? 'hidden' : 'visible' }}
          >
            <div>
              <Divider orientation="vertical" />
              <Tooltip
                arrow
                title={<div className={classes.tooltip}>{t('view.batch.othersTable.move_qc')}</div>}
              >
                <IconButton
                  className={classes.toolbarButton}
                  aria-label="MoveToQualityControl"
                  onClick={() => multiValidate(0)}
                >
                  <SwapHorizIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                arrow
                title={
                  <div className={classes.tooltip}>{t('view.batch.othersTable.move_copy')}</div>
                }
              >
                <IconButton
                  className={classes.toolbarButton}
                  aria-label="Create sub-batch"
                  onClick={() => multiValidate(1)}
                >
                  <CreateNewFolderIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                arrow
                title={
                  <div className={classes.tooltip}>{t('view.batch.othersTable.move_existing')}</div>
                }
              >
                <IconButton
                  className={classes.toolbarButton}
                  aria-label="Move to an existing batch"
                  onClick={() => multiValidate(2)}
                >
                  <FolderIcon />
                </IconButton>
              </Tooltip>
            </div>
          </span>
        )}
      </Paper>
    )
  }

  return (
    <Fragment>
      <CustomDialog
        fullWidth={true}
        maxWidth={'xl'}
        open={openExtentedView}
        onClose={() => setOpenExtentedView(false)}
        TransitionComponent={TransitionTop}
        aria-labelledby="draggable-dialog-title"
      >
        {openExtentedView && getRenderCmp(true)}
        <DialogActions>
          <Button onClick={() => setOpenExtentedView(false)} color="primary">
            {t('view.batch.othersTable.close')}
          </Button>
        </DialogActions>
      </CustomDialog>
      {getRenderCmp()}
    </Fragment>
  )
})
export default OthersTable
