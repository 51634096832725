import React, { Fragment, useEffect } from 'react'
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Input,
  makeStyles,
} from '@material-ui/core'
import { fade } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import { ChromatoHeight } from '../Style'
import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'
import { GET_CHROMATO_COLORS, PUBLIC_URL, ZOOM_DEFAULT_FACTO } from '../../../utils/config'
import OpacityIcon from '@mui/icons-material/Opacity'
import { t } from 'i18next'
import LineWeightIcon from '@mui/icons-material/LineWeight'
import ColorLensIcon from '@mui/icons-material/ColorLens'
import Button from '@mui/material/Button'
import { Stack } from '@mui/material'
import { fetchDisplayDetail } from '../../../redux/detail/actions'
import Box from '@material-ui/core/Box'
import { HexColorPicker } from 'react-colorful'
import DialogActions from '@material-ui/core/DialogActions'
import Popover from '@material-ui/core/Popover'
import { Trans } from 'react-i18next'
import { displayPopupMessage } from '../Message'
import { useDispatch } from 'react-redux'
import BlockIcon from '@mui/icons-material/Block'

const useStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: 13,
    padding: 5,
  },
  sortableTableHeader: {
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    borderRadius: 3,
    margin: '0px 10px',
    padding: '5px 10px',
    background: fade(theme.palette.primary.main, 0.02),
    minWidth: 80,
    cursor: 'grab',
  },
  sortableTableHeadertitle: {
    fontWeight: 600,
    whiteSpace: 'nowrap',
    borderBottom: '1px solid',
    borderBottomColor: fade(theme.palette.primary.main, 0.35),
  },
  sorting: {
    zIndex: theme.zIndex.modal + 100,
  },
  columnSizetextfield: {
    width: 70,
    marginLeft: 'calc(50% - 35px)',
    marginTop: 8,
    '& input': {
      padding: 8,
      background: 'white',
    },
  },
  formControl: {
    margin: '0px 0px 0px 24px',
  },
  colorButton: {
    minWidth: 23,
    minHeight: 20,
    marginLeft: 1,
  },
  colorPicker: {
    width: '100%',
    padding: '18.5px 14px',
    '& div': {
      cursor: 'pointer',
    },
  },
  colorOld: {
    width: '100%',
    padding: '18.5px 14px',
  },
  colorPickerCnt: {
    border: '1px solid #c4c4c4',
    width: 'calc(50% - 5px)',
    display: 'inline-flex',
    // marginTop: 30,
    borderRadius: 4,
  },
  colorPickerCntResult: {
    border: '1px solid #c4c4c4',
    width: 'calc(100% - 5px)',
    display: 'inline-flex',
    borderRadius: 4,
    height: 94,
    marginBottom: 15,
  },
  colorPickerTitle: {
    backgroundColor: 'white',
    position: 'absolute',
    color: 'rgba(0, 0, 0, 0.6)',
    padding: '0px 7px',
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.00938em',
    transform: 'translate(-4px, -8px) scale(0.75)',
  },
  titleDiv: {
    background: 'url(' + PUBLIC_URL + '/images/colors_background.jpg) no-repeat',
    backgroundSize: '100%',
    boxShadow: 'inset 0px 0px 35px 0px rgb(0 0 0)',
  },
  title: {
    color: theme.palette.secondary.main,
    fontSize: 18,
    padding: 15,
    textShadow: '1px 1px 3px white',
  },
  colorCtn: {
    height: '100%',
    borderRadius: 8,
  },
}))
export default function ChromatoTab({
  labelVisibility,
  setLabelVisibility,
  chromatoHeight,
  setChromatoHeight,
  chromatoOpacity,
  setChromatoOpacity,
  isChromatoPeakAsDefaultWindow,
  setIsChromatoPeakAsDefaultWindow,
  chromatoWeight,
  setChromatoWeight,
  tmpChromatoColors,
  setTmpChromatoColors,
}) {
  const classes = useStyles()

  const [openChangeColor, setOpenChangeColor] = React.useState(false)
  const [anchorChangeColor, setAnchorChangeColor] = React.useState(null)
  const [itemChangeColor, setItemChangeColor] = React.useState(null)
  const [colorTmp, setColorTmp] = React.useState(null)

  const handleChange = (event) => {
    setLabelVisibility({ ...labelVisibility, [event.target.name]: event.target.checked })
  }

  const {
    area,
    height,
    concVol,
    rt,
    flag,
    transitions,
    rtCor,
    rtCal,
    rtMth,
    validation,
    lockChromatoEdition,
  } = labelVisibility

  function handleChromatoHeightChange(event, newValue) {
    setChromatoHeight(newValue)
  }
  function handleChromatoWindowDefaultChange(event, newValue) {
    setIsChromatoPeakAsDefaultWindow(newValue)
  }

  function handleInputChromatoHeightChange(event) {
    setChromatoHeight(event.target.value === '' ? '' : Number(event.target.value))
  }

  function handleBlurChromatoHeight() {
    if (chromatoHeight < 1) {
      setChromatoHeight(1)
    } else if (chromatoHeight > 10) {
      setChromatoHeight(10)
    }
  }

  function handleChromatoOpacityChange(event, newValue) {
    setChromatoOpacity(newValue)
  }

  function handleChromatoWeightChange(event, newValue) {
    setChromatoWeight(newValue)
  }

  function handleInputChromatoOpacityChange(event) {
    setChromatoOpacity(event.target.value === '' ? '' : Number(event.target.value))
  }

  function handleBlurChromatoOpacity() {
    if (chromatoOpacity < 0.01) {
      setChromatoOpacity(0.01)
    } else if (chromatoOpacity > 1) {
      setChromatoOpacity(1)
    }
  }

  function handleBlurChromatoWeight() {
    if (chromatoOpacity < 1) {
      setChromatoOpacity(1)
    } else if (chromatoOpacity > 5) {
      setChromatoOpacity(5)
    }
  }

  function handleChromatoColorChange(event, index) {
    setOpenChangeColor(true)
    setAnchorChangeColor(event.currentTarget)
    setItemChangeColor(index)
  }

  const handleCloseChangeColor = (save) => {
    if (save) {
      setTmpChromatoColors((oldValues) =>
        oldValues.map((color, index) => (index === itemChangeColor ? colorTmp : color)),
      )
    }
    setOpenChangeColor(false)
    setAnchorChangeColor(null)
    setItemChangeColor(null)
    setColorTmp(null)
  }

  return (
    <span>
      <Popover
        id={openChangeColor ? 'simple_popover' : null}
        open={openChangeColor}
        anchorEl={anchorChangeColor}
        onClose={() => handleCloseChangeColor()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={classes.titleDiv}>
          <Typography className={classes.title}>
            <Trans i18nKey="view.common.settingsView.chromatoTab.colorsTitle">
              Set the transition color n°{{ val: itemChangeColor + 1 }}:
            </Trans>
          </Typography>
        </div>
        <Box p={2} style={{ width: 340 }}>
          <div className={classes.colorPickerCnt} style={{ marginLeft: 5 }}>
            <HexColorPicker
              className={classes.colorPicker}
              color={
                typeof itemChangeColor === 'number' ? tmpChromatoColors[itemChangeColor] : '#000'
              }
              onChange={(col) => setColorTmp(col)}
            />
            <div className={classes.colorPickerTitle}>
              {t('view.common.chromatoDetail.chromatoDetail.change_color')}
            </div>
          </div>
          <div
            style={{
              width: 'calc(50% - 5px)',
              display: 'block',
              float: 'right',
              // marginTop: 30,
            }}
          >
            <div className={classes.colorPickerCntResult} style={{ marginLeft: 5 }}>
              <div className={classes.colorOld}>
                <div
                  className={classes.colorCtn}
                  style={{
                    backgroundColor:
                      typeof itemChangeColor === 'number'
                        ? tmpChromatoColors[itemChangeColor]
                        : '#000',
                  }}
                />
              </div>
              <div className={classes.colorPickerTitle}>
                {t('view.common.chromatoDetail.chromatoDetail.old_color')}
              </div>
            </div>
            <div className={classes.colorPickerCntResult} style={{ marginLeft: 5 }}>
              <div className={classes.colorOld}>
                <div
                  className={classes.colorCtn}
                  style={{
                    backgroundColor: colorTmp
                      ? colorTmp
                      : typeof itemChangeColor === 'number'
                      ? tmpChromatoColors[itemChangeColor]
                      : '#000',
                  }}
                />
              </div>
              <div className={classes.colorPickerTitle}>
                {t('view.common.chromatoDetail.chromatoDetail.new_color')}
              </div>
            </div>
          </div>
          <div>
            <DialogActions style={{ float: 'right' }}>
              <Button autoFocus onClick={() => handleCloseChangeColor()} color="primary">
                {t('view.common.chromatoDetail.chromatoDetail.cancel')}
              </Button>
              <Button onClick={() => handleCloseChangeColor(true)} color="primary">
                {t('view.common.chromatoDetail.chromatoDetail.apply')}
              </Button>
            </DialogActions>
          </div>
        </Box>
      </Popover>
      <div style={{ padding: 0, margin: '0px 14px' }}>
        <div style={{ margin: '20px 0px 20px 10px', fontSize: '1.1rem' }}>
          {t('view.common.settingsView.chromatoTab.show_hide')}
        </div>
      </div>
      <div style={{ padding: 0, margin: '0px 14px' }}>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <FormControl component="fieldset" className={classes.formControl}>
            {/*<FormLabel component="legend">Values</FormLabel>*/}
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox checked={transitions} onChange={handleChange} name="transitions" />
                }
                label={t('view.common.settingsView.chromatoTab.transition')}
              />
              <FormControlLabel
                control={<Checkbox checked={flag} onChange={handleChange} name="flag" />}
                label={t('view.common.settingsView.chromatoTab.flag')}
              />
              <FormControlLabel
                control={<Checkbox checked={rtCor} onChange={handleChange} name="rtCor" />}
                label={t('view.common.settingsView.chromatoTab.rt_correct')}
              />
              <FormControlLabel
                control={<Checkbox checked={rtCal} onChange={handleChange} name="rtCal" />}
                label={t('view.common.settingsView.chromatoTab.rt_calcu')}
              />
              <FormControlLabel
                control={<Checkbox checked={rtMth} onChange={handleChange} name="rtMth" />}
                label={t('view.common.settingsView.chromatoTab.rt_method')}
              />
              <FormControlLabel
                control={
                  <Checkbox checked={validation} onChange={handleChange} name="validation" />
                }
                label={t('view.common.settingsView.chromatoTab.validation')}
              />
            </FormGroup>
          </FormControl>
          <FormControl component="fieldset" className={classes.formControl}>
            {/*<FormLabel component="legend">Values</FormLabel>*/}
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={area} onChange={handleChange} name="area" />}
                label={t('view.common.settingsView.chromatoTab.area')}
              />
              <FormControlLabel
                control={<Checkbox checked={height} onChange={handleChange} name="height" />}
                label={t('view.common.settingsView.chromatoTab.height')}
              />
              <FormControlLabel
                control={<Checkbox checked={concVol} onChange={handleChange} name="concVol" />}
                label={t('view.common.settingsView.chromatoTab.volum_conc')}
              />
              <FormControlLabel
                control={<Checkbox checked={rt} onChange={handleChange} name="rt" />}
                label={t('view.common.settingsView.chromatoTab.rt')}
              />
              <FormControlLabel
                // style={{ margin: '0px 15px' }}
                control={
                  <Checkbox
                    checked={lockChromatoEdition}
                    onChange={handleChange}
                    name="lockChromatoEdition"
                  />
                }
                label={t('view.common.settingsView.chromatoTab.lockChromatoEdition')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChromatoPeakAsDefaultWindow}
                    onChange={handleChromatoWindowDefaultChange}
                    name="peakWindow"
                  />
                }
                label={t('view.common.settingsView.chromatoTab.defaultWindow')}
              />
            </FormGroup>
          </FormControl>
          <span style={{ minWidth: 260 }}>
            <div style={{ marginLeft: 23, marginRight: 30, marginTop: 6 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <ChromatoHeight style={{ color: '#757575' }} />
                </Grid>
                <Grid item xs>
                  <Slider
                    value={typeof chromatoHeight === 'number' ? chromatoHeight : 1}
                    onChange={handleChromatoHeightChange}
                    marks={true}
                    min={1}
                    max={10}
                  />
                </Grid>
                <Grid item style={{ width: 50 }}>
                  <Input
                    value={chromatoHeight}
                    margin="dense"
                    onChange={handleInputChromatoHeightChange}
                    onBlur={handleBlurChromatoHeight}
                    inputProps={{
                      step: 1,
                      min: 1,
                      max: 10,
                      type: 'number',
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            <div style={{ marginLeft: 23, marginRight: 30 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <OpacityIcon style={{ color: '#757575' }} />
                </Grid>
                <Grid item xs>
                  <Slider
                    value={typeof chromatoOpacity === 'number' ? chromatoOpacity : 1}
                    onChange={handleChromatoOpacityChange}
                    // marks={true}
                    step={0.01}
                    min={0.01}
                    max={1}
                  />
                </Grid>
                <Grid item style={{ width: 62 }}>
                  <Input
                    value={chromatoOpacity}
                    margin="dense"
                    onChange={handleInputChromatoOpacityChange}
                    onBlur={handleBlurChromatoOpacity}
                    inputProps={{
                      step: 0.01,
                      min: 0.01,
                      max: 1,
                      type: 'number',
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            <div style={{ marginLeft: 23, marginRight: 30 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <LineWeightIcon style={{ color: '#757575' }} />
                </Grid>
                <Grid item xs>
                  <Slider
                    value={typeof chromatoWeight === 'number' ? chromatoWeight : 1}
                    onChange={handleChromatoWeightChange}
                    marks={true}
                    step={0.5}
                    min={0.5}
                    max={5}
                  />
                </Grid>
                <Grid item style={{ width: 62 }}>
                  <Input
                    value={chromatoWeight}
                    margin="dense"
                    onChange={handleChromatoWeightChange}
                    onBlur={handleBlurChromatoWeight}
                    inputProps={{
                      step: 0.5,
                      min: 0.5,
                      max: 5,
                      type: 'number',
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            <Stack
              style={{ marginLeft: 23, marginRight: 30, marginTop: 10 }}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={0}
            >
              <ColorLensIcon style={{ color: '#757575', marginRight: 10 }} />
              {[...Array(8).keys()].map((key) => (
                <Button
                  key={key}
                  className={classes.colorButton}
                  style={{ background: tmpChromatoColors[key] }}
                  variant="text"
                  onClick={(e) => handleChromatoColorChange(e, key)}
                >
                </Button>
              ))}
            </Stack>
          </span>
        </Grid>
      </div>
    </span>
  )
}
