import { API_BO_URL, API_URL } from '../../utils/config'
import { displayPopupMessage, generateErrorPart } from '../../view/common/Message'
import React, { Fragment } from 'react'
import { fetchChangeSettings } from '../settings/actions'

export const REQUEST_CONFIGURATION_FEATURES = 'REQUEST_CONFIGURATION_FEATURES'
export const RECEIVE_CONFIGURATION_FEATURES = 'RECEIVE_CONFIGURATION_FEATURES'

export const REQUEST_CONFIGURATIONS = 'REQUEST_CONFIGURATIONS'
export const RECEIVE_CONFIGURATIONS = 'RECEIVE_CONFIGURATIONS'
export const REQUEST_UPDATE_CONFIGURATION = 'REQUEST_UPDATE_CONFIGURATION'
export const RECEIVE_UPDATE_CONFIGURATION = 'RECEIVE_UPDATE_CONFIGURATION'
export const RECEIVE_CONFIGURATIONS_ERROR = 'RECEIVE_CONFIGURATIONS_ERROR'
export const CLEAN_CONFIGURATION_FEATURES = 'CLEAN_CONFIGURATION_FEATURES'

export function fetchConfigurationFeatures(configName) {
  return async (dispatch) => {
    // Do not use isFetching to avoid the loading on Analysis / Compound view
    // dispatch(requestConfigurationFeatures(configName))
    let filter = [
      {
        property: 'name',
        value: configName,
        filterType: 'string',
        operator: 'eq',
      },
    ]
    let v = encodeURIComponent(JSON.stringify(filter))
    return fetch(`${API_BO_URL()}.configuration/?filter=${v}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((json) => {
        return dispatch(receiveConfigurationFeatures(json[0]))
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to get features ',
          <Fragment>
            <div>An error does not allow the get the configuration features:</div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      })
  }
}
export function requestConfigurationFeatures(configName) {
  return {
    type: REQUEST_CONFIGURATION_FEATURES,
    configName: configName,
  }
}
export function receiveConfigurationFeatures(configFeatures) {
  return {
    type: RECEIVE_CONFIGURATION_FEATURES,
    configFeatures: configFeatures,
  }
}

export function fetchCleanConfigurationFeatures() {
  return async (dispatch) => {
    return dispatch(ConfigurationFeatures())
  }
}
export function ConfigurationFeatures() {
  return {
    type: CLEAN_CONFIGURATION_FEATURES,
  }
}

export function fetchConfigurations(database) {
  return async (dispatch) => {
    dispatch(requestConfigurations())
    const includes = ['name', '_id', 'content.features'].map((inc) => 'include=' + inc).join('&')
    // const response = await fetch(`${API_BO_URL()}.configuration/page/?${includes}`, {
    fetch(`${API_URL()}/bo/${database}.configuration/page`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((json) => {
        return dispatch(receiveConfigurations(json.result))
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        /*displayPopupMessage(
          dispatch,
          'error',
          'Failed to get features ',
          <Fragment>
            <div>An error does not allow the get the configuration features:</div>
            {generateErrorPart(error)}
          </Fragment>,
        )*/
        return dispatch(receiveConfigurations([]))
      })
  }
}
export function requestConfigurations() {
  return {
    type: REQUEST_CONFIGURATIONS,
  }
}
export function receiveConfigurations(configurations) {
  // const user = JSON.parse(localStorage.getItem('SESSION_user_json'))
  // const userTeams = user.teams.map((team) => team.name)
  // const configurationsFiltered = configurations.filter(
  //   (config) => userTeams.indexOf(config.name) !== -1,
  // )
  return {
    type: RECEIVE_CONFIGURATIONS,
    configurations: configurations,
  }
}
export function receiveConfigurationsFailed() {
  return {
    type: RECEIVE_CONFIGURATIONS_ERROR,
  }
}

export function fetchUpdateConfiguration(configuration) {
  return async (dispatch) => {
    dispatch(requestUpdateConfiguration())
    return fetch(`${API_BO_URL()}.configuration/${configuration._id}`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(configuration),
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((json) => {
        return dispatch(receiveUpdateConfiguration(json))
      })
      .catch((error) => {
        if (error.status === 403) {
          dispatch(fetchChangeSettings('loginPopup', true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to update configuration ',
          <Fragment>
            <div>An error does not allow the update the configuration features:</div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      })
  }
}
export function requestUpdateConfiguration() {
  return {
    type: REQUEST_UPDATE_CONFIGURATION,
  }
}
export function receiveUpdateConfiguration(configuration) {
  return {
    type: RECEIVE_UPDATE_CONFIGURATION,
    configurationUpdated: configuration,
  }
}
