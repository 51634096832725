import { API_BO_URL } from '../../utils/config'
import { fetchChangeSettings } from '../settings/actions'
import { displayPopupMessage, generateErrorPart } from '../../view/common/Message'
import React, { Fragment } from 'react'

export const REQUEST_COD_DRIVE_CONFIGS = 'REQUEST_COD_DRIVE_CONFIGS'
export const RECEIVE_COD_DRIVE_CONFIGS = 'RECEIVE_COD_DRIVE_CONFIGS'

export function fetchGetCodDriveConfigs() {
  return async (dispatch) => {
    dispatch(requestGetCodDriveConfigs())
    return fetch(`${API_BO_URL()}.cod_drive_configuration/page/`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          const statusText = response.statusText
          const status = response.status
          const url = response.url
          return response.text().then((errorMessage) => {
            const error = new Error(`${statusText} : ${errorMessage}`)
            if (response.headers.get('content-type') === 'application/json') {
              error.stack = JSON.stringify(
                JSON.parse(errorMessage.replaceAll('\\n    ', '').replaceAll('\\n', '')),
                null,
                2,
              )
            } else {
              error.stack = new Error().stack
            }
            error.statusText = statusText
            error.status = status
            error.url = url
            throw error
          })
        }
        return response.json()
      })
      .then((resp) => {
        return dispatch(receiveCodDriveConfigs(resp.result))
      })
      .catch((error) => {
        if (error.status === 403) {
          return dispatch(fetchChangeSettings('loginPopup', true))
        }
        displayPopupMessage(
          dispatch,
          'error',
          'Failed to get Logs',
          <Fragment>
            <div>An error does not allow to get COD Drive configurations:</div>
            {generateErrorPart(error)}
          </Fragment>,
        )
      })
  }
}

export function requestGetCodDriveConfigs() {
  return {
    type: REQUEST_COD_DRIVE_CONFIGS,
  }
}

export function receiveCodDriveConfigs(configs) {
  return {
    type: RECEIVE_COD_DRIVE_CONFIGS,
    codDriveConfig: configs,
  }
}
