import React, { Fragment } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import WarningIcon from '@material-ui/icons/ReportProblemOutlined'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import { uid } from 'react-uid'
import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { PUBLIC_URL } from '../../utils/config'
import Box from '@material-ui/core/Box'
import { fetchDisplayDetail } from '../../redux/detail/actions'
import { connect } from 'react-redux'
import { FlagsTooltip } from './Style'
import { t } from 'i18next'
import Badge from '@mui/material/Badge'
import clsx from 'clsx'
import { getSeverity } from './Utils'

const styles = (theme) => ({
  info: {
    color: theme.palette.common.infoBlue,
  },
  success: {
    color: theme.palette.common.success,
  },
  warning: {
    color: theme.palette.common.warning,
  },
  error: {
    color: theme.palette.common.error,
  },
  chipTooltip: {
    fontSize: 14,
    '& .mainIcon .MuiSvgIcon-root': {
      width: 35,
      height: 35,
      filter: 'drop-shadow( 2px 1px 2px white)',
    },
  },
  divider: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.secondary.main,
    width: '85%',
    opacity: 0.5,
  },
  header: {
    height: 80,
    background: 'url(' + PUBLIC_URL + '/images/flags_details_img_blur.jpg) no-repeat',
    backgroundSize: '100%',
    boxShadow: 'inset 0px 0px 20px 10px rgba(0,0,0,0.1)',
  },
  title: {
    paddingTop: 33,
    paddingLeft: 20,
    fontSize: 40,
    textShadow: '2px 1px 2px white',
  },
  flagBouton: {
    padding: 0,
    width: 24,
    height: 24,
    minWidth: 24,
    '&:hover': {
      backgroundColor: 'transparent',
      transform: 'scale(1.2)',
    },
  },
  newInfo_high: {
    '& .MuiBadge-badge': {
      backgroundColor: '#ff0000',
      border: '1px solid white',
    },
  },
  newInfo_middle: {
    '& .MuiBadge-badge': {
      backgroundColor: '#ff7600',
      border: '1px solid white',
    },
  },
  newInfo_low: {
    '& .MuiBadge-badge': {
      backgroundColor: '#ffeb00',
      border: '1px solid #a1a1a1',
    },
  },
})

const StyledBadge = withStyles((theme) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    padding: '0 4px',
  },
}))(Badge)

export default connect()(
  withStyles(styles)(
    class AlphacodFlagIcon extends React.Component {
      getClassificationIcon = function (classification) {
        const { classes } = this.props
        switch (classification) {
          case 'info':
            return <InfoIcon className={classes.info} />
          case 'warning':
            return <WarningIcon className={classes.warning} />
          case 'error':
            return <ErrorIcon className={classes.error} />
          default:
            return <Fragment />
        }
      }

      getFlagIcon = function (item) {
        const flags = item.flags
        var flags_dict = new Map()
        flags_dict.set('info', 0)
        flags_dict.set('warning', 1)
        flags_dict.set('error', 2)
        var icon_value = -1
        var icon = ''

        const flags_globals = flags.globals ? flags.globals : []
        flags_globals.forEach((flag) => {
          if (flags_dict.get(flag.level) > icon_value) {
            icon_value = flags_dict.get(flag.level)
            icon = flag.level
          }
        })
        const flags_channels = flags.channels ? flags.channels : {}
        const flags_channels_entries = Object.entries(flags_channels)
        if (flags_channels_entries.length > 0 && icon_value !== 2) {
          flags_channels_entries.forEach((channel) => {
            channel[1].forEach((flag) => {
              if (flags_dict.get(flag.level) > icon_value) {
                if (channel[0] !== item.mainChannel.toString() && flag.level === 'error') {
                  icon_value = 1
                  icon = 'warning'
                } else {
                  icon_value = flags_dict.get(flag.level)
                  icon = flag.level
                }
              }
            })
          })
        }
        return icon
      }

      /**
       * Return all the flags in db.
       *
       * @param flags the flags
       * @returns The title of tooltip html tag
       */
      getFlagTooltip = function (item, hideGeneralTab, mainIcon, classes, collection) {
        const { dispatch } = this.props
        const mainChannel = item.mainChannel
        const flags = item.flags
        const classification = item.classification
        const flags_globals = flags && flags.globals ? flags.globals : []
        const flags_globals_items = flags_globals.map((flag, index) => {
          return (
            <table key={uid(flag, index)} style={{ overflowWrap: 'anywhere' }}>
              <tbody>
                <tr>
                  <td style={{ verticalAlign: 'top', width: 35 }}>
                    <StyledBadge
                      className={clsx(
                        getSeverity(flag.title)
                          ? classes['newInfo_' + getSeverity(flag.title)]
                          : null,
                      )}
                      // color="secondary"
                      badgeContent={flag.blocking === true ? ' ' : 0}
                      variant="dot"
                    >
                      {this.getClassificationIcon(flag.level)}
                    </StyledBadge>
                  </td>
                  <td style={{ lineHeight: 1.2 }}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            {flag.title}
                            <span style={{ fontSize: 13 }}>
                              {flag.classification ? ' (' + flag.classification + ')' : ''}
                            </span>
                            :
                          </td>
                        </tr>
                        <tr style={{ fontSize: 12 }}>
                          <td>
                            <i>{flag.reason}</i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          )
        })
        const flags_channels = flags && flags.channels ? flags.channels : {}
        const flags_channel_entries = Object.entries(flags_channels)
        const classification_channels =
          classification && classification.channels ? classification.channels : {}

        const classification_channel_entries = Object.entries(classification_channels)
        // If tooltip, only the main channel. For detail, all channels
        // const flags_channels_items = flags_channel_entries.map((channel, index) => {
        const flags_channels_items = flags_channel_entries
          .filter((channel) => parseInt(channel[0]) === mainChannel)
          .map((channel, index) => {
            const flags = channel[1].map((flag, index) => {
              return (
                <table key={uid(flag, index)} style={{ overflowWrap: 'anywhere' }}>
                  <tbody>
                    <tr>
                      <td style={{ verticalAlign: 'top', width: 35 }}>
                        {this.getClassificationIcon(flag.level)}
                      </td>
                      <td style={{ lineHeight: 1.2 }}>
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                {flag.title}
                                <span style={{ fontSize: 13 }}>
                                  {flag.classification ? ' (' + flag.classification + ')' : ''}
                                </span>
                                :
                              </td>
                            </tr>
                            <tr style={{ fontSize: 12 }}>
                              <td>
                                <i>{flag.reason}</i>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )
            })
            return (
              <React.Fragment key={uid(channel, index)}>
                <div style={{ margin: '20px 15px' }}>
                  <div style={{ fontSize: 16, marginBottom: 10, marginLeft: 5 }}>
                    Main channel:{' '}
                    {classification_channel_entries.length > 0 &&
                    classification_channel_entries[index]
                      ? classification_channel_entries[index][1]
                      : ''}
                  </div>
                  {flags}
                </div>
              </React.Fragment>
            )
          })
        return (
          <div className={classes.chipTooltip}>
            {
              <React.Fragment>
                <div style={{ marginBottom: 20, marginTop: 10 }}>
                  <Box className={classes.header}>
                    <div className={classes.title}>
                      <table style={{ marginBottom: 10 }}>
                        <tbody>
                          <tr>
                            <td className={'mainIcon'} style={{ verticalAlign: 'top', width: 40 }}>
                              {mainIcon}
                            </td>
                            <td style={{ fontSize: 16 }}>
                              {t('view.common.alphacod_flag_icon.classification')}
                              {classification && classification.global
                                ? ' ' + classification.global
                                : ''}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Box>
                  <Box style={{ margin: '10px 15px' }}>{flags_globals_items}</Box>
                </div>
              </React.Fragment>
            }
            {flags_globals_items.length > 0 ? <hr className={classes.divider} /> : ''}
            {flags_channels_items.length > 0 ? (
              <React.Fragment>{flags_channels_items}</React.Fragment>
            ) : (
              ''
            )}
            <div
              style={{
                justifyContent: 'flex-end',
                display: 'flex',
                paddingRight: 10,
                paddingBottom: 10,
              }}
            >
              <Button
                onClick={function (event) {
                  dispatch(
                    fetchDisplayDetail(item, 1, hideGeneralTab, event.currentTarget, collection),
                  )
                }}
                color="primary"
              >
                {t('view.common.alphacod_flag_icon.details')}
              </Button>
            </div>
          </div>
        )
      }

      render() {
        const { classes, dispatch, item, hideGeneralTab, collection } = this.props

        const renderIcon = () => {
          let _flagIcon = this.getFlagIcon(item)
          let buttonIcon
          switch (_flagIcon) {
            case 'info':
              buttonIcon = <InfoIcon className={classes.info} />
              break
            case 'warning':
              buttonIcon = <WarningIcon className={classes.warning} />
              break
            case 'error':
              buttonIcon = <ErrorIcon className={classes.error} />
              break
            default:
              buttonIcon = null
              break
          }
          return buttonIcon === null ? null : (
            <Button
              color="primary"
              className={classes.flagBouton}
              onClick={(event) => {
                event.stopPropagation()
                event.preventDefault()
                //TODO force the onClose
                dispatch(
                  fetchDisplayDetail(item, 1, hideGeneralTab, event.currentTarget, collection),
                )
              }}
            >
              <StyledBadge
                className={clsx(
                  item.extraFlags ? classes['newInfo_' + item.extraFlags.severity] : null,
                )}
                // color="secondary"
                badgeContent={
                  item?.flags?.globals.flat().some((i) => i.blocking === true) ? ' ' : 0
                }
                variant="dot"
              >
                {buttonIcon}
              </StyledBadge>
            </Button>
          )
        }

        const renderedIcon = renderIcon()

        return renderedIcon === null ? (
          ''
        ) : (
          <Fragment>
            <FlagsTooltip
              interactive
              placement="right"
              title={this.getFlagTooltip(item, hideGeneralTab, renderIcon(), classes, collection)}
            >
              {renderedIcon}
            </FlagsTooltip>
          </Fragment>
        )
      }
    },
  ),
)
