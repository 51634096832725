import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { t } from 'i18next'
import Grid from '@material-ui/core/Grid'
import Title from '../Title'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import { connect } from 'react-redux'
import {
  fetchBatches,
  fetchBatchesDevices,
  fetchBatchesServices,
  fetchBatchesTypes,
} from '../../redux/batches/actions'
import Loading from '../Loading'
import Tooltip from '@material-ui/core/Tooltip'
import Box from '@material-ui/core/Box'
import DateFnsUtils from '@date-io/date-fns'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DnsIcon from '@material-ui/icons/Dns'
import StorageIcon from '@material-ui/icons/Storage'
import { getTimeEndDay, getTimeStartDay } from '../common/Utils'
import { Analysis, Analyses } from '../common/Style'
import Button from '@material-ui/core/Button'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import DateRangeIcon from '@material-ui/icons/DateRange'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import IconButton from '@material-ui/core/IconButton'
import { fade } from '@material-ui/core'
import ReceiveAllBatchDialog from '../dialog/ReceiveAllBatchDialog'
import UpdateBatchDialog from '../dialog/UpdateBatchDialog'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import ViewListIcon from '@material-ui/icons/ViewList'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import CreateTrainingBatchDialog from '../dialog/CreateTrainingBatchDialog'
import { displayPopupMessage } from '../common/Message'
import { fetchChangeSettings } from '../../redux/settings/actions'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import PersonIcon from '@material-ui/icons/Person'
import SplitterLayout from 'react-splitter-layout'
import Divider from '@material-ui/core/Divider'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import { Checkbox } from '@mui/material'
import Stack from '@mui/material/Stack'
import EmptyBacklog from '../EmptyBacklog'
import { fetchDisplayChromatoDetail } from '../../redux/detail/actions'
import TableCmp from './TableCmp'
import TreeCmp from './TreeCmp'
import ExtraDialogs from './ExtraDialogs'

const styles = (theme) => ({
  paper: {
    // boxShadow:
    //   '0px 2px 7px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    borderRadius: '0px 0px 4px 4px',
    width: '94%',
    height: 'calc(100% - 8px)',
    padding: 10,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    marginLeft: '3%',
    '& h2': {
      margin: 10,
    },
    '& .MuiTableBody-root td.MuiTableCell-root': {
      cursor: 'pointer',
    },
    '& .MuiTableHead-root .MuiTableCell-root': {
      backgroundColor: 'white',
      borderBottom: '0px solid #e0e0e0',
    },
  },
  datePickers: {
    '& input': {
      padding: '12px 14px',
      width: 90,
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0,
    },
  },
  fromCtn: {
    '& .MuiInputBase-root': {
      borderRadius: '4px 0px 0px 4px',
    },
    '& fieldset': {
      borderRight: 0,
    },
  },
  toCtn: {
    '& .MuiInputBase-root': {
      borderRadius: '0px 4px 4px 0px',
    },
  },
  displayMode: {
    position: 'absolute',
    width: 30,
    height: 30,
    background: theme.palette.secondary.main,
    top: 'calc(50% - 15px)',
    left: 'calc(3% - 17px)',
    borderRadius: 25,
    transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '& button:hover': {
      transform: 'scale(1.1)',
      background: 'transparent',
    },
    '& button': {
      opacity: 0,
      color: 'white',
      transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      padding: 2,
      position: 'absolute',
      transform: 'scale(0.8)',
    },
    '& button.activeMode': {
      opacity: 1,
    },
    '& button.treeButton.activeMode': {
      marginTop: 3,
    },
    '&:hover button': {
      padding: 11,
      opacity: 0.5,
      transform: 'scale(1.2)',
    },
    '&:hover button.activeMode': {
      opacity: 1,
    },
    '&:hover button.treeButton': {
      marginTop: 48,
    },
    '&:hover button:hover': {
      transform: 'scale(1.3)',
    },
    '&:hover': {
      top: 'calc(50% - 48px)',
      paddingTop: 2,
      width: 48,
      height: 96,
    },
  },
  deviceFormControl: {
    '& .MuiSelect-select': {
      padding: '12px 36px 12px 14px',
    },
  },
  mainSplitterFull: {
    height: 'inherit',
    // paddingLeft: 241,
    '& > .layout-splitter': {
      display: 'none',
    },
  },
  no_data: {
    width: '100%',
    height: '100%',
    fontSize: 'xx-large',
    color: theme.palette.secondary.main,
  },
})

function mapStateToProps(state) {
  const { batches, tasks, settings } = state
  const { items, isFetching, devices, services, types } = batches

  return {
    services: services,
    devices: devices,
    types: types,
    items,
    tasks: null, //tasks.tasks,
    isFetching,
    authenticationError: batches.error,
    delta: settings.dateTimeDelta,
    isDatabaseOffline: settings.configurationDatabase.length > 0,
  }
}

class Backlog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedFromDate: localStorage.getItem('BACKLOG_filter_fromDate')
        ? new Date(localStorage.getItem('BACKLOG_filter_fromDate'))
        : new Date(getTimeStartDay() - 604800000),
      selectedToDate: localStorage.getItem('BACKLOG_filter_toDate')
        ? new Date(localStorage.getItem('BACKLOG_filter_toDate'))
        : new Date(getTimeEndDay()),
      order: localStorage.getItem('BACKLOG_order')
        ? localStorage.getItem('BACKLOG_order').split('$')[0]
        : 'asc',
      orderBy: localStorage.getItem('BACKLOG_order')
        ? localStorage.getItem('BACKLOG_order').split('$')[1]
        : 'name',
      backlogFilterDevice: localStorage.getItem('BACKLOG_filter_device')
        ? localStorage.getItem('BACKLOG_filter_device')
        : 'all',
      backlogFilterService: localStorage.getItem('BACKLOG_filter_service')
        ? localStorage.getItem('BACKLOG_filter_service')
        : 'all',
      backlogFilterType: localStorage.getItem('BACKLOG_filter_type')
        ? localStorage.getItem('BACKLOG_filter_type')
        : 'all',
      wipChecked: localStorage.getItem('BACKLOG_filter_wip')
        ? JSON.parse(localStorage.getItem('BACKLOG_filter_wip')) === true
        : false,
      anchorDateFilterEl: null,
      openConfirmReceiveAllBatch: false,
      openConfirmUpdateBatch: false,
      openCreateTrainingBatch: false,
      currentUpdateBatch: null,
      displayModeSelected: localStorage.getItem('BACKLOG_display_view')
        ? localStorage.getItem('BACKLOG_display_view')
        : 'table',
      expanded: [],
      otherMenuEl: null,
      dateFilterActive: localStorage.getItem('BATCH_activeDateFilter')
        ? localStorage.getItem('BATCH_activeDateFilter') === 'true'
        : false,
    }
    this.handleConfirmReceiveAllBatch = this.handleConfirmReceiveAllBatch.bind(this)
    this.handleConfirmUpdateBatch = this.handleConfirmUpdateBatch.bind(this)
    this.updateDisplayMode = this.updateDisplayMode.bind(this)
    this.handleCreateTrainingBatch = this.handleCreateTrainingBatch.bind(this)
  }

  async componentDidMount() {
    const { dispatch, isDatabaseOffline, match } = this.props
    const {
      selectedFromDate,
      selectedToDate,
      backlogFilterService,
      wipChecked,
      backlogFilterDevice,
      backlogFilterType,
      dateFilterActive,
    } = this.state
    if (!isDatabaseOffline) {
      // Open the chromato detail if an ID is set in the URL
      const chromatoId = match.params.chromatoId
      if (chromatoId) {
        const regex = /ua=/i
        dispatch(fetchDisplayChromatoDetail(chromatoId.replace(regex, '')))
      }

      dispatch(fetchBatchesServices())
      dispatch(fetchBatchesTypes())
      dispatch(fetchBatchesDevices(backlogFilterService, backlogFilterType))
      await dispatch(
        fetchBatches(
          dateFilterActive ? Math.trunc(selectedFromDate.getTime() / 1000) : null,
          dateFilterActive ? Math.trunc(selectedToDate.getTime() / 1000) : null,
          wipChecked,
          backlogFilterService,
          backlogFilterType,
          backlogFilterDevice,
        ),
      )
    }
    if (this.props.authenticationError) {
      displayPopupMessage(
        dispatch,
        'error',
        t('view.backlog.authentication_error.title'),
        <Fragment>
          <div>{t('view.backlog.authentication_error.msg1')}</div>
          <div>{t('view.backlog.authentication_error.msg2')}</div>
          <div>{t('view.backlog.authentication_error.msg3')}</div>
        </Fragment>,
      )
      // localStorage.removeItem('SESSION_user_name')
      // localStorage.removeItem('SESSION_user_json')
      // this.props.history.push('/?login')
      return dispatch(fetchChangeSettings('loginPopup', true))
    }
  }

  applyDateFilter(event, filter) {
    const { dispatch } = this.props
    const {
      selectedFromDate,
      backlogFilterService,
      wipChecked,
      backlogFilterDevice,
      backlogFilterType,
      dateFilterActive,
    } = this.state
    const startTimestamp = getTimeStartDay()
    let fromDate = selectedFromDate
    let toDate = new Date(getTimeEndDay())
    switch (filter) {
      case 'month':
        fromDate = new Date(startTimestamp - 2628000000)
        break
      case 'week':
        fromDate = new Date(startTimestamp - 604800000)
        break
      case 'yesterday':
        fromDate = new Date(startTimestamp - 86400000)
        break
      case 'day':
        fromDate = new Date(startTimestamp)
        break
      default:
        break
    }
    this.setState({ selectedFromDate: fromDate, selectedToDate: toDate, anchorDateFilterEl: null })
    localStorage.setItem('BACKLOG_filter_fromDate', fromDate)
    localStorage.setItem('BACKLOG_filter_toDate', toDate)
    dispatch(
      fetchBatches(
        dateFilterActive ? Math.trunc(fromDate.getTime() / 1000) : null,
        dateFilterActive ? Math.trunc(toDate.getTime() / 1000) : null,
        wipChecked,
        backlogFilterService,
        backlogFilterType,
        backlogFilterDevice,
      ),
    )
  }

  handleDateChange(date, origin) {
    const { dispatch } = this.props
    const {
      selectedFromDate,
      selectedToDate,
      backlogFilterService,
      wipChecked,
      backlogFilterDevice,
      backlogFilterType,
      dateFilterActive,
    } = this.state
    const oneMonth = 2629800000

    let from = selectedFromDate
    let to = selectedToDate

    switch (origin) {
      case 'from':
        this.setState({ selectedFromDate: date })
        localStorage.setItem('BACKLOG_filter_fromDate', date)
        if (
          date.getTime() + oneMonth < selectedToDate.getTime() ||
          date.getTime() > selectedToDate.getTime()
        ) {
          const newToDateOneMonthLater = new Date(new Date(date).setMonth(date.getMonth() + 1))
          this.setState({ selectedToDate: newToDateOneMonthLater })
          localStorage.setItem('BACKLOG_filter_toDate', newToDateOneMonthLater)
          to = newToDateOneMonthLater
        }
        break
      case 'to':
        this.setState({ selectedToDate: date })
        localStorage.setItem('BACKLOG_filter_toDate', date)
        if (
          date.getTime() - oneMonth > selectedFromDate.getTime() ||
          date.getTime() < selectedFromDate.getTime()
        ) {
          const newFromDateOneMonthLater = new Date(new Date(date).setMonth(date.getMonth() - 1))
          this.setState({ selectedFromDate: newFromDateOneMonthLater })
          localStorage.setItem('BACKLOG_filter_fromDate', newFromDateOneMonthLater)
          from = newFromDateOneMonthLater
        }
        break
      default:
        break
    }

    dispatch(
      fetchBatches(
        dateFilterActive ? Math.trunc((origin === 'from' ? date : from).getTime() / 1000) : null,
        dateFilterActive ? Math.trunc((origin === 'to' ? date : to).getTime() / 1000) : null,
        wipChecked,
        backlogFilterService,
        backlogFilterType,
        backlogFilterDevice,
      ),
    )
  }

  handleDeviceChange(event) {
    const { dispatch } = this.props
    const {
      selectedFromDate,
      selectedToDate,
      backlogFilterService,
      backlogFilterType,
      dateFilterActive,
    } = this.state
    const device = event.target.value
    dispatch(
      fetchBatches(
        dateFilterActive ? Math.trunc(selectedFromDate.getTime() / 1000) : null,
        dateFilterActive ? Math.trunc(selectedToDate.getTime() / 1000) : null,
        !this.state.wipChecked,
        backlogFilterService,
        backlogFilterType,
        device,
      ),
    )
    localStorage.setItem('BACKLOG_filter_device', device)
    this.setState({ backlogFilterDevice: device })
  }

  handleServiceChange(event) {
    const { dispatch } = this.props
    const {
      selectedFromDate,
      selectedToDate,
      wipChecked,
      backlogFilterDevice,
      backlogFilterType,
      dateFilterActive,
    } = this.state
    const service = event.target.value
    dispatch(fetchBatchesDevices(service, backlogFilterType))
    dispatch(
      fetchBatches(
        dateFilterActive ? Math.trunc(selectedFromDate.getTime() / 1000) : null,
        dateFilterActive ? Math.trunc(selectedToDate.getTime() / 1000) : null,
        wipChecked,
        service,
        backlogFilterType,
        backlogFilterDevice,
      ),
    )
    localStorage.setItem('BACKLOG_filter_service', service)
    localStorage.setItem('BACKLOG_filter_device', 'all')
    this.setState({ backlogFilterService: service, backlogFilterDevice: 'all' })
  }

  handleTypeChange(event) {
    const { dispatch } = this.props
    const {
      selectedFromDate,
      selectedToDate,
      wipChecked,
      backlogFilterDevice,
      backlogFilterService,
      dateFilterActive,
    } = this.state
    const type = event.target.value
    dispatch(fetchBatchesDevices(backlogFilterService, type))
    // If type change, always reset the device to all
    dispatch(
      fetchBatches(
        dateFilterActive ? Math.trunc(selectedFromDate.getTime() / 1000) : null,
        dateFilterActive ? Math.trunc(selectedToDate.getTime() / 1000) : null,
        wipChecked,
        backlogFilterService,
        type,
        'all',
      ),
    )
    localStorage.setItem('BACKLOG_filter_type', type)
    localStorage.setItem('BACKLOG_filter_device', 'all')
    this.setState({ backlogFilterType: type, backlogFilterDevice: 'all' })
  }

  handleWipChange() {
    const { dispatch } = this.props
    const {
      selectedFromDate,
      selectedToDate,
      backlogFilterService,
      backlogFilterDevice,
      backlogFilterType,
      dateFilterActive,
    } = this.state
    dispatch(
      fetchBatches(
        dateFilterActive ? Math.trunc(selectedFromDate.getTime() / 1000) : null,
        dateFilterActive ? Math.trunc(selectedToDate.getTime() / 1000) : null,
        !this.state.wipChecked,
        backlogFilterService,
        backlogFilterType,
        backlogFilterDevice,
      ),
    )
    localStorage.setItem('BACKLOG_filter_wip', (!this.state.wipChecked).toString())
    this.setState({ wipChecked: !this.state.wipChecked })
  }

  handleDateFilterClick(event) {
    this.setState({ anchorDateFilterEl: event.currentTarget })
  }

  handleChangeDateFilterActive(event) {
    const { dispatch } = this.props
    const {
      selectedFromDate,
      selectedToDate,
      wipChecked,
      backlogFilterDevice,
      backlogFilterService,
      backlogFilterType,
    } = this.state
    this.setState({ dateFilterActive: event.target.checked })
    localStorage.setItem('BATCH_activeDateFilter', event.target.checked)
    dispatch(
      fetchBatches(
        event.target.checked ? Math.trunc(selectedFromDate.getTime() / 1000) : null,
        event.target.checked ? Math.trunc(selectedToDate.getTime() / 1000) : null,
        wipChecked,
        backlogFilterService,
        backlogFilterType,
        backlogFilterDevice,
      ),
    )
    // if (!event.target.checked) {
    //   displayPopupMessage(
    //     dispatch,
    //     'info',
    //     t('view.backlog.info_date.title'),
    //     <Fragment>
    //       <div>{t('view.backlog.info_date.msg1')}</div>
    //       <div>{t('view.backlog.info_date.msg2')}</div>
    //     </Fragment>,
    //   )
    // }
  }

  handleDateFilterClose() {
    this.setState({ anchorDateFilterEl: null })
  }

  handleConfirmReceiveAllBatch() {
    this.setState({ openConfirmReceiveAllBatch: false })
  }

  handleConfirmUpdateBatch() {
    this.setState({ openConfirmUpdateBatch: false })
  }

  handleCreateTrainingBatch() {
    this.setState({ openCreateTrainingBatch: false })
  }

  updateDisplayMode(event, mode) {
    this.setState({ displayModeSelected: mode })
    localStorage.setItem('BACKLOG_display_view', mode)
  }

  render() {
    const { isFetching, classes, devices, services, types, t, isDatabaseOffline } = this.props
    const {
      selectedFromDate,
      selectedToDate,
      wipChecked,
      anchorDateFilterEl,
      openConfirmReceiveAllBatch,
      openConfirmUpdateBatch,
      openCreateTrainingBatch,
      currentUpdateBatch,
      displayModeSelected,
      backlogFilterDevice,
      backlogFilterService,
      backlogFilterType,
      dateFilterActive,
    } = this.state

    document.title = localStorage.getItem('__config.json__APPLICATION_TITLE')

    const setOpenConfirmUpdateBatch = (open) => {
      this.setState({ openConfirmUpdateBatch: open })
    }
    const setOpenCreateTrainingBatch = (open) => {
      this.setState({ openCreateTrainingBatch: open })
    }
    const setCurrentUpdateBatch = (batch) => {
      this.setState({ currentUpdateBatch: batch })
    }

    if (isDatabaseOffline) {
      return <EmptyBacklog />
    } else {
      return (
        <>
          {/*<ErrorBoundaryGuard isDialog>*/}
          {/*  <ReceiveAllBatchDialog*/}
          {/*    open={openConfirmReceiveAllBatch}*/}
          {/*    closeFct={this.handleConfirmReceiveAllBatch}*/}
          {/*  />*/}
          {/*</ErrorBoundaryGuard>*/}
          {/*<ErrorBoundaryGuard isDialog>*/}
          {/*  <UpdateBatchDialog*/}
          {/*    open={openConfirmUpdateBatch}*/}
          {/*    closeFct={this.handleConfirmUpdateBatch}*/}
          {/*    batch={currentUpdateBatch}*/}
          {/*  />*/}
          {/*</ErrorBoundaryGuard>*/}
          {/*<ErrorBoundaryGuard isDialog>*/}
          {/*  <CreateTrainingBatchDialog*/}
          {/*    open={openCreateTrainingBatch}*/}
          {/*    closeFct={this.handleCreateTrainingBatch}*/}
          {/*    batch={currentUpdateBatch}*/}
          {/*  />*/}
          {/*</ErrorBoundaryGuard>*/}
          <ErrorBoundaryGuard isDialog>
            <ExtraDialogs />
          </ErrorBoundaryGuard>
          <SplitterLayout
            primaryIndex={1}
            secondaryInitialSize={0}
            customClassName={classes.mainSplitterFull}
          >
            <Fragment></Fragment>
            <Paper elevation={0} className={classes.paper}>
              <Title>
                <Grid container spacing={3} direction="row" justify="space-between">
                  <Grid item>
                    <Box>
                      {t('view.backlog.backlog')}
                      {/*<Tooltip*/}
                      {/*  key={'tooltip_get_batch-today'}*/}
                      {/*  arrow*/}
                      {/*  title={*/}
                      {/*    <div style={{ fontSize: 13, padding: 5 }}>*/}
                      {/*      {'Retrieve all the Batch of the day'}*/}
                      {/*    </div>*/}
                      {/*  }*/}
                      {/*>*/}
                      {/*  <IconButton*/}
                      {/*    color="primary"*/}
                      {/*    style={{ paddingTop: 9, marginLeft: 5 }}*/}
                      {/*    onClick={(event) => handleGetAllCurrentBatch(event)}*/}
                      {/*  >*/}
                      {/*    <ReplayIcon />*/}
                      {/*  </IconButton>*/}
                      {/*</Tooltip>*/}
                    </Box>
                  </Grid>
                  <Grid item style={{ padding: '17px 0px 0px' }}>
                    {/*<ToggleButtonGroup*/}
                    {/*  value={backlogFilterService}*/}
                    {/*  exclusive*/}
                    {/*  onChange={(event, filter) => this.applyChromatoFilter(filter)}*/}
                    {/*  aria-label="text alignment"*/}
                    {/*>*/}
                    {/*  <StyledToggleButton className={classes.buttonCalib} value={'gc'} aria-label="GC">*/}
                    {/*    <Analysis />*/}
                    {/*    <Box mr={4}>GC</Box>*/}
                    {/*  </StyledToggleButton>*/}
                    {/*  <StyledToggleButton className={classes.buttonCalib} value={'lc'} aria-label="LC">*/}
                    {/*    <Analysis />*/}
                    {/*    <Box mr={4}>LC</Box>*/}
                    {/*  </StyledToggleButton>*/}
                    {/*</ToggleButtonGroup>*/}

                    <FormControl
                      variant="outlined"
                      className={classes.deviceFormControl}
                      style={{ marginLeft: 50 }}
                    >
                      <InputLabel id="service-select-outlined-label">
                        {t('view.backlog.service')}
                      </InputLabel>
                      <Select
                        labelId="service-select-outlined-label"
                        id="service-simple-select-outlined"
                        value={backlogFilterService}
                        onChange={(e) => this.handleServiceChange(e)}
                        label={t('view.backlog.service')}
                      >
                        <MenuItem value="all">
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <PeopleAltIcon
                              style={{ marginRight: 12, color: '#737373', height: 19 }}
                            />
                            <em>{t('view.backlog.all')}</em>
                          </Grid>
                        </MenuItem>
                        {Object.keys(services)
                          .sort(function (a, b) {
                            if (a < b) return -1
                            if (a > b) return 1
                            return 0
                          })
                          .map((service, index) => (
                            <MenuItem value={service} key={service + '_key_' + index}>
                              <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                              >
                                <PersonIcon
                                  style={{ marginRight: 10, color: '#737373', height: 19 }}
                                />
                                <span>{service}</span>
                              </Grid>
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      className={classes.deviceFormControl}
                      style={{ marginLeft: 30 }}
                    >
                      <InputLabel id="type-select-outlined-label">
                        {t('view.backlog.type')}
                      </InputLabel>
                      <Select
                        labelId="type-select-outlined-label"
                        id="type-simple-select-outlined"
                        value={backlogFilterType}
                        onChange={(e) => this.handleTypeChange(e)}
                        label={t('view.backlog.type')}
                      >
                        <MenuItem value="all">
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Analyses style={{ marginRight: 12, color: '#737373', height: 16 }} />
                            <em>{t('view.backlog.all')}</em>
                          </Grid>
                        </MenuItem>
                        {Object.keys(types)
                          .sort(function (a, b) {
                            if (a < b) return -1
                            if (a > b) return 1
                            return 0
                          })
                          .map((type, index) => (
                            <MenuItem value={type} key={type + '_key_' + index}>
                              <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                              >
                                <Analysis
                                  style={{ marginRight: 10, color: '#737373', height: 16 }}
                                />
                                <span>{type}</span>
                              </Grid>
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      className={classes.deviceFormControl}
                      style={{ marginLeft: 30 }}
                    >
                      <InputLabel id="device-select-outlined-label">
                        {t('view.backlog.device')}
                      </InputLabel>
                      <Select
                        labelId="device-select-outlined-label"
                        id="device-simple-select-outlined"
                        value={backlogFilterDevice}
                        onChange={(e) => this.handleDeviceChange(e)}
                        label={t('view.backlog.device')}
                      >
                        <MenuItem value="all">
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <StorageIcon
                              style={{ marginRight: 12, color: '#737373', height: 19 }}
                            />
                            <em>{t('view.backlog.all')}</em>
                          </Grid>
                        </MenuItem>
                        {Object.keys(devices)
                          .sort(function (a, b) {
                            if (a < b) return -1
                            if (a > b) return 1
                            return 0
                          })
                          .map((device, index) => (
                            <MenuItem value={device} key={device + '_key_' + index}>
                              <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                              >
                                <DnsIcon
                                  style={{ marginRight: 10, color: '#737373', height: 19 }}
                                />
                                <span>{device}</span>
                              </Grid>
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <FormControlLabel
                    style={{ marginTop: 0, marginLeft: 50, color: 'rgba(0, 0, 0, 0.87)' }}
                    control={
                      <Switch checked={wipChecked} onChange={() => this.handleWipChange()} />
                    }
                    label={t('view.backlog.in_progress')}
                  />
                  <Grid item style={{ padding: 0 }} className={classes.datePickers}>
                    {/*<Button*/}
                    {/*  style={{*/}
                    {/*    padding: '9px 16px 8px',*/}
                    {/*    borderColor: 'rgb(196 196 196)',*/}
                    {/*    margin: '16px 20px 8px 0px',*/}
                    {/*    border: '1px solid #c4c4c4',*/}
                    {/*    color: 'rgba(0, 0, 0, 0.54)',*/}
                    {/*  }}*/}
                    {/*  aria-controls="filter-date-menu"*/}
                    {/*  aria-haspopup="true"*/}
                    {/*  onClick={(event) => this.handleDateFilterClick(event)}*/}
                    {/*  startIcon={<DateRangeIcon />}*/}
                    {/*  endIcon={<ArrowForwardIosIcon />}*/}
                    {/*>*/}
                    {/*  Quick filter*/}
                    {/*</Button>*/}
                    <div
                      style={{
                        border: '1px solid rgba(0, 0, 0, 0.23)',
                        borderRadius: 4,
                        display: 'inline-block',
                        marginTop: 16,
                        marginRight: 15,
                      }}
                    >
                      <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem style={{ margin: 0 }} />}
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                        style={{ height: 41 }}
                      >
                        <Checkbox
                          checked={dateFilterActive}
                          onChange={(event) => this.handleChangeDateFilterActive(event)}
                          style={{ borderRadius: 0, height: 41, width: 40 }}
                        />
                        <Button
                          disabled={!dateFilterActive}
                          variant={'text'}
                          style={{
                            padding: '9px 16px 8px',
                            // borderColor: 'rgb(196 196 196)',
                            margin: 0,
                            // border: '1px solid #c4c4c4',
                            color: dateFilterActive ? 'rgba(0, 0, 0, 0.66)' : 'rgba(0, 0, 0, 0.26)',
                            height: 41,
                          }}
                          aria-controls="filter-date-menu"
                          aria-haspopup="true"
                          onClick={(event) => this.handleDateFilterClick(event)}
                          // startIcon={<DateRangeIcon sx={{ color: 'rgba(0, 0, 0, 0.60)', fontSize: '1.5rem' }} />}
                          startIcon={
                            <DateRangeIcon
                              sx={{ /*color: 'rgba(0, 0, 0, 0.60)',*/ fontSize: '1.5rem' }}
                            />
                          }
                          endIcon={
                            <ArrowForwardIosIcon
                              sx={
                                {
                                  /*color: 'rgba(0, 0, 0, 0.60)'*/
                                }
                              }
                            />
                          }
                        >
                          {t('view.backlog.quick_filters')}
                        </Button>
                      </Stack>
                    </div>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorDateFilterEl}
                      getContentAnchorEl={null}
                      keepMounted
                      open={Boolean(anchorDateFilterEl)}
                      onClose={() => this.handleDateFilterClose()}
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                      }}
                    >
                      {/*<MenuItem onClick={(event) => this.applyDateFilter(event, 'year')}>Year</MenuItem>*/}
                      <MenuItem onClick={(event) => this.applyDateFilter(event, 'month')}>
                        {t('view.backlog.month')}
                      </MenuItem>
                      <MenuItem onClick={(event) => this.applyDateFilter(event, 'week')}>
                        {t('view.backlog.week')}
                      </MenuItem>
                      <MenuItem onClick={(event) => this.applyDateFilter(event, 'yesterday')}>
                        {t('view.backlog.yesterday')}
                      </MenuItem>
                      <MenuItem onClick={(event) => this.applyDateFilter(event, 'day')}>
                        {t('view.backlog.today')}
                      </MenuItem>
                    </Menu>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disabled={!dateFilterActive}
                        className={classes.fromCtn}
                        style={{ marginRight: -1 }}
                        disableToolbar
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-from"
                        label={t('view.backlog.from')}
                        value={selectedFromDate}
                        onChange={(e) => this.handleDateChange(e, 'from')}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                      <KeyboardDatePicker
                        disabled={!dateFilterActive}
                        style={{ marginRight: 15 }}
                        className={classes.toCtn}
                        disableToolbar
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-to"
                        label={t('view.backlog.to')}
                        value={selectedToDate}
                        onChange={(e) => this.handleDateChange(e, 'to')}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
              </Title>
              {isFetching && displayModeSelected === 'tree' && <Loading mode="alpha" size={10} />}
              {/*{!isFetching && batchesPrepared.length === 0 && (*/}
              {/*  <Stack*/}
              {/*    className={classes.no_data}*/}
              {/*    direction="column"*/}
              {/*    justifyContent="center"*/}
              {/*    alignItems="center"*/}
              {/*    spacing={0.5}*/}
              {/*  >*/}
              {/*    {t('view.backlog.no_data')}*/}
              {/*    <Nothing style={{ width: 35, height: 35 }} />*/}
              {/*  </Stack>*/}
              {/*)}*/}
              {displayModeSelected === 'table' && (
                <TableCmp
                  setCurrentUpdateBatch={setCurrentUpdateBatch}
                  setOpenConfirmUpdateBatch={setOpenConfirmUpdateBatch}
                  setOpenCreateTrainingBatch={setOpenCreateTrainingBatch}
                />
              )}
              {!isFetching && displayModeSelected === 'tree' && (
                <TreeCmp
                  setCurrentUpdateBatch={setCurrentUpdateBatch}
                  setOpenConfirmUpdateBatch={setOpenConfirmUpdateBatch}
                  setOpenCreateTrainingBatch={setOpenCreateTrainingBatch}
                />
              )}
            </Paper>
          </SplitterLayout>
          <div className={classes.displayMode}>
            <Tooltip
              placement="top"
              arrow
              title={
                <div style={{ fontSize: 13, padding: 5 }}>{t('view.backlog.table_layout')}</div>
              }
            >
              <IconButton
                aria-label="table"
                // style={{ opacity: displayModeSelected === 'table' ? 1 : 0.5 }}
                className={`${displayModeSelected === 'table' ? 'activeMode' : ''}`}
                onClick={(e) => this.updateDisplayMode(e, 'table')}
              >
                <ViewListIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              placement="bottom"
              arrow
              title={
                <div style={{ fontSize: 13, padding: 5 }}>{t('view.backlog.tree_layout')}</div>
              }
            >
              <IconButton
                aria-label="tree"
                className={`treeButton ${displayModeSelected === 'tree' ? 'activeMode' : ''}`}
                // style={{ opacity: displayModeSelected === 'tree' ? 1 : 0.5 }}
                onClick={(e) => this.updateDisplayMode(e, 'tree')}
              >
                <AccountTreeIcon style={{ width: 20, height: 20, marginLeft: 2 }} />
              </IconButton>
            </Tooltip>
          </div>
        </>
      )
    }
  }
}
export default withRouter(connect(mapStateToProps)(withTranslation()(withStyles(styles)(Backlog))))
