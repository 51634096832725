import React from 'react'

export const GET_SETTINGS = 'GET_SETTINGS'
export const ADD_SETTINGS = 'ADD_SETTINGS'
export const CHANGE_SETTING = 'CHANGE_SETTING'
export const RESET_SETTINGS = 'RESET_SETTINGS'

export function fetchGetSettings() {
  return async (dispatch) => {
    return dispatch(getSettings())
  }
}
export function getSettings() {
  return {
    type: GET_SETTINGS,
  }
}
export function fetchChangeSettings(setting, value) {
  return async (dispatch) => {
    return dispatch(setChangeSettings(setting, value))
  }
}
export function setChangeSettings(setting, value) {
  return {
    type: CHANGE_SETTING,
    setting: setting,
    value: value,
  }
}
