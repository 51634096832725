import React, { Fragment, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Trans, useTranslation } from 'react-i18next'
import { getBatchById } from '../../redux/batches/selectors'
import Loading from '../Loading'
import ErrorBoundaryGuard from '../ErrorBoundaryGuard'
import { ChromatoSettings, Molecule, Nothing, Relance } from '../common/Style'
import Title from '../Title'
import Calibration from '../common/Calibration'
import SplitterLayout from 'react-splitter-layout'
import ChromatoGraph from '../common/chromato/ChromatoGraph'
import SelectAllIcon from '@material-ui/icons/SelectAll'
import Typography from '@material-ui/core/Typography'
import TimelineIcon from '@material-ui/icons/Timeline'
import NotesIcon from '@mui/icons-material/Notes'
// import ToggleButton from '@mui/material/ToggleButton'
// import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import TextField from '@material-ui/core/TextField'
import { alpha } from '@material-ui/core'
import {
  convertMassConcentrationValue,
  convertVolumeConcentrationValue,
  customFormatDecimals,
  displayHumanReadable,
  handleValidation,
  canIModifyTheCalibration,
} from '../common/Utils'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import PersonPinIcon from '@material-ui/icons/PersonPin'
import MetaboliteBadge from '../common/MetaboliteBadge'
import AIScoreTableTooltip from '../common/AIScoreTableTooltip'
import i18n, { t } from 'i18next'
import Button from '@material-ui/core/Button'
import Stack from '@mui/material/Stack'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import AlphacodFlagIcon from '../common/AlphacodFlagIcon'
import AlphacodModifiedButtonIcon from '../common/AlphacodModifiedButtonIcon'
import IconButton from '@material-ui/core/IconButton'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import { fetchChangeSelectedChannel } from '../../redux/unitaryAnalyses/actions'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    height: 'calc(100% - 60px)',
    // overflow: 'hidden',
    // '&:hover': {
    //   overflow: 'auto',
    // },
    '& .ReactVirtualized__Grid:focus': {
      outline: 'none',
    },
  },
  mainContainerSplitter: {
    width: 'calc(100% - 28px)',
    height: 'calc(100% - 95px)',
    '& > .layout-pane': {
      // padding: '6px 5px 0px 0px',
      overflow: 'hidden',
    },
    '& > .layout-pane:first-child': {
      padding: '0px 5px 6px 2px',
    },
  },
  containerSplitter: {
    '& > .layout-pane': {
      padding: '6px 5px 0px 0px',
      overflow: 'hidden',
    },
    '& > .layout-pane:first-child': {
      padding: '0px 5px 6px 2px',
    },
  },
  noItem: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    top: 'calc(50% - 24px)',
    // position: 'relative',
    position: 'sticky', //New version of Chrome doesnt work with "relative".....
  },
  title: {
    height: 41,
    padding: '4px 8px',
    textAlign: 'center',
    color: theme.palette.primary.main,
    border: '1px solid #d3d3d38a',
    borderRadius: 5,
    background: alpha(theme.palette.primary.main, 0.05),
  },
  colorPickerCnt: {
    border: '1px solid #c4c4c4',
    width: 'calc(50% - 5px)',
    height: 40,
    display: 'inline-flex',
    borderRadius: 4,
  },
  colorPickerTitle: {
    background:
      'linear-gradient(0deg, rgb(255 255 255) 56%, rgba(255,255,255,0) 56%, rgba(255,255,255,0) 100%)',
    position: 'absolute',
    color: 'rgba(0, 0, 0, 0.6)',
    padding: '0px 7px',
    fontSize: '0.75rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.00938em',
    transform: 'translate(7px, -8px)',
  },
  actionContainer: {
    '& button': {
      padding: 0,
      marginRight: 5,
      width: 24,
    },
    '& > button:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
    },
  },
  selected: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  notSelected: {
    opacity: 0.5,
    '&:hover': {
      opacity: 1,
    },
  },
  lowerColor: {
    // background: alpha(theme.palette.error.main, 0.15),
    color: theme.palette.error.main,
  },
  midColor: {
    // background: alpha(theme.palette.warning.main, 0.15),
    color: theme.palette.warning.main,
  },
  heighColor: {
    // background: alpha(theme.palette.success.main, 0.15),
    color: theme.palette.success.main,
  },
}))
const Details = withRouter((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const batch = useSelector((state) => getBatchById(state.batches, props.match.params.batchId))
  const selectedUA = useSelector((state) => state.unitaryAnalyses.globalUnitaryAnalysesSelected)
  const fetchingSelectedUA = useSelector(
    (state) => state.unitaryAnalyses.isFetchingGlobalUnitaryAnalysesSelected,
  )
  const configFeatures = useSelector((state) => state.configuration.configFeatures)
  const isFetchingConfigFeatures = useSelector(
    (state) => state.configuration.isFetchingConfigFeatures,
  )
  const isGlobalConfigsLoaded = useSelector((state) => state.global.isGlobalConfigsLoaded)
  const customerConfig = useSelector((state) => state.global.customerConfig)
  const featuresConfig = useSelector((state) => state.global.featuresConfig)
  const canEdit = useMemo(() => {
    if (featuresConfig && selectedUA?.sampleType === 'Standard') {
      const edit_calibration = featuresConfig?.content?.edit_calibration
      const param_exist = typeof edit_calibration === 'boolean'
      const getRights = canIModifyTheCalibration(batch)
      return param_exist ? edit_calibration && getRights : true
    } else {
      return true
    }
  }, [featuresConfig, selectedUA])

  const refCalibration = React.createRef()
  const contentRef = React.createRef()
  const detailRef = React.createRef()

  const [panels, setPanels] = React.useState(
    localStorage.getItem('GLOBAL_detail_panels')
      ? localStorage.getItem('GLOBAL_detail_panels').split(',')
      : ['info', 'chromato', 'calib'],
  )
  const [chromatoSize, setChromatoSize] = React.useState(338)

  const currentLanguage = i18n.language.slice(0, 2)

  useEffect(() => {
    if (selectedUA && refCalibration.current) {
      refCalibration.current.displayCalib(
        selectedUA.event,
        selectedUA.mainChannel,
        null,
        selectedUA,
      )
    }
  }, [selectedUA])

  const getGlobalScoreTitle = function () {
    if (
      isGlobalConfigsLoaded &&
      customerConfig &&
      customerConfig.content.hasOwnProperty('columnIA') &&
      currentLanguage &&
      customerConfig.content.columnIA.hasOwnProperty(currentLanguage)
    ) {
      return customerConfig.content.columnIA[currentLanguage]
    } else {
      return t('view.analysis.analysis.column.ai_score')
    }
  }

  const getListItems = function (canEdit) {
    const massConcentrationUnit = localStorage.getItem('SETTINGS_mass_concentration_unit')
      ? localStorage.getItem('SETTINGS_mass_concentration_unit')
      : 'mg/kg'
    const volumeConcentrationUnit = localStorage.getItem('SETTINGS_volume_concentration_unit')
      ? localStorage.getItem('SETTINGS_volume_concentration_unit')
      : 'µg/l'
    const initList = [
      {
        key: 'flags',
        label: t('view.analysis.analysis.column.flags'),
        type: 'custom',
        customDisplay: function (value, item) {
          return (
            <ErrorBoundaryGuard isDialog>
              <AlphacodFlagIcon item={item} hideGeneralTab={false} collection={'analyse'} />
            </ErrorBoundaryGuard>
          )
        },
      },
      {
        key: 'requested',
        label: t('view.analysis.analysis.column.requested'),
        type: 'boolean',
        customDisplay: function (value) {
          if (value === 'True') {
            return <PersonPinIcon title="Requested" className={classes.requestedIcon} />
          }
          return ''
        },
      },
      {
        key: 'modified',
        label: t('view.analysis.analysis.column.modif'),
        type: 'boolean',
        customDisplay: function (value, item) {
          return (
            <ErrorBoundaryGuard isDialog>
              <AlphacodModifiedButtonIcon item={item} />
            </ErrorBoundaryGuard>
          )
        },
      },
      {
        key: 'event',
        label: t('view.analysis.analysis.column.event'),
        type: 'number',
      },
      {
        key: 'peakName',
        label: t('view.analysis.analysis.column.peak'),
        type: 'text',
        customDisplay: function (molecule) {
          return (
            <ErrorBoundaryGuard isDialog>
              <MetaboliteBadge molecule={molecule} />
            </ErrorBoundaryGuard>
          )
        },
      },
      {
        key: 'status',
        type: 'text',
        label: t('view.analysis.analysis.column.status'),
      },
      {
        key: 'global_score',
        label: getGlobalScoreTitle(),
        type: 'number',
        size: 100,
        customDisplay: function (value, item, columnInfos) {
          const expertMode = localStorage.getItem('SETTINGS_expert_mode') === 'true'
          const returnValue =
            value && value !== '' && value !== null
              ? displayHumanReadable(value * 100, 2, 2, true) + '%'
              : value
          return expertMode ? (
            <ErrorBoundaryGuard isDialog>
              {localStorage.getItem('GLOBAL_table_color') &&
              localStorage.getItem('GLOBAL_table_color') === 'true' ? (
                <div
                  className={clsx(
                    value < 0.1
                      ? classes.lowerColor
                      : value < 0.9
                      ? classes.midColor
                      : classes.heighColor,
                  )}
                >
                  <AIScoreTableTooltip item={item} value={returnValue} />
                </div>
              ) : (
                <AIScoreTableTooltip item={item} value={returnValue} />
              )}
            </ErrorBoundaryGuard>
          ) : (
            returnValue
          )
        },
      },
      {
        key: 'validation',
        label: t('view.analysis.analysis.column.validation'),
        type: 'custom',
        customDisplay: function (value, item) {
          return (
            <div className={classes.actionContainer}>
              <IconButton
                disabled={!canEdit}
                aria-label="detected"
                onClick={(event) => {
                  handleValidation(event, item, '1', 'unitary_analysis', dispatch)
                }}
              >
                {value === '1' ? (
                  item.status === 'ok' || item.status === 'ko' ? (
                    <CheckIcon className={classes.selected} />
                  ) : (
                    <Molecule className={classes.selected} />
                  )
                ) : item.status === 'ok' || item.status === 'ko' ? (
                  <CheckIcon className={classes.notSelected} />
                ) : (
                  <Molecule className={classes.notSelected} />
                )}
              </IconButton>
              <IconButton
                disabled={!canEdit}
                aria-label="notDetected"
                onClick={(event) =>
                  handleValidation(event, item, '2', 'unitary_analysis', dispatch)
                }
              >
                {value === '2' ? (
                  item.status === 'ok' || item.status === 'ko' ? (
                    <ClearIcon className={classes.selected} />
                  ) : (
                    <Nothing className={classes.selected} />
                  )
                ) : item.status === 'ok' || item.status === 'ko' ? (
                  <ClearIcon className={classes.notSelected} />
                ) : (
                  <Nothing className={classes.notSelected} />
                )}
              </IconButton>
              <IconButton
                disabled={!canEdit}
                aria-label="relaunch"
                onClick={(event) =>
                  handleValidation(event, item, '3', 'unitary_analysis', dispatch)
                }
              >
                {value === '3' ? (
                  <Relance className={classes.selected} />
                ) : (
                  <Relance className={classes.notSelected} />
                )}
              </IconButton>
            </div>
          )
        },
      },
    ]

    if (configFeatures && typeof configFeatures.lmr === 'boolean' && configFeatures.lmr) {
      initList.push({
        key: 'lmr',
        label: t('view.analysis.analysis.column.lmr'),
        type: 'text',
      })
    }

    initList.push(
      ...[
        {
          key: 'rt',
          label: t('view.analysis.analysis.column.rt_cal'),
          type: 'number',
          customDisplay: function (value, item, columnInfos) {
            if (value && value !== '' && value !== null) return value.toFixed(1)
            return value
          },
        },
        {
          key: 'dev_ion_ratio',
          label: t('view.analysis.analysis.column.dev_ion_ratio'),
          type: 'number',
          customDisplay: function (value, item, columnInfos) {
            return value ? displayHumanReadable(value, 2, 2, true) + '%' : value
          },
        },
        {
          key: 'ion_ratio',
          label: t('view.analysis.analysis.column.ion_ratio'),
          type: 'number',
          customDisplay: function (value, item, columnInfos) {
            return value ? displayHumanReadable(value, 2, 2) : value
          },
        },
        {
          key: 'mass_conc',
          label: (
            <Trans i18nKey="view.analysis.analysis.column.conc">
              Conc({{ val: massConcentrationUnit }})
            </Trans>
          ),
          type: 'number',
          customDisplay: function (value, item, columnInfos) {
            if (value && value !== '' && value !== null) {
              const con_val = convertMassConcentrationValue(value)
              return displayHumanReadable(con_val, 4)
            }
            return value
          },
        },
        {
          key: 'amt_conc',
          label: (
            <Trans i18nKey="view.analysis.analysis.column.conc">
              Conc({{ val: volumeConcentrationUnit }})
            </Trans>
          ),
          type: 'number',
          customDisplay: function (value, item, columnInfos) {
            if (value && value !== '' && value !== null) {
              // return convertVolumeConcentrationValue(displayHumanReadable(value, 4), item)
              return convertVolumeConcentrationValue(displayHumanReadable(value, 4), item)
            }
            return value
          },
        },
        {
          key: 'mass_conc_rdt',
          label: (
            <Trans i18nKey="view.analysis.analysis.column.conc_rdt">
              Conc RDT({{ val: massConcentrationUnit }})
            </Trans>
          ),
          type: 'number',
          customDisplay: function (value, item, columnInfos) {
            if (value && value !== '' && value !== null) {
              const con_val = convertMassConcentrationValue(value)
              return displayHumanReadable(con_val, 4)
            }
            return value
          },
        },
      ],
    )

    for (
      let step = 1;
      step <=
      Number(
        localStorage.getItem('__config.json__NUMBER_CHANNEL_VISIBLE_FOR_VOLUMIC_CONCENTRATION'),
      );
      step++
    ) {
      initList.push({
        key: `amt_conc_${step}`,
        label: (
          <Trans i18nKey="view.analysis.analysis.column.conc_ch">
            Conc ch.{{ val1: step }} ({{ val2: volumeConcentrationUnit }})
          </Trans>
        ),
        type: 'number',
        customDisplay: function (value, item, columnInfos) {
          if (value && value !== '' && value !== null) {
            return convertVolumeConcentrationValue(displayHumanReadable(value, 4), item)
          }
          return value
        },
      })
    }

    initList.push(
      ...[
        {
          key: 'loq',
          label: (
            <Trans i18nKey="view.analysis.analysis.column.loq">
              LQ({{ val: volumeConcentrationUnit }})
            </Trans>
          ),
          type: 'number',
          customDisplay: function (value, item, columnInfos) {
            if (value && value !== '' && value !== null) {
              const con_val = convertVolumeConcentrationValue(value, item)
              return displayHumanReadable(con_val, 2, 2)
            }
            return value
          },
        },
        {
          key: 'area',
          label: t('view.analysis.analysis.column.area'),
          type: 'number',
          customDisplay: function (value, item, columnInfos) {
            if (value && value !== '' && value !== null) {
              return displayHumanReadable(value, 0)
            }
            return value
          },
        },
        {
          key: 'isArea',
          label: t('view.analysis.analysis.column.isArea'),
          type: 'number',
          customDisplay: function (value, item, columnInfos) {
            if (value && value !== '' && value !== null) {
              return displayHumanReadable(value, 0)
            }
            return value
          },
        },
        {
          key: 'areaRatio',
          label: t('view.analysis.analysis.column.area_ratio'),
          type: 'number',
          customDisplay: function (value, item, columnInfos) {
            if (value && value !== '' && value !== null) {
              return displayHumanReadable(value, 2, 2)
            }
            return value
          },
        },
        {
          key: 'isPeak_istdGroup',
          label: t('view.analysis.analysis.column.istd'),
          type: 'text',
        },
        {
          key: 's_n',
          label: t('view.analysis.analysis.column.s_n'),
          type: 'number',
          customDisplay: function (value, item, columnInfos) {
            if (value && value !== '' && value !== null) {
              if (value < 100) {
                return displayHumanReadable(value, 1, 1)
              } else {
                return '> 100'
              }
            }
            return value
          },
        },
        {
          key: 'level',
          label: t('view.analysis.analysis.column.level'),
          type: 'number',
        },
        {
          key: 'conc_target',
          label: (
            <Trans i18nKey="view.analysis.analysis.column.conc_target">
              Conc target({{ val: volumeConcentrationUnit }})
            </Trans>
          ),
          type: 'number',
          customDisplay: function (value, item, columnInfos) {
            if (value && value !== '' && value !== null) {
              return displayHumanReadable(convertVolumeConcentrationValue(value, item), 4)
            }
            return value
          },
        },
        {
          key: 'height',
          label: t('view.analysis.analysis.column.height'),
          type: 'number',
          customDisplay: function (value, item, columnInfos) {
            if (value && value !== '' && value !== null) {
              return displayHumanReadable(value, 2, 2)
            }
            return value
          },
        },
        {
          key: 'type',
          label: t('view.analysis.analysis.column.type'),
          type: 'text',
        },
        {
          key: 'm_z',
          label: t('view.analysis.analysis.column.m_z'),
          type: 'custom',
        },
        {
          key: 'refIons',
          label: t('view.analysis.analysis.column.refIons'),
          type: 'custom',
        },
        {
          key: 'rt_peak',
          label: t('view.analysis.analysis.column.rt_peak'),
          type: 'number',
          customDisplay: function (value, item, columnInfos) {
            if (value && value !== '' && value !== null) {
              return displayHumanReadable(value, 2, 2)
            }
            return value
          },
        },
        {
          key: 'devRrt',
          label: t('view.analysis.analysis.column.dev_ret'),
          type: 'number',
          customDisplay: function (value, item, columnInfos) {
            if (value && value !== '' && value !== null) {
              return displayHumanReadable(value, 2, 2, true) + ' %'
            }
            return value
          },
        },
        {
          key: 'devAccuracy',
          label: t('view.analysis.analysis.column.dev_accu'),
          type: 'number',
          customDisplay: function (value, item, columnInfos) {
            if (value && value !== '' && value !== null) {
              return displayHumanReadable(value, 2, 2, true) + ' %'
            }
            return value
          },
        },
      ],
    )
    return initList
  }
  const listItems = useMemo(() => {
    const list = getListItems(canEdit)
    if (!isFetchingConfigFeatures && configFeatures && list.length > 0) {
      return list
    } else {
      return []
    }
  }, [configFeatures, isFetchingConfigFeatures, canEdit])

  const onContentSplitterSizeSave = function () {
    localStorage.setItem(
      'LAYOUT_globalDetail_secondaryPaneSize',
      contentRef.current.state.secondaryPaneSize,
    )
    // this.refGridChromato.current.updateCharts()
    setChromatoSize(
      detailRef.current.container.offsetHeight -
        (detailRef.current.container.offsetHeight / 100) *
          detailRef.current.state.secondaryPaneSize -
        15,
    )
  }

  const onDetailSplitterSizeSave = function () {
    localStorage.setItem(
      'LAYOUT_globalDetail_primaryPaneSize',
      detailRef.current.state.secondaryPaneSize,
    )
    setChromatoSize(
      detailRef.current.container.offsetHeight -
        (detailRef.current.container.offsetHeight / 100) *
          detailRef.current.state.secondaryPaneSize -
        15,
    )
  }

  const handlePanel = (event, newPanels) => {
    setPanels(newPanels)
    localStorage.setItem('GLOBAL_detail_panels', newPanels)
  }

  // When the calib tab is shown after a hide period, force to display again the calib
  useEffect(() => {
    if (panels.indexOf('calib') !== -1 && selectedUA && refCalibration.current) {
      refCalibration.current.displayCalib(
        selectedUA.event,
        selectedUA.mainChannel,
        null,
        selectedUA,
      )
    }
  }, [panels])

  const panelSize = useMemo(() => {
    return props.panelWidth
  }, [props.panelWidth])

  const getSelectedChannel = function () {
    return selectedUA.selectedChannel
  }
  const onChangeChannel = function (event, ua, mainChannel) {
    dispatch(fetchChangeSelectedChannel(ua._id, mainChannel, 'analyse'))
      .then
      // this.setState({ tableItemSelectedForColor: ua._id }),
      // this.refGridChromato.current.setGridItemSelectedForColor(ua._id),
      // this.refCalibration.current.displayCalib(ua.event, mainChannel, null, ua),
      ()
  }

  return !batch ? (
    <Loading mode="alpha" size={10} />
  ) : !selectedUA ? (
    <Typography className={classes.noItem} variant="h5" noWrap>
      {t('view.common.calibration.no_item')}
      <Box>
        <SelectAllIcon style={{ fontSize: 40 }} />
      </Box>
    </Typography>
  ) : (
    <Fragment>
      <ErrorBoundaryGuard>
        <Title>
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="space-between"
            style={{ marginTop: 0, marginBottom: 14, paddingRight: 16 }}
          >
            <Grid item style={{ paddingBottom: 0, width: 'calc(100% - 145px)' }}>
              <Typography className={classes.title} variant="h5" noWrap>
                {selectedUA.name + ' (' + selectedUA.analyse + ')'}
              </Typography>
            </Grid>
            <Grid item style={{ paddingBottom: 0, height: 50 }}>
              <ToggleButtonGroup
                color="primary"
                variant="outlined"
                value={panels}
                onChange={handlePanel}
                aria-label="text formatting"
                size="small"
              >
                <ToggleButton color="primary" variant="outlined" value="info">
                  <NotesIcon />
                </ToggleButton>
                <ToggleButton color="primary" variant="outlined" value="chromato">
                  <ChromatoSettings />
                </ToggleButton>
                <ToggleButton color="primary" variant="outlined" value="calib">
                  <TimelineIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </Title>
      </ErrorBoundaryGuard>
      <SplitterLayout
        vertical
        percentage
        customClassName={classes.mainContainerSplitter}
        ref={contentRef}
        onDragEnd={onContentSplitterSizeSave}
        secondaryInitialSize={
          localStorage.getItem('LAYOUT_globalDetail_secondaryPaneSize')
            ? parseFloat(localStorage.getItem('LAYOUT_globalDetail_secondaryPaneSize'))
            : 33
        }
      >
        {panels.indexOf('info') !== -1 &&
          (!selectedUA ? (
            <Typography className={classes.noItem} variant="h5" noWrap>
              {t('view.common.calibration.no_item')}
              <Box>
                <SelectAllIcon style={{ fontSize: 40 }} />
              </Box>
            </Typography>
          ) : fetchingSelectedUA ? (
            <Loading mode="miniCircle" />
          ) : (
            <Grid container spacing={2} style={{ height: '100%' }}>
              <Grid
                item
                xs={12}
                style={{
                  height: 'calc(100% - 10px)',
                  marginTop: 10,
                  marginRight: 27,
                  overflowY: 'auto',
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    {!isFetchingConfigFeatures &&
                      listItems.map((item) => (
                        //       key: 'rt',
                        //     label: t('view.analysis.analysis.column.rt_cal'),
                        //   type: 'number',
                        //   customDisplay: function (value, item, columnInfos) {
                        //   if (value && value !== '' && value !== null)
                        //   return customFormatDecimals(value, value.toFixed(1), columnInfos)
                        //   return value
                        // }
                        <Grid item xs={panelSize}>
                          {item.customDisplay ? (
                            <div
                              style={{
                                width: '100%',
                                height: 40,
                                background: 'white',
                                position: 'relative',
                              }}
                            >
                              <div className={classes.colorPickerCnt} style={{ width: '100%' }}>
                                <Grid
                                  container
                                  spacing={2}
                                  justify="center"
                                  alignItems="center"
                                  className={classes.root}
                                  style={{ margin: 10 }}
                                >
                                  {item.customDisplay(selectedUA[item.key], selectedUA)}
                                </Grid>
                                <div className={classes.colorPickerTitle}>{item.label}</div>
                              </div>
                            </div>
                          ) : (
                            <TextField
                              fullWidth
                              InputProps={{
                                readOnly: true,
                              }}
                              size="small"
                              label={item.label}
                              variant="outlined"
                              value={selectedUA[item.key]}
                            />
                          )}
                        </Grid>
                      ))}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          ))}
        <SplitterLayout
          vertical
          percentage
          customClassName={classes.containerSplitter}
          ref={detailRef}
          onDragEnd={onDetailSplitterSizeSave}
          secondaryInitialSize={
            localStorage.getItem('LAYOUT_globalDetail_primaryPaneSize')
              ? parseFloat(localStorage.getItem('LAYOUT_globalDetail_primaryPaneSize'))
              : 50
          }
        >
          {panels.indexOf('chromato') !== -1 &&
            (!selectedUA ? (
              <Typography className={classes.noItem} variant="h5" noWrap>
                {t('view.common.calibration.no_item')}
                <Box>
                  <SelectAllIcon style={{ fontSize: 40 }} />
                </Box>
              </Typography>
            ) : fetchingSelectedUA ? (
              <Loading mode="miniCircle" />
            ) : (
              <ErrorBoundaryGuard>
                <ChromatoGraph
                  getSelectedChannel={getSelectedChannel}
                  key={`chromato_G`}
                  uidKey={`chromato_G`}
                  unitaryAnalyse={selectedUA}
                  selected={true}
                  canEdit={canEdit}
                  style={{
                    height: 'calc(100% - 10px)',
                    width: 'calc(100% - 10px)',
                    margin: '5px 0px',
                    position: 'absolute',
                    background: 'transparent',
                  }}
                  chromatoSize={chromatoSize}
                  onClick={() => true}
                  onDoubleClick={() => true}
                  dispatch={dispatch}
                  title={(UA) => ''}
                  collection={'analyse'}
                  onChangeChannel={onChangeChannel}
                />
              </ErrorBoundaryGuard>
            ))}
          {panels.indexOf('calib') !== -1 &&
            (fetchingSelectedUA ? (
              <Loading mode="miniCircle" />
            ) : (
              <ErrorBoundaryGuard>
                <Calibration
                  batch={batch}
                  wrappedComponentRef={refCalibration}
                  collection={'analyse'}
                  options={{ displayTitle: false, displayPaper: false }}
                />
              </ErrorBoundaryGuard>
            ))}
        </SplitterLayout>
      </SplitterLayout>
    </Fragment>
  )
})
export default Details
